import React from 'react';
import { navigate } from '@reach/router';
import GoogleMapReact from 'google-map-react';
import { Typography } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Room from '@material-ui/icons/Room';

import strings from 'common/strings';
import permissions from 'common/permissions';
import ApiError from 'components/shared/ApiError';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import PaneHeader from 'components/shared/PaneHeader';
import { vehicleDetailsBuilder } from 'navigation/routes';
import { usePermissions, useWindowSize } from 'hooks';

import './Location.scss';
import { testIds } from 'common/testIds';
import { useVehicleLocation } from 'api';

interface LocationProps {
  vehicleId: string;
}

const Location = ({ vehicleId }: LocationProps) => {
  const windowSize = useWindowSize();
  const {
    data: { data } = {},
    isLoading,
    error,
  } = useVehicleLocation(vehicleId);
  const location = data?.[0]?.location;

  const handleVDPNavigation = () => {
    // TODO - this function is duplicated in multiple components
    navigate(vehicleDetailsBuilder(vehicleId!, true), {
      replace: true,
    });
  };

  const renderLoadingIndicator = () => <LoadingIndicator />;
  const { hasPermission } = usePermissions();

  const renderLocation = () => {
    if (hasPermission(permissions.INVENTORY_VDP_LOCATION_VIEW) && location) {
      const mapDivProps = {
        lat: location.latitude,
        lng: location.longitude,
      };
      return (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAP_API_KEY || '',
          }}
          defaultCenter={{ lat: location.latitude, lng: location.longitude }}
          defaultZoom={20}
          options={(map) => ({ mapTypeId: map.MapTypeId.SATELLITE })}
        >
          <div {...mapDivProps} className="location-marker">
            <Room className="location-marker-icon-shadow" />
            <Room className="location-marker-icon-outline" />
            <Room className="location-marker-icon" color="primary" />
          </div>
        </GoogleMapReact>
      );
    }

    return (
      <Typography className="text-center full-height flex-rows align-center justify-center">
        {strings.LOCATION_EMPTY_SCREEN}
      </Typography>
    );
  };

  return (
    <Grid
      data-vas-testing={testIds.LOCATION_CONTAINER_GRID}
      container
      direction="column"
      className="location-container Location-flat"
      wrap="nowrap"
    >
      {!windowSize.isMobileViewport() && (
        <PaneHeader
          title={strings.LOCATION}
          onBackButtonClick={handleVDPNavigation}
        />
      )}
      <ApiError error={error} />
      {isLoading ? renderLoadingIndicator() : renderLocation()}
    </Grid>
  );
};

export default Location;
