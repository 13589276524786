import React from 'react';
import { navigate, Router, WindowLocation, Redirect } from '@reach/router';
import { VehicleSummary } from 'models';

import strings from 'common/strings';
import VehicleDetails2 from 'components/pages/VehicleDetails';
import Location from 'components/pages/Location';
import FlatMedia from 'components/pages/FlatMedia';
import Notes2 from 'components/pages/Notes/Notes';
import FlatTasks from 'components/pages/FlatTasks';
import MultipointInspection from 'components/pages/MultipointInspection';
import Recalls from 'components/pages/Recalls';
import FlatHistory from 'components/pages/History';
import ReconVelocityOverview from 'components/pages/ReconVelocityOverview';
import VelocityEngage from 'components/pages/VelocityEngage';
import RepairOrder from 'components/pages/RepairOrder';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { useVehicleSummary } from 'api';
import { PinnableComponentType } from 'components/pages/VehicleDetails/PinnedComponent';
import Alert from '../components/shared/Alert';
import permissions from 'common/permissions';

import {
  RECALLS_RELATIVE,
  MPI_RELATIVE,
  NOTES_RELATIVE,
  RECON_INFO_RELATIVE,
  TASKS_RELATIVE,
  VEHICLE_INFO_RELATIVE,
  LOCATION_RELATIVE,
  MEDIA_RELATIVE,
  INDEX,
  HISTORY_RELATIVE,
  OVERVIEW_RELATIVE,
  RECON_VELOCITY_OVERVIEW_RELATIVE,
  ENGAGE_RELATIVE,
  INVENTORY_RELATIVE,
  REPAIR_ORDERS_RELATIVE,
} from './routes';
import { useCurrentLocationId, usePermissions } from 'hooks';

const FLAT_SECTIONS: Record<string, any> = {
  [OVERVIEW_RELATIVE]: ReconVelocityOverview,
  [ENGAGE_RELATIVE]: VelocityEngage,
  [VEHICLE_INFO_RELATIVE]: ReconVelocityOverview,
  [NOTES_RELATIVE]: Notes2,
  [RECON_INFO_RELATIVE]: ReconVelocityOverview,
  [TASKS_RELATIVE]: FlatTasks,
  [MPI_RELATIVE]: MultipointInspection,
  [LOCATION_RELATIVE]: Location,
  [MEDIA_RELATIVE]: FlatMedia,
  [RECALLS_RELATIVE]: Recalls,
  [HISTORY_RELATIVE]: FlatHistory,
  [RECON_VELOCITY_OVERVIEW_RELATIVE]: ReconVelocityOverview,
  [REPAIR_ORDERS_RELATIVE]: RepairOrder,
};
interface VDPRouterProps {
  location?: WindowLocation;
  path?: string;
  vehicle: VehicleSummary;
  setCustomHeaderTitle?: (title: string) => void;
  setBackAction?: (backAction: () => void) => void;
  mobileVDPInfoHeight?: number;
  rooftopId?: string;
  vehicleId?: string;
  openPinnedComponent?: (componentName: PinnableComponentType) => void;
}

export const FlatVDP: React.FC<React.PropsWithChildren<VDPRouterProps>> = ({
  location,
  path,
  rooftopId,
  vehicleId,
  ...otherProps
}) => {
  const { hasPermission } = usePermissions();
  return (
    <Router className="white-background vertical-scroll full-height-calc">
      <VehicleDetails2 path={INDEX} showCloseButton={true} {...otherProps} />
      <VehicleDetailsSection path=":sectionName/*" {...otherProps} />
      {!hasPermission(permissions.RECON_VIEW) &&
        hasPermission(permissions.PLUGIN_VELOCITYENGAGE_VIEW) && (
          <Redirect
            from="/overview/*"
            to={`/${rooftopId}/${INVENTORY_RELATIVE}/${vehicleId}/${ENGAGE_RELATIVE}`}
            noThrow
          />
        )}
      {!hasPermission(permissions.RECON_VIEW) && (
        <Redirect
          from="/overview/*"
          to={`/${rooftopId}/${INVENTORY_RELATIVE}/${vehicleId}/${NOTES_RELATIVE}`}
          noThrow
        />
      )}
    </Router>
  );
};

interface VehicleDetailsSectionProps {
  location?: WindowLocation;
  path?: string;
  sectionName?: string;
  vehicle: VehicleSummary;
  openPinnedComponent?: (componentName: PinnableComponentType) => void;
}

export const VehicleDetailsSection = (props: VehicleDetailsSectionProps) => {
  const { sectionName, vehicle, ...otherProps } = props;
  const { hasPermission } = usePermissions();
  if (!sectionName) return null;
  if (
    !hasPermission(permissions.RECON_VIEW) &&
    sectionName === OVERVIEW_RELATIVE
  )
    return null;
  if (
    !hasPermission(permissions.RECON_VIEW) &&
    sectionName === RECON_VELOCITY_OVERVIEW_RELATIVE
  )
    return null;

  const Section = FLAT_SECTIONS[sectionName];
  return (
    <Section
      {...otherProps}
      vehicle={vehicle} // TODO remove vehicle since only vehicleId is only needed
      vehicleId={vehicle.vehicleCard.id || ''}
      shouldFocus={sectionName === NOTES_RELATIVE}
    />
  );
};

interface VehicleDetailsRouterProps {
  location?: WindowLocation;
  path?: string;
  vehicleId?: string;
  rooftopId?: string;
}

const VehicleDetailsRouter: React.FC<
  React.PropsWithChildren<VehicleDetailsRouterProps>
> = (props) => {
  const { location, path, vehicleId } = props;
  const locationId = useCurrentLocationId();
  const { data, isError } = useVehicleSummary(
    vehicleId === 'background' ? undefined : vehicleId
  );

  const goBackToSRP = async () => {
    await navigate(`/${locationId}`);
  };

  if (vehicleId && data?.data) {
    return (
      <VehicleDetails2
        path={path}
        location={location}
        vehicle={data.data}
        showCloseButton={true}
      />
    );
  }

  return (
    <>
      <Alert
        open={isError}
        contentProps={{
          message: strings.VEHICLE_NOT_FOUND,
          variant: 'error',
          onClose: goBackToSRP,
        }}
      />
      {!isError && <LoadingIndicator size={80} />}
    </>
  );
};

export default VehicleDetailsRouter;
