import React, { useState } from 'react';
import { Row, Col, Accordion, Card } from 'react-bootstrap';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { getRelativeCalendarWithTime } from 'utils/time';
import './RecallItem.scss';
import Alert from 'components/shared/icons/Alert';
import strings from 'common/strings';

const RecallItems: React.FC<React.PropsWithChildren<any>> = ({
  name,
  id,
  remedy,
  nhtsaId,
  description,
  issuedDate,
  responsive = true,
}) => {
  const [isOpen, setToggle] = useState(false);
  const toggleIcon = () => setToggle(!isOpen);

  const largeColumnSize = responsive ? '6' : '12';

  return (
    <Accordion>
      <Card className="RecallItem-card mb-2">
        <Accordion.Toggle eventKey={id} onClick={() => toggleIcon()}>
          <div className="RecallItem-header">
            <div className="RecallItem-warning-icon">
              <Alert size={26} color="#263238" />
            </div>
            <div className="RecallItem-title-container">
              <div className="RecallItem-header-label">
                {strings.RECALL_TITLE_LABEL}
              </div>
              <div className="RecallItem-header-value">
                {`${nhtsaId ? `${nhtsaId} - ` : ''}${name}`}
              </div>
            </div>
            <div className="icon-col">
              {isOpen ? (
                <KeyboardArrowUp fontSize="small" />
              ) : (
                <KeyboardArrowDown fontSize="small" />
              )}
            </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={id}>
          <Card.Body className="RecallItem-body">
            <Row>
              <Col md="12">
                <div className="RecallItem-body-label">
                  {strings.DESCRIPTION}
                </div>
                <p>{description}</p>
              </Col>
              <Col md="12" lg={largeColumnSize}>
                <div className="RecallItem-body-label">{strings.REMEDY}</div>
                <p>{remedy}</p>
              </Col>
              <Col md="12" lg={largeColumnSize}>
                <div className="RecallItem-body-label">{strings.ISSUED}</div>
                <p>{getRelativeCalendarWithTime(issuedDate)}</p>
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default RecallItems;
