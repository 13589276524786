export const generateSelectorId = (testId: string) =>
  `[data-vas-testing="${testId}"]`;
export const generatedSelectorClassName = (testId: string) =>
  `[class="${testId}"]`;
export const generatedSelectorById = (testId: string) => `#${testId}`;
export const generateCssClassSelector = (testId: string) => `.${testId}`;

export function generateTestId(
  message: string,
  params?: Record<string, string>
): string {
  let newMessage = message;
  if (params) {
    Object.keys(params).forEach((key) => {
      const value = params[key];
      if (!value) return;
      newMessage = newMessage.replace(`{${key}}`, value.toLowerCase());
    });
  }
  return newMessage;
}

export const testIds = {
  LOGIN_ERROR_MESSAGE_ALERT: 'login-error-text',

  ACTIVE_RECON_OVERVIEW_CONTAINER: 'active-recon-overview-container',
  ACTIVE_RECON_PREVIEW_PANEL_CONTAINER: 'active-recon-preview-panel-container',
  INVENTORY_VEHICLE_LIST: 'inventory-vehicle-list',

  DASHBOARD_CONTAINER: 'dashboard-main-container',
  HIGHLIGHTS_ACTIVE_RECON_CONTAINER:
    'dashboard-highlights-active-recon-container',
  HIGHLIGHTS_VEHICLES_IN_RECON_GRAPH_CONTAINER:
    'dashboard-highlights-vehicles-in-recon-graph-container',
  ASSIGNMENT_VEHICLE_CARD: 'dashboard-vehicle-card-{position}-container',
  DASHBOARD_ASSIGNMENT_CONTAINER: 'dashboard-assignment-container',
  DASHBOARD_ASSIGNMENT_EMPTY_STATE: 'dashboard-assignment-empty',
  DASHBOARD_OVERVIEW_VEHICLES_IN_RECON_CONTAINER:
    'summary-card-vehicles-in-recon',
  DASHBOARD_OVERVIEW_AVERAGE_RETAIL_CONTAINER:
    'summary-card-average-retail-ready-time',
  DASHBOARD_OVERVIEW_AVERAGE_RECON_TIME_CONTAINER:
    'summary-card-average-recon-time',
  DASHBOARD_OVERVIEW_AVERAGE_APPROVAL_TIME_CONTAINER:
    'summary-card-average-approval-time',

  FILTERS_TAB: 'Sidebar-tabs-filter-tab',
  FILTER_CHIPS_CLEAR_BUTTON: 'inventory-filters-clear-button',
  FILTER_CHIP_ELEMENT: 'inventory-filters-{value}-chip',
  FILTER_MULTI_SELECT_OPTION:
    'inventory-filters-{filterOptionId}-multi-select-item',
  DROPDOWN_CONTAINER: 'dropdown-{optionContainerId}-container',
  DROPDOWN_OPTION_ITEM: 'dropdown-{optionItemId}-item',

  INVENTORY_SRP_CARD_VIN: 'inventory-srp-{position}-vin-text',
  INVENTORY_CARD_ENGAGE_CONTAINER: 'inventory-card-{position}-engage-container',

  // Preview Panel
  PREVIEW_PANEL_STOCK_NUMBER: 'inventory-preview-panel-stocknumber-text',
  PREVIEW_PANEL_VIN: 'inventory-preview-panel-vin-text',
  PREVIEW_PANEL_VIEW_VEHICLE: 'inventory-preview-panel-view-vehicle-button',

  // VDP MENU ITEMS
  VDP_MENU_ITEM: 'vdp-menu-item-{label}',
  RECONVELOCITY_VDP_MENU_ITEM: 'vdp-menu-item-velocity recon',
  VELOCITY_ENGAGE_VDP_MENU_ITEM: 'vdp-menu-item-velocity engage',
  NOTE_VDP_MENU_ITEM: 'vdp-menu-item-notes',
  INSPECTION_VDP_MENU_ITEM: 'vdp-menu-item-inspection',
  RECALL_VDP_MENU_ITEM: 'vdp-menu-item-recalls',
  LOCATION_VDP_MENU_ITEM: 'vdp-menu-item-location',
  TASK_VDP_MENU_ITEM: 'vdp-menu-item-tasks',
  MEDIA_AND_DOCS_VDP_MENU_ITEM: 'vdp-menu-item-media & docs',
  HISTORY_VDP_MENU_ITEM: 'vdp-menu-item-history',

  // Engage Header Buttons
  VDP_ENGAGE_VEHICLE_SEND_BUTTON: 'vdp-engage-vehicle-send-button',
  VDP_ENGAGE_VEHICLE_VIEW_BUTTON: 'vdp-engage-vehicle-view-button',

  // Velocity Engage SendVehicleModal Page
  SEND_VEHICLE_MODAL_TITLE: 'send-vehicle-modal-title',
  FROM_USER_DROPDOWN: 'from-logged-in-user',
  SEND_TO_SHOPPER_DROPDOWN: 'select-shopper-to-send-to',
  FROM_USER_DROPDOWN_DISABLED: 'logged-in-user-disabled',
  FROM_USER_DROPDOWN_LOGGED_IN_USER: 'logged-in-user-name',
  SEND_VEHICLE_MODAL_SUBMIT_BUTTON: 'send-vehicle-submit-button',
  SEND_VEHICLE_MODAL_CANCEL_BUTTON: 'send-vehicle-cancel-button',
  ADD_SHOPPER_BUTTON: 'add-shopper-button',

  // SELECT ROOFTOP USERS
  ROOFTOP_DROPDOWN: 'rooftop',
  STEP_DROPDOWN: 'step',
  STEP_DROPDOWN_CONTAINER: 'dropdown-step-container',
  ASSIGNEE_DROPDOWN: 'assignee',
  ASSIGNEE_DROPDOWN_CONTAINER: 'dropdown-assignee-container',

  // ENGAGE ADDSHOPPER PAGE
  ADD_SHOPPER_FIRST_NAME: 'add-shopper-firstName',
  ADD_SHOPPER_LAST_NAME: 'add-shopper-lastName',
  ADD_SHOPPER_EMAIL: 'add-shopper-email-address',
  ADD_SHOPPER_PHONE: 'add-shopper-phone-number',
  ADD_SHOPPER_ADDRESS1: 'add-shopper-address-one',
  ADD_SHOPPER_CITY: 'add-shopper-city',
  ADD_SHOPPER_ZIP: 'add-shopper-zipcode',
  ADD_SHOPPER_ADDRESS2: 'add-shopper-address-two',
  ADD_SHOPPER_STATE: 'add-shopper-state',
  ADD_SHOPPER_SUBMIT_BUTTON: 'add-shopper-submit-button',
  ADD_SHOPPER_CANCEL_BUTTON: 'add-shopper-cancel-button',

  INVENTORY_SEARCH_INPUT: 'inventory-search-input',
  INVENTORY_SEARCH_CLEAR_ICON: 'inventory-search-clear-icon',

  // SHOWROOM VEHICLE CARDS
  SHOWROOM_MODE_VIEW_BUTTON: 'showroom-engage-{position}-view-button',
  SHOWROOM_MODE_SEND_BUTTON: 'showroom-engage-{position}-send-button',

  // VDP MENU TASKS FEATURES
  TASKS_ICON_BUTTON_PRIMARY: 'task-{variant}-icon-button',
  TASKS_CONTAINER_SECTION_TITLE: 'task-{variant}-title',
  TASKS_CONTAINER_SECTION: 'tasks-{variant}-container-section',
  TASKS_ESTIMATES_HEADER_SELECT_CHECKBOX:
    'tasks-estimates-header-select-checkbox',
  TASKS_SECTION_HEADER_CHECKBOX: 'tasks-{variant}-header-select-checkbox',
  SELECT_TASK_TYPE_CLASSNAME_PREFIX_CSS:
    'FlatTask-{variant}-dropdown-{position}',
  SELECT_TASK_TYPE_DROPDOWN_CSS: 'FlatTask-option',

  TASK_ROW_SELECT_TASK_TYPE_TEXT: 'tasks-{variant}-select-task_type-{position}',
  TASK_ROW_CUSTOM_NAME_TEXT: 'tasks-{variant}-custom-name-{position}-text',
  TASK_ROW_DESCRIPTION_TEXT: 'tasks-{variant}-description-{position}-text',
  TASKS_ROW_SELECT_ASSIGNEE_TEXT: 'tasks-{variant}-select-assignee-{position}',
  TASK_ROW_PARTS_COST_TEXT: 'tasks-{variant}-partsCost-{position}-text',
  TASK_ROW_LABOR_COST_TEXT: 'tasks-{variant}-laborCost-{position}-text',
  TASK_ROW_TOTAL_COST_TEXT: 'tasks-{variant}-totalCost-{position}-text',
  TASK_ROW_DELETE_BUTTON: 'tasks-{variant}-delete-{position}-button',
  SELECT_TASK_ASSIGNEE_CLASSNAME_PREFIX_CSS:
    'FlatTask-{variant}-dropdown-{position}',

  TASKS_CONTAINER_PARTS_PRICE: 'tasks-{variant}-parts-price',
  TASKS_CONTAINER_LABOR_PRICE: 'tasks-{variant}-labor-price',
  ESTIMATES_SECTION_TASKS_SELECTED_TOTAL:
    'estimates-section-tasks-selected-total',
  ESTIMATE_SECTION_REQUEST_APPROVAL_BUTTON:
    'estimate-section-request-approval-button',
  ESTIMATE_SECTION_APPROVE_BUTTON: 'estimate-section-approve-button',
  ESTIMATE_SECTION_ADD_BUTTON: 'estimate-section-add-button',
  REQUESTS_SECTION_APPROVE_BUTTON: 'requests-section-approve-button',
  REQUESTS_SECTION_DENY_BUTTON: 'requests-section-deny-button',
  TODO_SECTION_ADD_BUTTON: 'todo-section-add-button',

  TASKS_CONTAINER_TOTAL_PRICE: 'tasks-{variant}-total-price',
  TASKS_ESTIMATES_TOTAL_PRICE: 'tasks-estimates-total-price',
  TASKS_ESTIMATES_PARTS_COST: 'tasks-estimates-parts-price',
  TASKS_ESTIMATES_LABOR_COST: 'tasks-estimates-labor-price',

  // Labor Cost Calculator Modal
  LABOR_RATE_MODAL_INPUT_ID: 'labor-rate-modal-text-input',
  LABOR_RATE_MODAL_HOURS_INPUT_ID: 'labor-rate-modal-hours-input',
  LABOR_RATE_MODAL_TOTAL_INPUT_ID: 'labor-rate-modal-total-input',
  LABOR_RATE_MODAL_TITLE_CSS: 'modal-title',
  MODAL_PRIMARY_BUTTON: '{variant}-button',

  TASK_ROW_CONTAINER_VIEW: 'tasks-{variant}-container-{position}-view',
  TASK_ROW_CHECKBOX: 'tasks-{variant}-select-{position}-checkbox',

  // ENGAGE PRICE HISTORY MODAL
  PRICE_HISTORY_MODAL_DATE_HEADER: 'price_history_modal_date_header',
  PRICE_HISTORY_MODAL_START_PRICE_HEADER:
    'price_history_modal_start_price_header',
  PRICE_HISTORY_MODAL_NEW_PRICE_HEADER: 'price_history_modal_new_price_header',

  ADD_VEHICLE_STOCK_NUMBER_CSS: 'add-vehicle-stock-number',
  ADD_VEHICLE_VIN_CSS: 'add-vehicle-vin',
  ADD_VEHICLE_COLOR_CSS: 'add-vehicle-color',
  ADD_VEHICLE_MILEAGE_CSS: 'add-vehicle-mileage',
  ADD_VEHICLE_APPRAISED_VALUE_CSS: 'add-vehicle-appraised-value',
  ADD_VEHICLE_RECON_COST_CSS: 'add-vehicle-recon-cost',
  ADD_VEHICLE_IS_IN_RECON_CSS: 'add-vehicle-is-in-recon',
  ADD_VEHICLE_MODAL_SAVE_BUTTON_ID: 'modalPrimaryButton',
  ADD_VEHICLE_MODAL_TITLE_CSS: 'modal-title',
  ADD_VEHICLE_YEAR_CSS: 'add-vehicle-year',
  ADD_VEHICLE_MAKE_CSS: 'add-vehicle-make',
  ADD_VEHICLE_MODEL_CSS: 'add-vehicle-model',

  TOGGLE_SIDEBAR_CSS: 'toggle-sidebar',

  RECONVELOCITY_PROGRESS_SECTION_CONTAINER:
    'reconvelocity-progress-section-container',
  RECONVELOCITY_PROGRESS_SECTION_CHART:
    'reconvelocity-progress-section-container',
  RECON_PROGRESS_STEP_BUTTON_CONTAINER: 'recon-progress-step-continue-button',
  RECON_INFO_SECTION_CONTAINER: 'recon-info-section-container',
  RECON_INFO_SECTION_TITLE_CONTAINER: 'recon-info-section-title-container',
  RECON_INFO_SECTION_DATA_CONTAINER: 'recon-info-section-data-container',
  RECON_STEP_PROGRESS_SECTION_CONTAINER:
    'recon-step-preogress-section-container',
  RECON_INFO_ROW_TITLE: 'recon-info-row-{title}',

  // VDP MENU ITEM NOTES CONTAINER
  NOTES_ATTACHMENT_INPUT_CONTAINER: 'note-attachment-input-container',
  NOTES_ATTACHMENT_ICON_BUTTON: 'note-attachment-icon-button',
  NOTES_MENTIONABLE_INPUT_CONTAINER: 'notes-mentionable-input-container',

  // VDP MENU ITEM INSPECTION CONTAINER
  INSPECTION_CONTAINER_TITLE: 'inspection-container-title',
  CONTAINER_TITLES: 'vdp-{title}-container-title',

  // VDP MENU ITEM RECALLS CONTAINER
  RECALLS_CONTAINER: 'recalls-container',

  // VDP MENU ITEM REPAIR ORDER CONTAINER
  REPAIR_ORDER_CONTAINER: 'repair-order-container',

  // VDP MENU ITEM LOCATION CONTAINER
  LOCATION_CONTAINER_GRID: 'location-container-grid',

  // VDP MENU ITEM MEDIA AND DOCS CONTAINER CONTENTS
  MEDIA_AND_DOCS_CONTAINER_TITLE: 'media&docs-container-title',
  MEDIA_UPLOAD_BUTTON_ICON: 'media-upload-button-icon',
  DOCUMENTS_SECTION_UPLOAD_BUTTON_ICON: 'document-upload-button-icon',
  MEDIA_TABS_CONTAINER: 'media-tabs-container',
  MEDIA_TABS: 'media-{title}-tab',
  MEDIA_GALLERY_VEHICLE_IMAGE_CONTAINER:
    'media-gallery-vehicle-image-container',
  MEDIA_SYNDICATION_MODAL_UPLOAD_CONTAINER:
    'syndication-modal-upload-container',
  SYNDICATION_MODAL_SELECT_FILE_BUTTON: 'syndication-modal-slect-file-button',
  SYNDICATION_MODAL_TEXT: 'syndication-modal-text',
  SYNDICATION_MODAL_ASSIGN_BUTTON: 'assign-button',
  SYNDICATION_MODAL_CANCEL_BUTTON: 'cancel-button',

  // VDP MENU ITEM HISTORTY CONTAINER
  VDP_HISTORY_CONTAINER: 'vdp-history-container',
};
