import React from 'react';
import { Container } from 'react-bootstrap';
import { navigate } from '@reach/router';

import strings from 'common/strings';
import permissions from 'common/permissions';
import ApiError from 'components/shared/ApiError';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import PaneHeader from 'components/shared/PaneHeader';
import { usePermissions, useWindowSize } from 'hooks';
import { Pin } from 'components/shared/icons';
import { vehicleDetailsBuilder } from 'navigation/routes';
import { testIds } from 'common/testIds';
import { useVehicleRecalls } from 'api';
import RecallItem from './RecallItem';

import './Recalls.scss';
import { PinnableComponentType } from '../VehicleDetails/PinnedComponent';

interface RecallsProps {
  vehicleId: string;
  responsive?: boolean;
  openPinnedComponent?: (componentName: PinnableComponentType) => void;
}

const Recalls: React.FC<React.PropsWithChildren<RecallsProps>> = ({
  vehicleId,
  responsive = true,
  openPinnedComponent,
}) => {
  const {
    data: { data } = {},
    isError,
    isLoading,
  } = useVehicleRecalls(vehicleId);
  const handleVDPNavigation = () => {
    navigate(vehicleDetailsBuilder(vehicleId, true), {
      replace: true,
    });
  };
  const windowSize = useWindowSize();
  const { hasPermission } = usePermissions();

  return (
    <Container fluid className="Recalls full-height">
      <div
        className="flex-rows full-height"
        data-vas-testing={testIds.RECALLS_CONTAINER}
      >
        {!windowSize.isMobileViewport() && (
          <PaneHeader
            title={strings.RECALLS}
            onBackButtonClick={handleVDPNavigation}
          >
            {windowSize.isVdpLargeViewport() && openPinnedComponent && (
              <button
                type="button"
                className="Recalls-pin-button"
                onClick={() => openPinnedComponent('recalls')}
              >
                <Pin size={35} />
              </button>
            )}
          </PaneHeader>
        )}
        <ApiError error={isError} />
        <Container className="flex-grow vertical-scroll">
          {isLoading ? (
            <LoadingIndicator />
          ) : hasPermission(permissions.INVENTORY_VDP_RECALLS_VIEW) &&
            data?.length ? (
            <>
              {data?.map((recall: any) => (
                <RecallItem
                  {...recall}
                  key={recall.id}
                  responsive={responsive}
                />
              ))}
            </>
          ) : (
            <div className="Recalls-empty text-center full-height flex-rows align-center justify-center">
              {hasPermission(permissions.INVENTORY_VDP_RECALLS_VIEW)
                ? strings.RECALLS_EMPTY
                : strings.RECALLS_PERMISSION_DENIED}
            </div>
          )}
        </Container>
      </div>
    </Container>
  );
};

export default Recalls;
