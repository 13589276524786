import { Attachment } from 'models';
import { VehicleImageShots } from '../utility/vehicleImageShot';

export enum VehicleImageType {
  STOCK = 'STOCK',
  DEALER = 'DEALER',
  IMS = 'IMS',
  UNKNOWN = 'UNKNOWN',
  ALL = 'ALL',
}

export interface VehicleImage {
  id?: string;
  backgroundColor?: string;
  color?: string;
  heightPixels: number;
  widthPixels: number;
  imageSource: string;
  imageType?: VehicleImageType;
  shot?: VehicleImageShots;
  uri: string;
  name?: string;
  createdAt?: string;
}

export interface VehicleImageShot {
  name: string;
  sortOrder: number;
  value: string;
}

export function convertTaskAttachmentsToVehicleImage(
  attachments: Attachment[]
): VehicleImage[] {
  return attachments.map((attachment) => ({
    id: attachment.id,
    backgroundColor: '',
    color: '',
    heightPixels: 0,
    widthPixels: 0,
    imageSource: '',
    imageType: VehicleImageType.UNKNOWN,
    shot: VehicleImageShots.UNKNOWN,
    uri: attachment.uri,
    name: attachment.name,
    createdAt: attachment.createdDate,
  }));
}
