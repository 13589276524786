import React, { useRef, useState, useEffect } from 'react';
import { Star } from '@material-ui/icons';
import { FolioFolder, VelocityEngageFolioFolderDocument } from 'models';

import { TabProps } from 'components/shared/Tabs/Tabs';
import strings from 'common/strings';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import Alert from 'components/shared/Alert';

import MediaContainer from './MediaContainer';
import {
  useFolio,
  useDeleteFolioDocument,
  useUpdateFolioDocumentUrl,
} from 'api';

import './VelocityEngageMediaTab.scss';
import CarfaxModalProvider from './CarfaxModalProvider';

var Divider = () => {
  return <div className="Divider" />;
};

interface VelocityEngageMediaTabProps extends TabProps {
  vehicleId: string;
}

const VelocityEngageMediaTab: React.FC<VelocityEngageMediaTabProps> = ({
  vehicleId,
}) => {
  const { data, isLoading } = useFolio(vehicleId);
  const { mutateAsync: deleteFolioDocument } = useDeleteFolioDocument();
  const { mutateAsync: updateFolioDocumentUrl } = useUpdateFolioDocumentUrl();
  const [folioFolders, setFolioFolders] = useState<FolioFolder[]>([]);
  const [error, setError] = useState('');
  const scrollRef = useRef<HTMLDivElement>(null);
  const lastFolderIndex = folioFolders.length - 1;
  useEffect(() => {
    if (data?.data?.folders) {
      setFolioFolders(data?.data?.folders);
    }
  }, [data]);

  const onDeleteDocument = async (
    document: VelocityEngageFolioFolderDocument
  ) => {
    await deleteFolioDocument({ vehicleId, document });
  };

  const onEditDocument = async (
    vehicleId: string,
    documentId: string,
    url: string
  ) => {
    await updateFolioDocumentUrl({ vehicleId, documentId, url });
  };

  const handleClose = () => setError('');

  return (
    <CarfaxModalProvider>
      <div className="VelocityEngageMediaTab" ref={scrollRef}>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          folioFolders.map((folder: FolioFolder, idx: number) => (
            <>
              <MediaContainer
                folderId={folder.id}
                vehicleId={vehicleId}
                icon={
                  folder.name === strings.FEATURED ? (
                    <Star className="Featured-icon" />
                  ) : null
                }
                title={folder.name}
                media={folder.documents}
                scrollRef={scrollRef}
                onDelete={onDeleteDocument}
                onEdit={onEditDocument}
                setErrorMessage={setError}
              />
              {idx !== lastFolderIndex && <Divider />}
            </>
          ))
        )}
        <Alert
          open={!!error}
          duration={3000}
          handleClose={handleClose}
          className="VelocityEngageMediaTab-alert"
          contentProps={{
            variant: 'error',
            message: error,
            onClose: handleClose,
          }}
        />
      </div>
    </CarfaxModalProvider>
  );
};

export default VelocityEngageMediaTab;
