import { useMutation, useQueryClient } from 'react-query';
import useUploadStore from 'store/uploads/useUploadStore';
import { useUpdateDeviceTokenMutation } from './useUpdateDeviceTokenMutation';
import { Authentication } from 'models';
import {
  clearCredentials,
  saveCredentials,
} from 'api/lib/localStorageManagement';
import { messaging } from '../../firebase';
import { getAPIHeaders } from 'api/lib';
import { merge } from 'lodash';

export function useLoginMutation() {
  const client = useQueryClient();
  const uploadStoreData = useUploadStore((uploadStore) => ({
    initialize: uploadStore.initialize,
    closeUppyInstances: uploadStore.closeUppyInstances,
  }));
  const { mutateAsync: updateDeviceToken } = useUpdateDeviceTokenMutation();
  return useMutation(
    async ({
      emailAddress,
      password,
    }: {
      emailAddress: string;
      password: string;
    }) => {
      clearCredentials();
      const headers = await getAPIHeaders('POST');
      const requestOptions: RequestInit = merge<RequestInit, RequestInit>(
        headers?.options,
        {
          body: JSON.stringify({
            password,
            username: emailAddress,
            authenticationStrategy: 'V2',
          }),
        }
      );

      const response = await fetch(
        `${headers?.host}/authenticate`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }

      const user: Authentication = await response.json();
      if (user) {
        saveCredentials({
          authBearerToken: user.token,
          authBearerTokenExpiration: user.expirationDate,
          authRefreshToken: user.refreshToken,
        });
        client.setQueryData('/authenticate/token', user, {
          updatedAt: Date.now(),
        });
      }
      uploadStoreData.closeUppyInstances();
      uploadStoreData.initialize();
      try {
        const deviceId = await messaging?.getToken();
        if (deviceId) {
          updateDeviceToken(deviceId);
        }
      } catch (error) {
        console.log('Error getting deviceId from Firebase', error);
      }
    }
  );
}
