import React from 'react';
import { Modal } from 'react-bootstrap';

import LoadingIndicator from 'components/shared/LoadingIndicator';
import strings from 'common/strings';
import './VehicleStepReportModal.scss';
import { DateRange } from 'common/DateRange';
import { useDealerStepReport, useReportTimeInStep } from 'api';
import { DealerStepReportSummaryItem } from 'models';
import { StepReportTable } from './StepReportTable';

export interface VehicleStepReportModalProps {
  showModal: boolean;
  onClose: any;
  dateRange: DateRange;
  step: DealerStepReportSummaryItem;
  tenantId?: string;
}

const VehicleStepReportModal: React.FC<VehicleStepReportModalProps> = ({
  onClose,
  showModal,
  dateRange,
  step,
  tenantId,
}) => {
  const queryResult = useDealerStepReport(dateRange, tenantId);
  const { data, isLoading } = useReportTimeInStep(
    step.stepId,
    dateRange,
    tenantId
  );

  function closeModal() {
    onClose();
  }

  const allVehicles = queryResult.data?.data?.vehicles ?? [];
  const selectedVehicles =
    step === null
      ? allVehicles
      : allVehicles.filter((v) => step.vehicleIds?.includes(v.vehicle.id));

  return (
    <>
      <Modal
        centered
        size="xl"
        show={showModal}
        onHide={closeModal}
        className="VehicleStepReportModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {step === null ? strings.TOTAL_COMPLETED : step.stepName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {queryResult.isLoading ? (
            <LoadingIndicator />
          ) : (
            <StepReportTable
              selectedVehicles={selectedVehicles}
              data={data?.data}
              isLoading={isLoading}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={closeModal}
            className="white-button modal-footer-button-base"
          >
            {strings.CLOSE}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VehicleStepReportModal;
