import React from 'react';
import { WorkflowMenuItem } from '../../Sidebar/Sidebar';

import './StepItem.scss';

interface StepItemProps {
  className: string;
  stepId?: string;
  level: number;
  workflowMenuItem?: WorkflowMenuItem;
  handleSelected: () => void;
  children: any;
}

const StepItem = ({
  stepId,
  className,
  level,
  workflowMenuItem,
  handleSelected,
  children,
}: StepItemProps) => {
  const name = workflowMenuItem ? workflowMenuItem.name : 'All';
  const indent = Math.max(0, 22 * level);
  const nameClass = level < 0 ? 'bold' : '';

  const stepDefinitionId = workflowMenuItem?.id || 'ALL';

  const selectedClassName = stepId === stepDefinitionId ? 'selected' : '';

  return (
    <div
      className={`StepItem ${className}`}
      onClick={handleSelected}
      role="link"
      tabIndex={-1}
    >
      <div className={`item flex-columns ${selectedClassName}`}>
        <div
          className={`${nameClass} flex-grow truncate`}
          style={{ paddingLeft: indent }}
        >
          {name}
        </div>
        {children}
      </div>
    </div>
  );
};

export default StepItem;
