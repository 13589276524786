import React, { useState } from 'react';
import Sidebar from 'react-sidebar';

import Alert from 'components/shared/Alert';
import strings from 'common/strings';

import NotificationCenterContent from './NotificationCenterContent';

const zIndex = 1001;

const sidebarStyles = {
  sidebar: { background: 'white', width: '320px', zIndex: zIndex.toString() },
  overlay: { top: '60px', zIndex: (zIndex - 1).toString() },
};

export interface NotificationCenterProps {
  isOpen: boolean;
  onClose: () => void;
}

const NotificationCenter: React.FC<NotificationCenterProps> = ({
  isOpen,
  onClose,
}) => {
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  return (
    <>
      <Sidebar
        open={isOpen}
        pullRight
        sidebar={
          <NotificationCenterContent
            onClose={onClose}
            onError={() => setShowErrorAlert(true)}
          />
        }
        styles={sidebarStyles}
      >
        {/* Added to appease the react error stating that Sidebar has a required prop of children */}
        <span />
      </Sidebar>
      <Alert
        open={showErrorAlert}
        contentProps={{
          message: strings.API_MESSAGE,
          onClose: () => setShowErrorAlert(false),
          variant: 'error',
        }}
      />
    </>
  );
};

export default NotificationCenter;
