import { defaultMutationFn } from 'api';
import { isImage } from 'common';
import { VehicleImageShot } from 'models';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export function useDeleteGalleryMedia() {
  const queryClient = useQueryClient();

  type DeleteImageData = {
    vehicleId: string;
    mediaId: string;
    mediaUrl: string;
  };
  const mutation = useMutation(
    ({ vehicleId, mediaId, mediaUrl }: DeleteImageData) => {
      const endpoint = isImage(mediaUrl) ? 'images' : 'videos';
      const path = `/inventory/${vehicleId}/media/${endpoint}/${mediaId}`;
      return defaultMutationFn(path, 'DELETE');
    }
  );

  async function deleteImageAsync({
    vehicleId,
    mediaId,
    mediaUrl,
  }: DeleteImageData) {
    const response = await mutation.mutateAsync({
      vehicleId,
      mediaId,
      mediaUrl,
    });
    await queryClient.invalidateQueries(`/inventory/${vehicleId}/media`);
    return response;
  }

  return {
    ...mutation,
    deleteImageAsync,
  };
}

export function useUpdateVehicleImage() {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      vehicleId,
      imageId,
      shot,
    }: {
      vehicleId: string;
      imageId: string;
      shot: string;
    }) => {
      const path = `/inventory/${vehicleId}/media/images/${imageId}?shot=${shot}`;
      return defaultMutationFn(path, 'PUT');
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(
          `/inventory/${variables.vehicleId}/media`
        );
      },
    }
  );
}

export function useVehicleImageShots() {
  const path = '/utility/vehicle/imageshots';
  return useQuery<VehicleImageShot[]>(path);
}

export function useDeleteAttachment() {
  const queryClient = useQueryClient();

  type DeleteImageData = { vehicleId: string; attachmentId: string };
  const mutation = useMutation(
    ({ vehicleId, attachmentId }: DeleteImageData) => {
      const path = `/inventory/${vehicleId}/attachments/${attachmentId}`;
      return defaultMutationFn(path, 'DELETE');
    }
  );

  async function deleteAttachmentAsync({
    vehicleId,
    attachmentId,
  }: DeleteImageData) {
    const response = await mutation.mutateAsync({ vehicleId, attachmentId });
    await queryClient.invalidateQueries(`/inventory/${vehicleId}/attachments`);
    return response;
  }

  return {
    ...mutation,
    deleteAttachmentAsync,
  };
}
