import React, { useEffect, useState } from 'react';

import strings from 'common/strings';
import permissions from 'common/permissions';
import Dropdown from 'components/shared/Dropdown';

import './UsersDropdown.scss';
import { testIds } from 'common/testIds';
import { User } from 'models/utility/user';
import { useUsers } from 'api';
import { useAssignUserToStep } from 'api/inventory/workflows';
import { usePermissions } from 'hooks';

export interface StepItemSubset {
  id?: string;
  assignedTo?: {
    id?: string;
    firstName?: string;
    lastName?: string;
  };
}

// TODO: could be a common function
const getUsersFullName = (
  emptyValue: string,
  user?: { firstName?: string; lastName?: string }
) =>
  user && user.firstName !== undefined && user.lastName !== undefined
    ? `${user.firstName} ${user.lastName}`
    : emptyValue;

const getUserList = (users: User[], currentStep?: StepItemSubset | null) => {
  const currentStepId = currentStep?.id;
  if (!currentStepId) {
    return [];
  }

  const workingUserList = users
    .filter((item: User) => item.allowedSteps?.includes(currentStepId))
    .map((item: User) => ({
      id: item.id!,
      value: getUsersFullName(strings.EMPTY_VALUE, item),
    }));
  if (workingUserList.length >= 1 && currentStep?.assignedTo?.id) {
    workingUserList.push({ id: '', value: strings.UNASSIGN_USER });
  }
  return workingUserList;
};

interface IUserDropdown {
  loaderType?: 'srp' | 'default' | 'srpGradient';
  currentStep?: StepItemSubset | null;
  vehicleId?: string;
}

const UsersDropdown: React.FC<IUserDropdown> = ({
  loaderType,
  currentStep,
  vehicleId,
}) => {
  const { data: { data: users = [] } = { data: [] } } = useUsers();
  const assignToStepMutation = useAssignUserToStep(vehicleId);

  const { assignedTo } = currentStep || {};
  const [selectedUser, setSelectedUser] = useState({
    id: 'not-assigned',
    value: '',
  });
  const { hasPermission } = usePermissions();

  // TODO: if we get complaints we can do optimitic update when setting dropdown
  useEffect(() => {
    if (selectedUser.id !== assignedTo?.id) {
      setSelectedUser({
        id: assignedTo?.id ?? 'not-assigned',
        value: getUsersFullName(strings.NOT_ASSIGNED, assignedTo),
      });
    }
  }, [assignedTo, selectedUser.id]);

  const setUser = async (userId: string) => {
    const user = users.find((listUser) => listUser.id === userId);
    assignToStepMutation.mutateAsync(userId);
    setSelectedUser({
      id: user?.id || 'not-assigned',
      value: getUsersFullName(strings.NOT_ASSIGNED, user),
    });
  };

  if (hasPermission(permissions.RECON_VDP_WORKFLOW_STEP_UPDATE)) {
    return (
      <Dropdown
        optionContainerId={testIds.ASSIGNEE_DROPDOWN}
        options={getUserList(users, currentStep)}
        selectedOption={selectedUser}
        onSelect={setUser}
        disabled={assignToStepMutation.isLoading}
        containerClassName="UsersDropdown"
        userCanChange
        loaderType={loaderType}
      />
    );
  }
  if (hasPermission(permissions.RECON_ASSIGNMENTS_VIEW)) {
    return (
      <div className="UsersDropdown-no-permission">
        <div className="assigneeName">{selectedUser.value}</div>
      </div>
    );
  }
  return null;
};

export default UsersDropdown;
