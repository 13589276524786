import React, { useMemo } from 'react';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import strings from 'common/strings';
import { truncateEllipsisMiddle } from 'utils/string';
import Dropdown from 'components/shared/Dropdown';

import './RooftopSelect.scss';
import { testIds } from 'common/testIds';
import { useChangeLocation, useCurrentLocation, useLocations } from 'api';
import { useCurrentLocationId } from 'hooks/useCurrentLocationId';
import { removeUUIDs } from 'api/authenticate/urlManagement';
import { INVENTORY_RELATIVE } from 'navigation/routes';

const RooftopSelect: React.FC = () => {
  const locationsQuery = useLocations();
  const activeLocationId = useCurrentLocationId();
  const activeLocation = useCurrentLocation();
  const changeLocationQuery = useChangeLocation();

  const locations = useMemo(
    () => locationsQuery?.data ?? [],
    [locationsQuery?.data]
  );

  const options = useMemo(
    () =>
      locations.map((rooftop) => ({
        testId: rooftop.name.replace(' ', '_'),
        value: truncateEllipsisMiddle(rooftop.name, 35),
        id: rooftop.id,
      })),
    [locations]
  );

  const handleRooftopSelect = async (locationId: string) => {
    const currentRoute = removeUUIDs(window.location.pathname, false);
    const routePath = currentRoute.includes(INVENTORY_RELATIVE)
      ? `${locationId}/${INVENTORY_RELATIVE}`
      : `/${locationId}${currentRoute}`;

    if (locationId !== activeLocationId) {
      await changeLocationQuery.mutateAsync({
        locationId,
        routePath,
      });
    }
  };

  if (!activeLocation) {
    return null;
  }

  if (!locations || locations?.length === 0) {
    return null;
  }

  if (locations.length === 1) {
    return <span className="RooftopSelect-span">{activeLocation?.name}</span>;
  }

  return (
    <div className="RooftopSelect d-none d-sm-block">
      <Dropdown
        enableSearchOption
        searchOptionPlaceholder={strings.ROOFTOP_SEARCH_PLACE_HOLDER}
        optionContainerId={testIds.ROOFTOP_DROPDOWN}
        options={options}
        selectedOption={{
          id: activeLocation?.id || locations[0]?.id,
          value: activeLocation?.name || locations[0]?.name,
        }}
        onSelect={handleRooftopSelect}
        customCaretComponent={
          <ArrowDropDown className="Dropdown-expand-icon" />
        }
        truncateOptions={false}
      />
    </div>
  );
};

export default RooftopSelect;
