import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { Link as RLink } from '@reach/router';
import { FORGOT_PASSWORD } from 'navigation/routes';
import './ForgotPasswordLink.scss';

const ForgotPasswordLink: React.FC<{}> = () => {
  return (
    <Grid
      container
      className="ForgotPasswordLink"
      alignItems="center"
      justify="flex-end"
    >
      <Typography variant="body2" className="grey-text">
        <Link color="inherit" component={RLink} to={`/${FORGOT_PASSWORD}`}>
          Forgot password?
        </Link>
      </Typography>
    </Grid>
  );
};

export default ForgotPasswordLink;
