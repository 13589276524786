import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import images from 'common/images';
import strings from 'common/strings';
import './LoginScreenLayout.scss';

var Copyright = () => {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      className="grey-text"
    >
      Copyright &copy; {new Date().getFullYear() + ' '}
      <Link color="inherit" href="http://www.reconvelocity.com">
        Velocity Automotive
      </Link>
      .
    </Typography>
  );
};

const LoginScreenLayout: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  return (
    <Grid container component="main" className="root LoginScreenLayout">
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className="paper">
          <img alt="recon-img" src={images.ReconIcon} className="icon" />
          <div className="form">
            <Typography
              align="center"
              paragraph
              variant="subtitle1"
              className="grey-text description"
            >
              {strings.LOGIN_APP_DESCRIPTION}
            </Typography>
            <Typography
              align="center"
              variant="h5"
              className="blue-text font-bold"
            >
              {strings.LOGIN_FORM_TITLE}
            </Typography>
            {children}
            <Box mt={5}>
              <Copyright />
              <div className="versionSummary">
                {window.recon.versionSummary}
                <div id="gtm-element">
                  {process.env.REACT_APP_GOOGLE_CONTAINER_ID}
                </div>
              </div>
            </Box>
          </div>
        </div>
      </Grid>
      <Grid item xs={false} sm={4} md={7} className="image" />
    </Grid>
  );
};

export default LoginScreenLayout;
