/* eslint-disable no-unused-expressions */
import Uppy from '@uppy/core';
import Tus from '@uppy/tus';
import XHRUpload from '@uppy/xhr-upload';
import create from 'zustand';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { uniqBy } from 'lodash';

import { getAPIHeaders, getBearerToken } from 'api';
import strings from 'common/strings';

import UploadState, {
  ALLOWED_IMAGE_FILETYPES,
  MAX_AJAX_FILE_SIZE,
  MAX_UPLOADS,
  UploadMethod,
  UploadTarget,
  UploadItem,
  UploadStatus,
  UploadMetadata,
  ProgressState,
  UploadResponse,
  UploadProgress,
  UploadCompleteResult,
} from './types';
import {
  buildFormData,
  buildMetadata,
  getFileExtension,
  getUrl,
  sortByTimestamp,
  sortByUploadStatus,
} from './helpers';

const useUploadStore = create<UploadState>((set, get) => ({
  tusUppy: undefined,
  ajaxUppy: undefined,
  uploads: [],
  addFile: async (
    file: File,
    vehicleId: string,
    target: UploadTarget,
    onUploadSuccess: () => void,
    onUploadError: (message?: string) => void,
    onUploadCancel: () => void
  ) => {
    try {
      const useAjax = ALLOWED_IMAGE_FILETYPES.includes(file.type);
      const fileExtension = getFileExtension(file.type);
      const uploadMethod: UploadMethod = useAjax ? 'AJAX' : 'TUS';
      const formData = buildFormData(file);
      const meta = await buildMetadata(
        vehicleId,
        formData,
        target,
        uploadMethod,
        file
      );
      const fileToUpload = {
        source: 'file input',
        name: get().getFileName(file.name, meta.uploadMethod),
        type: file.type,
        data: file,
        meta,
      };

      let uppyUploadId;
      if (useAjax) {
        if (file.size > MAX_AJAX_FILE_SIZE) {
          onUploadError(strings.FILE_TOO_LARGE);
          return;
        }
        uppyUploadId = get().ajaxUppy?.addFile(fileToUpload);
        const { baseUrl } = await get().getUploadUrlData();
        const { xhrUploadState } = get().ajaxUppy!.getState();
        get().ajaxUppy?.setState({
          xhrUpload: {
            ...xhrUploadState,
            endpoint: getUrl(
              baseUrl,
              '',
              vehicleId,
              target.type,
              target.id,
              fileExtension,
              false,
              target.shot
            ),
          },
        });
      } else {
        uppyUploadId = get().tusUppy?.addFile(fileToUpload);
      }

      const uploadItem: UploadItem = {
        id: uuidv4(),
        uppyId: uppyUploadId,
        uploadStatus: UploadStatus.STAGED,
        progressState: ProgressState.DETERMINATE,
        uploadOptions: fileToUpload,
        meta: fileToUpload.meta as UploadMetadata,
        timestamp: Date.now(),
        progress: 0,
        fileExtension,
        onUploadSuccess,
        onUploadCancel,
        onUploadError,
      };

      set((state) => ({
        uploads: [...state.uploads, uploadItem],
      }));
    } catch (error) {
      if (onUploadError) {
        onUploadError();
      }
    }
  },
  addFiles: async (
    files: File[],
    vehicleId: string,
    target: UploadTarget,
    onUploadSuccess: () => void,
    onUploadError: (message?: string) => void,
    onUploadCancel: () => void
  ) => {
    if (files.length > MAX_UPLOADS) {
      onUploadError(strings.TOO_MANY_FILES);
      return;
    }
    const promises = files.map(async (file: File) =>
      get().addFile(
        file,
        vehicleId,
        target,
        onUploadSuccess,
        onUploadError,
        onUploadCancel
      )
    );
    await Promise.all(promises);
  },
  removeFile: (upload: UploadItem) => {
    if (upload.onUploadCancel) {
      upload.onUploadCancel();
    }

    const uppyFiles = get()
      .tusUppy?.getFiles()
      .concat(get().ajaxUppy?.getFiles() || []);
    const uppyFile = uppyFiles?.find(
      (file) => (file.meta as UploadMetadata).id === upload.meta.id
    );
    if (uppyFile?.id) {
      upload.meta.uploadMethod === 'TUS'
        ? get().tusUppy?.removeFile(uppyFile.id)
        : get().ajaxUppy?.removeFile(uppyFile.id);
    }
    set((uploadStore) => ({
      uploads: uploadStore.uploads.filter(
        (storedUpload: UploadItem) => storedUpload.meta.id !== upload.meta.id
      ),
    }));
  },
  removeFiles: (uploads: UploadItem[]) => {
    uploads.forEach((upload: UploadItem) => get().removeFile(upload));
  },
  upload: () => {
    set((uploadStore) => ({
      uploads: uploadStore.uploads.map((uploadItem: UploadItem) => {
        uploadItem.uploadStatus = UploadStatus.UPLOADING;
        return uploadItem;
      }),
    }));
    get().uploadViaAjax();
    get().uploadViaTus();
  },
  uploadFiles: async (
    files: File[],
    vehicleId: string,
    target: UploadTarget,
    onUploadSuccess: (url?: string) => void,
    onUploadError: (message?: string) => void,
    onUploadCancel: () => void
  ) => {
    await get().addFiles(
      files,
      vehicleId,
      target,
      onUploadSuccess,
      onUploadError,
      onUploadCancel
    );
    get().upload();
  },
  closeUppyInstances: () => {
    get().tusUppy?.close();
    get().ajaxUppy?.close();
    set({ uploads: [] });
  },
  sortedUploads: () =>
    get().uploads.sort(sortByTimestamp).sort(sortByUploadStatus),
  uploadManagerSortedUploads: () =>
    get()
      .uploads.filter(
        (uploadItem: UploadItem) =>
          uploadItem.uploadStatus !== UploadStatus.STAGED
      )
      .sort(sortByTimestamp)
      .sort(sortByUploadStatus),
  getUploadsForParentId: (parentId: string) =>
    get().uploads.filter(
      (upload: UploadItem) => upload.meta.parentId === parentId
    ),
  getUploadsForTargetId: (targetId: string) =>
    get().uploads.filter(
      (upload: UploadItem) => upload.meta.target.id === targetId
    ),
  parentHasUploadsInProgress: (parentId: string) =>
    get()
      .getUploadsForParentId(parentId)
      .some(
        (upload: UploadItem) => upload.uploadStatus === UploadStatus.UPLOADING
      ),
  targetHasUploadsInProgress: (targetId: string) =>
    get()
      .getUploadsForTargetId(targetId)
      .some(
        (upload: UploadItem) => upload.uploadStatus === UploadStatus.UPLOADING
      ),
  pauseOrResumeUpload: (upload: UploadItem) => {
    const uppyFiles = get()
      .tusUppy?.getFiles()
      .concat(get().ajaxUppy?.getFiles() || []);
    const fileToPause = uppyFiles?.find(
      (uppyFile) => (uppyFile.meta as UploadMetadata).id === upload.meta.id
    );
    if (fileToPause?.id) {
      upload.meta.uploadMethod === 'TUS'
        ? get().tusUppy?.pauseResume(fileToPause.id)
        : get().ajaxUppy?.pauseResume(fileToPause.id);
    }
    set((uploadStore) => ({
      uploads: uploadStore.uploads.map((storedUpload: UploadItem) => {
        if (storedUpload.meta.id === upload.meta.id) {
          storedUpload.uploadStatus === UploadStatus.PAUSED
            ? (storedUpload.uploadStatus = UploadStatus.UPLOADING)
            : (storedUpload.uploadStatus = UploadStatus.PAUSED);
        }
        return storedUpload;
      }),
    }));
  },
  resolveTusUpload: async (file: Uppy.UppyFile, response: UploadResponse) => {
    // TODO: Verify this is working
    const { baseUrl, options } = await get().getUploadUrlData();
    const { uploadURL } = response;
    const { parentId, target } = file.meta as UploadMetadata;
    const url = getUrl(
      baseUrl!,
      uploadURL,
      parentId,
      target.type,
      target.id,
      file.extension,
      true,
      target.shot
    );
    return (await axios.post(url, null, options)).data;
  },
  addNoteTargetId: (
    parentId: string,
    targetId: string,
    onUploadCancel?: () => void
  ) => {
    set((uploadStore) => ({
      uploads: uploadStore.uploads.map((upload: UploadItem) => {
        if (upload.meta.parentId === parentId && !upload.meta.target.id) {
          upload.meta.target.id = targetId;
          upload.onUploadCancel = onUploadCancel;
          if (upload.uppyId) {
            const file =
              upload.meta.uploadMethod === 'TUS'
                ? get().tusUppy?.getFile(upload.uppyId!)
                : get().ajaxUppy?.getFile(upload.uppyId!);
            const meta = file?.meta as UploadMetadata;
            upload.meta.uploadMethod === 'TUS'
              ? get().tusUppy?.setFileMeta(upload.uppyId, {
                  ...meta,
                  target: { ...meta.target, id: targetId },
                })
              : get().ajaxUppy?.setFileMeta(upload.uppyId, {
                  ...meta,
                  target: { ...meta.target, id: targetId },
                });
          }
        }
        return upload;
      }),
    }));
  },
  onUploadSuccess: (file: Uppy.UppyFile, response: UploadResponse) => {
    set((uploadStore) => ({
      uploads: uploadStore.uploads.map((upload: UploadItem) => {
        if (upload.meta.id === (file.meta as UploadMetadata).id) {
          upload.uploadStatus = UploadStatus.COMPLETED;
          upload.meta.uploadUrl = response.uploadURL;
        }

        return upload;
      }),
    }));
  },
  onUploadProgress: (file: Uppy.UppyFile, progress: UploadProgress) => {
    set((uploadStore) => ({
      uploads: uploadStore.uploads.map((upload: UploadItem) => {
        if (upload.meta.id === (file.meta as UploadMetadata).id) {
          upload.progress =
            (progress.bytesUploaded / progress.bytesTotal) * 100;
        }
        return upload;
      }),
    }));
  },
  onError: (file: Uppy.UppyFile) => {
    set((uploadStore) => ({
      uploads: uploadStore.uploads.filter((upload: UploadItem) => {
        if (upload.meta.id === (file.meta as UploadMetadata).id) {
          upload.uploadStatus = UploadStatus.ERROR;

          if (upload.onUploadError) {
            upload.onUploadError();
          }

          upload.meta.uploadMethod === 'TUS'
            ? get().tusUppy?.removeFile(file.id)
            : get().ajaxUppy?.removeFile(file.id);
          return false;
        }
        return true;
      }),
    }));
  },
  onComplete: async (result: UploadCompleteResult) => {
    const successfulIds = result.successful.map(
      (file: Uppy.UppyFile) => (file.meta as UploadMetadata).id
    );
    const failedIds = result.failed.map(
      (file: Uppy.UppyFile) => (file.meta as UploadMetadata).id
    );

    const resolutionPromises = result.successful.map(
      async (file: Uppy.UppyFile) => {
        if ((file.meta as UploadMetadata).uploadMethod === 'TUS') {
          file.response = await get().resolveTusUpload(file, {
            uploadURL: (file as any).uploadURL,
          });
        }
      }
    );
    await Promise.all(resolutionPromises);

    const dedupedSuccessfulUploads = uniqBy(
      get().uploads.filter((upload: UploadItem) =>
        successfulIds.includes(upload.meta.id)
      ),
      'meta.target.id'
    );

    const promises = dedupedSuccessfulUploads.map(
      async (upload: UploadItem) => {
        const uploadResponse = result.successful.find(
          (file: Uppy.UppyFile) =>
            (file.meta as UploadMetadata).id === upload.meta.id
        )?.response;
        const uploadAWSUrl =
          (uploadResponse?.body as any)?.data?.uri ?? // ajax
          (uploadResponse as any)?.data?.uri; // TUS

        if (upload.onUploadSuccess) {
          return upload.onUploadSuccess!(uploadAWSUrl);
        }
        return undefined;
      }
    );
    await Promise.all(promises);

    result.successful.forEach((file: Uppy.UppyFile) => {
      (file.meta as UploadMetadata).uploadMethod === 'TUS'
        ? get().tusUppy?.removeFile(file.id)
        : get().ajaxUppy?.removeFile(file.id);
    });
    set((uploadStore) => ({
      uploads: uploadStore.uploads
        .map((upload: UploadItem) => {
          if (successfulIds.includes(upload.meta.id)) {
            upload.uploadStatus = UploadStatus.COMPLETED;
          }

          if (failedIds.includes(upload.meta.id)) {
            upload.uploadStatus = UploadStatus.ERROR;
          }

          return upload;
        })
        .filter(
          (upload: UploadItem) => !successfulIds.includes(upload.meta.id)
        ),
    }));
  },
  initialize: async () => {
    console.log('INITIALIZE USEUPLOADSTORE !!');
    // Reset current instancess
    if (get().tusUppy) {
      get().tusUppy?.close();
    }
    if (get().ajaxUppy) {
      get().ajaxUppy?.close();
    }
    const { baseUrl, options } = await get().getUploadUrlData();

    const tusUppy = Uppy().use(Tus, {
      endpoint: `${baseUrl}/uploads`,
      chunkSize: 5 * 1024 * 1024,
      headers: {
        ...options.headers,
      },
      retry: true,
    });

    const ajaxUppy = Uppy().use(XHRUpload, {
      endpoint: '',
      headers: {
        ...options.headers,
      },
      fieldName: 'file',
    });

    // Set Uppy event listeners
    tusUppy.on(
      'upload-success',
      (file: Uppy.UppyFile, response: UploadResponse) => {
        get().onUploadSuccess(file, response);
      }
    );

    tusUppy.on(
      'upload-progress',
      (file: Uppy.UppyFile, progress: UploadProgress) => {
        get().onUploadProgress(file, progress);
      }
    );

    tusUppy.on('complete', (result: UploadCompleteResult) => {
      get().onComplete(result);
    });

    tusUppy.on('upload-error', (file: Uppy.UppyFile) => {
      get().onError(file);
    });

    ajaxUppy.on(
      'upload-success',
      (file: Uppy.UppyFile, response: UploadResponse) => {
        get().onUploadSuccess(file, response);
      }
    );

    ajaxUppy.on(
      'upload-progress',
      (file: Uppy.UppyFile, progress: UploadProgress) => {
        get().onUploadProgress(file, progress);
      }
    );

    ajaxUppy.on('complete', (result: UploadCompleteResult) => {
      get().onComplete(result);
    });

    ajaxUppy.on('upload-error', (file: Uppy.UppyFile) => {
      get().onError(file);
    });

    set({
      tusUppy,
      ajaxUppy,
    });
  },
  getExistingFileInUppy: (filename: string, uploadMethod: UploadMethod) =>
    uploadMethod === 'TUS'
      ? get()
          .tusUppy?.getFiles()
          .find((file: Uppy.UppyFile) => file.name === filename)
      : get()
          .ajaxUppy?.getFiles()
          .find((file: Uppy.UppyFile) => file.name === filename),
  getFileName: (filename: string, uploadMethod: UploadMethod) => {
    const existingFile = get().getExistingFileInUppy(filename, uploadMethod);
    if (!existingFile) {
      return filename;
    }

    const nameWithoutExtension =
      filename.substr(0, filename.lastIndexOf('.')) || filename;
    const extensionWithoutName = filename.substr(filename.lastIndexOf('.'));

    return `${nameWithoutExtension}-1${extensionWithoutName}`;
  },
  uploadViaTus: () => {
    get().tusUppy?.upload();
  },
  uploadViaAjax: () => {
    get().ajaxUppy?.upload();
  },
  getUploadUrlData: async () => {
    const { host } = await getAPIHeaders('POST');
    const authToken = await getBearerToken();
    const Authorization = `Bearer ${authToken}`;
    const tenantId = window.location.pathname.split('/')[1];
    const options = {
      headers: {
        Authorization,
        'Auth-Tenant-Id': tenantId,
      },
    };
    return { baseUrl: host, options };
  },
}));

export default useUploadStore;
