import React, { useCallback } from 'react';
import strings from 'common/strings';
import { getFormattedDurationFromSeconds } from 'utils/time';
import './Reports.scss';
import { DealerStepReportSummaryItem } from 'models/reports/DealerStepReportPayload';

interface StepCellProps {
  step: DealerStepReportSummaryItem;
  value: number;
  formatValue?: boolean;
  addClassName?: string;
  onClick: () => void;
}

const StepCell: React.FC<StepCellProps> = (props) => {
  const { step, value, formatValue = false, addClassName, onClick } = props;

  const clickable = !!step.totalVehicles;
  const selectableClassName = clickable ? 'selectable' : '';
  let strValue = value;
  if (formatValue) {
    strValue =
      strValue === 0
        ? strings.EMPTY_VALUE
        : getFormattedDurationFromSeconds(value);
  }

  const onStepCellClick = useCallback(
    (e) => {
      if (clickable) {
        onClick();
        e.stopPropagation();
      }
    },
    [clickable, onClick]
  );

  return (
    <div
      role="button"
      tabIndex={-1}
      onClick={onStepCellClick}
      className={`evenly-spaced-item ${selectableClassName} ${addClassName}`}
    >
      {strValue}
    </div>
  );
};

export default StepCell;
