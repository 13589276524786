import { APIResponse, defaultMetaQueryFn } from 'api';
import { DateRange } from 'common/DateRange';
import { ReportType } from 'components/shared/DownloadMenu/DownloadMenu';
import { DealerStepReportPayload } from 'models';
import { useQuery } from 'react-query';
import { getApiDateStringFromDate } from 'utils/time';

const requestPath: string = '/reports/step';

export function useDealerStepReport(
  { start, end }: DateRange,
  tenantId?: string
) {
  let params = `startDate=${getApiDateStringFromDate(
    start
  )}&endDate=${getApiDateStringFromDate(end)}`;
  const key = [
    requestPath,
    getApiDateStringFromDate(start),
    getApiDateStringFromDate(end),
  ];
  if (tenantId) {
    params += `&tenantId=${tenantId}`;
    key.push(tenantId);
  }
  const url = `${requestPath}?${params}`;

  return useQuery<APIResponse<DealerStepReportPayload>>(key, () =>
    defaultMetaQueryFn(url)
  );
}

export function DealerStepReport({ start, end }: DateRange, tenantId?: string) {
  let params = `startDate=${getApiDateStringFromDate(
    start
  )}&endDate=${getApiDateStringFromDate(end)}`;
  const key = [
    requestPath,
    getApiDateStringFromDate(start),
    getApiDateStringFromDate(end),
  ];
  if (tenantId) {
    params += `&tenantId=${tenantId}`;
    key.push(tenantId);
  }
  const url = `${requestPath}?${params}`;
  return useQuery<APIResponse<DealerStepReportPayload>>(key, () =>
    defaultMetaQueryFn(url)
  );
}

const getDownloadUrl = (
  exportType: ReportType,
  dateRange: DateRange,
  tenantId?: string
) => {
  const url = '/reports/export/step?';
  const params = {
    startDate: getApiDateStringFromDate(dateRange.start),
    endDate: getApiDateStringFromDate(dateRange.end),
    tenantId: tenantId,
    exportType,
  };
  return (
    url +
    Object.entries(params)
      .filter((entry) => entry[1] !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
  );
};

export function useDealerStepReportDownloadLink(
  reportType: ReportType | null,
  dateRange: DateRange,
  tenantId?: string
) {
  const downloadUrl =
    reportType !== null ? getDownloadUrl(reportType, dateRange, tenantId) : '';
  const result = useQuery<{ uri: string }>(downloadUrl, {
    enabled: reportType !== null,
  });
  return {
    isLoading: result.isLoading,
    downloadFileUrl: result?.data?.uri,
  };
}

export function useReportTimeInStep(
  stepId: string,
  dateRange: DateRange,
  tenantId?: string
) {
  const path = `/reports/steps/${stepId}/timeInStep?`;
  const params = {
    startDate: getApiDateStringFromDate(dateRange.start),
    endDate: getApiDateStringFromDate(dateRange.end),
    tenantId: tenantId,
  };
  const url =
    path +
    Object.entries(params)
      .filter((entry) => entry[1] !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

  return useQuery<
    APIResponse<{
      [key: string]: { reconTimeInStep: number; retailTimeInStep: number };
    }>
  >(url, () => defaultMetaQueryFn(url));
}
