import React, { useState } from 'react';
import { isUndefined } from 'lodash';

import SummaryCard from '../../Dashboard/SummaryCard';
import {
  compareAverageOverage,
  compareGoalValue,
  compareTotalOverage,
  ratings,
} from '../ReportsState';
import { currencyFormatter } from 'utils/formatter';
import strings from 'common/strings';
import { permissions } from 'common';
import { StepSummary } from './StepSummary';
import {
  Price,
  DealerStepReportPayload,
  DealerStepReportSummaryItem,
  SummaryItem,
} from 'models';
import { useDealerStepReportDownloadLink } from 'api';
import { ReportType } from 'components/shared/DownloadMenu/DownloadMenu';

import { DateRange } from 'common/DateRange';
import { usePermissions } from 'hooks';

export const Reports = ({
  dealerStepReport,
  openStepModal,
  dateRange,
  tenantId,
}: {
  dealerStepReport: DealerStepReportPayload;
  openStepModal: (step: DealerStepReportSummaryItem | null) => void;
  dateRange: DateRange;
  tenantId?: string;
}) => {
  let overviewItems: SummaryItem[] = [];
  const [selectedReportType, setSelectedReportType] =
    useState<ReportType | null>(null);
  const { isLoading, downloadFileUrl = '' } = useDealerStepReportDownloadLink(
    selectedReportType,
    dateRange,
    tenantId
  );

  if (!isUndefined(dealerStepReport)) {
    overviewItems = [
      {
        itemType: 'VehiclesCompleted',
        statValue: dealerStepReport.completedVehicles,
        statLabel: String(strings.VEHICLES_COMPLETED).toUpperCase(),
        unit: 'Items',
      },
      {
        itemType: 'RetailReadyTime',
        statValue: dealerStepReport.averageRetailReadyAsSeconds,
        statLabel: String(strings.RETAIL_READY_TIME).toUpperCase(),
        unit: 'Seconds',
        goalValue: dealerStepReport.averageRetailReadyGoalAsSeconds,
        goalLabel: `${strings.GOAL}:`,
      },
      {
        itemType: 'AverageReconTime',
        statValue: dealerStepReport.averageReconTimeAsSeconds,
        statLabel: String(strings.AVERAGE_RECON_TIME).toUpperCase(),
        unit: 'Seconds',
        goalValue: dealerStepReport.averageReconTimeGoalAsSeconds,
        goalLabel: `${strings.GOAL}:`,
      },
    ];
  }

  return (
    <>
      <div className="DealerStepReport-title">{strings.OVERVIEW}</div>
      {overviewItems.map((summaryItem: SummaryItem, index) => (
        <div
          key={`summary-card-key-${index}`}
          className="DealerStepReport-SummaryCard-container"
          id={`step-report-${summaryItem?.itemType}-container`}
        >
          <SummaryCard
            summaryItem={summaryItem}
            setStyle={compareGoalValue(summaryItem)}
            onStatClick={
              summaryItem.itemType === 'VehiclesCompleted' &&
              summaryItem.statValue !== 0 &&
              !isLoading
                ? () => {
                    openStepModal(null);
                  }
                : undefined
            }
            statSelectable={
              summaryItem.itemType === 'VehiclesCompleted' &&
              summaryItem.statValue !== 0
            }
          />
        </div>
      ))}
      {overviewItems.length > 0 && (
        <GridOverview
          averageActualRecon={dealerStepReport.averageActualRecon}
          averageOverage={dealerStepReport.averageOverage}
          totalOverage={dealerStepReport.totalOverage}
          averageEstimatedRecon={dealerStepReport.averageEstimatedRecon}
        />
      )}
      {dealerStepReport && (
        <StepSummary
          setSelectedReportType={setSelectedReportType}
          vehicles={dealerStepReport.vehicles}
          summaryItems={dealerStepReport.summaryItems}
          dateRange={dateRange}
          downloadFileUrl={downloadFileUrl}
          isLoading={isLoading}
          openStepModal={openStepModal}
        />
      )}
    </>
  );
};

const GridOverview = ({
  averageActualRecon,
  averageOverage,
  totalOverage,
  averageEstimatedRecon,
}: {
  averageActualRecon: Price;
  averageOverage: Price;
  totalOverage: Price;
  averageEstimatedRecon: Price;
}) => {
  const { hasPermission } = usePermissions();
  const userHasReconInfoPermission = hasPermission(
    permissions.INVENTORY_VDP_RECON_INFO_VIEW
  );
  const averageOverageAmount = averageOverage?.amount;
  const averageOverageString = [
    compareAverageOverage(totalOverage) === ratings.GOOD ? '' : '+',
    averageOverageAmount ? currencyFormatter(averageOverageAmount) : '',
  ].join(' ');

  const averageOverageClassName = userHasReconInfoPermission
    ? `DealerStepReport-quadrant-${compareAverageOverage(totalOverage)}`
    : '';

  const totalOverageString = [
    `${compareAverageOverage(totalOverage) === ratings.GOOD ? '' : '+'}`,
    `${totalOverage?.amount && currencyFormatter(totalOverage.amount)}`,
  ].join(' ');

  const totalOverageClassName = userHasReconInfoPermission
    ? `DealerStepReport-quadrant-${compareTotalOverage(totalOverage)}`
    : '';

  return (
    <div className="DealerStepReport-SummaryCard-container">
      <div className="DealerStepReport-QuadrantCard">
        <div className="DealerStepReport-Quadrant border-right border-bottom">
          <h4>
            {!averageEstimatedRecon?.amount || !userHasReconInfoPermission
              ? strings.EMPTY_VALUE
              : currencyFormatter(averageEstimatedRecon.amount)}
          </h4>
          {strings.EST_RECON}
        </div>
        <div className="DealerStepReport-Quadrant border-bottom">
          <h4>
            {!averageActualRecon?.amount || !userHasReconInfoPermission
              ? strings.EMPTY_VALUE
              : currencyFormatter(averageActualRecon?.amount)}
          </h4>
          {strings.ACTUAL_RECON}
        </div>
        <div className="DealerStepReport-Quadrant border-right">
          <h4 className={averageOverageClassName}>
            {!averageOverage?.amount || !userHasReconInfoPermission
              ? strings.EMPTY_VALUE
              : averageOverageString}
          </h4>
          {strings.OVERAGE}
        </div>
        <div className="DealerStepReport-Quadrant">
          <h4 className={totalOverageClassName}>
            {!totalOverage?.amount || !userHasReconInfoPermission
              ? strings.EMPTY_VALUE
              : totalOverageString}
          </h4>
          {strings.TOTAL_OVERAGE}
        </div>
      </div>
    </div>
  );
};
