import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import strings from 'common/strings';
import { currencyFormatter } from 'utils/formatter';
import Button from 'components/shared/Button';
import PermissionsGate from 'components/shared/PermissionsGate';
import permissions from 'common/permissions';
import { testIds, generateTestId } from 'common/testIds';
import EditReconInfoModal from './EditReconInfoModal';

import './ReconInfoSection.scss';
import { VehicleSummary } from 'models';
import { usePermissions } from 'hooks';

interface ReconInfoRowProps {
  title: string;
  value: number | null;
}

const ReconInfoRow: React.FC<ReconInfoRowProps> = ({ title, value }) => (
  <Row className="ReconInfoRow">
    <Col
      data-vas-testing={generateTestId(testIds.RECON_INFO_ROW_TITLE, {
        title: `${title}`,
      })}
      className="ReconInfoRow-title d-flex justify-content-start align-items-center"
    >
      {title}
    </Col>
    <Col
      className="ReconInfoRow-value d-flex justify-content-end align-items-center"
      id={title.replace(/\s+/g, '-').toLowerCase()}
    >
      {typeof value === 'number'
        ? currencyFormatter(value)
        : strings.EMPTY_VALUE}
    </Col>
  </Row>
);

interface ReconInfoSectionProps {
  vehicleSummary: VehicleSummary;
}

const ReconInfoSection: React.FC<ReconInfoSectionProps> = ({
  vehicleSummary,
}) => {
  const { hasPermission } = usePermissions();
  const [showEditReconInfoModal, setShowEditReconInfoModal] = useState(false);
  const userHasViewReconInfoPermissions = hasPermission(
    permissions.INVENTORY_VDP_RECON_INFO_VIEW
  );

  const getReconInfoData = (reconInfo: VehicleSummary['reconInfo']) => {
    if (!reconInfo) return [];
    return [
      {
        title: strings.INITIAL_COST,
        value:
          reconInfo?.initialCost?.amount && userHasViewReconInfoPermissions
            ? reconInfo.initialCost.amount
            : strings.EMPTY_VALUE,
      },
      {
        title: strings.APPRAISED_VALUE,
        value:
          reconInfo?.appraisedValue?.amount && userHasViewReconInfoPermissions
            ? reconInfo.appraisedValue.amount
            : strings.EMPTY_VALUE,
      },
      {
        title: strings.ESTIMATED_RECON,
        value:
          reconInfo?.estimatedRecon?.amount && userHasViewReconInfoPermissions
            ? reconInfo.estimatedRecon.amount
            : strings.EMPTY_VALUE,
      },
      {
        title: strings.TARGET_COST,
        value:
          reconInfo?.targetCost?.amount && userHasViewReconInfoPermissions
            ? reconInfo.targetCost.amount
            : strings.EMPTY_VALUE,
      },
      {
        title: strings.COST,
        value:
          reconInfo?.cost?.amount && userHasViewReconInfoPermissions
            ? reconInfo.cost.amount
            : strings.EMPTY_VALUE,
      },
      {
        title: strings.PACK,
        value:
          reconInfo?.pack?.amount && userHasViewReconInfoPermissions
            ? reconInfo.pack.amount
            : strings.EMPTY_VALUE,
      },
      {
        title: strings.ACTUAL_RECON,
        value:
          reconInfo?.actualRecon?.amount && userHasViewReconInfoPermissions
            ? reconInfo.actualRecon.amount
            : strings.EMPTY_VALUE,
      },
      {
        title: strings.OVERAGE,
        value:
          reconInfo?.overage?.amount && userHasViewReconInfoPermissions
            ? reconInfo.overage.amount
            : strings.EMPTY_VALUE,
      },
      // TODO: hook this up after we get data from the API
      // {
      //   title: 'Book Value',
      //   value: 50,
      // },
    ];
  };
  return (
    <Container
      fluid
      className="ReconInfoSection"
      data-vas-testing={testIds.RECON_INFO_SECTION_CONTAINER}
    >
      <Row
        className="ReconInfoSection-title-row pb-3"
        data-vas-testing={testIds.RECON_INFO_SECTION_TITLE_CONTAINER}
      >
        <Col className="d-flex justify-content-start align-items-center p-0">
          <div className="ReconInfoSection-title">Recon Info</div>
        </Col>
        <Col className="d-flex justify-content-end align-items-center p-0">
          <PermissionsGate
            permissions={[
              permissions.RECON_VDP_UPDATE,
              permissions.INVENTORY_VDP_RECON_INFO_VIEW,
            ]}
          >
            <Button
              id="edit-recon-info-button"
              variant="secondary"
              onClick={() => setShowEditReconInfoModal(true)}
              type="button"
              className="ReconInfoSection-edit-btn"
            >
              Edit Recon Info
            </Button>
          </PermissionsGate>
        </Col>
      </Row>
      <div
        className="ReconInfoSection-data-container"
        data-vas-testing={testIds.RECON_INFO_SECTION_DATA_CONTAINER}
      >
        {vehicleSummary.reconInfo ? (
          getReconInfoData(vehicleSummary.reconInfo).map(
            (item: ReconInfoRowProps) => (
              <ReconInfoRow
                key={`recon-info-row-${item.title}`}
                title={item.title}
                value={item.value}
              />
            )
          )
        ) : (
          <div>{strings.NO_RECON_INFO}</div>
        )}
      </div>
      {showEditReconInfoModal && (
        <EditReconInfoModal
          vehicleSummary={vehicleSummary}
          onClose={() => setShowEditReconInfoModal(false)}
        />
      )}
    </Container>
  );
};

export default ReconInfoSection;
