import { TabProps } from 'components/shared/Tabs/Tabs';
import { isUndefined } from 'lodash';

import React, { useState, useCallback } from 'react';

import { useDealerStepReport } from 'api';
import { DealerStepReportSummaryItem } from 'models/reports/DealerStepReportPayload';
import './DealerStepReport.scss';
import ReportContainer from '../ReportContainer';
import VehicleStepReportModal from '../VehicleStepReportModal';
import { DateRange } from 'common/DateRange';
import { Reports } from './OverviewItem';
import { EmbeddedDealerStepReport } from './EmbeddedDealerStepReport';

interface ReportsProps extends TabProps {
  dateRange: DateRange;
  corporateTenantId?: string;
}
interface VehicleStepReportModalState {
  open: boolean;
  step: DealerStepReportSummaryItem | null;
}

const DealerStepReport: React.FC<ReportsProps> = (props) => {
  const queryResult = useDealerStepReport(
    props.dateRange,
    props.corporateTenantId
  );
  const dealerStepReport = queryResult.data?.data;
  const [vehicleStepReportModalState, setVehicleStepReportModalState] =
    useState<VehicleStepReportModalState>({
      open: false,
      step: null,
    });

  const onCloseStepModal = useCallback(
    () =>
      setVehicleStepReportModalState({
        open: false,
        step: vehicleStepReportModalState.step,
      }),
    [vehicleStepReportModalState.step]
  );

  const onOpenStepModal = useCallback(
    (step) =>
      setVehicleStepReportModalState({
        open: true,
        step,
      }),
    []
  );

  return (
    <>
      <ReportContainer
        className="DealerStepReport"
        loading={queryResult.isLoading}
        error={queryResult.error}
      >
        {queryResult.isLoading || isUndefined(dealerStepReport) ? (
          <div />
        ) : !props.corporateTenantId ? (
          <Reports
            dateRange={props.dateRange}
            dealerStepReport={dealerStepReport}
            openStepModal={onOpenStepModal}
            tenantId={props.corporateTenantId}
          />
        ) : (
          <EmbeddedDealerStepReport
            dateRange={props.dateRange}
            dealerStepReport={dealerStepReport}
            openStepModal={onOpenStepModal}
            tenantId={props.corporateTenantId}
          />
        )}
      </ReportContainer>
      {vehicleStepReportModalState.step && (
        <VehicleStepReportModal
          onClose={onCloseStepModal}
          showModal={vehicleStepReportModalState.open}
          step={vehicleStepReportModalState.step}
          dateRange={props.dateRange}
          tenantId={props.corporateTenantId}
        />
      )}
    </>
  );
};

export default DealerStepReport;
