import { useCurrentLocation } from 'api';
import permissions from 'common/permissions';
import { useMemo } from 'react';

export type Permission = keyof typeof permissions;
export function usePermissions() {
  const { permissions } = useCurrentLocation() ?? {};
  return useMemo(() => {
    const permissionsObject: any =
      permissions?.reduce((previousValue, currentValue: string) => {
        return { ...previousValue, [currentValue]: true };
      }, {}) ?? {};

    function hasPermission(checkedPermission: Permission) {
      return permissionsObject[checkedPermission] || false;
    }

    function hasPermissions(checkedPermissions: Permission[]) {
      return checkedPermissions.every(hasPermission);
    }

    return { hasPermission, hasPermissions };
  }, [permissions]);
}
