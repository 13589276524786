import { useCorporateReportFilters } from 'api/reports/useCorporateReport';
import MultipleSearchSelection from 'components/shared/MultipleSearchSelection';
import './TagFilter.scss';
interface TagFilterProps {
  selectedTags: string[];
  onChange: (tags: string[]) => void;
  allOptionsLabel?: string;
}
const TagFilter: React.FC<TagFilterProps> = ({
  selectedTags,
  onChange,
  allOptionsLabel,
}) => {
  const { data: { data: filters } = {} } = useCorporateReportFilters();
  const tagFilterOptions = filters?.tags.map((tag) => ({
    id: tag.id,
    value: tag.label,
    checked: selectedTags.includes(tag.id),
  }));
  function switchById(optionId: string) {
    if (selectedTags.includes(optionId)) {
      onChange(selectedTags.filter((tag) => tag !== optionId));
    } else {
      onChange([...selectedTags, optionId]);
    }
  }
  if (!tagFilterOptions) {
    return null;
  }
  return (
    <div className="TagFilter">
      <MultipleSearchSelection
        containerClassName="TagFilter-select-container"
        options={tagFilterOptions}
        filterId="TAGS"
        onSelect={switchById}
        allOptionsLabel={allOptionsLabel ?? 'All'}
      />
    </div>
  );
};

export default TagFilter;
