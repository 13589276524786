import moment from 'moment';
import 'moment-duration-format';
import strings from 'common/strings';

type FormattedDurationMaxLength = 1 | 2 | 3;

const DEFAULT_MAX_TERMS = 2;

const DEFAULT_MAX_DURATION_SETTINGS = {
  trim: true,
};

moment.updateLocale('en', {
  calendar: {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    lastWeek: 'ddd, ll',
    sameElse: 'ddd, ll',
  },
});

interface Duration extends moment.Duration {
  format: (template?: string, settings?: DurationSettings) => string;
}

interface DurationSettings {
  forceLength?: boolean;
  precision?: number;
  template?: string;
  trim: boolean | 'left' | 'right';
  trunc?: boolean;
}

interface StaticDuration {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}

export const round = (time: number) => (time < 10 ? `0${time}` : time);

/** Returns a d[d] h[h] m[m] formatted string of hours with optional precision.
 * @param {Number} hours Time in hours to be formatted
 * @param {FormattedDurationMaxLength} maxTerms Max number of terms used to describe the duration (default: 2)
 */
export const getFormattedDurationFromHours = (
  hours: number,
  maxTerms: FormattedDurationMaxLength = DEFAULT_MAX_TERMS
) => getFormattedDurationFromMinutes(hours * 60, maxTerms);

/** Returns a d[d] h[h] m[m] formatted string of minutes with optional precision.
 * @param {Number} minutes Time in minutes to be formatted
 * @param {FormattedDurationMaxLength} maxTerms Max number of terms used to describe the duration (default: 2)
 */
export const getFormattedDurationFromMinutes = (
  minutes: number,
  maxTerms: FormattedDurationMaxLength = DEFAULT_MAX_TERMS
) => getFormattedDurationFromSeconds(minutes * 60, maxTerms);

/** Returns a d[d] h[h] m[m] formatted string of seconds with optional precision.
 * @param {Number} seconds Time in seconds to be formatted
 * @param {FormattedDurationMaxLength} maxTerms Max number of terms used to describe the duration (default: 2)
 */
export const getFormattedDurationFromSeconds = (
  seconds: number,
  maxTerms: FormattedDurationMaxLength = DEFAULT_MAX_TERMS,
  includeSeconds: boolean = false,
  durationSettings: DurationSettings = DEFAULT_MAX_DURATION_SETTINGS
) => {
  const duration = moment.duration(seconds, 's') as Duration;
  const patternTerms = [];
  if (seconds > 86400) {
    patternTerms.push('d[d]');
  }
  if (seconds > 3600) {
    patternTerms.push('h[h]');
  }
  patternTerms.push('m[m]');

  if (includeSeconds) {
    patternTerms.push('s[s]');
  }

  return duration
    .format(patternTerms.splice(0, maxTerms).join(' '), durationSettings)
    .replace(/( 0\w)+$/, '');
};

export const getCalendarDateTimeStringFromDate = (time: Date) =>
  moment(time).format('MMMM D, Y [-] hh:mm A');

export const getCalendarDateStringFromDate = (time: Date) =>
  moment(time).format('MMMM D, Y');

export const fromNowFormat = (time: number) =>
  `Added ${moment(time).fromNow()}`;

export const getFormattedHour = (time: Date) => moment(time).format('h:mm A');

export const getRelativeCalendar = (time: number) => moment(time).calendar();

export const getRelativeCalendarWithTime = (time: number) =>
  moment(time).calendar(moment(), {
    lastDay: '[Yesterday] @ h:mma',
    sameDay: '[Today] @ h:mma',
    sameElse: 'MMMM D, YYYY @ h:mma',
  });

export const formatHoursAndMinutes = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  minutes %= 60;
  const parts = [];
  if (hours > 0) {
    parts.push(`${hours}h`);
  }
  if (minutes > 0) {
    parts.push(`${minutes}m`);
  }
  return parts.join(' ');
};

export const getDateAndTimeWithTimeZone = (date: Date) =>
  moment(date).format('MM/DD/YYYY hh:mm zz');

export const formatDuration = (duration?: StaticDuration) => {
  if (duration) {
    const { days, hours, minutes, seconds } = duration;

    const displayDays = days ? `${days}d ` : '';
    const displayHours = hours ? `${hours}h ` : '';
    const displayMinutes = minutes ? `${minutes}m ` : '';
    const displaySeconds = seconds ? `${seconds}s` : '';

    const formattedDuration = `${displayDays}${displayHours}${displayMinutes}${displaySeconds}`;

    return formattedDuration || strings.EMPTY_VALUE;
  }

  return strings.EMPTY_VALUE;
};

export function getApiDateStringFromDate(date: moment.Moment) {
  const month =
    date.get('month') > 8
      ? date.get('month') + 1
      : '0' + (date.get('month') + 1);
  const day = date.get('date') > 9 ? date.get('date') : '0' + date.get('date');
  const year = date.get('year');

  return `${month}/${day}/${year}`;
}

export function getHyphenatedApiDateStringFromDate(date: moment.Moment) {
  const month =
    date.get('month') > 8
      ? date.get('month') + 1
      : '0' + (date.get('month') + 1);
  const day = date.get('date') > 9 ? date.get('date') : '0' + date.get('date');
  const year = date.get('year');

  return `${year}-${month}-${day}`;
}
