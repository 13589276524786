import React from 'react';
import Pagination from './Pagination';
interface SmartPaginationProps {
  onPageSizeChange: (size: number) => void;
  onPageChange: (page: number) => void;
  totalItems: number | null;
  page: number;
  pageSize: number;
}
const SmartPagination: React.FC<SmartPaginationProps> = ({
  totalItems,
  pageSize,
  page,
  onPageChange,
  onPageSizeChange,
}) => {
  const totalPages = totalItems ? Math.ceil(totalItems / pageSize) : null;
  const canGoToNextPage = !!totalPages && page < totalPages - 1;
  const canGoToPreviousPage = !!totalPages && page > 0;
  return (
    <Pagination
      canGoToNextPage={canGoToNextPage}
      canGoToPreviousPage={canGoToPreviousPage}
      onNextPage={() => canGoToNextPage && onPageChange(page + 1)}
      onPreviousPage={() => canGoToPreviousPage && onPageChange(page - 1)}
      onPageSizeChange={onPageSizeChange}
      page={page}
      pageSize={pageSize}
      totalItems={totalItems}
    />
  );
};
export default SmartPagination;
