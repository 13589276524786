import { navigate } from '@reach/router';
import {
  getCredentialValue,
  saveCredentials,
} from 'api/lib/localStorageManagement';
import { defaultMutationFn } from 'api/useAPI';
import { Authentication, Location } from 'models';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSwitchRooftopMutation } from './useSwitchRooftopMutation';

const TWELVE_HOURS = 12 * 60 * 60 * 1000;

export function useToken() {
  const path = '/authenticate/token';
  const enabled = !!getCredentialValue('authBearerToken');
  return useQuery<Authentication>(path, {
    enabled,
    staleTime: TWELVE_HOURS,
    cacheTime: TWELVE_HOURS,
  });
}

interface useChangeLocationArgs {
  locationId: string;
  routePath?: string;
}
export function useChangeLocation() {
  const client = useQueryClient();
  const { mutateAsync: switchRooftop } = useSwitchRooftopMutation();
  return useMutation(
    ({ locationId, routePath }: useChangeLocationArgs) =>
      new Promise(async (resolve) => {
        const user = await defaultMutationFn(
          `/authenticate/locations/${locationId}`,
          'POST'
        );
        saveCredentials({
          authBearerToken: user.token,
          authBearerTokenExpiration: user.expirationDate,
          authRefreshToken: user.refreshToken,
        });

        client.setQueryData('/authenticate/token', user, {
          updatedAt: Date.now(),
        });

        if (routePath) {
          navigate(routePath);
        }

        await switchRooftop();

        resolve(user);
      })
  );
}

export function useLocations() {
  const path = '/authenticate/locations';
  return useQuery<Location[]>(path);
}

export function useCurrentLocation() {
  const { data } = useToken();
  return data?.location;
}
