import React from 'react';
import { Router } from '@reach/router';

import ChangePassword from 'components/pages/ChangePassword';
import ForgotPassword from 'components/pages/ForgotPassword';
import Login from 'components/pages/Login';

import {
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  VELOCITY_INSIGHT,
  LOGIN,
} from './routes';
import UserSessionRouter from './UserSessionRouter';
import VehicleCardExtension from 'components/pages/VehicleCollectionBrowser/VehicleCard/VehicleCardExtension';
import { VelocityInsightVehicleDetails } from 'components/pages/VehicleDetails/VelocityInsightVehicleDetails';
import ProtectedRoute from './ProtectedRoute';

const AppRouter: React.FC = () => {
  return (
    <Router>
      <ForgotPassword path={FORGOT_PASSWORD} />
      <ChangePassword path={CHANGE_PASSWORD} />
      <Login path={LOGIN} />
      <VehicleCardExtension
        path={`${VELOCITY_INSIGHT}/tenant/:tenantId/inventory/:inventoryId/card`}
      />
      <VelocityInsightVehicleDetails
        path={`${VELOCITY_INSIGHT}/tenant/:tenantId/inventory/:inventoryId/details/*`}
      />
      <ProtectedRoute default component={UserSessionRouter} />
    </Router>
  );
};

export default AppRouter;
