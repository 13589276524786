import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { isUndefined } from 'lodash';

import CircularProgressChart from 'components/shared/CircularProgressChart';

import './ReconProgressSection.scss';
import StepDropdown from 'components/shared/StepDropdown';
import UsersDropdown from 'components/shared/UsersDropdown';
import ProgressBar from 'components/shared/ProgressBar';
import strings from 'common/strings';
import StepButtons from 'components/shared/StepButtons';
import { getFormattedDurationFromSeconds } from 'utils/time';
import { testIds } from 'common/testIds';
import { VehicleSummary } from 'models';

interface ReconProgressSectionProps {
  vehicleSummary: VehicleSummary;
  vehicleId: string;
}

const ReconProgressSection: React.FC<ReconProgressSectionProps> = ({
  vehicleSummary,
  vehicleId,
}) => {
  const currentStep = vehicleSummary.stepItem;

  let formattedActualStepDuration: string | undefined;
  let formattedTargetStepDuration: string | undefined;

  if (currentStep) {
    formattedActualStepDuration = getFormattedDurationFromSeconds(
      currentStep?.reconTimeInStepSeconds || 0
    );
    formattedTargetStepDuration = isUndefined(
      currentStep?.recommendedDurationSeconds
    )
      ? ''
      : getFormattedDurationFromSeconds(
          currentStep?.recommendedDurationSeconds
        );
  }

  const computePercentage = (value1: number, value2: number) => {
    let percentage =
      value1 && value2 ? Number((value1 / value2).toFixed(4)) * 100 : 0;
    if (percentage > 100) percentage = 100;
    return percentage;
  };

  const computeStatus = (current: number, goal: number, warn: number) =>
    current < warn ? 'ontime' : current < goal ? 'warning' : 'danger';

  const retailReadyProgress = {
    percentage:
      vehicleSummary.retailReadyTimeAsSeconds &&
      vehicleSummary.retailReadyGoalTimeAsSeconds
        ? computePercentage(
            vehicleSummary.retailReadyTimeAsSeconds,
            vehicleSummary.retailReadyGoalTimeAsSeconds
          )
        : strings.EMPTY_VALUE,
    status:
      vehicleSummary.retailReadyTimeAsSeconds &&
      vehicleSummary.retailReadyGoalTimeAsSeconds &&
      vehicleSummary.retailReadyWarningTimeAsSeconds
        ? computeStatus(
            vehicleSummary.retailReadyTimeAsSeconds,
            vehicleSummary.retailReadyGoalTimeAsSeconds,
            vehicleSummary.retailReadyWarningTimeAsSeconds
          )
        : '',
  };

  const reconTimeProgress = {
    percentage:
      vehicleSummary.reconTimeAsSeconds && vehicleSummary.reconGoalTimeAsSeconds
        ? computePercentage(
            vehicleSummary.reconTimeAsSeconds,
            vehicleSummary.reconGoalTimeAsSeconds
          )
        : strings.EMPTY_VALUE,
    status:
      vehicleSummary.reconTimeAsSeconds &&
      vehicleSummary.reconGoalTimeAsSeconds &&
      vehicleSummary.reconWarningTimeAsSeconds
        ? computeStatus(
            vehicleSummary.reconTimeAsSeconds,
            vehicleSummary.reconGoalTimeAsSeconds,
            vehicleSummary.reconWarningTimeAsSeconds
          )
        : '',
  };

  return (
    <Container
      fluid
      className="ReconProgressSection"
      data-vas-testing={testIds.RECONVELOCITY_PROGRESS_SECTION_CONTAINER}
    >
      <Row
        data-vas-testing={testIds.RECONVELOCITY_PROGRESS_SECTION_CHART}
        className="ReconProgressSection-charts"
        noGutters
      >
        <Col xs="auto">
          <CircularProgressChart
            id="retail-ready-chart"
            title="Retail-Ready"
            timeLogged={
              vehicleSummary?.retailReadyTimeAsSeconds
                ? getFormattedDurationFromSeconds(
                    vehicleSummary.retailReadyTimeAsSeconds
                  )
                : strings.EMPTY_VALUE
            }
            goalTime={
              vehicleSummary?.retailReadyGoalTimeAsSeconds
                ? getFormattedDurationFromSeconds(
                    vehicleSummary.retailReadyGoalTimeAsSeconds
                  ).toUpperCase()
                : undefined
            }
            percentage={retailReadyProgress.percentage}
            variant={
              retailReadyProgress.status as
                | 'ok'
                | 'ontime'
                | 'danger'
                | 'warning'
            }
          />
        </Col>
        <Col className="d-flex justify-content-end" xs="auto">
          <CircularProgressChart
            id="recon-time-chart"
            title="Recon Time"
            timeLogged={
              vehicleSummary?.reconTimeAsSeconds &&
              vehicleSummary.reconTimeAsSeconds > 0
                ? getFormattedDurationFromSeconds(
                    vehicleSummary.reconTimeAsSeconds
                  )
                : strings.EMPTY_VALUE
            }
            goalTime={
              vehicleSummary?.reconGoalTimeAsSeconds &&
              vehicleSummary?.reconTimeAsSeconds > 0
                ? getFormattedDurationFromSeconds(
                    vehicleSummary.reconGoalTimeAsSeconds
                  ).toUpperCase()
                : undefined
            }
            percentage={reconTimeProgress.percentage}
            variant={
              reconTimeProgress.status as 'ok' | 'ontime' | 'danger' | 'warning'
            }
          />
        </Col>
      </Row>
      <Row className="ReconProgressSection-progress" noGutters>
        <Col className="ReconProgressSection-progress-title">
          {strings.RECON_STEP_PROGRESS}
        </Col>
      </Row>
      {currentStep && (
        <Row className="ReconProgressSection-row-container" noGutters>
          <Col className="ReconProgressSection-info-title">
            {strings.CURRENT_STEP}
            <StepDropdown currentStep={currentStep} vehicleId={vehicleId} />
          </Col>
          <Col className="ReconProgressSection-info-title">
            {strings.ASSIGNEE}
            <UsersDropdown currentStep={currentStep} vehicleId={vehicleId} />
          </Col>
        </Row>
      )}
      {currentStep && (
        <>
          <Row noGutters>
            <Col className="ReconProgressSection-info-title">
              {strings.PROGRESS}
            </Col>
            <Col className="ReconProgressSection-info-value d-flex justify-content-end">
              <div id="step-progress-duration-text">
                {`${formattedActualStepDuration} / ${formattedTargetStepDuration}`}
              </div>
            </Col>
          </Row>
          <Row className="ReconProgressSection-row-container" noGutters>
            <Col>
              <ProgressBar currentStep={currentStep} />
            </Col>
          </Row>
        </>
      )}
      {currentStep && (
        <Row noGutters>
          <Col
            className="ReconProgressSection-step-button"
            data-vas-testing={testIds.RECON_PROGRESS_STEP_BUTTON_CONTAINER}
          >
            <StepButtons vehicleId={vehicleId} currentStep={currentStep} />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ReconProgressSection;
