import React from 'react';
import { EngagedShopper } from 'models';

import strings from 'common/strings';
import { getCalendarDateTimeStringFromDate } from 'utils/time';
import { useWindowSize } from 'hooks';

import './MostEngagedShoppers.scss';

interface MostEngagedShoppersProps {
  engagedShoppers: EngagedShopper[];
}

const MostEngagedShoppers: React.FC<MostEngagedShoppersProps> = ({
  engagedShoppers = [],
}) => {
  const windowSize = useWindowSize();

  const renderLargeMostEngagedShoppers = () => (
    <table className="MostEngagedShoppers-table">
      <tr className="MostEngagedShoppers-table-header">
        <th>{strings.NAME}</th>
        <th>{strings.OPENS}</th>
        <th>{strings.ENGAGEMENTS}</th>
        <th>{strings.LAST_ACTIVE}</th>
      </tr>
      {engagedShoppers.map((shopper) => (
        <tr>
          <td className="MostEngagedShoppers-table-item">{shopper.name}</td>
          <td className="MostEngagedShoppers-table-item">
            {shopper.engagements}
          </td>
          <td className="MostEngagedShoppers-table-item">{shopper.actions}</td>
          <td className="MostEngagedShoppers-table-item">
            {shopper.lastAction === null
              ? '--'
              : getCalendarDateTimeStringFromDate(new Date(shopper.lastAction))}
          </td>
        </tr>
      ))}
    </table>
  );

  const renderSmallMostEngagedShoppers = () =>
    engagedShoppers.map((shopper) => (
      <div className="MostEngagedShoppers-small">
        <div className="MostEngagedShoppers-small-row">
          <div className="MostEngagedShoppers-small-label">{strings.NAME}</div>
          <div className="MostEngagedShoppers-small-value">{shopper.name}</div>
        </div>
        <div className="MostEngagedShoppers-small-row">
          <div className="MostEngagedShoppers-small-label">
            {strings.ENGAGEMENTS}
          </div>
          <div className="MostEngagedShoppers-small-value">
            {shopper.engagements}
          </div>
          <div className="MostEngagedShoppers-small-label">
            {strings.ACTIONS}
          </div>
          <div className="MostEngagedShoppers-small-value">
            {shopper.actions}
          </div>
        </div>
        <div className="MostEngagedShoppers-small-row">
          <div className="MostEngagedShoppers-small-label">
            {strings.LAST_ACTIVE}
          </div>
          <div className="MostEngagedShoppers-small-value">
            {shopper.lastAction === null
              ? '--'
              : getCalendarDateTimeStringFromDate(new Date(shopper.lastAction))}
          </div>
        </div>
      </div>
    ));

  return (
    <div className="MostEngagedShoppers">
      <div className="MostEngagedShoppers-title">
        {strings.MOST_ENGAGED_SHOPPERS}
      </div>
      {engagedShoppers.length === 0 ? (
        <div className="MostEngagedShoppers-empty-title">
          {strings.NO_ENGAGED_SHOPPERS}
        </div>
      ) : windowSize.isMobileViewport() ? (
        renderSmallMostEngagedShoppers()
      ) : (
        renderLargeMostEngagedShoppers()
      )}
    </div>
  );
};

export default MostEngagedShoppers;
