import React, { useEffect, useMemo, useState } from 'react';
import { TextInput } from 'components/shared/Inputs';
import Select from 'react-select';
import strings from 'common/strings';
import Modal from 'components/shared/Modal';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { Profile, User } from 'models';
import { useProfile } from 'api';
import '../../../../scss/theme/theme.scss';
import './EditProfileModal.scss';

export interface EditProfileModalProps {
  onClose: any;
}

const EditProfileModal: React.FC<EditProfileModalProps> = ({ onClose }) => {
  const {
    query: { isLoading, error, data: { data } = {} },
    mutation,
  } = useProfile();

  const [formData, setFormData] = useState<Profile | undefined>(undefined);

  function onSubmitEditProfile() {
    if (!formData?.user) return;
    // @todo - validation of fields here
    // @todo - should we just use Formik?
    mutation.mutate(
      {
        ...formData.user,
        email: formData.user.emailAddress,
        phone: formData.user.mobilePhone,
        defaultLocationId: formData.defaultLocation.id,
      },
      { onSuccess: onClose }
    );
  }

  function handleProfileUpdate(field: keyof User, value: string) {
    if (!formData) return;
    const newData: Profile = {
      ...formData,
      user: { ...formData?.user, [`${field}`]: value },
    };
    setFormData(newData);
  }

  function handleSelectRooftop(option: any) {
    const rooftop = formData?.locations.find((i) => i.id === option.value);
    if (rooftop && formData) {
      setFormData({ ...formData, defaultLocation: rooftop });
    }
  }

  useEffect(() => {
    if (formData === undefined && data?.user) {
      setFormData(data);
    }
  }, [data, formData]);

  // Build our select options only once
  const rooftopOptions = useMemo(
    () => data?.locations?.map((i) => ({ value: i.id, label: i.name })) || [],
    [data]
  );

  // Keeping things clean, build text input props once.
  const inputProps = [
    {
      key: 'firstName',
      placeholder: strings.EDIT_PROFILE_FIRST_NAME,
      required: false,
    },
    {
      key: 'lastName',
      placeholder: strings.EDIT_PROFILE_LAST_NAME,
      required: true,
    },
    {
      key: 'emailAddress',
      placeholder: strings.EDIT_PROFILE_EMAIL,
      required: true,
    },
    {
      key: 'mobilePhone',
      placeholder: strings.EDIT_PROFILE_PHONE,
      required: true,
    },
  ].map((fieldProps) => ({
    ...fieldProps,
    containerClassName: 'mt-4 mb-0 border-primary',
    helperTextClassName: 'mt-2',
    placeholderClassName: 'text-primary',
  }));

  return (
    <Modal
      title={strings.EDIT_PROFILE_TITLE}
      open
      onClose={onClose}
      primaryButtonLabel={mutation.isLoading ? 'saving...' : strings.SAVE}
      onPrimaryButtonClick={onSubmitEditProfile}
      primaryButtonDisabled={mutation.isLoading}
      secondaryButtonLabel={strings.CANCEL}
      primaryButtonLoading={mutation.isLoading}
      className="EditProfileModal"
    >
      {isLoading && <LoadingIndicator />}
      {error && <>Something went wrong</>}
      {formData?.user && (
        <>
          {inputProps.map((input) => {
            const field = input.key as keyof User;
            const value = formData.user[field];
            return (
              <TextInput
                onChange={(e) => handleProfileUpdate(field, e.target.value)}
                value={`${value}` || ''}
                placeholder={input.placeholder}
                required={false}
                disabled={mutation.isLoading}
                {...inputProps}
              />
            );
          })}
          <Select
            className="mt-4"
            placeholder={strings.EDIT_PROFILE_DEFAULT_ROOFTOP_LOCATION}
            options={rooftopOptions}
            onChange={handleSelectRooftop}
            value={{
              label: formData?.defaultLocation?.name || '',
              value: formData?.defaultLocation?.id || '',
            }}
          />
          <span className="select-floating-label">
            {strings.EDIT_PROFILE_DEFAULT_ROOFTOP_LOCATION}
          </span>
        </>
      )}
    </Modal>
  );
};
export default EditProfileModal;
