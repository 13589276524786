import { useMutation, useQueryClient } from 'react-query';
import useUploadStore from 'store/uploads/useUploadStore';

export function useSwitchRooftopMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      new Promise(async (resolve) => {
        queryClient.clear();
        useUploadStore.getState().closeUppyInstances();
        await useUploadStore.getState().initialize();
        resolve(true);
      })
  );
}
