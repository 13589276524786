/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import strings from 'common/strings';
import { TabProps } from 'components/shared/Tabs/Tabs';
import LoadingIndicator from 'components/shared/LoadingIndicator';

import InventoryVehicleSummary from 'models/inventory/inventoryVehicleSummary';
import { testIds } from 'common/testIds';
import { useDashboardAssignments } from 'api';
import AssignmentsCard from '../AssignmentsCard/AssignmentsCard';

const AssignmentsTab: React.FC<TabProps> = () => {
  const { data: { data } = {}, isLoading, error } = useDashboardAssignments();
  if (isLoading) {
    return (
      <div className="vehicle-attention-grid">
        <Row>
          <LoadingIndicator />
        </Row>
      </div>
    );
  }

  if (!data?.length || error) {
    return (
      <Col
        xs={12}
        className="Dashboard-no-notices-col"
        data-vas-testing={testIds.DASHBOARD_ASSIGNMENT_EMPTY_STATE}
      >
        <div className="Dashboard-no-notices-title">
          {String(strings.NO_ASSIGNMENTS).toUpperCase()}
        </div>
        <div className="Dashboard-no-notices-message">
          {strings.NO_ASSIGNMENTS_MESSAGE}
        </div>
      </Col>
    );
  }

  return (
    <div
      className="vehicle-attention-grid"
      data-vas-testing={testIds.DASHBOARD_ASSIGNMENT_CONTAINER}
    >
      <Row>
        {data.map(
          (inventoryVehicleSummary: InventoryVehicleSummary, idx: number) => (
            <Col
              key={`${idx.toString()}`}
              xl="4"
              md="6"
              className="AssignmentsCard-col"
            >
              <AssignmentsCard
                index={idx}
                inventoryVehicleSummary={inventoryVehicleSummary}
              />
            </Col>
          )
        )}
      </Row>
    </div>
  );
};

export default AssignmentsTab;
