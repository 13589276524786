import React, { useState, useRef, useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import strings from 'common/strings';
import InputLabel from '@material-ui/core/InputLabel';
import lodash from 'lodash';

import './EditTagsModal.scss';
import Select from 'components/shared/Inputs/Select';
import { useAssignTags, useInventoryTags, useUnassignTags } from 'api';
import { VehicleTag } from 'models/inventory/vehicleTag';
import CreateTagModal from './CreateTagModal';
import LoadingIndicator from '../LoadingIndicator';
import TagSrp from 'components/pages/VehicleCollectionBrowser/VehicleCard/components/VehicleCardTagListAndActions/TagListSrp/TagSrp';
import permissions from 'common/permissions';
import { usePermissions } from 'hooks';

interface TagOption {
  label: string;
  value: string;
}

interface EditTagsModalProps {
  onClose: () => void;
  tags?: VehicleTag[];
  vehicleId?: string;
}

const EditTagsModal: React.FC<React.PropsWithChildren<EditTagsModalProps>> = ({
  onClose,
  tags,
  vehicleId,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const assignTagsMutation = useAssignTags(vehicleId);
  const unassignTagsMutation = useUnassignTags(vehicleId);
  const isSubmitting =
    assignTagsMutation.isLoading || unassignTagsMutation.isLoading;
  const [createTagText, setCreateTagText] = useState('');
  const [stateTags, setStateTags] = useState(tags || []);
  const [tagsToRemove, setTagsToRemove] = useState<VehicleTag[]>([]);
  const [tagsToAdd, setTagsToAdd] = useState<VehicleTag[]>([]);

  const [shouldShowCreateTagModal, setShouldShowCreateTagModal] =
    useState(false);
  const { hasPermission } = usePermissions();

  const setShouldShowCreateTagModalWithAudit = (value: boolean) => {
    setShouldShowCreateTagModal(value);
  };

  const openCreateTagModal = () => {
    setShouldShowCreateTagModalWithAudit(true);
  };

  const closeCreateTagModal = () => {
    setShouldShowCreateTagModalWithAudit(false);
  };

  const { data: allRooftopTags } = useInventoryTags();
  const tagOptions = useMemo(
    () =>
      allRooftopTags
        ? lodash
            .differenceBy(allRooftopTags, stateTags, 'tagId')
            .reduce(
              (acc: TagOption[], tag: VehicleTag) =>
                tag.tagType !== strings.TAG_TYPE_SYSTEM &&
                tag.tagId &&
                tag.value
                  ? acc.concat({ value: tag.tagId, label: tag.value })
                  : acc,
              [] as TagOption[]
            )
        : [],
    [allRooftopTags, stateTags]
  );

  const removeTag = (tag: VehicleTag) => {
    setStateTags(
      stateTags.filter((listTag: VehicleTag) => listTag.tagId !== tag.tagId)
    );
    setTagsToAdd(
      tagsToAdd.filter((listTag: VehicleTag) => listTag.tagId !== tag.tagId)
    );
    setTagsToRemove([...tagsToRemove, tag]);
  };

  const addCreatedTag = (newTag: VehicleTag) => {
    setStateTags([...stateTags, newTag]);
  };

  const onSave = async () => {
    try {
      if (tagsToAdd.length > 0) {
        await assignTagsMutation.mutateAsync(tagsToAdd);
      }
      if (tagsToRemove.length > 0) {
        await unassignTagsMutation.mutateAsync(tagsToRemove);
      }
    } catch (error) {
      // Todo: implement error UX and logging. https://carpediem.atlassian.net/browse/VAS-5089
    }
  };

  return (
    <Modal
      show
      centered
      onHide={onClose}
      className="EditTagsModal"
      animation={false}
      backdrop="static"
    >
      <Modal.Header className="modal-header-container">
        <Modal.Title>{strings.EDIT_TAGS_MODAL_TITLE}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="EditTagsModal-body flex-grow">
        {stateTags.length !== 0 && !shouldShowCreateTagModal && (
          <div className="EditTagsModal-tags-row">
            {stateTags.map((tag: VehicleTag) => (
              <TagSrp tag={tag} removeTag={removeTag} removable />
            ))}
          </div>
        )}
        <InputLabel shrink id="tags-dropdown-label">
          {strings.EDIT_TAGS_DROPDOWN_LABEL}
        </InputLabel>
        <Select
          isSearchable
          isCreatable
          options={tagOptions}
          placeholder="Add tags..."
          onChange={(item: any) => {
            const { value: tagId } = item;
            const tagToAdd = allRooftopTags?.find(
              (listTag) => listTag.tagId === tagId
            );
            if (tagToAdd) {
              setStateTags([...stateTags, tagToAdd]);
              setTagsToAdd([...tagsToAdd, tagToAdd]);
              setTagsToRemove(
                tagsToRemove.filter(
                  (listTag: VehicleTag) => listTag.tagId !== tagToAdd.tagId
                )
              );
            }
          }}
          formatCreateLabel={(inputValue: string) =>
            `Create new tag "${inputValue}"`
          }
          onCreateOption={(item: string) => {
            if (buttonRef && buttonRef.current) {
              buttonRef.current.focus();
            }
            setCreateTagText(item);
            openCreateTagModal();
          }}
          validator={(inputValue: string) => {
            const existingTags = allRooftopTags?.filter(
              (tag: VehicleTag) =>
                tag.value?.trim().toLowerCase() ===
                inputValue.trim().toLowerCase()
            );
            return (
              existingTags?.length === 0 &&
              hasPermission(permissions.TAGS_CREATE) &&
              inputValue.trim().length > 0
            );
          }}
          controlShouldRenderValue={false}
          closeMenuOnSelect={false}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="EditTagsModal-footer">
          <button
            type="button"
            className="EditTagsModal-cancel-button"
            onClick={onClose}
          >
            {strings.CANCEL}
          </button>
          <button
            ref={buttonRef}
            type="button"
            className="blue-button EditTagsModal-save-button"
            onClick={async () => {
              await onSave();
              onClose();
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <LoadingIndicator size={30} /> : strings.SAVE}
          </button>
        </div>
      </Modal.Footer>
      {shouldShowCreateTagModal && (
        <CreateTagModal
          onCreateTag={addCreatedTag}
          onClose={closeCreateTagModal}
          text={createTagText}
          existingTags={allRooftopTags}
          vehicleId={vehicleId}
        />
      )}
    </Modal>
  );
};

export default EditTagsModal;
