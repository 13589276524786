import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import validateJs from 'validate.js';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { navigate } from '@reach/router';

import strings from 'common/strings';
import images from 'common/images';
import Alert from 'components/shared/Alert';
import Wrapper from 'components/shared/Wrapper';
import { changePasswordValidations } from './validations';

import './ChangePassword.scss';
import { useResetPassword } from 'api';

function getTokenFromUrl() {
  const regex = /token=([^&]*)/;
  const matches = window!.location!.href!.match(regex);
  const token = matches && matches[1];
  return token;
}

const ChangePassword: React.FC<React.PropsWithChildren<any>> = () => {
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [confirmPasswordError, setConfirmPasswordError] = useState<
    string | null
  >(null);
  const [formError, setFormError] = useState<boolean>(false);

  const clearErrors = (): void => {
    setPasswordError(null);
    setConfirmPasswordError(null);
    setFormError(false);
  };

  const validateFields = (): boolean => {
    clearErrors();
    const results = validateJs(
      { password, confirmPassword },
      { ...changePasswordValidations }
    );

    if (results) {
      setPasswordError(
        results.password && results.password.length ? results.password[0] : null
      );
      setConfirmPasswordError(
        results?.confirmPassword.length ? results.confirmPassword[0] : null
      );
      return false;
    }

    return true;
  };
  const token = getTokenFromUrl();
  const { mutateAsync: changePassword, isLoading } = useResetPassword({
    passwordToken: token!,
    newPassword: password,
  });

  const handleSubmit = async (): Promise<void> => {
    if (validateFields()) {
      try {
        if (token) {
          await changePassword();
          await navigate('/', { replace: true });
        }
      } catch (e) {
        setFormError(true);
      }
    }
  };

  const handleClose = () => {
    setFormError(false);
  };

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      component="main"
      className="root page-container"
    >
      <Grid
        item
        component={Paper}
        elevation={6}
        xs={12}
        className="form-container"
        square
      >
        <img alt="recon-img" src={images.ReconIcon} className="change-logo" />
        <Typography variant="h4" className="blue-text font-bold">
          {strings.CHANGE_PASSWORD_TITLE}
        </Typography>
        <Typography
          paragraph
          variant="subtitle1"
          className="grey-text font-light"
        >
          {strings.CHANGE_PASSWORD_DESCRIPTION}
        </Typography>
        <Typography variant="caption" className="blue-text font-bold">
          {strings.CHANGE_PASSWORD_SUBTITLE}
        </Typography>
        <Alert
          open={formError}
          contentProps={{
            message: strings.API_MESSAGE,
            variant: 'error',
            onClose: handleClose,
          }}
        />
        <ul className="list">
          <li>
            <Typography
              variant="caption"
              className="grey-text font-light list-item"
            >
              {strings.CHANGE_PASSWORD_TEXT_1}
            </Typography>
          </li>
          <li>
            <Typography
              variant="caption"
              className="grey-text font-light list-item"
            >
              {strings.CHANGE_PASSWORD_TEXT_2}
            </Typography>
          </li>
          <li>
            <Typography
              variant="caption"
              className="grey-text font-light list-item"
            >
              {strings.CHANGE_PASSWORD_TEXT_3}
            </Typography>
          </li>
          <li>
            <Typography
              variant="caption"
              className="grey-text font-light list-item"
            >
              {strings.CHANGE_PASSWORD_TEXT_4}
            </Typography>
          </li>
        </ul>
        <Wrapper class={passwordError ? 'input-error' : 'input'}>
          <TextField
            variant="filled"
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            id="password"
            label={strings.PASSWORD_FIELD}
            error={!!passwordError || formError}
            helperText={passwordError}
            name="password"
            className="double-margin-bottom"
            autoFocus
            disabled={isLoading}
            type="password"
          />
        </Wrapper>
        <Wrapper class={confirmPasswordError ? 'input-error' : 'input'}>
          <TextField
            variant="filled"
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
            id="confirm-password"
            label={strings.CONFIRM_PASSWORD_FIELD}
            error={!!confirmPasswordError || formError}
            helperText={confirmPasswordError}
            name="confirmPassword"
            className="double-margin-bottom"
            autoFocus
            disabled={isLoading}
            type="password"
          />
        </Wrapper>
        <Button
          id="change-password"
          type="submit"
          fullWidth
          variant="contained"
          className="submit blue-button double-margin-bottom"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {strings.CHANGE_PASSWORD_BUTTON_TEXT}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ChangePassword;
