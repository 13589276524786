import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import validateJs from 'validate.js';

import strings from 'common/strings';
import images from 'common/images';
import Alert from 'components/shared/Alert';
import Wrapper from 'components/shared/Wrapper';
import { forgotPasswordValidations } from './validations';

import './ForgotPassword.scss';
import { useSendResetPassword } from 'api';

const ForgotPassword: React.FC<React.PropsWithChildren<any>> = ({
  navigate,
}) => {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [formError, setFormError] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const clearErrors = (): void => {
    setEmailError(null);
    setFormError(false);
  };

  const validateFields = (): boolean => {
    clearErrors();
    const results = validateJs({ email }, { ...forgotPasswordValidations });

    if (results) {
      setEmailError(
        results.email && results.email.length ? results.email[0] : null
      );
      return false;
    }

    return true;
  };

  const handleNavigateLogin = () => {
    navigate('/');
  };

  const { mutateAsync: sendPasswordResetEmail, isLoading } =
    useSendResetPassword(email);

  const handleSubmit = async () => {
    if (validateFields()) {
      try {
        await sendPasswordResetEmail();
        setSubmitted(true);
      } catch (e) {
        setFormError(true);
      }
    }
  };

  const handleClose = () => {
    setFormError(false);
  };

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      component="main"
      className="root page-container"
    >
      <Grid
        item
        component={Paper}
        elevation={6}
        xs={12}
        className="form-container"
        square
      >
        <Alert
          open={formError}
          contentProps={{
            message: strings.API_MESSAGE,
            variant: 'error',
            onClose: handleClose,
          }}
        />
        <img alt="recon-img" src={images.ReconIcon} className="reset-logo" />
        <Typography paragraph variant="h4" className="blue-text-text font-bold">
          {strings.FORGOT_PASSWORD_TITLE}
        </Typography>
        {submitted ? (
          <Typography
            paragraph
            variant="subtitle1"
            className="blue-text font-bold"
          >
            {strings.FORGOT_PASSWORD_CONFIRMATION_EMAIL(email)}
          </Typography>
        ) : (
          <>
            <Typography
              paragraph
              variant="subtitle1"
              className="grey-text font-light"
            >
              {strings.FORGOT_PASSWORD_DESCRIPTION}
            </Typography>
            <Wrapper class={emailError ? 'input-error' : 'input'}>
              <TextField
                variant="filled"
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                id="email"
                label={strings.EMAIL_FIELD}
                error={!!emailError || formError}
                helperText={emailError}
                name="email"
                autoComplete="email"
                className="double-margin-bottom"
                autoFocus
                disabled={isLoading}
              />
            </Wrapper>
            <Button
              id="reset-password"
              type="submit"
              fullWidth
              variant="contained"
              className="submit blue-button double-margin-bottom"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {strings.FORGOT_PASSWORD_BUTTON_TEXT}
            </Button>
          </>
        )}
        <Button
          id="return-login"
          type="submit"
          fullWidth
          variant="text"
          className={`submit ${submitted && 'blue-button'}`}
          onClick={handleNavigateLogin}
          disabled={isLoading}
          color="secondary"
        >
          {strings.FORGOT_PASSWORD_BUTTON_RETURN_TEXT}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
