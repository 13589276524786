export const getExtensionUrlParams = () => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get('source') || '';
    if (source === 'extension' && urlParams.get('sourceId')) {
      return {
        source: source,
        sourceId: urlParams.get('sourceId'),
        purpose: urlParams.get('purpose'),
      };
    }
  } catch (e) {
    // Implement error logging in VAS-5089
  }
};
