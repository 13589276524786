import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

import PermissionsGate from 'components/shared/PermissionsGate/PermissionsGate';
import VehicleImageView from 'components/shared/VehicleImageView';
import TagList from 'components/pages/VehicleCollectionBrowser/VehicleCard/components/VehicleCardTagListAndActions/TagListSrp';
import permissions from 'common/permissions';
import { vehicleExtensionCardBuilder } from 'navigation/routes';
import { navigate } from '@reach/router';
import { extensionStrings } from 'common/constants';
import {
  sanitizeWindowPostMessage,
  NewHoverTargetMessage,
  sendMessageToParentViaWindow,
  ID_LENGTH,
  isSupportedExtention,
} from 'utils/extension';
import { VehicleCardTabType } from 'models';
import VehicleCardReconInventoryTabs from './components/VehicleCardReconInventoryTabs';
import VehicleCardProgressBar from './components/VehicleCardProgressBar';
import VehicleDetailsTable from './components/VehicleCardDetailsTable';
import VehicleCardYearMakeModel from './components/VehicleCardYearMakeModel';
import './VehicleCard.scss';
import { VehicleCardContext } from './VehicleCard';
import { useCurrentLocation, useVehicleSummary, useToken } from 'api';
import { Location } from 'models';
import { useQueryClient } from 'react-query';
import features from 'common/features';
import { useFeatures } from 'hooks';

const VehicleCardExtensionV2 = React.lazy(
  () => import('./VehicleCardExtensionV2')
);

// If locationId is different return rooftop.id from user.rooftops
const verifyTenantId = (
  tenantId: string,
  userCurrentTenant: Location | undefined,
  userTenantList: Location[] = []
): string | undefined => {
  const newTenant = userTenantList.find((tenant: Location) => {
    if (tenantId.length === ID_LENGTH) {
      return tenant.id === `ROOFTOP-${tenant}`;
    }

    return tenant.id === tenantId;
  });

  if (newTenant && userCurrentTenant) {
    return newTenant.id;
  }

  return undefined;
};

interface VehicleCardExtensionProps {
  path?: string;
  inventoryId?: string;
}

const VehicleCardExtension = ({ inventoryId }: VehicleCardExtensionProps) => {
  const style = { height: 480, maxHeight: 480 };
  const { data: vehicleData } = useVehicleSummary(
    !inventoryId || inventoryId === 'background' ? undefined : inventoryId
  );
  const [activeCollectionType, setActiveCollectionType] =
    useState<VehicleCardTabType>(
      vehicleData?.data?.vehicleCard?.inRecon ? 'recon' : 'inventory'
    );
  const { data: tokenData } = useToken();
  const location = useCurrentLocation();
  const queryClient = useQueryClient();

  const { hasFeature } = useFeatures();
  const hasUIPreview = hasFeature(features.UI_PREVIEW);

  const onNewHoverTarget = useCallback(
    async (vehicleId: string, tenantId: string) => {
      let locationQueryParam = '';
      const rooftopLocationId = verifyTenantId(
        tenantId,
        location,
        tokenData?.locations
      );
      if (rooftopLocationId) {
        locationQueryParam = `&locationId=${rooftopLocationId}`;
      }
      if (vehicleData?.data?.vehicleCard?.id === vehicleId) {
        queryClient.invalidateQueries(`/inventory/${vehicleId}`);
      }
      const vdpCardURL = `${vehicleExtensionCardBuilder(
        vehicleId,
        rooftopLocationId
      )}${locationQueryParam}`;
      navigate(vdpCardURL);
    },
    [
      location,
      queryClient,
      tokenData?.locations,
      vehicleData?.data?.vehicleCard?.id,
    ]
  );

  const receiveMessageHandler = useCallback(
    (event: MessageEvent) => {
      if (event.source !== window.parent && !event.data?.action) {
        return;
      }

      switch (event.data.action) {
        case extensionStrings.EXTENSION_EVENT_NEW_HOVER_TARGET: {
          const newEvent = sanitizeWindowPostMessage(event) as
            | NewHoverTargetMessage
            | undefined;
          if (newEvent?.vehicleId && newEvent?.locationId) {
            onNewHoverTarget(newEvent.vehicleId, newEvent.locationId);
          }
          break;
        }
        default:
          break;
      }
    },
    [onNewHoverTarget]
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessageHandler, false);
    return () => {
      window.removeEventListener('message', receiveMessageHandler);
    };
  }, [receiveMessageHandler]);

  useEffect(() => {
    setActiveCollectionType(
      vehicleData?.data?.vehicleCard?.inRecon ? 'recon' : 'inventory'
    );
  }, [vehicleData?.data?.vehicleCard?.inRecon]);

  useEffect(() => {
    sendMessageToParentViaWindow({ action: 'extension_card_loaded' });
  }, []);

  // TODO: remove suspense after full transition
  if (hasUIPreview) {
    return (
      <Suspense fallback={<></>}>
        <VehicleCardExtensionV2 inventoryId={inventoryId} />
      </Suspense>
    );
  }

  if (!isSupportedExtention()) {
    return null;
  }

  return (
    <VehicleCardContext.Provider
      value={{
        vehicle: vehicleData?.data,
        inExtensionCardMode: true,
        isOnboarding: false,
        isShowroomMode: false,
        position: 0,
        forceShowEngageTab: false,
        currentTab: activeCollectionType,
        setCurrentTab: setActiveCollectionType,
      }}
    >
      <Card
        className={`VehicleCard2 VehicleCard-mode-${activeCollectionType} VehicleCardExtension`}
      >
        <Row className="VehicleCard-main-row" style={style}>
          <Col>
            <div
              className="d-flex"
              tabIndex={-1}
              role="button"
              onClick={() =>
                sendMessageToParentViaWindow({
                  action: 'extension_card_clicked',
                })
              }
            >
              <VehicleImageView
                thumbnail
                imageUrl={vehicleData?.data?.vehicleCard?.vehicleImage}
                skeleton={!vehicleData?.data}
              />
              <VehicleCardYearMakeModel />
            </div>
            <Row
              role="button"
              onClick={() =>
                sendMessageToParentViaWindow({
                  action: 'extension_card_clicked',
                })
              }
            >
              <Col>
                <VehicleDetailsTable showVin={false} />
              </Col>
            </Row>
            <Row
              role="button"
              className="mt-1"
              onClick={() =>
                sendMessageToParentViaWindow({
                  action: 'extension_card_clicked',
                })
              }
            >
              <Col className="TagList-Container-extension">
                <PermissionsGate permissions={[permissions.TAGS_VIEW]}>
                  {vehicleData?.data ? (
                    <TagList
                      showEditButton
                      vehicleIsSold={
                        vehicleData?.data?.vehicleCard?.sold ?? true
                      }
                      initialTags={vehicleData?.data?.tags}
                      vehicleId={vehicleData?.data?.vehicleCard?.id ?? ''}
                    />
                  ) : (
                    <Skeleton />
                  )}
                </PermissionsGate>
              </Col>
            </Row>
            <Row
              className={`VehicleCard-progress-row ${
                activeCollectionType === 'recon' ? '' : 'mt-0'
              }`}
              onClick={() =>
                sendMessageToParentViaWindow({
                  action: 'extension_card_clicked',
                })
              }
              role="button"
            >
              <Col className="px-0">
                <VehicleCardProgressBar />
              </Col>
            </Row>
            <Row>
              <Col className="VehicleCard-details-container">
                <VehicleCardReconInventoryTabs />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </VehicleCardContext.Provider>
  );
};

export default VehicleCardExtension;
