import {
  APIResponse,
  defaultTextPlainMutationFn,
  defaultMutationFn,
  getVehicleListParams,
} from 'api';
import {
  useQuery,
  useQueryClient,
  useMutation,
  QueryClient,
} from 'react-query';
import { StepItem, Workflow, StepItemState } from 'models/inventory/stepItem';
import { PagedVehicleList, VehicleSummary } from 'models';
import { cloneDeep } from 'lodash';
import { getLastQueryParamWithName } from '../../navigation/util/ParamHelpers';
import { useMatch } from '@reach/router';
import { usePermissions } from 'hooks';
import { permissions } from 'common';

/**
 * @package api/inventory
 * @description - Get steps for a vehicle
 * @param id vehicle UUID
 */
export function useVehicleWorkflow(vehicleId?: string) {
  const path = `/recon/${vehicleId}/workflow/steps`;
  const { hasPermission } = usePermissions();
  const hasReconView = hasPermission(permissions.RECON_VIEW);
  return useQuery<APIResponse<Workflow>>(path, {
    enabled: hasReconView && !!vehicleId,
  });
}

export function useUtilityWorkflow() {
  const path = '/utility/workflow/steps';
  const { hasPermission } = usePermissions();
  const enabled = hasPermission(permissions.RECON_VIEW);
  return useQuery<APIResponse<Workflow>>(path, { enabled });
}

/**
 * Determines whether a vehicle is still part of the current SRP vehicle list
 * after a step change.
 * @param newStepId
 */
const isMatchSrpFilter = (newStepId?: string): boolean => {
  const currentStepIdFilter = getLastQueryParamWithName('stepId') || '';
  if (currentStepIdFilter !== 'ALL' && currentStepIdFilter !== newStepId) {
    return false;
  }
  return true;
};

function updateVehicleListQueryCache(
  vehicleId: string | undefined,
  queryClient: QueryClient,
  updatedValues: {
    inRecon?: boolean;
    reconStatus?: string;
    stepItem?: StepItem | null;
  }
) {
  if (
    !vehicleId ||
    (!updatedValues.hasOwnProperty('inRecon') &&
      !updatedValues?.reconStatus &&
      !updatedValues.hasOwnProperty('stepItem'))
  ) {
    return;
  }
  let isInListFilter = true;
  if (
    updatedValues.hasOwnProperty('stepItem') &&
    !isMatchSrpFilter(updatedValues.stepItem?.id)
  ) {
    isInListFilter = false;
  }
  const { queryKey } = getVehicleListParams();
  const inventoryList: PagedVehicleList | undefined =
    queryClient.getQueryData(queryKey);
  if (inventoryList?.pages) {
    let updatedVehicleList = cloneDeep(inventoryList);
    if (!isInListFilter) {
      updatedVehicleList.pages.forEach((page) => {
        const pageLengthBeforeFilter = page.data.length;
        page.data = page.data.filter((vehicleSummary) => {
          return vehicleSummary.vehicleCard.id !== vehicleId;
        });
        if (pageLengthBeforeFilter !== page.data.length && page.meta) {
          page.meta.totalRecords = page.meta?.totalRecords
            ? page.meta?.totalRecords - 1
            : 0;
        }
      });
    } else {
      updatedVehicleList.pages.forEach((page) => {
        page.data.forEach((vehicleSummary) => {
          if (vehicleSummary.vehicleCard.id === vehicleId) {
            if (updatedValues.hasOwnProperty('inRecon')) {
              vehicleSummary.vehicleCard.inRecon = updatedValues.inRecon;
            }
            if (updatedValues?.reconStatus) {
              vehicleSummary.vehicleCard.reconStatus =
                updatedValues.reconStatus;
            }
            if (updatedValues.hasOwnProperty('stepItem')) {
              vehicleSummary.stepItem = updatedValues.stepItem;
            }
          }
        });
      });
    }
    queryClient.setQueryData(queryKey, updatedVehicleList);
  }
}

function updateVehicleQueryCache(
  vehicleId: string | undefined,
  queryClient: QueryClient,
  updatedValues: {
    inRecon?: boolean;
    reconStatus?: string;
    stepItem?: StepItem | null;
  }
) {
  if (
    !vehicleId ||
    (!updatedValues.hasOwnProperty('inRecon') &&
      !updatedValues?.reconStatus &&
      !updatedValues.hasOwnProperty('stepItem'))
  ) {
    return;
  }
  const queryKey = `/inventory/${vehicleId}`;
  const vehicleData: APIResponse<VehicleSummary> | undefined =
    queryClient.getQueryData(queryKey);
  if (vehicleData?.data) {
    const updatedVehicleData = {
      ...vehicleData,
      data: {
        ...vehicleData.data,
        stepItem: updatedValues.hasOwnProperty('stepItem')
          ? updatedValues.stepItem
          : vehicleData?.data?.stepItem,
        vehicleCard: {
          ...vehicleData?.data?.vehicleCard,
          inRecon: updatedValues.hasOwnProperty('inRecon')
            ? updatedValues.inRecon
            : vehicleData.data.vehicleCard.inRecon,
          reconStatus: updatedValues?.reconStatus
            ? updatedValues.reconStatus
            : vehicleData?.data?.vehicleCard?.reconStatus,
        },
      },
    };
    queryClient.setQueryData(queryKey, updatedVehicleData);
  }
}

export function useChangeStep(vehicleId?: string) {
  const path = `/recon/${vehicleId}/workflow/step`;
  const queryClient = useQueryClient();
  const isRouteSrp = useMatch('/:rooftopId/inventory');

  return useMutation(
    (stepId: string) => defaultTextPlainMutationFn(path, 'PUT', stepId),
    {
      onSuccess: async (data) => {
        if (isRouteSrp) {
          updateVehicleListQueryCache(vehicleId, queryClient, {
            stepItem: data.data,
          });
        } else {
          queryClient.invalidateQueries(['/inventory']);
        }
        updateVehicleQueryCache(vehicleId, queryClient, {
          stepItem: data.data,
        });
        await queryClient.invalidateQueries('/dashboard/goals');
      },
    }
  );
}

export function useAssignUserToStep(vehicleId?: string) {
  const path = `/recon/${vehicleId}/workflow/step/user`;
  const queryClient = useQueryClient();
  const isRouteSrp = useMatch('/:rooftopId/inventory');

  return useMutation(
    (userId?: string) =>
      userId
        ? defaultTextPlainMutationFn(path, 'PUT', userId)
        : defaultMutationFn(path, 'DELETE'),
    {
      onSuccess: async (data) => {
        if (isRouteSrp) {
          updateVehicleListQueryCache(vehicleId, queryClient, {
            stepItem: data.data,
          });
        }
        updateVehicleQueryCache(vehicleId, queryClient, {
          stepItem: data.data,
        });
        queryClient.invalidateQueries(`/inventory/${vehicleId}`);
      },
    }
  );
}

export function useChangeStepState(vehicleId?: string) {
  const path = `/recon/${vehicleId}/workflow/step/state`;
  const queryClient = useQueryClient();
  const isRouteSrp = useMatch('/:rooftopId/inventory');

  return useMutation(
    (newState: StepItemState) => defaultMutationFn(path, 'PUT', newState),
    {
      onSuccess: async (data) => {
        if (isRouteSrp) {
          updateVehicleListQueryCache(vehicleId, queryClient, {
            stepItem: data.data,
          });
        } else {
          queryClient.invalidateQueries(['/inventory']);
        }
        updateVehicleQueryCache(vehicleId, queryClient, {
          stepItem: data.data,
        });
        await queryClient.invalidateQueries('/dashboard/goals');
      },
    }
  );
}

export function useCompleteWorkflow(vehicleId?: string) {
  const path = `/recon/${vehicleId}/workflow/complete`;
  const queryClient = useQueryClient();
  const isRouteSrp = useMatch('/:rooftopId/inventory');

  return useMutation(() => defaultMutationFn(path, 'PUT'), {
    onSuccess: async (data) => {
      const updatedValues = {
        reconStatus: 'COMPLETED',
        stepItem: data?.data ?? null,
      };
      if (isRouteSrp) {
        updateVehicleListQueryCache(vehicleId, queryClient, updatedValues);
      } else {
        queryClient.invalidateQueries(['/inventory']);
      }
      updateVehicleQueryCache(vehicleId, queryClient, updatedValues);
      await queryClient.invalidateQueries('/dashboard/goals');
    },
  });
}

export function useDeleteWorkflow(vehicleId?: string) {
  const path = `/recon/${vehicleId}`;
  const queryClient = useQueryClient();
  const isRouteSrp = useMatch('/:rooftopId/inventory');

  return useMutation(() => defaultMutationFn(path, 'DELETE'), {
    onSuccess: async (data) => {
      const updatedValues = {
        inRecon: false,
        reconStatus: 'NOT_STARTED',
        stepItem: null,
      };
      if (isRouteSrp) {
        updateVehicleListQueryCache(vehicleId, queryClient, updatedValues);
      } else {
        queryClient.invalidateQueries(['/inventory']);
      }
      updateVehicleQueryCache(vehicleId, queryClient, updatedValues);
      await queryClient.invalidateQueries('/dashboard/goals');
    },
  });
}
