import { APIResponse, defaultMutationFn } from 'api';
import { Profile, ProfileUpdateData } from 'models';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const path = '/profile';

export function useProfile() {
  const queryClient = useQueryClient();

  return {
    query: useQuery<APIResponse<Profile>>(path),
    mutation: useMutation(
      (data: ProfileUpdateData) => defaultMutationFn(path, 'PUT', data),
      {
        onSuccess: async (data) => {
          queryClient.setQueryData(path, data);
        },
      }
    ),
  };
}
