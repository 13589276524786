import { APIResponse } from 'api';
import EngageOverview from 'models/inventory/EngageOverview';
import { useQuery } from 'react-query';

export default function useEngageOverview(
  inventoryId?: string,
  enabled = true
) {
  const path = `/inventory/${inventoryId}/plugins/vdp/velocityengage/engagements/overview`;
  return useQuery<APIResponse<EngageOverview>>(path, {
    enabled: !!(inventoryId && enabled),
  });
}
