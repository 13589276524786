import React, { useContext } from 'react';
import ProgressBar from 'components/shared/ProgressBar';
import { getFormattedDurationFromSeconds } from 'utils/time';
import strings from 'common/strings';
import { VehicleCardContext } from '../../VehicleCard';
import './VehicleCardProgressBar.scss';
import Skeleton from 'components/shared/Skeleton';

const VehicleCardProgressBar = () => {
  const { vehicle, position } = useContext(VehicleCardContext);

  if (
    !vehicle?.vehicleCard?.inRecon ||
    vehicle?.stepItem?.state === 'COMPLETE'
  ) {
    return (
      <Skeleton renderSkeleton={!vehicle}>
        <div className="VehicleCardProgressBar" />
      </Skeleton>
    );
  }

  const currentStep = vehicle?.stepItem;
  const actualStepDuration = getFormattedDurationFromSeconds(
    currentStep?.reconTimeInStepSeconds ?? 0
  );
  const formattedTargetStepDuration = getFormattedDurationFromSeconds(
    currentStep?.recommendedDurationSeconds ?? 0
  );

  return (
    <div className="VehicleCardProgressBar">
      <Skeleton renderSkeleton={!vehicle}>
        <div className="VehicleCard-step-duration-goal">
          <span className="Step-progress-title">{strings.STEP_PROGRESS}</span>
          <span id={`step-duration-text-${position}`}>
            {actualStepDuration}
            <span> / </span>
            {formattedTargetStepDuration}
          </span>
        </div>
        {currentStep && <ProgressBar currentStep={currentStep} />}
      </Skeleton>
    </div>
  );
};

export default VehicleCardProgressBar;
