import { useCallback, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import strings from 'common/strings';
import { testIds } from 'common/testIds';

import './Overview.scss';
import StepItem from './StepItem';
import StepVehicleCounts from './StepItem/StepVehicleCounts';
import { useActiveFilters, useDashboardGoals } from 'api';
import {
  QueryParamValue,
  updateUrlSearchParam,
} from 'navigation/util/ParamHelpers';
import {
  DashboardGoals,
  WorkflowStepStatus,
  workflowStatusesDownMap,
} from 'models';
import { WorkflowMenuItem } from '../Sidebar/Sidebar';

interface StepCountsProps {
  counts: Record<string, Record<WorkflowStepStatus, number>>;
  stepId: string;
  status: string;
  isSelected: (value: string) => boolean;
  updateFilter: (filters: Record<string, QueryParamValue>) => void;
}

const useStepCounts = (): StepCountsProps => {
  const { data } = useDashboardGoals();
  const activeFilters = useActiveFilters();

  const status = useMemo(
    () => activeFilters.find((item) => item.filter === 'STEP_STATUS')?.value,
    [activeFilters]
  );
  const stepId = useMemo(
    () => activeFilters.find((item) => item.filter === 'STEP')?.value,
    [activeFilters]
  );

  const updateFilter = (filters: Record<string, QueryParamValue>) => {
    updateUrlSearchParam(filters, false);
  };

  const handleWorkflowCount = (item: DashboardGoals) => {
    const TOTAL: number = item.total;
    const WARNING: number = item.warning;
    const DANGER: number = item.danger;
    const UNKNOWN = 0;
    const OK = TOTAL - WARNING - DANGER;
    counts[item.step?.id ?? ''] = { TOTAL, OK, WARNING, DANGER, UNKNOWN };
    if (item.childSteps) {
      item.childSteps.forEach(handleWorkflowCount);
    }
  };

  const counts = {} as Record<string, Record<WorkflowStepStatus, number>>;
  data?.data.forEach(handleWorkflowCount);
  counts['ALL'] = {
    TOTAL: data?.meta?.total ?? 0,
    WARNING: data?.meta?.warning ?? 0,
    DANGER: data?.meta?.danger ?? 0,
    OK: 0,
    UNKNOWN: 0,
  };

  const isSelected = useCallback(
    (value: string) => {
      if (stepId === 'ALL') {
        return (
          workflowStatusesDownMap[status] === value ||
          (value === 'TOTAL' && status === 'ALL')
        );
      }
      return false;
    },
    [status, stepId]
  );

  if (counts.ALL.OK === 0) {
    counts.ALL.OK = counts.ALL.TOTAL - counts.ALL.WARNING - counts.ALL.DANGER;
  }

  return { counts, isSelected, stepId, status, updateFilter };
};

interface OverviewProps {
  workflowMenuItems: WorkflowMenuItem[];
}

const Overview = ({ workflowMenuItems }: OverviewProps) => {
  const {
    counts,
    isSelected,
    stepId = 'ALL',
    status,
    updateFilter,
  } = useStepCounts(); // TODO: fix hard to follow

  function handleSelected(value: string) {
    if (!isSelected(value)) {
      updateFilter({
        stepId: 'ALL',
        status: value !== 'TOTAL' ? value : 'ALL',
        disposition: '',
        assignee: '',
        area: '',
        s: '',
        preview: '',
        condition: 'ALL',
        inventoryState: 'IN_RECON',
      });
    }
  }

  const handleStepVehicleSelected = (
    value: string,
    stepDefinitionId?: string
  ) => {
    const statusLoc = value !== 'TOTAL' ? value : 'ALL';
    if (statusLoc !== status || stepDefinitionId !== stepId) {
      updateFilter({
        stepId: stepDefinitionId,
        status: statusLoc,
        disposition: '',
        assignee: '',
        area: '',
        s: '',
        preview: '',
        condition: 'ALL',
        inventoryState: 'IN_RECON',
      });
    }
  };

  const handleStepItemSelected = (stepDefinitionId: string) => {
    if (stepDefinitionId !== stepId) {
      updateFilter({
        stepId: stepDefinitionId,
        status: 'ALL',
        disposition: '',
        assignee: '',
        area: '',
        s: '',
        preview: '',
        condition: 'ALL',
        inventoryState: 'IN_RECON',
      });
    }
  };

  return (
    <div
      data-vas-testing={testIds.ACTIVE_RECON_OVERVIEW_CONTAINER}
      className="Overview flex-rows"
    >
      <Container fluid className="Overview-container">
        <Row className="totals m-3 p-0">
          {Object.keys(workflowStatusesDownMap).map((key: string) => {
            const value =
              workflowStatusesDownMap[key] === 'UNKNOWN'
                ? 'TOTAL'
                : workflowStatusesDownMap[key];
            return (
              <Col key={`total-${key}`} className="p-0">
                <div
                  tabIndex={-1}
                  role="link"
                  className={`selection-area ${
                    isSelected(value) ? 'selected-header' : ''
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelected(value);
                  }}
                >
                  <div className={`summary summary-${value.toLowerCase()}`}>
                    {counts.ALL[value]}
                  </div>
                  {strings[value as keyof typeof strings]}
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
      <div className="step-items scroll-area">
        <StepItem
          stepId={stepId}
          key="All"
          level={-1}
          className="top-level-step "
          handleSelected={() => handleStepItemSelected('ALL')}
        >
          <StepVehicleCounts
            stepId={stepId}
            status={status}
            stepDefinitionId={'ALL'}
            handleSelected={(value) => handleStepVehicleSelected(value, 'ALL')}
            counts={counts.ALL}
          />
        </StepItem>
        {workflowMenuItems.map((step: WorkflowMenuItem, idx) => (
          <StepItem
            stepId={stepId}
            key={`${step.name}-${idx}`}
            workflowMenuItem={step}
            level={step.level}
            handleSelected={() => handleStepItemSelected(step?.id)}
            className={step.level === 0 ? 'top-level-step' : ''}
          >
            <StepVehicleCounts
              stepId={stepId}
              status={status}
              stepDefinitionId={step.id}
              handleSelected={(value) =>
                handleStepVehicleSelected(value, step.id)
              }
              counts={counts[step.id]}
            />
          </StepItem>
        ))}
      </div>
    </div>
  );
};

export default Overview;
