import React from 'react';
import { currencyFormatter } from 'utils/formatter';
import { VehicleRepairOrderLine } from 'models';

import './RepairOrderLine.scss';

interface RepairOrderLineProps {
  repairOrderLine: VehicleRepairOrderLine;
}

const RepairOrderLine: React.FunctionComponent<RepairOrderLineProps> = ({
  repairOrderLine,
}) => {
  const { opCode, opCodeDescription, laborSale, partsSale, miscSale, total } =
    repairOrderLine;

  return (
    <div className="RepairOrderLine">
      <div className="OpCode-and-OpCodeDescription">
        <div className="OpCode">{opCode}</div>
        <div className="OpCodeDescription">{opCodeDescription}</div>
      </div>
      <div className="prices">
        <div className="price">{currencyFormatter(laborSale)}</div>
        <div className="price">{currencyFormatter(partsSale)}</div>
        <div className="price">{currencyFormatter(miscSale)}</div>
        <div className="price">{currencyFormatter(total)}</div>
      </div>
    </div>
  );
};

export default RepairOrderLine;
