import React from 'react';
import { Search, Clear } from '@material-ui/icons';
import { debounce } from 'lodash';
import { updateUrlSearchParam } from 'navigation/util/ParamHelpers';

import { DEBOUNCE_DELAY } from 'common/constants';
import strings from 'common/strings';
import { testIds } from 'common/testIds';

import './VehicleSearch.scss';

const updateSearchDebounce = debounce((value: string | string[]) => {
  updateUrlSearchParam({ s: value });
}, DEBOUNCE_DELAY);

interface VehicleSearchProps {
  searchInputValue: string;
  setSearchInputValue: (searchString: string) => void;
}

const VehicleSearch: React.FC<VehicleSearchProps> = ({
  searchInputValue,
  setSearchInputValue,
}: VehicleSearchProps) => {
  const handleSearchInputChange = (event: any) => {
    setSearchInputValue(event.target.value);
    updateSearchDebounce(event.target.value);
  };

  return (
    <div className="VehicleSearch-container">
      <input
        type="text"
        data-vas-testing={testIds.INVENTORY_SEARCH_INPUT}
        value={searchInputValue}
        onChange={handleSearchInputChange}
        className="VehicleSearch-bar"
        placeholder={strings.SEARCH_INVENTORY}
      />
      {searchInputValue ? (
        <Clear
          data-vas-testing={testIds.INVENTORY_SEARCH_CLEAR_ICON}
          className="VehicleSearch-search-icon"
          onClick={() => {
            setSearchInputValue('');
            updateUrlSearchParam({ s: '' });
          }}
        />
      ) : (
        <Search className="VehicleSearch-search-icon" />
      )}
    </div>
  );
};

export default VehicleSearch;
