import React, { ChangeEvent, forwardRef, KeyboardEventHandler } from 'react';
import LoadingIndicator from 'components/shared/LoadingIndicator';

import './TextInput.scss';

interface TextInputProps {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string | number;

  adornment?: string;
  className?: string;
  containerClassName?: string;
  placeholderClassName?: string;
  disabled?: boolean;
  id?: string;
  placeholder?: string;
  showLoadingIndicator?: boolean;
  type?: string;
  error?: boolean;
  helperText?: string;
  helperTextClassName?: string;
  ref?: React.Ref<HTMLInputElement> | null;
  required?: boolean;
  readOnly?: boolean;
  autoComplete?: string;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
}

const TextInput: React.FC<TextInputProps> = forwardRef(
  (props: TextInputProps, ref: React.Ref<HTMLInputElement>) => {
    const {
      className = '',
      containerClassName = '',
      placeholderClassName = '',
      disabled,
      id,
      onChange,
      onBlur,
      onFocus,
      placeholder,
      showLoadingIndicator,
      value,
      type = 'text',
      adornment = '',
      error = false,
      helperText = '',
      helperTextClassName = '',
      required = true,
      readOnly = false,
      autoComplete,
      onKeyDown,
    } = props;

    const disabledClassName = disabled ? 'TextInput-disabled' : '';
    return (
      <>
        <div className={`TextInput ${disabledClassName} ${containerClassName}`}>
          {adornment && <div className="TextInput-adornment">{adornment}</div>}
          <input
            ref={props.ref || ref}
            className={`TextInput-input ${className}`}
            disabled={disabled}
            id={id}
            onChange={onChange}
            type={type}
            value={value}
            required={required}
            onBlur={onBlur}
            onFocus={onFocus}
            readOnly={readOnly}
            autoComplete={autoComplete}
            onKeyDown={onKeyDown}
          />
          <span
            className={`floating-label ${
              disabled && value ? 'floating-label-disabled' : ''
            }
          ${placeholderClassName} ${error ? 'floating-label-error' : ''}`}
          >
            {placeholder}
          </span>
          {showLoadingIndicator && (
            <LoadingIndicator className="full-width full-height TextInput-loader" />
          )}
        </div>
        {helperText && (
          <div className={`TextInput-helper-text ${helperTextClassName}`}>
            {helperText}
          </div>
        )}
      </>
    );
  }
);

export default TextInput;
