import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { strings } from 'common';
import Dropdown from 'components/shared/Dropdown';
import { ConditionOption } from 'models/reports/corporatePayload';
import './ConditionFilter.scss';

interface ConditionFilterProps {
  onChange: (conditionId: string) => void;
  selectedConditionId: string | null;
  conditions?: ConditionOption[];
}
const ConditionFilter: React.FC<ConditionFilterProps> = ({
  onChange,
  selectedConditionId,
  conditions,
}: ConditionFilterProps) => {
  const selectedCondition = conditions?.find(
    (condition) => condition.value === selectedConditionId
  );

  return (
    <div className="ConditionFilter">
      {strings.FILTER_BY_CONDITION}
      <Dropdown
        containerClassName="ConditionFilter-select-dropdown"
        selectedOption={{
          id: selectedCondition?.value || 'no-id',
          value: selectedCondition?.label || '',
        }}
        options={
          conditions?.map((condition) => ({
            id: condition.value,
            value: condition.label,
          })) ?? []
        }
        onSelect={onChange}
        customCaretComponent={
          <ArrowDropDown className="Dropdown-expand-icon" />
        }
      />
    </div>
  );
};

export default ConditionFilter;
