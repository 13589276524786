import { sendMessageToExtensionAsync } from '../extension';

export const EXTENSION_WEB_STORAGE_ACTION = 'web_storage';
export const GET_ITEM_KEY = 'getItem';

export const getItem = async (
  extensionId: string,
  key: string
): Promise<string | null> => {
  const response = await sendMessageToExtensionAsync(extensionId, {
    action: EXTENSION_WEB_STORAGE_ACTION,
    method: GET_ITEM_KEY,
    key,
  });

  return response?.value ? response.value : null;
};
