import { configuredFetch, HttpMethod } from 'api';
import { UIFilter } from 'models';
import { logoutActions } from './authenticate';
import { getCredentialValue } from './lib/localStorageManagement';

export interface SortItem {
  id: string;
  label: string;
}

export interface APIResponse<T> {
  data: T;
  meta?: {
    returnedRecords: number;
    totalRecords: number;
    sortOptions?: SortItem[];
    filters?: UIFilter[];
    show_onboarding_banner?: boolean;
    total?: number;
    warning?: number;
    danger?: number;
  };
  links?: any;
  errors?: any;
}

// This function will be call anytime useQuery is used
type DefaultQueryFnParams = { queryKey: string[] };
export async function defaultQueryFn({ queryKey }: DefaultQueryFnParams) {
  const url = Array.isArray(queryKey) ? queryKey.join('/') : queryKey;
  return configuredFetch(url).then((res) => res.json());
}

export async function defaultMetaQueryFn(url: string) {
  return configuredFetch(url).then((res) => res.json());
}

export async function defaultMutationFn(
  url: string,
  method: HttpMethod,
  data?: any
) {
  return configuredFetch(url, {
    method,
    body: data ? JSON.stringify(data) : undefined,
  }).then((res) => res.json());
}

export async function defaultTextPlainMutationFn(
  url: string,
  method: HttpMethod,
  data: any = {}
) {
  return configuredFetch(url, {
    method,
    body: data,
    headers: {
      'content-type': 'text/plain',
    },
  }).then((res) => res.json());
}

export const reactQueryRetry = (failureCount: number, error: any) => {
  const url = window?.location?.pathname;
  const authBearerToken = getCredentialValue('authBearerToken');
  if (!authBearerToken) {
    if (url !== '/') {
      logoutActions();
    }
    return false;
  }

  const isNoToken = error?.message?.search(/No Auth Token/g) >= 0;
  if (isNoToken) {
    logoutActions();
    return false;
  }

  const isUnauthorized = error?.message?.search(/401/g) >= 0;
  if (failureCount === 3) {
    if (isUnauthorized) {
      logoutActions();
    }
    return false;
  }

  return true;
};
