import { permissions, strings } from 'common';
import { Badge } from '@material-ui/core';
import { ReportProblem, AssignmentTurnedIn, Comment } from '@material-ui/icons';
import { navigate } from '@reach/router';
import { notesBuilder, recallsBuilder, tasksBuilder } from 'navigation/routes';
import { VehicleSummary } from 'models';
import './VehicleCardTabFooter.scss';
import { RVLogo, VelocityEngageIcon } from 'components/shared/icons';
import { usePermissions } from 'hooks';

interface VehicleCardTabFooterProps {
  vehicle: VehicleSummary | undefined;
  navClick: (route: string) => void;
  children?: any;
  showEngage?: boolean;
  showRecon?: boolean;
}

const VehicleCardTabFooter = ({
  vehicle,
  navClick,
  children,
  showEngage = false,
  showRecon = false,
}: VehicleCardTabFooterProps) => {
  const vehicleCard = vehicle?.vehicleCard;
  const recallCount = vehicleCard?.recallCount || 0;
  const taskCount = vehicleCard?.tasksNeedingAttentionCount || 0;
  const { hasPermission } = usePermissions();

  return (
    <div className="VehicleCardTabFooter-container">
      <Badge
        className={`VehicleCardTabFooter-badge recallBadge ${
          taskCount > 0 ? 'active' : 'inactive'
        }`}
        onClick={() => {
          navigate(`${notesBuilder(vehicleCard?.id!, false)}`);
        }}
      >
        <Comment color="secondary" />
        <div>{strings.NOTES.toUpperCase()}</div>
      </Badge>
      <Badge
        badgeContent={taskCount}
        invisible={!taskCount}
        className={`VehicleCardTabFooter-badge recallBadge ${
          taskCount > 0 ? 'active' : 'inactive'
        }`}
        onClick={() => {
          navigate(`${tasksBuilder(vehicleCard?.id!, false)}`);
        }}
      >
        <AssignmentTurnedIn color="secondary" />
        <div>{strings.TASKS.toUpperCase()}</div>
      </Badge>
      <Badge
        badgeContent={recallCount}
        invisible={!recallCount}
        className={`VehicleCardTabFooter-badge recallBadge ${
          recallCount > 0 ? 'active' : 'inactive'
        }`}
        onClick={() => {
          navigate(
            `${recallsBuilder(vehicleCard?.id!, false)}?fromVDP=inventory`
          );
        }}
      >
        <ReportProblem color="secondary" />
        <div>{strings.RECALLS.toUpperCase()}</div>
      </Badge>
      {showRecon && hasPermission(permissions.RECON_VIEW) && (
        <div className="VehicleCardTabFooter-Inventory">
          <div
            className="VehicleCardTabFooter-badge"
            onClick={() => navClick('VehicleCard-step-buttons-container')}
          >
            <RVLogo
              className="VehicleCardTabFooter-badge-icon"
              viewBox="-1 0 28 20"
            />
            <div>{strings.RECON.toUpperCase()}</div>
          </div>
        </div>
      )}
      {showEngage && hasPermission(permissions.PLUGIN_VELOCITYENGAGE_VIEW) && (
        <div
          className="VehicleCardTabFooter-badge"
          onClick={() => navClick('VehicleCard-view-more-container')}
        >
          <VelocityEngageIcon
            className="VehicleCardTabFooter-badge-icon"
            viewBox="3 -3 24 28"
          />
          <div>{strings.ENGAGE.toUpperCase()}</div>
        </div>
      )}
      <div className="VehicleCardTabFooter-children">{children}</div>
    </div>
  );
};

export default VehicleCardTabFooter;
