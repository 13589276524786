import { v4 as uuidv4 } from 'uuid';

import { isImage } from 'common/images';

import {
  UploadItem,
  UploadTarget,
  UploadMethod,
  UploadType,
  UPLOAD_STATUS_WEIGHT,
} from './types';

export const getFileExtension = (fileMimeType: string) => {
  switch (fileMimeType) {
    case 'image/gif':
      return '.gif';
    case 'image/jpeg':
      return '.jpg';
    case 'image/png':
      return '.png';
    case 'image/svg':
      return '.svg';
    case 'image/webp':
      return '.webp';
    case 'video/mp4':
      return '.mp4';
    case 'video/webm':
      return '.webm';
    case 'application/pdf':
      return '.pdf';
    default:
      return '.png';
  }
};

export const sortByTimestamp = (a: UploadItem, b: UploadItem) =>
  b.timestamp - a.timestamp;
export const sortByUploadStatus = (a: UploadItem, b: UploadItem) =>
  UPLOAD_STATUS_WEIGHT[b.uploadStatus] - UPLOAD_STATUS_WEIGHT[a.uploadStatus];

export const buildFormData = (file: File) => {
  const data = new FormData();
  data.append('file', file);
  data.append('name', 'file');
  data.append('filename', file.name);
  data.append('type', file.type);

  return data;
};

export const buildMetadata = async (
  vehicleId: string,
  formData: FormData,
  target: UploadTarget,
  uploadMethod: UploadMethod,
  file: File
) => {
  const objectUrl = await URL.createObjectURL(file);
  const meta = {
    id: uuidv4(),
    parentId: vehicleId,
    target,
    formData,
    url: getUrlForUploadTarget(vehicleId, target.type),
    name: 'name',
    uploadMethod,
    objectUrl,
    fileSize: file.size,
  };
  return meta;
};

export const buildBaseUrl = (vehicleId: string) => {
  const tenantId = window.location.pathname.split('/')[1];
  return `${tenantId}/inventory/${vehicleId}`;
};

export const getTasksUrl = (vehicleId: string) => {
  const baseUrl = buildBaseUrl(vehicleId);
  return `${baseUrl}/tasks`;
};

export const getMediaUrl = (vehicleId: string) => {
  const baseUrl = buildBaseUrl(vehicleId);
  return `${baseUrl}/media`;
};

export const getMediaGalleryUrl = (vehicleId: string) => {
  const baseUrl = getMediaUrl(vehicleId);
  return `${baseUrl}/gallery`;
};

export const getMediaSyndicationUrl = (vehicleId: string) => {
  const baseUrl = getMediaUrl(vehicleId);
  return `${baseUrl}/syndication`;
};

export const getNotesUrl = (vehicleId: string) => {
  const baseUrl = buildBaseUrl(vehicleId);
  return `${baseUrl}/notes`;
};

export const getEngageMediaUrl = (vehicleId: string) => {
  const baseUrl = buildBaseUrl(vehicleId);
  return `${baseUrl}/engage/media`;
};

export const getUrlForUploadTarget = (
  vehicleId: string,
  targetType: UploadType
) => {
  switch (targetType) {
    case UploadType.TASK:
      return getTasksUrl(vehicleId);
    case UploadType.DOCUMENT:
      return getMediaUrl(vehicleId);
    case UploadType.MEDIA:
      return getMediaGalleryUrl(vehicleId);
    case UploadType.SYNDICATION:
      return getMediaSyndicationUrl(vehicleId);
    case UploadType.NOTE:
      return getNotesUrl(vehicleId);
    case UploadType.ENGAGE_MEDIA:
      return getEngageMediaUrl(vehicleId);
    default:
      return getTasksUrl(vehicleId);
  }
};
const getVehicleUrl = (baseUrl: string, parentId: string) =>
  `${baseUrl}/recon/${parentId}`;

const getTaskUploadUrl = (
  baseUrl: string,
  uploadUrl: string,
  parentId: string,
  targetId: string,
  includeUploadUrl: boolean = true
): string => {
  const vehicleUrl = getVehicleUrl(baseUrl, parentId);
  return `${vehicleUrl}/tasks/${targetId}/attachments${
    includeUploadUrl ? `?uploadUrl=${encodeURIComponent(uploadUrl)}` : ''
  }`;
};

const getEngageMediaUploadUrl = (
  baseUrl: string,
  uploadUrl: string,
  parentId: string,
  targetId: string,
  includeUploadUrl: boolean = true
): string => {
  const vehicleUrl = getVehicleUrl(baseUrl, parentId);
  return `${vehicleUrl}/plugins/vdp/velocityengage/media${
    includeUploadUrl ? `?uploadUrl=${encodeURIComponent(uploadUrl)}` : ''
  }`;
};

const getMediaUploadUrl = (
  baseUrl: string,
  uploadUrl: string,
  parentId: string,
  targetId?: string,
  fileExtension?: string,
  includeUploadUrl: boolean = true,
  syndicationShot?: string
): string => {
  const vehicleUrl = getVehicleUrl(baseUrl, parentId);
  const mediaEndpoint = isImage(fileExtension!)
    ? 'media/images'
    : 'media/videos';
  const syndicationShotParameter =
    mediaEndpoint === 'media/images' && syndicationShot
      ? `shot=${syndicationShot}`
      : '';
  const uploadUrlParameter = includeUploadUrl
    ? `uploadUrl=${encodeURIComponent(uploadUrl)}`
    : '';

  const parameters = `?${syndicationShotParameter}${
    syndicationShotParameter && uploadUrlParameter ? '&' : ''
  }${uploadUrlParameter}`;
  return `${vehicleUrl}/${mediaEndpoint}${parameters}`;
};

const getDocumentUploadUrl = (
  baseUrl: string,
  uploadUrl: string,
  parentId: string,
  includeUploadUrl: boolean = true
): string => {
  const vehicleUrl = getVehicleUrl(baseUrl, parentId);
  return `${vehicleUrl}/attachments${
    includeUploadUrl ? `?uploadUrl=${encodeURIComponent(uploadUrl)}` : ''
  }`;
};

const getNoteUploadUrl = (
  baseUrl: string,
  uploadUrl: string,
  parentId: string,
  targetId: string,
  includeUploadUrl: boolean = true
): string => {
  const vehicleUrl = getVehicleUrl(baseUrl, parentId);
  return `${vehicleUrl}/notes/${targetId}/attachments${
    includeUploadUrl ? `?uploadUrl=${encodeURIComponent(uploadUrl)}` : ''
  }`;
};

export const getUrl = (
  baseUrl: string,
  uploadUrl: string,
  parentId: string,
  targetType: UploadType,
  targetId?: string,
  fileExtension?: string,
  includeUploadUrl: boolean = true,
  syndicationShot?: string
) => {
  switch (targetType) {
    case UploadType.TASK:
      return getTaskUploadUrl(
        baseUrl,
        uploadUrl,
        parentId,
        targetId!,
        includeUploadUrl
      );
    case UploadType.MEDIA:
      return getMediaUploadUrl(
        baseUrl,
        uploadUrl,
        parentId,
        undefined,
        fileExtension,
        includeUploadUrl,
        syndicationShot
      );
    case UploadType.DOCUMENT:
      return getDocumentUploadUrl(
        baseUrl,
        uploadUrl,
        parentId,
        includeUploadUrl
      );
    case UploadType.NOTE:
      return getNoteUploadUrl(
        baseUrl,
        uploadUrl,
        parentId,
        targetId!,
        includeUploadUrl
      );
    case UploadType.ENGAGE_MEDIA:
      return getEngageMediaUploadUrl(
        baseUrl,
        uploadUrl,
        parentId,
        targetId!,
        includeUploadUrl
      );
    default:
      return getTaskUploadUrl(
        baseUrl,
        uploadUrl,
        parentId,
        targetId!,
        includeUploadUrl
      );
  }
};
