import React, { useCallback } from 'react';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { Checklist } from 'models';
import strings from 'common/strings';
import images from 'common/images';
import { Typography } from '@material-ui/core';
import { useVehicleChecklists } from 'api';

interface MpiChecklistMenuProps {
  vehicleId: string;
  onClick: (idx: Checklist) => void;
}

var MpiChecklistMenu = ({ vehicleId, onClick }: MpiChecklistMenuProps) => {
  const { data: { data: checklists = [] } = {}, isLoading } =
    useVehicleChecklists(vehicleId);

  const renderAdminMessage = useCallback(() => {
    if (checklists && checklists.length > 0) {
      return null;
    }
    return (
      <Typography
        variant="subtitle1"
        className="no-note-title text-center full-height flex-rows align-center justify-center"
      >
        {strings.CONTACT_ADMIN_MESSAGE}
      </Typography>
    );
  }, [checklists]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      {checklists.map((checklist, idx: number) => (
        <div
          key={`checklist-${idx.toString()}`}
          role="none"
          className="border-bottom py-4 d-flex align-items-center pointer Mpi-checklist-menu-item"
          onClick={() => onClick(checklist)}
        >
          <img src={images.Clipboard} alt="Clipboard Icon" />
          <div className="ml-3">
            <div className="text-lg text-spacing-3">{checklist.name}</div>
            <div className="text-muted text-lg">
              <span className="mr-1">{checklist.compeletedChecklistItems}</span>
              <span className="mr-1">/</span>
              <span className="mr-1">{checklist.totalChecklistItems}</span>
              <span className="mr-1">{strings.COMPLETED}</span>
            </div>
          </div>
        </div>
      ))}
      {renderAdminMessage()}
    </div>
  );
};

export default MpiChecklistMenu;
