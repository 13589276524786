import Uppy, { AddFileOptions } from '@uppy/core';
import { Task, Note, Attachment, VehicleImage } from 'models';

export const MAX_UPLOADS = 5;

// File size limit for AJAX file uploads
// Currently set to 5MB
export const MAX_AJAX_FILE_SIZE = Math.floor(5 * 1024 * 1024);

export const ALLOWED_IMAGE_EXTENSIONS = [
  '.gif',
  '.jpg',
  '.jpeg',
  '.png',
  '.svg',
  '.webp',
];
export const ALLOWED_IMAGE_FILETYPES = [
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg',
  'image/webp',
];

export const ALLOWED_VIDEO_EXTENSIONS = ['.mp4', '.webm'];
export const ALLOWED_VIDEO_FILETYPES = ['video/mp4', 'video/webm'];

export const ALLOWED_ATTACHMENT_FILETYPES = ['application/pdf'];
export const ALLOWED_ATTACHMENT_FILETYPES_STRING =
  ALLOWED_ATTACHMENT_FILETYPES.join(',');

export const ALLOWED_MEDIA_FILETYPES = ALLOWED_IMAGE_FILETYPES.concat(
  ALLOWED_VIDEO_FILETYPES
);
export const ALLOWED_MEDIA_FILETYPES_STRING = ALLOWED_MEDIA_FILETYPES.join(',');
export const ALLOWED_SYNDICATION_FILETYPES = ALLOWED_IMAGE_FILETYPES.filter(
  (fileType: string) => fileType !== 'image/gif'
);
export const ALLOWED_SYNDICATION_FILETYPES_STRING =
  ALLOWED_SYNDICATION_FILETYPES.join(',');

export const ALLOWED_FILETYPES = ALLOWED_IMAGE_FILETYPES.concat(
  ALLOWED_VIDEO_FILETYPES
).concat(ALLOWED_ATTACHMENT_FILETYPES);
export const ALLOWED_FILETYPES_STRING = ALLOWED_FILETYPES.join(',');

export enum ProgressState {
  DETERMINATE = 'DETERMINATE',
  INDETERMINATE = 'INDETERMINATE',
}
export enum UploadType {
  NOTE = 'NOTE',
  TASK = 'TASK',
  DOCUMENT = 'DOCUMENT',
  MEDIA = 'MEDIA',
  SYNDICATION = 'SYNDICATION',
  ENGAGE_MEDIA = 'ENGAGE_MEDIA',
  NOT_SUPPORTED = 'NOT_SUPPORTED',
}
export enum UploadStatus {
  STAGED = 'STAGED',
  UPLOADING = 'UPLOADING',
  PAUSED = 'PAUSED',
  ERROR = 'ERROR',
  COMPLETED = 'COMPLETED',
  REMOVED = 'REMOVED',
}
export interface UploadMetadata {
  id: string;
  formData: FormData;
  parentId: string;
  target: UploadTarget;
  error?: string;
  name: string;
  url: string;
  uploadUrl?: string;
  uploadMethod: UploadMethod;
  objectUrl?: string;
  fileSize: number;
}

export type UploadMethod = 'AJAX' | 'TUS';

export interface UploadItem {
  id: string;
  uppyId?: string | void;
  uploadStatus: UploadStatus;
  progressState: ProgressState;
  uploadOptions: AddFileOptions;
  meta: UploadMetadata;
  timestamp: number;
  progress: number;
  fileExtension: string;
  onUploadSuccess?: (resolutionUrl?: string) => void;
  onUploadCancel?: () => void;
  onUploadError?: (message?: string) => void;
}
export interface UploadTarget {
  id?: string;
  type: UploadType;
  targetObj?: Note | Task | Attachment | VehicleImage;
  shot?: string;
}

export const UPLOAD_STATUS_WEIGHT: Record<string, number> = {
  [UploadStatus.UPLOADING]: 4,
  [UploadStatus.PAUSED]: 3,
  [UploadStatus.COMPLETED]: 2,
  [UploadStatus.ERROR]: 1,
};

export interface UploadCompleteResult {
  successful: Uppy.UppyFile[];
  failed: Uppy.UppyFile[];
}

export interface UploadResponse {
  uploadURL: string;
}

export interface UploadProgress {
  bytesUploaded: number;
  bytesTotal: number;
}

type UploadState = {
  uploads: UploadItem[];
  tusUppy?: Uppy.Uppy;
  ajaxUppy?: Uppy.Uppy;
  addFile: (
    file: File,
    vehicleId: string,
    target: UploadTarget,
    onUploadSuccess: () => void,
    onUploadError: (message?: string) => void,
    onUploadCancel: () => void
  ) => void;
  addFiles: (
    files: File[],
    vehicleId: string,
    target: UploadTarget,
    onUploadSuccess: () => void,
    onUploadError: (message?: string) => void,
    onUploadCancel: () => void
  ) => void;
  removeFile: (upload: UploadItem) => void;
  upload: () => void;
  uploadFiles: (
    files: File[],
    vehicleId: string,
    target: UploadTarget,
    onUploadSuccess: (url?: string) => void,
    onUploadError: (message?: string) => void,
    onUploadCancel: () => void
  ) => void;
  closeUppyInstances: () => void;
  sortedUploads: () => UploadItem[];
  uploadManagerSortedUploads: () => UploadItem[];
  getUploadsForParentId: (parentId: string) => UploadItem[];
  getUploadsForTargetId: (targetId: string) => UploadItem[];
  parentHasUploadsInProgress: (parentId: string) => boolean;
  targetHasUploadsInProgress: (targetId: string) => boolean;
  pauseOrResumeUpload: (upload: UploadItem) => void;
  resolveTusUpload: (file: Uppy.UppyFile, response: UploadResponse) => any;
  addNoteTargetId: (
    parentId: string,
    targetId: string,
    onUploadCancel?: () => void
  ) => void;
  onUploadSuccess: (file: Uppy.UppyFile, response: UploadResponse) => void;
  onUploadProgress: (file: Uppy.UppyFile, progress: UploadProgress) => void;
  onComplete: (result: UploadCompleteResult) => void;
  onError: (file: Uppy.UppyFile) => void;
  initialize: () => Promise<void>;
  getExistingFileInUppy: (
    filename: string,
    uploadMethod: UploadMethod
  ) => Uppy.UppyFile | undefined;
  getFileName: (filename: string, uploadMethod: UploadMethod) => string;
  uploadViaTus: () => void;
  uploadViaAjax: () => void;
  getUploadUrlData: () => Promise<{ baseUrl: string; options: any }>;
};

export default UploadState;
