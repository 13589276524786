import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import './SortSelector.scss';
import {
  getLastQueryParamWithName,
  updateUrlSearchParam,
} from 'navigation/util/ParamHelpers';
import { SortItem, useVehicleList } from 'api';

const SortSelector = () => {
  const [sortOptions, setSortOptions] = useState<SortItem[]>([]);
  const [sortLabel, setSortLabel] = useState('');
  const { data, isLoading } = useVehicleList();

  const selectedSortValue = getLastQueryParamWithName('sort') || 'AGE';

  useEffect(() => {
    const responseMeta = data?.pages?.[0]?.meta;
    if (!isLoading && responseMeta?.sortOptions) {
      if (sortOptions !== responseMeta.sortOptions) {
        setSortOptions(responseMeta.sortOptions);
      }

      if (sortOptions.length > 0) {
        const selectedSortOption = sortOptions.find(
          (item: SortItem) => item.id === selectedSortValue
        ) || { label: '' };
        if (sortLabel !== selectedSortOption.label) {
          setSortLabel(selectedSortOption.label);
        }
      }
    }
  }, [data, isLoading, selectedSortValue, sortLabel, sortOptions]);

  function handleSortChange(eventKey: string | null) {
    if (typeof eventKey !== 'string') return;
    const paramSortValue = sortOptions.find(
      (item: SortItem) => item.id === eventKey
    ) || { label: '' };

    if (sortLabel !== paramSortValue.label) {
      setSortLabel(paramSortValue.label);
    }
    updateUrlSearchParam({ sort: eventKey });
  }

  return (
    <div className="SortSelector">
      <DropdownButton
        alignRight
        onSelect={handleSortChange}
        className="sort-select"
        size="sm"
        variant="light"
        key="sort-button"
        id="sort-dropdown"
        title={sortLabel}
      >
        {sortOptions.map((sortOption: SortItem) => (
          <Dropdown.Item key={sortOption.id} eventKey={sortOption.id}>
            {sortOption.label}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );
};

export default SortSelector;
