import { QueryClient } from 'react-query';
import { configuredFetchText } from '../base';

export type CrmVendor =
  | 'DEALERSOCKET'
  | 'DRIVE_CENTRIC'
  | 'ELEAD'
  | 'VIN_SOLUTIONS';

/**
 * Get email template.
 * Endpoint: GET /emailTemplate
 */
export async function getEmailTemplate(crmVendor: CrmVendor) {
  const queryClient = new QueryClient();
  const url = `/plugins/vdp/velocityengage/crmEmailTemplate?crmVendor=${crmVendor}`;
  return queryClient.fetchQuery(url, () => configuredFetchText(url));
}

export default getEmailTemplate;
