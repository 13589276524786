import { messaging } from '../firebase';

export const notificationPermissions = async () => {
  try {
    if (messaging !== null) {
      await messaging.requestPermission();
    }
  } catch (err) {}
};

export const getToken = async () => {
  try {
    if (messaging !== null) {
      const currentToken = await messaging.getToken();
      return currentToken;
    }

    return null;
  } catch (err) {
    console.warn(err);
    return false;
  }
};
