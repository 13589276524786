/* eslint-disable max-len */
// @ts-nocheck
import React from 'react';
import { IconProps } from './types';

const VelocityEngageIcon: React.FC<IconProps> = ({
  className,
  viewBox = '0 0 36 20',
}) => (
  <svg
    className={className}
    width="36"
    height="20"
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Union_8"
      data-name="Union 8"
      d="M9.9,17,0,0H4.66L6.23,2.74h0L12.9,14.33h0l3.36,5.8L14,24Z"
      fill="#1a1818"
    />
    <path
      id="Subtraction_17"
      data-name="Subtraction 17"
      d="M25.69,3.91,9.84,3.85,9.21,2.74h0L7.62,0H28l-2.28,3.9Z"
      fill="#ce0e2d"
    />
    <path
      id="Subtraction_18"
      data-name="Subtraction 18"
      d="M21.88,10.5H13.76L11.48,6.55H24.19l-2.31,4Z"
      fill="#ce0e2d"
    />
    <path
      id="Subtraction_20"
      data-name="Subtraction 20"
      d="M17.63,17.78h0L15.16,13.2h5.13l-2.66,4.58Z"
      fill="#ce0e2d"
    />
  </svg>
);

export default VelocityEngageIcon;
