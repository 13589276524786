import React from 'react';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { Card } from 'react-bootstrap';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import EngagementsPayload, {
  EngagementGroup,
} from 'models/engage/engagementsPayload';
import Button from 'components/shared/Button';
import 'components/pages/VelocityEngage/VelocityEngageEngagementsTab/EngagementsHeader/EngagementsHeader.scss';
import strings from 'common/strings';
import { EngagementDetail } from 'models/engage/engagementDetail';
import RenderSelect, {
  RenderDateRangeSelect,
  RenderGroupSelect,
} from '../EngageToolbar';
import EngageGroupHeading from '../EngageGroup';
import EngageItem from '../EngageItem';
import { EngagementSearchFacets, GroupAttribute } from 'models';
import { EngageSelectContext } from '../EngageSelectContext';
import { usePermissions } from 'hooks';
import Pagination from 'components/shared/Pagination';
import ToolbarSpacer from './ToolbarSpacer';

function isFilterAttribute(
  attributeName: string
): attributeName is FilterAttribute {
  return [
    'TIMESTAMP_BEGIN',
    'TIMESTAMP_END',
    'SHOPPER',
    'SALESPERSON',
    'METHOD',
  ].includes(attributeName);
}
export type FilterAttribute =
  | 'TIMESTAMP_BEGIN'
  | 'TIMESTAMP_END'
  | 'SHOPPER'
  | 'USER'
  | 'METHOD';
export type FilterBy = Record<FilterAttribute, string | null>;
export type SortDirection = 'ASC' | 'DESC';
export type SortAttribute =
  | 'DATE'
  | 'CUSTOMER'
  | 'VEHICLE'
  | 'METHOD'
  | 'OPENS'
  | 'VIEW_TIME'
  | 'ACTIONS'
  | 'LAST_OPEN'
  | 'SALESPERSON'
  | 'CRM_EMAILS_SENT';
interface EngagementsTableProps {
  isLoading: boolean;
  isLoadingFacets: boolean;
  isError: boolean;

  // pagination
  data?: EngagementsPayload;
  onNextPage: () => void;
  onPreviousPage: () => void;
  canGoToPreviousPage: boolean;
  canGoToNextPage: boolean;
  currentPage: number;
  setPageSize: (size: number) => void;
  pageSize: number;

  //filtering, sorting, grouping
  facets?: EngagementSearchFacets;

  //filter
  filterBy: FilterBy;
  setFilterBy: (newFilter: FilterBy) => void;
  resetFilters: () => void;

  //ordering
  sortBy: SortAttribute | null;
  sortDirection: SortDirection;
  onSwitchOrderBy: (o: SortAttribute) => void;

  //grouping
  groupBy: string | null;
  setGroupBy: (newValue: string | null) => void;

  //specific settings
  hideVehicleColumn?: boolean;
  hideGroupSelector?: boolean;
}

export const SortableTH: React.FC<{
  label: string;
  attr: SortAttribute;
  centered?: boolean;
  onSwitchOrderBy?: (o: SortAttribute) => void;
  sortBy?: SortAttribute | null;
  sortDirection?: SortDirection;
}> = ({ centered, label, attr, onSwitchOrderBy, sortBy, sortDirection }) => {
  let optionalProps = {};
  if (onSwitchOrderBy && attr) {
    optionalProps = { onClick: () => onSwitchOrderBy(attr) };
  }
  return (
    <div
      className={`${centered ? 'centered' : null} cell`}
      style={{ cursor: 'pointer' }}
      {...optionalProps}
    >
      {label}
      {sortBy === attr &&
        (sortDirection === 'ASC' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)}
    </div>
  );
};

export const Thead: React.FC<{
  hideVehicleColumn?: boolean;
  onSwitchOrderBy?: (o: SortAttribute) => void;
  sortBy?: SortAttribute | null;
  sortDirection?: SortDirection;
}> = ({ hideVehicleColumn, onSwitchOrderBy, sortBy, sortDirection }) => {
  return (
    <div className="engage-header engage-row">
      <div className="cell" style={{ cursor: 'pointer' }} />
      <SortableTH
        label={strings.DATE_AND_TIME as string}
        attr="DATE"
        onSwitchOrderBy={onSwitchOrderBy}
        sortBy={sortBy}
        sortDirection={sortDirection}
      />
      <SortableTH
        label={strings.SHOPPER as string}
        attr="CUSTOMER"
        onSwitchOrderBy={onSwitchOrderBy}
        sortBy={sortBy}
        sortDirection={sortDirection}
      />
      {hideVehicleColumn || (
        <SortableTH
          label={strings.TARGET_TYPE_VEHICLE as string}
          attr="VEHICLE"
          onSwitchOrderBy={onSwitchOrderBy}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
      )}
      <SortableTH
        label={strings.USER as string}
        attr="SALESPERSON"
        onSwitchOrderBy={onSwitchOrderBy}
        sortBy={sortBy}
        sortDirection={sortDirection}
      />
      <SortableTH
        centered
        label={strings.METHOD as string}
        attr="METHOD"
        onSwitchOrderBy={onSwitchOrderBy}
        sortBy={sortBy}
        sortDirection={sortDirection}
      />
      <SortableTH
        centered
        label={strings.CVDP_OPENS as string}
        attr="OPENS"
        onSwitchOrderBy={onSwitchOrderBy}
        sortBy={sortBy}
        sortDirection={sortDirection}
      />
      <SortableTH
        centered
        label={strings.TOTAL_DURATION as string}
        attr="VIEW_TIME"
        onSwitchOrderBy={onSwitchOrderBy}
        sortBy={sortBy}
        sortDirection={sortDirection}
      />
      <SortableTH
        centered
        label={strings.SHOPPER_ACTIONS as string}
        attr="ACTIONS"
        onSwitchOrderBy={onSwitchOrderBy}
        sortBy={sortBy}
        sortDirection={sortDirection}
      />
      <SortableTH
        centered
        label={strings.LAST_OPEN as string}
        attr="LAST_OPEN"
        onSwitchOrderBy={onSwitchOrderBy}
        sortBy={sortBy}
        sortDirection={sortDirection}
      />
    </div>
  );
};

export const EngageGroupThead: React.FC<{ groupAttribute?: GroupAttribute }> =
  ({ groupAttribute }) => {
    return (
      <div className="engage-header engage-row">
        <div className="cell" />
        <div className="cell" />
        <div className="cell" />
        <div className="cell" />
        <div className="cell" />
        <div className="cell" />
        {groupAttribute === 'USER' ? (
          <SortableTH
            centered
            label={strings.CRM_EMAILS_SENT as string}
            attr="CRM_EMAILS_SENT"
          />
        ) : (
          <div className="cell" />
        )}
        <SortableTH
          centered
          label={strings.CVDP_OPENS as string}
          attr="OPENS"
        />
        <SortableTH
          centered
          label={strings.TOTAL_DURATION as string}
          attr="VIEW_TIME"
        />
        <SortableTH
          centered
          label={strings.SHOPPER_ACTIONS as string}
          attr="ACTIONS"
        />
      </div>
    );
  };

const EngagementsTable: React.FC<EngagementsTableProps> = ({
  isLoading,
  isLoadingFacets,
  data,
  isError,
  onNextPage,
  onPreviousPage,
  currentPage,
  setPageSize,
  facets,
  filterBy,
  setFilterBy,
  sortBy,
  sortDirection,
  onSwitchOrderBy,
  groupBy,
  setGroupBy,
  canGoToNextPage,
  canGoToPreviousPage,
  resetFilters,
  hideVehicleColumn,
  hideGroupSelector,
  pageSize,
}) => {
  const totalItems = data?.totalItems ?? 0;
  const [selectedItem, setSelectedItem] = React.useState<string | null>(null);
  const { list } = data || {};
  const hasResults = list && list[0].engagements?.length;
  /**
   * Component Parts separated out for readability.
   * - PageSelect
   * - Toolbar
   * - Thead
   * - DataTable
   * - EmptyTable
   */

  const { hasPermission } = usePermissions();

  const Toolbar = (
    <ToolbarSpacer title={`${totalItems} Engagements`}>
      <RenderDateRangeSelect
        filterCallback={(start, end) =>
          setFilterBy({
            ...filterBy,
            TIMESTAMP_BEGIN: start,
            TIMESTAMP_END: end,
          })
        }
        current={filterBy.TIMESTAMP_BEGIN}
      />
      {facets?.filterFacets &&
        facets.filterFacets
          .filter((i) => i.displayType === 'SELECT_LIST')
          // @todo - this will suppress option to filter by users if lacking permission. Only needed as the backend
          // service does not make this distinction for us. Remove when able.
          .filter((i) =>
            !hasPermission('PLUGIN_VELOCITYENGAGE_VIEW_OTHER_USER_ACTIVITY')
              ? i.attribute !== 'USER'
              : i
          )
          .map((att) => {
            if (isFilterAttribute(att.attribute)) {
              return (
                <RenderSelect
                  facet={att}
                  queryCallback={(name, value) =>
                    setFilterBy({ ...filterBy, [att.attribute]: value })
                  }
                  current={filterBy[att.attribute] ?? null}
                  disabled={!!(groupBy !== null)}
                />
              );
            }
            return null;
          })}
      {hideGroupSelector ||
        (facets?.groupFacets && (
          <RenderGroupSelect
            facets={facets?.groupFacets}
            queryCallback={(optionId) => {
              if (!optionId || optionId === 'reset') {
                setGroupBy(null);
              } else {
                setGroupBy(optionId);
              }
            }}
            current={groupBy}
          />
        ))}
    </ToolbarSpacer>
  );

  const FooterToolbar = (
    <div className="EngagementsFooter">
      <Pagination
        canGoToNextPage={canGoToNextPage}
        canGoToPreviousPage={canGoToPreviousPage}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
        onPageSizeChange={setPageSize}
        page={currentPage}
        pageSize={pageSize}
        totalItems={totalItems}
      />
    </div>
  );

  const DataTable = (
    <EngageSelectContext.Provider value={[selectedItem, setSelectedItem]}>
      {data?.list &&
        data.list.map((group: EngagementGroup) => (
          <>
            {group?.engagements?.map((item: EngagementDetail) => {
              if (group.groupFacet !== null) {
                return (
                  <>
                    <EngageGroupHeading
                      group={group}
                      detail={item}
                      key={`engage-groupheading-${item.id}`}
                    />
                  </>
                );
              }
              return (
                <>
                  <EngageItem
                    id={item.id}
                    item={item}
                    groupBy={groupBy}
                    key={`engage-item-${item.id}`}
                  />
                </>
              );
            })}
          </>
        ))}
    </EngageSelectContext.Provider>
  );

  const isTimeRange = React.useMemo(
    () => filterBy.TIMESTAMP_BEGIN !== null,
    [filterBy.TIMESTAMP_BEGIN]
  );

  const EmptyTable = (
    <div className="engage-row helper">
      {isLoadingFacets && <span>Waiting for search facets</span>}
      {isLoading && <LoadingIndicator />}
      {isError && <h3>Encountered an error</h3>}

      {data && (
        <div>
          <p>
            <h4>No results</h4>
          </p>
          {isTimeRange ? (
            <Button
              type="button"
              variant="primary"
              onClick={() =>
                setFilterBy({
                  ...filterBy,
                  TIMESTAMP_BEGIN: null,
                  TIMESTAMP_END: null,
                })
              }
            >
              Remove date filters
            </Button>
          ) : (
            <Button type="button" variant="primary" onClick={resetFilters}>
              Reset filters
            </Button>
          )}
        </div>
      )}
    </div>
  );

  return (
    <>
      {Toolbar}
      <div className="engage-table-custom">
        {groupBy ? (
          <EngageGroupThead
            groupAttribute={data?.list?.[0]?.groupFacet?.attribute}
          />
        ) : (
          <Thead
            hideVehicleColumn={hideVehicleColumn}
            onSwitchOrderBy={onSwitchOrderBy}
            sortBy={sortBy}
            sortDirection={sortDirection}
          />
        )}
        {hasResults ? DataTable : EmptyTable}
      </div>
      <Card.Footer>{FooterToolbar}</Card.Footer>
    </>
  );
};

export default EngagementsTable;
