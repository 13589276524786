import React, { useEffect, useState } from 'react';
import { Dropdown, Image, Nav, Navbar } from 'react-bootstrap';
import {
  Avatar,
  Badge,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Notifications, Menu, PeopleAlt } from '@material-ui/icons';
import { navigate, useLocation } from '@reach/router';

import { useLogoutMutation, useNotifications, useToken } from 'api';
import { Notification } from 'models';
import permissions from 'common/permissions';
import strings from 'common/strings';
import CustomDropdownToggle from 'components/shared/CustomDropdownToggle';
import {
  INDEX,
  DASHBOARD_RELATIVE,
  REPORTS_RELATIVE,
  INVENTORY_RELATIVE,
} from 'navigation/routes';
import UploadManager from 'components/shared/UploadManager';
import features from 'common/features';
import { UploadItem, UploadStatus, UploadType } from 'store/uploads/types';
import useUploadStore from 'store/uploads/useUploadStore';

import './Header.scss';
import { sendMessageToExtensionAsync } from 'utils/extension';
import {
  useCurrentLocationId,
  useFeatures,
  usePermissions,
  useWindowSize,
} from 'hooks';
import {
  EXTENSION_WEB_STORAGE_ACTION,
  GET_ITEM_KEY,
} from 'utils/storage/ExtensionStorage';

import copyStringToClipboard from 'utils/clipboard';
import Alert from 'components/shared/Alert';
import { CrmVendor, getEmailTemplate } from 'api';
import Logo from '../../../Images/Velocity-Logo-White-250.png';
import RooftopSelect from './RooftopSelect';
import PermissionsGate from '../PermissionsGate';

import ChangePasswordModal from './ChangePasswordModal';
import EditProfileModal from './EditProfileModal';
import SubmitFeedbackModal from '../SubmitFeedbackModal';
import VINInquiryModal from './VINInquiryModal';
import { EnvironmentName } from '../../../index';
import { getCredentialValue } from 'api/lib/localStorageManagement';

interface HeaderProps {
  isOpenHamburgerMenu: boolean;
  setIsOpenHamburgerMenu: (isOpen: boolean) => void;
  isNotificationCenterOpen: boolean;
  onOpenNotificationCenter: () => void;
}

const Header: React.FC<HeaderProps> = ({
  isOpenHamburgerMenu,
  setIsOpenHamburgerMenu,
  isNotificationCenterOpen,
  onOpenNotificationCenter,
}) => {
  const { hasFeature } = useFeatures();
  const { hasPermission } = usePermissions();
  const { data: { data: notifications } = { data: [] } } = useNotifications();
  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [isSubmitFeedbackModalOpen, setIsSubmitFeedbackModalOpen] =
    useState(false);
  const tabs: any[] = [];
  if (
    hasPermission(permissions.PLUGIN_VELOCITYENGAGE_VIEW_DASHBOARD) ||
    hasPermission(permissions.DASHBOARD_INVENTORY_NEEDING_ATTENTION_VIEW) ||
    hasPermission(permissions.DASHBOARD_SUMMARY_VIEW)
  ) {
    tabs.push({ label: strings.DASHBOARD, route: DASHBOARD_RELATIVE });
  }
  if (
    hasPermission(permissions.RECON_VIEW) ||
    hasPermission(permissions.INVENTORY_VIEW)
  ) {
    tabs.push({ label: strings.INVENTORY, route: INVENTORY_RELATIVE });
  }
  if (
    hasPermission(permissions.REPORTS_STEP_REPORT_VIEW) ||
    (hasPermission(permissions.PLUGIN_VELOCITYENGAGE_CORPORATE_REPORT_VIEW) &&
      hasFeature(features.VELOCITY_ENGAGE_CORPORATE_REPORT))
  )
    tabs.push({ label: strings.REPORTS, route: REPORTS_RELATIVE });

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      indicator: {
        height: '4px',
      },
      avatar: {
        marginTop: theme.spacing(1),
        marginRight: 0,
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),
      },
    })
  );
  const uploadStoreData = useUploadStore((uploadStore) => ({
    uploads: uploadStore.uploads,
    closeUppyInstances: uploadStore.closeUppyInstances,
  }));

  const classes = useStyles();
  const windowSize = useWindowSize();
  const location = useLocation();
  const [extensionInstalled, setExtensionInstalled] = useState(false);
  const [isCopyTemplateSuccess, setIsCopyTemplateSuccess] = useState(false);
  const [isCopyTemplateError, setIsCopyTemplateError] = useState(false);
  const [isVINInquiryModalOpen, setIsVINInquiryModalOpen] = useState(false);
  const [isVINInquiryModalError, setIsVINInquiryModalError] = useState(false);

  const checkExtensionStatus = async (): Promise<void> => {
    const BEARER_TOKEN_STORAGE_KEY = 'authBearerToken';
    let extensionSourceId = 'nmnbkeffccomlhaepaaiglmolinmjapm';

    const environmentName =
      (process.env.REACT_APP_RECONVELOCITY_APPSERVICE_ENV?.toLowerCase() ||
        'dev') as EnvironmentName;

    if (environmentName === 'staging') {
      extensionSourceId = 'mcgepijpaooibppegecokeodkmanncij';
    } else if (environmentName === 'production') {
      extensionSourceId = 'mkanlikcnccbgabpaphgjidigdjnekab';
    }

    const response = await sendMessageToExtensionAsync(extensionSourceId, {
      action: EXTENSION_WEB_STORAGE_ACTION,
      method: GET_ITEM_KEY,
      key: BEARER_TOKEN_STORAGE_KEY,
    });

    if (response?.status) {
      setExtensionInstalled(true);
    }
  };

  useEffect(() => {
    checkExtensionStatus();
  }, []);

  const handleDealershipAdminSelect = () => {
    const adminUrl = process.env.REACT_APP_ADMIN_URL_OVERRIDE;
    const token = getCredentialValue('authRefreshToken');
    if (!!adminUrl && !!token) {
      window.open(`${adminUrl}?refreshToken=${token}`, '_newtab');
    }
  };
  const { data: sessionData } = useToken();
  const activeRooftopId = useCurrentLocationId();
  const handleLinkSelect = async (eventKey: string) => {
    const tab = tabs.find((tab) => tab.route === eventKey);
    if (tab) {
      if (!activeRooftopId) return;
      await navigate(`/${activeRooftopId}/${tab.route}`);
      window.scrollTo(0, 0);
    }
  };

  function handleCloseModal() {
    setIsEditProfileModalOpen(false);
  }

  const closeChangePasswordModal = () => {
    setIsChangePasswordModalOpen(false);
  };

  const closeSubmitFeedbackModal = () => {
    setIsSubmitFeedbackModalOpen(false);
  };

  const closeVINInquiryModal = () => {
    setIsVINInquiryModalOpen(false);
  };

  // TODO: Add initials to User model.

  const initials = () => {
    const user = sessionData?.user;
    if (!user) return null;
    return (user.firstName?.[0] || '') + (user.lastName?.[0] || '');
  };

  const handleEditProfileClick = () => {
    setIsEditProfileModalOpen(true);
  };

  const handleChangePasswordClick = () => {
    setIsChangePasswordModalOpen(true);
  };

  const handleSubmitFeedbackClick = () => {
    setIsSubmitFeedbackModalOpen(true);
  };

  const handleVelocityInsightDownload = () => {
    const chromeURL =
      'https://chrome.google.com/webstore/detail/velocityinsight/mkanlikcnccbgabpaphgjidigdjnekab';
    window.open(chromeURL, '_newtab');
  };

  const handleCopyEmailTemplateClick = async (crmVendor: CrmVendor) => {
    try {
      const emailTemplate: string = await getEmailTemplate(crmVendor);
      copyStringToClipboard(emailTemplate);
      setIsCopyTemplateSuccess(true);
    } catch (e: any) {
      setIsCopyTemplateError(true);
    }
  };

  const handleVINInquiryClick = async () => {
    setIsVINInquiryModalOpen(true);
  };

  const onCloseCopyTemplateAlert = () => {
    setIsCopyTemplateSuccess(false);
    setIsCopyTemplateError(false);
  };

  const { mutateAsync: logout } = useLogoutMutation();

  const handleLogoutClick = async () => {
    const activeNoteUploads = uploadStoreData.uploads.filter(
      (upload: UploadItem) =>
        upload.uploadStatus === UploadStatus.UPLOADING &&
        upload.meta.target.type === UploadType.NOTE
    );
    const deleteNotePromises = activeNoteUploads.map((upload: UploadItem) => {
      if (upload.onUploadCancel) {
        return upload.onUploadCancel();
      }
      return undefined;
    });

    await Promise.all(deleteNotePromises);
    uploadStoreData.closeUppyInstances();
    await logout();
    await navigate(INDEX);
  };

  const toggleNotificationCenter = async () => {
    onOpenNotificationCenter();
  };

  const toggleHamburgerMenu = async () => {
    setIsOpenHamburgerMenu(!isOpenHamburgerMenu);
  };

  // TODO this should be in its own component and the API should return this info
  const notificationCount = hasPermission(permissions.NOTIFICATIONS_VIEW)
    ? notifications.reduce(
        (acc: number, notification: Notification) =>
          notification.read ? acc : acc + 1,
        0
      )
    : 0;
  const badgeClassName = [
    'notifications-badge',
    notificationCount ? 'nonzero' : '',
    isNotificationCenterOpen ? 'open' : '',
  ].join(' ');

  const renderMobileMenu = () => (
    <Dropdown className="d-lg-none mr-2 mobileMenu" drop="down">
      <Dropdown.Toggle as={CustomDropdownToggle} id="Header-Toggle-dropdown">
        <Menu
          className="Header-Toggle-MenuIcon"
          onClick={toggleHamburgerMenu}
        />
      </Dropdown.Toggle>
    </Dropdown>
  );

  const dropdownMenuStyleByPermission =
    hasPermission(permissions.PLUGIN_VELOCITYINSIGHT_VIEW) &&
    !extensionInstalled
      ? 'Header-avatar-dropdown-RV-extension-added'
      : 'Header-avatar-dropdown';

  const activeTabName = location.pathname.split('/')[2] || 'dashboard';
  return (
    <div className="Header">
      <Navbar className="Header-Navbar" variant="dark">
        {renderMobileMenu()}
        <Navbar.Brand>
          <Image fluid src={Logo} />
        </Navbar.Brand>
        <>
          <Nav
            className={windowSize.isTabletLandscape() ? 'd-none' : 'mr-auto'}
          >
            {tabs.map((tab) => (
              <Nav.Link
                key={`${tab.label}-tab`}
                id={`${tab.label}-tab`}
                active={tab.route === activeTabName}
                eventKey={tab.route}
                onSelect={(key) => handleLinkSelect(`${key}`)}
              >
                {tab.label}
              </Nav.Link>
            ))}
          </Nav>
          <div className="flex-grow" />
        </>
        <RooftopSelect />
        {hasFeature(features.DEALERSHIP_ADMIN) &&
          hasPermission(permissions.DEALERSHIP_ADMIN_VIEW) &&
          windowSize.isDesktopViewport() && (
            <div
              role="button"
              className="Header-dealership-admin-link"
              tabIndex={0}
              onClick={handleDealershipAdminSelect}
            >
              <PeopleAlt fontSize="small" />
            </div>
          )}
        <>
          {hasFeature(features.BIG_FILE_UPLOAD) && <UploadManager />}
          <PermissionsGate permissions={[permissions.NOTIFICATIONS_VIEW]}>
            <span className="notifications-link">
              {notificationCount ? (
                <Badge
                  badgeContent={notificationCount}
                  className={badgeClassName}
                  role="link"
                  tabIndex={-1}
                  onClick={toggleNotificationCenter}
                >
                  <Notifications
                    className="notifications-icon"
                    fontSize="large"
                  />
                </Badge>
              ) : (
                <Notifications
                  className="notifications-icon"
                  onClick={toggleNotificationCenter}
                  fontSize="large"
                />
              )}
            </span>
          </PermissionsGate>
        </>
        <Dropdown className="d-none d-sm-block" drop="down">
          <Dropdown.Toggle as={CustomDropdownToggle} id="avatar-dropdown">
            <Avatar className={classes.avatar} alt="User Name">
              {initials()}
            </Avatar>
          </Dropdown.Toggle>
          <Dropdown.Menu className={dropdownMenuStyleByPermission}>
            <Dropdown.Item onClick={handleSubmitFeedbackClick}>
              {strings.SUBMIT_FEEDBACK}
            </Dropdown.Item>
            <Dropdown.Divider />
            {hasPermission(permissions.USERS_SELF_UPDATE) && (
              <>
                <Dropdown.Item onClick={handleEditProfileClick}>
                  {strings.EDIT_PROFILE}
                </Dropdown.Item>
                <Dropdown.Item onClick={handleChangePasswordClick}>
                  {strings.CHANGE_PASSWORD}
                </Dropdown.Item>
              </>
            )}
            {hasPermission(permissions.PLUGIN_VELOCITYENGAGE_CREATE) && (
              <>
                <Dropdown.Divider />
                <Dropdown.Item
                  onClick={() => handleCopyEmailTemplateClick('DEALERSOCKET')}
                >
                  {strings.VELOCITY_ENGAGE_EMAIL_TEMPLATE_DEALERSOCKET}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleCopyEmailTemplateClick('DRIVE_CENTRIC')}
                >
                  {strings.VELOCITY_ENGAGE_EMAIL_TEMPLATE_DRIVE_CENTRIC}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleCopyEmailTemplateClick('ELEAD')}
                >
                  {strings.VELOCITY_ENGAGE_EMAIL_TEMPLATE_ELEAD}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleCopyEmailTemplateClick('VIN_SOLUTIONS')}
                >
                  {strings.VELOCITY_ENGAGE_EMAIL_TEMPLATE_VIN_SOLUTIONS}
                </Dropdown.Item>
              </>
            )}
            {(hasPermission(permissions.PLUGIN_VELOCITYWINDOWSTICKER_VIEW) ||
              hasPermission(permissions.PLUGIN_VELOCITYENGAGE_VIEW)) && (
              <Dropdown.Item onClick={handleVINInquiryClick}>
                {strings.VIN_INQUIRY}
              </Dropdown.Item>
            )}
            {!extensionInstalled && (
              <>
                <PermissionsGate
                  permissions={[permissions.PLUGIN_VELOCITYINSIGHT_VIEW]}
                >
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={handleVelocityInsightDownload}>
                    {strings.VELOCITY_INSIGHT_DOWNLOAD}
                  </Dropdown.Item>
                </PermissionsGate>
              </>
            )}
            <Dropdown.Divider />
            <Dropdown.Item onClick={handleLogoutClick}>
              {strings.LOGOUT}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Navbar>
      {isCopyTemplateError && (
        <Alert
          open={isCopyTemplateError}
          contentProps={{
            message: strings.API_MESSAGE,
            variant: 'error',
            onClose: onCloseCopyTemplateAlert,
          }}
        />
      )}
      {isVINInquiryModalError && (
        <Alert
          open={isVINInquiryModalError}
          contentProps={{
            message: strings.VEHICLE_NOT_FOUND_ALERT_MESSAGE,
            variant: 'error',
            onClose: () => setIsVINInquiryModalError(false),
          }}
          handleClose={() => setIsVINInquiryModalError(false)}
          duration={5000}
        />
      )}
      {isCopyTemplateSuccess && (
        <Alert
          open={isCopyTemplateSuccess}
          contentProps={{
            message: strings.COPY_EMAIL_TEMPLATE_SUCCESS,
            variant: 'success',
            onClose: onCloseCopyTemplateAlert,
          }}
          handleClose={onCloseCopyTemplateAlert}
          duration={5000}
        />
      )}
      {isEditProfileModalOpen && (
        <EditProfileModal onClose={handleCloseModal} />
      )}
      {isChangePasswordModalOpen && (
        <ChangePasswordModal onClose={closeChangePasswordModal} />
      )}
      {isSubmitFeedbackModalOpen && (
        <SubmitFeedbackModal onClose={closeSubmitFeedbackModal} />
      )}
      {isVINInquiryModalOpen && (
        <VINInquiryModal
          onClose={closeVINInquiryModal}
          onError={() => setIsVINInquiryModalError(true)}
        />
      )}
    </div>
  );
};

export default Header;
