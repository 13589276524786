import { useState } from 'react';
import { StepSummary } from './StepSummary';
import { DealerStepReportPayload, DealerStepReportSummaryItem } from 'models';
import { useDealerStepReportDownloadLink } from 'api';
import { ReportType } from 'components/shared/DownloadMenu/DownloadMenu';
import { DateRange } from 'common/DateRange';

export const EmbeddedDealerStepReport = ({
  dealerStepReport,
  openStepModal,
  dateRange,
  tenantId,
}: {
  dealerStepReport: DealerStepReportPayload;
  openStepModal: (step: DealerStepReportSummaryItem | null) => void;
  dateRange: DateRange;
  tenantId?: string;
}) => {
  const [selectedReportType, setSelectedReportType] =
    useState<ReportType | null>(null);
  const { isLoading, downloadFileUrl = '' } = useDealerStepReportDownloadLink(
    selectedReportType,
    dateRange,
    tenantId
  );

  return (
    <>
      {dealerStepReport && (
        <StepSummary
          setSelectedReportType={setSelectedReportType}
          vehicles={dealerStepReport.vehicles}
          summaryItems={dealerStepReport.summaryItems}
          dateRange={dateRange}
          downloadFileUrl={downloadFileUrl}
          isLoading={isLoading}
          openStepModal={openStepModal}
          isInCorporateReport={tenantId}
        />
      )}
    </>
  );
};
