import React from 'react';
import moment from 'moment';

import strings from 'common/strings';
import SectionList from 'components/shared/SectionList';
import { HistoryItem } from 'models';
import HistoryMessage from '../HistoryMessage';

import './VehicleLog.scss';

interface VehicleLogProps {
  historyLog: {
    title: string;
    data: HistoryItem[];
  }[];
  isLoading: boolean;
}

const VehicleLog: React.FC<VehicleLogProps> = ({ isLoading, historyLog }) => {
  const renderHistoryMessage = (historyItem: HistoryItem) => (
    <HistoryMessage key={historyItem.id} historyItem={historyItem} />
  );

  const getSectionLabel = (date: string) => {
    const historySectionDate = moment(date);
    const today = moment();
    const yesterday = moment().subtract(1, 'days');

    const isToday = historySectionDate.isSame(today, 'day');
    const isYesterday = historySectionDate.isSame(yesterday, 'day');

    return isToday
      ? strings.TODAY
      : isYesterday
      ? strings.YESTERDAY
      : historySectionDate.format('MMMM D, YYYY');
  };

  const renderSection = (title: string) => {
    const label = getSectionLabel(title);

    return (
      <div key={`section-${label}`} className="VehicleLog-section-header">
        {label}
      </div>
    );
  };

  const renderNoHistory = () => (
    <div className="no-note-message">{strings.NO_CURRENT_HISTORY_MESSAGE}</div>
  );

  if (historyLog.length > 0) {
    return (
      <SectionList
        item
        container
        direction="column"
        xs={12}
        renderItem={renderHistoryMessage}
        wrap="nowrap"
        reverse={false}
        className="VehicleLog flex-grow vertical-scroll"
        renderSectionTitle={renderSection}
        sections={historyLog}
      />
    );
  }
  return !isLoading ? renderNoHistory() : null;
};

export default VehicleLog;
