import React, { useState, useMemo } from 'react';
import { CommonProps, ViewType } from 'react-images';
import { Close, Delete, MoreHoriz, SwapHoriz } from '@material-ui/icons';
import { Toast } from 'react-bootstrap';

import strings from 'common/strings';
import permissions from 'common/permissions';
import { getCalendarDateTimeStringFromDate } from 'utils/time';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import Modal from 'components/shared/Modal';
import Select from 'components/shared/Inputs/Select';
import { Option } from 'components/shared/Inputs/Select/types';
import PermissionsGate from 'components/shared/PermissionsGate';
import { useGetVehicleImagesShots } from 'api';

import './CustomHeader.scss';
import { usePermissions } from 'hooks';

interface CustomCurrentView extends ViewType {
  name?: string;
  createdDate?: Date;
  id?: string;
  type?: string;
}

export interface CustomHeaderProps extends CommonProps {
  currentView?: CustomCurrentView;
}

const CustomHeader: React.FC<CustomHeaderProps> = (props) => {
  const {
    modalProps,
    innerProps = {
      onDeleteImage: undefined,
      onAssignImage: undefined,
      deletePermission: '',
      assignPermission: '',
    },
    views,
  } = props;
  let { currentView } = props;
  if (!currentView && views) {
    [currentView] = views;
  }
  const { onAssignImage, onDeleteImage, deletePermission, assignPermission } =
    innerProps;
  const { data } = useGetVehicleImagesShots();
  const [isDeletingImage, setIsDeletingImage] = useState(false);
  const [isAssigningImage, setIsAssigningImage] = useState(false);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [isDeleteImageModalOpen, setIsDeleteImageModalOpen] = useState(false);
  const [isSyndicationModalOpen, setIsSyndicationModalOpen] = useState(false);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option>();
  const syndicationOptions = useMemo(() => {
    if (data) {
      return data.reduce(
        (acc: Option[], shot: { name: string; value: string }) => {
          if (shot.value.toUpperCase() === 'UNKNOWN') return acc;
          return [...acc, { label: shot.name, value: shot.value }];
        },
        []
      );
    }
    return [];
  }, [data]);
  const { hasPermission } = usePermissions();

  const userHasMenuPermission = [deletePermission, assignPermission].some(
    hasPermission
  );
  const showContextMenu =
    currentView?.type !== 'STOCK' &&
    userHasMenuPermission &&
    (onDeleteImage || onAssignImage);

  const assignImage = async () => {
    if (currentView?.id && selectedOption?.value && onAssignImage) {
      try {
        setIsAssigningImage(true);
        await onAssignImage(currentView.id, selectedOption.value);
        setIsAssigningImage(false);
        setIsSyndicationModalOpen(false);
        setIsToastVisible(true);
      } catch (err) {
        setIsAssigningImage(false);
      }
    }
  };

  const deleteImage = async () => {
    if (currentView?.id && onDeleteImage) {
      try {
        setIsDeletingImage(true);
        onDeleteImage(currentView.id);
        setIsDeletingImage(false);
        setIsContextMenuOpen(false);
        setIsDeleteImageModalOpen(false);
      } catch (err) {
        setIsDeletingImage(false);
        setIsDeleteImageModalOpen(false);
      }
    }
  };

  return (
    <>
      <div className="ModalCarousel-header" {...innerProps}>
        <button
          type="button"
          onClick={modalProps?.onClose}
          className="ModalCarousel-header-close"
        >
          <Close />
        </button>
        <div className="ModalCarousel-header-text">
          {currentView?.name && (
            <div className="ModalCarousel-header-text-title">
              {currentView.name}
            </div>
          )}
          {currentView?.createdDate && (
            <div className="ModalCarousel-header-text-subtitle">
              {getCalendarDateTimeStringFromDate(currentView.createdDate)}
            </div>
          )}
        </div>
        <div className="ModalCarousel-header-context">
          {showContextMenu && (
            <button
              type="button"
              onClick={() => setIsContextMenuOpen(!isContextMenuOpen)}
              className="ModalCarousel-header-more"
            >
              <MoreHoriz />
            </button>
          )}
          {currentView?.type !== 'STOCK' && isContextMenuOpen && (
            <div className="ModalCarousel-header-context-menu">
              {onDeleteImage &&
                currentView?.id &&
                currentView.type !== 'STOCK' && (
                  <PermissionsGate permissions={[deletePermission]}>
                    <button
                      type="button"
                      className="ModalCarousel-header-context-menu-item"
                      onClick={async () => {
                        setIsDeleteImageModalOpen(true);
                        setIsContextMenuOpen(false);
                      }}
                    >
                      {isDeletingImage ? (
                        <LoadingIndicator
                          size={16}
                          className="ModalCarousel-header-context-menu-item-icon"
                        />
                      ) : (
                        <Delete className="ModalCarousel-header-context-menu-item-icon" />
                      )}
                      Delete
                    </button>
                  </PermissionsGate>
                )}
              {onAssignImage && currentView?.type !== 'STOCK' && (
                <PermissionsGate
                  permissions={[permissions.INVENTORY_VDP_PHOTOS_CREATE]}
                >
                  <button
                    type="button"
                    className="ModalCarousel-header-context-menu-item"
                    onClick={() => {
                      setIsSyndicationModalOpen(true);
                      setIsContextMenuOpen(false);
                    }}
                  >
                    <SwapHoriz className="ModalCarousel-header-context-menu-item-icon" />
                    Assign To
                  </button>
                </PermissionsGate>
              )}
            </div>
          )}
        </div>
      </div>
      <Modal
        className="ModalCarousel-syndication-modal"
        title={strings.GALLERY_SYNDICATION_MODAL_TITLE}
        open={isSyndicationModalOpen}
        onClose={() => setIsSyndicationModalOpen(false)}
        onPrimaryButtonClick={assignImage}
        primaryButtonDisabled={!selectedOption}
        primaryButtonLoading={isAssigningImage}
        primaryButtonLabel={strings.ASSIGN}
        secondaryButtonLabel={strings.CANCEL}
      >
        <Select
          options={syndicationOptions}
          onChange={(option) => setSelectedOption(option)}
          placeholder={strings.GALLERY_SYNDICATION_MODAL_PLACEHOLDER}
        />
      </Modal>
      <Modal
        className="ModalCarousel-syndication-modal"
        title={strings.DELETE_IMAGE_MODAL_TITLE}
        open={isDeleteImageModalOpen}
        onClose={() => setIsDeleteImageModalOpen(false)}
        onPrimaryButtonClick={deleteImage}
        primaryButtonLabel={strings.DELETE_IMAGE_MODAL_CONFIRM}
        primaryButtonVariant="danger"
        primaryButtonLoading={isDeletingImage}
        secondaryButtonLabel={strings.DELETE_IMAGE_MODAL_CANCEL}
      >
        <span />
      </Modal>
      <Toast
        className="CustomHeader-toast"
        show={isToastVisible}
        autohide
        delay={3000}
        onClose={() => setIsToastVisible(false)}
      >
        <Toast.Header className="CustomHeader-toast-header" closeButton={false}>
          <div className="CustomHeader-toast-header-text">
            {`Assigned to "${selectedOption?.label}"`}
          </div>
          <button
            type="button"
            className="CustomHeader-toast-header-close-button"
            onClick={() => setIsToastVisible(false)}
          >
            {strings.OK}
          </button>
        </Toast.Header>
      </Toast>
    </>
  );
};

CustomHeader.displayName = 'CustomHeader';
export default CustomHeader;
