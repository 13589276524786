import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

import CarIcon from '@material-ui/icons/DriveEta';

import './VehicleImageView.scss';

const ICON_SIZES = {
  normal: 'large',
  sm: 'default',
  xs: 'small',
  square: 'large',
} as const;

type IconSize = keyof typeof ICON_SIZES;

interface VehicleImageViewProps {
  imageUrl?: string;
  size?: IconSize;
  thumbnail?: boolean;
  className?: string;
  skeleton?: boolean;
  onError?: () => void;
  onLoad?: () => void;
}

var VehicleImageView = ({
  imageUrl,
  size,
  skeleton,
  thumbnail,
  className,
  onError,
  onLoad,
}: VehicleImageViewProps) => {
  const [hasError, setHasError] = useState(false);
  if (!size) size = thumbnail ? 'sm' : 'normal';

  const style =
    imageUrl && !hasError ? { backgroundImage: `url(${imageUrl})` } : {};
  className = `VehicleImageView VehicleImageView-size-${size} ${
    imageUrl && !hasError ? '' : 'no-image'
  } ${className}`;

  useEffect(() => {
    setHasError(false);
  }, [imageUrl]);
  return (
    <div className={className} style={style}>
      {skeleton ? (
        <div className="full-width full-height">
          <Skeleton height="100%" />
        </div>
      ) : (
        (!imageUrl || hasError) && <CarIcon fontSize={ICON_SIZES[size]} />
      )}
      <img
        className="d-none"
        src={imageUrl}
        alt="vehicle"
        onError={() => {
          setHasError(true);
          if (onError) {
            onError();
          }
        }}
        onLoad={() => {
          if (onLoad) {
            onLoad();
          }
        }}
      />
    </div>
  );
};

export default VehicleImageView;
