import React from 'react';
import './Pane.scss';

interface PaneProps {
  className?: string;
  header?: React.ReactElement;
  children: React.ReactNode;
  footer?: React.ReactElement;
  bodyRef?: any;
}

var Pane = ({ header, children, className, footer, bodyRef }: PaneProps) => {
  return (
    <div className={`Pane ${className}`}>
      <div className="pane-wrapper">
        <div className="pane-header">{header}</div>
        <div className="pane-body" ref={bodyRef}>
          {children}
        </div>
        <div className="pane-footer">{footer}</div>
      </div>
    </div>
  );
};

export default Pane;
