import React, { useState } from 'react';
import { Send, Visibility } from '@material-ui/icons';

import strings from 'common/strings';

import PriceHistoryModal from 'components/shared/PriceHistoryModal';

import './PriceInfo.scss';
import { Price } from 'models';
import { PriceHistoryItem } from 'models/inventory/EngageOverview';

interface PriceInfoProps {
  price?: Price | null;
  sentCount: number;
  openCount: number;
  priceHistory: PriceHistoryItem[];
}

const PriceInfo: React.FC<PriceInfoProps> = ({
  price,
  sentCount,
  openCount,
  priceHistory,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const displayPrice =
    price && price.amount ? `$ ${price.amount}` : `$ ${strings.EMPTY_VALUE}`;

  return (
    <div className="PriceInfo">
      <div className="PriceInfo-price">{displayPrice}</div>
      <div className="PriceInfo-info">
        <div className="PriceInfo-label">{strings.PRICE_DROP_ALERTS}</div>
        <div className="PriceInfo-sent-info">
          <div className="PriceInfo-btn PriceInfo-btn-sent">
            <Send className="PriceInfo-btn-icon" />
            <span className="PriceInfo-btn-text">
              {`${sentCount ?? strings.EMPTY_VALUE} ${strings.SENT}`}
            </span>
          </div>
          <div className="PriceInfo-btn PriceInfo-btn-opened">
            <Visibility className="PriceInfo-btn-icon" />
            <span className="PriceInfo-btn-text">
              {`${openCount ?? strings.EMPTY_VALUE} ${strings.OPENED}`}
            </span>
          </div>
        </div>
      </div>
      <PriceHistoryModal
        open={isModalOpen}
        onClose={closeModal}
        onClick={openModal}
        priceHistory={priceHistory}
      />
    </div>
  );
};

export default PriceInfo;
