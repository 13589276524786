import React from 'react';
import { BarChart, XAxis, YAxis, Bar, ResponsiveContainer } from 'recharts';
import { OverviewOpensEntry } from 'models';

import strings from 'common/strings';

import './Opens.scss';

interface OpensProps {
  data: OverviewOpensEntry[];
}

const Opens: React.FC<OpensProps> = ({ data = [] }) => {
  // multiplier is used to increase the height of the Responsive Container by the number of OverviewOpensEntry
  const multiplier = data.length;
  data.map((item) => {
    item.displayComponent = item.displayComponent.replace(/[_-]/g, ' ');
    return data;
  });
  return (
    <div className="Opens">
      <div className="Opens-title">{strings.OPENS}</div>
      {data.length === 0 ? (
        <div className="Opens-empty-title">{strings.NO_OVERVIEW_OPENS}</div>
      ) : (
        <div className="Opens-chart">
          <ResponsiveContainer height={100 * multiplier}>
            <BarChart
              margin={{ left: 20, right: 0 }}
              layout="vertical"
              data={data}
              barSize={10}
            >
              <XAxis type="number" orientation="top" />
              <YAxis type="category" dataKey="displayComponent" width={115} />
              <Bar dataKey="openDurationSeconds" fill="#57AFEF" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default Opens;
