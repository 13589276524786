import React, { useState, useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { DeleteSweep } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';

import { Notification } from 'models';
import { useOnClickOutside } from 'hooks';
import { useNotifications, useDeleteAllNotifications } from 'api';
import PermissionsGate from 'components/shared/PermissionsGate';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import permissions from 'common/permissions';

import PaneHeader from '../../PaneHeader';
import NotificationListItem from '../NotificationListItem';

import './NotificationCenterContent.scss';

interface NotificationCenterContentProps {
  onClose: () => void;
  onError: () => void;
}

const NotificationCenterContent: React.FC<NotificationCenterContentProps> = ({
  onClose,
  onError,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, onClose);
  const {
    data: { data: notifications } = { data: [] },
    isLoading,
    refetch,
  } = useNotifications();
  const deleteAllNotificationsMutation = useDeleteAllNotifications();

  const [isDeletingAll, setIsDeletingAll] = useState(false);

  const handleDeleteAllButtonClick = async () => {
    // eslint-disable-next-line no-alert, no-restricted-globals
    if (confirm('Are you sure you wish to delete all notifications?')) {
      setIsDeletingAll(true);
      await deleteAllNotificationsMutation.mutateAsync();
      setIsDeletingAll(false);
    }
  };

  return (
    <div ref={ref} className="NotificationCenterContent flex-rows full-height">
      <PaneHeader
        title="Notifications"
        appearance="mobile"
        onCloseButtonClick={onClose}
      >
        <PermissionsGate permissions={[permissions.NOTIFICATIONS_DELETE]}>
          <IconButton onClick={handleDeleteAllButtonClick}>
            <DeleteSweep color="secondary" />
          </IconButton>
        </PermissionsGate>
      </PaneHeader>
      {isLoading || isDeletingAll ? (
        <LoadingIndicator />
      ) : notifications.length ? (
        <div className="NotificationCenterContent-notifications flex-grow vertical-scroll">
          {notifications.map((notification: Notification) => (
            <NotificationListItem
              notification={notification}
              key={notification.id}
              toggleShowAlert={onError}
              onReloadNotifications={refetch}
              closeNotificationCenter={onClose}
            />
          ))}
        </div>
      ) : (
        <Grid
          className="full-height full-width"
          container
          item
          xs={12}
          alignItems="center"
          justify="center"
        >
          No notifications.
        </Grid>
      )}
    </div>
  );
};

export default NotificationCenterContent;
