/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { Backup } from '@material-ui/icons';

import { UploadStatus } from 'store/uploads/types';
import useUploadStore from 'store/uploads/useUploadStore';
import strings from 'common/strings';
import { useWindowSize } from 'hooks';
import UploadRow from './UploadRow';

import './UploadManager.scss';

const UploadManager = () => {
  const windowSize = useWindowSize();
  const uploadStoreData = useUploadStore((uploadStore) => ({
    uploadManagerSortedUploads: uploadStore.uploadManagerSortedUploads,
    removeFile: uploadStore.removeFile,
    pauseOrResumeUpload: uploadStore.pauseOrResumeUpload,
  }));
  const [menuOpen, setMenuOpen] = useState(false);

  if (uploadStoreData.uploadManagerSortedUploads().length <= 0) {
    return null;
  }

  return (
    <div className="UploadManager-container">
      <div
        className="UploadManager"
        role="none"
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <div className="UploadManager-icon-container">
          <div className="UploadManager-icon-background" />
          <Backup className="UploadManager-icon" />
        </div>
        {!windowSize.isMobileViewport() && (
          <div className="UploadManager-text">
            {strings.UPLOADS_PLURAL(
              uploadStoreData.uploadManagerSortedUploads().length
            )}
          </div>
        )}
      </div>
      {menuOpen && uploadStoreData.uploadManagerSortedUploads().length > 0 && (
        <div
          className={`UploadManager-menu ${
            windowSize.width && windowSize.width < 380
              ? 'UploadManager-menu-mobile'
              : ''
          }`}
        >
          {uploadStoreData.uploadManagerSortedUploads().map((upload) => (
            <UploadRow
              key={upload.id}
              upload={upload}
              removeUpload={(event: React.MouseEvent) => {
                event.stopPropagation();
                event.preventDefault();
                uploadStoreData.removeFile(upload);
              }}
              onPauseResume={(event: React.MouseEvent) => {
                event.preventDefault();
                if (upload.uploadStatus === UploadStatus.COMPLETED) return;
                uploadStoreData.pauseOrResumeUpload(upload);
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default UploadManager;
