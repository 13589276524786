import Checkbox from 'components/shared/Inputs/Checkbox';
import { ChecklistItem, ChecklistOption } from 'models';
import React from 'react';
import FieldLabel from './MpiFieldLabel';

interface IMpiCheckboxGroup {
  field: ChecklistItem;
  answer: string[];
  onChange: (key: string, value: any) => void;
  disabled?: boolean;
}

var MpiCheckboxGroup = ({
  field,
  answer,
  onChange,
  disabled,
}: IMpiCheckboxGroup) => {
  const wrapClassName = field.options.length > 3 ? 'wrap' : 'no-wrap';
  const className = `field-container flex-column ${wrapClassName} full-width`;

  const renderCheckboxes = () =>
    field.options.map((option: ChecklistOption) => {
      const label = option.label || option.value;
      const selected = answer && answer.indexOf(option.value) !== -1;

      const onSelect = () => {
        if (selected && answer) {
          onChange(
            field.id,
            answer.filter((value) => value !== option.value)
          );
          return;
        }
        onChange(field.id, answer.concat(option.value));
      };

      return (
        <Checkbox
          disabled={disabled}
          name={field.id}
          id={field.id}
          label={label}
          onClick={onSelect}
          selected={selected}
        />
      );
    });

  return (
    <div
      key={`${field.id}_container`}
      className={`MpiCheckboxGroup ${className} py-1`}
      id={'mpi-checkbox-group'}
    >
      <FieldLabel
        className="text-muted text-uppercase font-weight-600"
        name={field.label}
        required={field.required}
      />
      {renderCheckboxes()}
    </div>
  );
};

export default MpiCheckboxGroup;
