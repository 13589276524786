import React, { useState, useCallback } from 'react';
import { WindowLocation, Router, Redirect } from '@reach/router';

import Dashboard from 'components/pages/Dashboard';
import VehicleCollectionBrowser from 'components/pages/VehicleCollectionBrowser';
import Reports from 'components/pages/Reports';
import HamburgerMenu from 'components/shared/HamburgerMenu';
import Header from 'components/shared/Header';
import NotificationCenter from 'components/shared/NotificationCenter';
import { useFeatures, usePermissions, useWindowSize } from 'hooks';
import permissions from 'common/permissions';
import features from 'common/features';
import { useUpdateData } from 'api';

import {
  REPORTS_RELATIVE,
  INVENTORY_RELATIVE,
  DASHBOARD_RELATIVE,
} from './routes';
import VehicleDetailsRouter from './VehicleDetailsRouter';

interface ReachComponentProps {
  default?: boolean;
  location?: WindowLocation;
  path: string;
  rooftopId?: string;
}

const VehicleCollectionView: React.FC<ReachComponentProps> = () => (
  <Router className="full-height">
    <VehicleCollectionBrowser default />
    <VehicleDetailsRouter path=":vehicleId/*" />
  </Router>
);

const RooftopPage: React.FC<ReachComponentProps> = (props) => {
  const { hasFeature } = useFeatures();
  const { hasPermission } = usePermissions();

  // Set up polling
  useUpdateData();

  const [isNotificationCenterOpen, setIsNotificationCenterOpen] =
    useState(false);

  const [isOpenHamburgerMenu, setIsOpenHamburgerMenu] = useState(false);

  const windowSize = useWindowSize();

  const showDashboard = React.useMemo(
    () =>
      [
        permissions.PLUGIN_VELOCITYENGAGE_VIEW_DASHBOARD,
        permissions.DASHBOARD_INVENTORY_NEEDING_ATTENTION_VIEW,
        permissions.DASHBOARD_SUMMARY_VIEW,
      ].filter((perm) => hasPermission(perm)).length > 0,
    [hasPermission]
  );

  const closeNotificationCenter = useCallback(
    () => setIsNotificationCenterOpen(false),
    []
  );

  const viewportClassName = windowSize.isMobileViewport()
    ? 'mobile-viewport overflow-hidden'
    : 'desktop-viewport';

  return (
    <div className={`page flex-rows ${viewportClassName}`}>
      <Header
        isOpenHamburgerMenu={isOpenHamburgerMenu}
        setIsOpenHamburgerMenu={setIsOpenHamburgerMenu}
        isNotificationCenterOpen={isNotificationCenterOpen}
        onOpenNotificationCenter={() => setIsNotificationCenterOpen(true)}
      />
      <div className="flex-grow relative header-margin">
        {hasPermission(permissions.NOTIFICATIONS_VIEW) && (
          <NotificationCenter
            isOpen={isNotificationCenterOpen}
            onClose={closeNotificationCenter}
          />
        )}
        <HamburgerMenu
          isOpen={isOpenHamburgerMenu}
          setIsOpen={setIsOpenHamburgerMenu}
        />
        <Router className="full-height full-width vertical-scroll">
          {/* we need a DASHBOARD_VIEW permission */}
          {showDashboard && <Dashboard path={`${DASHBOARD_RELATIVE}/*`} />}
          {(hasPermission(permissions.RECON_VIEW) ||
            hasPermission(permissions.INVENTORY_VIEW)) && (
            <VehicleCollectionView path={`${INVENTORY_RELATIVE}/*`} />
          )}
          {(hasPermission(permissions.REPORTS_STEP_REPORT_VIEW) ||
            (hasPermission(
              permissions.PLUGIN_VELOCITYENGAGE_CORPORATE_REPORT_VIEW
            ) &&
              hasFeature(features.VELOCITY_ENGAGE_CORPORATE_REPORT)) ||
            (hasPermission(permissions.PLUGIN_VELOCITYENGAGE_VIEW) &&
              hasFeature(features.VELOCITY_ENGAGE_MODULE_REPORT))) && (
            <Reports path={`${REPORTS_RELATIVE}/*`} />
          )}
          <Redirect
            from="*"
            to={`/${props.rooftopId}/${INVENTORY_RELATIVE}`}
            default
            noThrow
          />
        </Router>
      </div>
    </div>
  );
};

export default RooftopPage;
