import * as React from 'react';
import useEngagementHeatmap from 'api/engage/useEngagementHeatmap';
import EngagementActionsTable from 'components/shared/EngagementActionsTable';
import { strings } from 'common';

interface IEngageItemProps {
  id: string;
}

const EngageItemHeatmap: React.FC<IEngageItemProps> = ({ id }) => {
  const { data: { data } = {}, isLoading } = useEngagementHeatmap(id);
  return (
    <EngagementActionsTable
      isLoading={isLoading}
      data={data}
      durationLabel={strings.ENGAGE_HEATMAP_ITEM_DURATION}
    />
  );
};

export default EngageItemHeatmap;
