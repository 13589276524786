import * as firebase from 'firebase/app';
import 'firebase/messaging';
import * as Sentry from '@sentry/browser';
import { EXTENSION_SOURCE } from 'utils/extension';

const isSafari = navigator.vendor.match(/apple/i);
const isMobile = navigator.userAgent.match(/(Android|Mobile)/i);
const urlParams = new URLSearchParams(window.location.search);
const source = urlParams.get('source') || '';
const isExtension = source === EXTENSION_SOURCE;

// eslint-disable-next-line no-restricted-globals
const canInitialize =
  (window.location.protocol.match(/^https/) ||
    process.env.NODE_ENV === 'development') &&
  navigator.serviceWorker;

let initialized = false;

if (
  canInitialize &&
  !isSafari &&
  !isMobile &&
  !isExtension &&
  process.env.REACT_APP_FIREBASE_PROJECTID
) {
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
  });

  navigator.serviceWorker
    .register(`${process.env.PUBLIC_URL}/service-worker.js`)
    .then((registration) => {
      const messaging = firebase.messaging();
      messaging.useServiceWorker(registration);
      messaging.onMessage((payload) => {
        try {
          Sentry.setExtra('messagePayload', payload);
          Sentry.captureMessage(
            'onMessage callback: ' + JSON.stringify(payload),
            'warning'
          );

          const statusDescription = `Firebase Message: ${JSON.stringify(
            payload
          )}`;
          const message = {
            clientType: 'WEB',
            environmentName: process.env.NODE_ENV,
            deviceType: 'WEB',
            appVersion: process.env.REACT_APP_VERSION,
            appName: `Recon Velocity-WEB-${process.env.REACT_APP_VERSION}`,
            category: 'CLIENT_INFO',
            type: 'NotificationEvent',
            name: 'NotificationMessage',
            source: 'UNKNOWN',
            status: 'SUCCESS',
            statusDescription,
            level: 'INFO',
            message: statusDescription,
          };
          const url = `${process.env.REACT_APP_RECONVELOCITY_APPSERVICE_URL_OVERRIDE}/audit`;
          fetch(url, {
            method: 'POST',
            body: JSON.stringify(message),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          }).catch((err) => console.log('fetch error: ', err));
        } catch (e) {}
        /* eslint-disable-next-line no-restricted-globals */
        return registration.showNotification(payload.notification.title, {
          ...payload.notification,
          data: payload.data,
        });
      });
    });

  initialized = true;

  console.warn('Firebase Messaging INIT: ', firebase);
}

export const messaging = initialized ? firebase.messaging() : null;

export default firebase;
