import { inExtensionMode, isExtensionModeSupported } from 'utils/extension';

function isInExtensionIframe() {
  const urlParams = new URLSearchParams(window.location.search);
  const source = urlParams.get('source') || '';
  const sourceId = urlParams.get('sourceId') || '';
  const sourceVersion = urlParams.get('sourceVersion') || '';
  return (
    inExtensionMode(source, sourceId) &&
    isExtensionModeSupported(sourceVersion, 'extension_storage')
  );
}

function localstorageKeyWithPrefix(key: keyof Credentials) {
  if (isInExtensionIframe()) {
    return `extension_${key}`;
  } else {
    return `rvweb_${key}`;
  }
}

function saveWithPrefixIfPresent(
  key: keyof Credentials,
  value: string | undefined
) {
  if (value) {
    localStorage.setItem(localstorageKeyWithPrefix(key), value);
  }
}

interface Credentials {
  authBearerToken: string;
  authBearerTokenExpiration: string;
  authRefreshToken: string;
}
export function saveCredentials(credentials: Partial<Credentials>) {
  saveWithPrefixIfPresent('authBearerToken', credentials.authBearerToken);
  saveWithPrefixIfPresent(
    'authBearerTokenExpiration',
    credentials.authBearerTokenExpiration
  );
  saveWithPrefixIfPresent('authRefreshToken', credentials.authRefreshToken);
}

/**
 * This function gets the credential value depending on wether it is
 * run inside an iframe generated by the extension or not.
 *
 * The logic in this function remains compatible with previous keys, which
 * were not prefixed. When a non-prefixed value is found, it is moved to the
 * prefixed key.
 * @param key non-prefixed credential key
 * @returns value of the key
 */
export function getCredentialValue(key: keyof Credentials) {
  const prefixedValue = localStorage.getItem(localstorageKeyWithPrefix(key));
  if (prefixedValue) {
    return prefixedValue;
  } else {
    const notPrefixedValue = localStorage.getItem(key);
    if (notPrefixedValue) {
      saveWithPrefixIfPresent(key, notPrefixedValue);
      localStorage.removeItem(key);
    }
    return notPrefixedValue;
  }
}

export function clearCredentials() {
  removeCredentialValue('authBearerToken');
  removeCredentialValue('authBearerTokenExpiration');
  removeCredentialValue('authRefreshToken');
}

export function removeCredentialValue(key: keyof Credentials) {
  localStorage.removeItem(localstorageKeyWithPrefix(key));
  // Todo: remove after data layer is dropped (no more customers on BestTasksEverrr).
  // Only the data layer uses non-prefixed storage keys, and this ensures they're removed
  // on logout.
  localStorage.removeItem(key);
}
