import React, { useState, useEffect } from 'react';

import { StepItem, Workflow } from 'models';
import strings from 'common/strings';
import Dropdown from 'components/shared/Dropdown';
import permissions from 'common/permissions';
import { usePermissions } from 'hooks';

import './StepDropdown.scss';
import { useUtilityWorkflow, useChangeStep } from 'api/inventory/workflows';
import { testIds } from 'common/testIds';

interface StepDropdownProps {
  containerClassName?: string;
  loaderType?: 'srp' | 'default' | 'srpGradient';
  currentStep?: Pick<StepItem, 'id' | 'name'>;
  vehicleId?: string;
}

const StepDropdown: React.FC<StepDropdownProps> = ({
  vehicleId,
  containerClassName,
  loaderType,
  currentStep,
}) => {
  const { hasPermission } = usePermissions();
  const [selectedStep, setSelectedStep] = useState({
    id: currentStep?.id || 'no-step',
    value: currentStep?.name || strings.EMPTY_VALUE,
  });

  useEffect(() => {
    setSelectedStep({
      id: currentStep?.id || 'no-step',
      value: currentStep?.name || strings.EMPTY_VALUE,
    });
  }, [currentStep?.id, currentStep?.name]);

  const { data: { data: queryWorkflow } = { data: [] } } = useUtilityWorkflow();
  const changeStepMutation = useChangeStep(vehicleId);

  const recurseChildSteps = (
    result: Workflow,
    steps: Workflow,
    level: number = 0
  ) => {
    steps.forEach((step) => {
      step.level = level;
      result.push(step);
      if (step?.childSteps?.length) {
        recurseChildSteps(result, step.childSteps, level + 1);
      }
    });
    return result;
  };

  const handleStepSelect = async (newSelectedStepId: string) => {
    try {
      if (currentStep?.id !== newSelectedStepId) {
        const newStepDefinition = flattenedSteps.find(
          (listStep) => listStep.id === newSelectedStepId
        );
        setSelectedStep({
          id: newStepDefinition?.id || 'no-step',
          value: newStepDefinition?.name || strings.EMPTY_VALUE,
        });
        await changeStepMutation.mutateAsync(newSelectedStepId);
      }
    } catch (e) {
      setSelectedStep({
        id: currentStep?.id || 'no-step',
        value: currentStep?.name || strings.EMPTY_VALUE,
      });
    }
  };

  const flattenedSteps = recurseChildSteps([], queryWorkflow);

  if (hasPermission(permissions.RECON_VDP_WORKFLOW_STEP_UPDATE)) {
    return (
      <Dropdown
        optionContainerId={testIds.STEP_DROPDOWN}
        options={flattenedSteps.map((step: StepItem) => ({
          id: step.id || '',
          testId: step.name,
          value: step.name,
          level: step.level,
          isHeader: step?.childSteps && step?.childSteps?.length > 0,
        }))}
        selectedOption={selectedStep}
        userCanChange={hasPermission(
          permissions.RECON_VDP_WORKFLOW_STEP_UPDATE
        )}
        onSelect={handleStepSelect}
        disabled={changeStepMutation.isLoading}
        isSubmitting={changeStepMutation.isLoading}
        containerClassName={containerClassName}
        loaderType={loaderType}
      />
    );
  }

  return <span className="stepName">{selectedStep.value}</span>;
};

export default StepDropdown;
