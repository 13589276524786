import React from 'react';

import './Checkbox.scss';

interface CheckboxProps {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const Checkbox: React.FC<React.PropsWithChildren<CheckboxProps>> = ({
  checked,
  className,
  disabled,
  onClick,
}) => (
  <div className="Checkbox">
    <input type="checkbox" checked={checked} />
    <span
      role="checkbox"
      aria-checked={checked}
      aria-label="Checkbox"
      tabIndex={0}
      className={`Checkbox-custom-checkbox ${className}`}
      onClick={disabled ? () => {} : onClick}
    />
  </div>
);

export default Checkbox;
