import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const DEFAULT_SIZE = 50;
const THICKNESS = 3.6;
const DEFAULT_STYLING =
  'full-height full-width flex-rows align-center justify-center';

interface LoadingIndicatorProps {
  size?: number;
  className?: string;
}

var LoadingIndicator = ({ size, className }: LoadingIndicatorProps) => {
  if (!className) className = DEFAULT_STYLING;
  if (!size) size = DEFAULT_SIZE;
  const thickness = (THICKNESS * DEFAULT_SIZE) / size;
  return (
    <div className={className}>
      <CircularProgress color="primary" size={size} thickness={thickness} />
    </div>
  );
};

export default LoadingIndicator;
