import React, { useState } from 'react';
import useCorporateReports, {
  useCorporateReportFilters,
} from 'api/reports/useCorporateReport';
import { currencyFormatter } from 'utils/formatter';
import strings from 'common/strings';
import { TabProps } from 'components/shared/Tabs/Tabs';

import CorporatePayload, {
  Rooftop,
  Overview,
  Price,
} from 'models/reports/corporatePayload';
import { SummaryItem } from 'models';

import { DateRange } from 'common/DateRange';
import SummaryCard from '../../Dashboard/SummaryCard';

import './CorporateReport.scss';
import ReportContainer from '../ReportContainer';
import ReportRow from '../ReportRow';
import ReportTitleRow from '../ReportTitleRow';
import TagFilter from '../TagFilter';
import ConditionFilter from '../ConditionFilter';
import DealerStepReport from '../DealerStepReport';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';

interface ReportsProps extends TabProps {
  dateRange: DateRange;
}

interface OverviewRowProps {
  overviewData: Overview;
}

type CurrencyQuadSquareProps = Pick<
  CorporatePayload['overview'],
  | 'averageOverage'
  | 'totalOverage'
  | 'averageEstimatedRecon'
  | 'averageActualRecon'
>;

const RooftopSummaryRow: React.FC<{
  rooftop: Rooftop;
}> = ({
  rooftop: {
    averageActualRecon,
    averageOverage,
    averageReconTimeAsSeconds,
    averageReconTimeGoalAsSeconds,
    averageRetailReadyAsSeconds,
    averageRetailReadyGoalAsSeconds,
    completedVehicles,
    tenantId,
    tenantName,
  },
}) => (
  <ReportRow
    columns={[
      {
        contents: completedVehicles,
      },
      {
        seconds: averageRetailReadyAsSeconds,
        secondsGoal: averageRetailReadyGoalAsSeconds,
      },
      {
        seconds: averageReconTimeAsSeconds,
        secondsGoal: averageReconTimeGoalAsSeconds,
      },
      {
        money: averageActualRecon,
      },
      {
        money: averageOverage,
      },
    ]}
    title={tenantName}
    key={tenantId}
  />
);

// returns '--' or '73.05' or '+$90.65' or '-$89.48'
const displayOptionalDollarAmount = (
  price: Price | null,
  unshiftPlusSymbol = true
): string =>
  price && price?.amount
    ? `${unshiftPlusSymbol && price?.amount > 0 ? '+' : ''}${currencyFormatter(
        price?.amount
      )}`
    : strings.EMPTY_VALUE;

var CurrencyQuadSquare = ({
  averageActualRecon,
  averageOverage,
  averageEstimatedRecon,
  totalOverage,
}: CurrencyQuadSquareProps) => {
  // return '' or 'quadrant-bad' or 'quadrant-good' style string
  const determineOverageClassName = (averageOverage: Price | null) =>
    averageOverage && averageOverage.amount
      ? averageOverage.amount > 0
        ? 'quadrant-bad'
        : 'quadrant-good'
      : '';

  return (
    <div className="CorporateReport-SummaryCard-container">
      <div className="CorporateReport-QuadrantCard">
        <div className="CorporateReport-Quadrant border-right border-bottom">
          <h4>{displayOptionalDollarAmount(averageEstimatedRecon, false)}</h4>
          {strings.EST_RECON}
        </div>
        <div className="CorporateReport-Quadrant border-bottom">
          <h4>{displayOptionalDollarAmount(averageActualRecon, false)}</h4>
          {strings.ACTUAL_RECON}
        </div>
        <div className="CorporateReport-Quadrant border-right">
          <h4 className={determineOverageClassName(averageOverage)}>
            {displayOptionalDollarAmount(averageOverage)}
          </h4>
          {strings.OVERAGE}
        </div>
        <div className="CorporateReport-Quadrant">
          <h4 className={determineOverageClassName(totalOverage)}>
            {displayOptionalDollarAmount(totalOverage)}
          </h4>
          {strings.TOTAL_OVERAGE}
        </div>
      </div>
    </div>
  );
};

const CorporateReport: React.FC<ReportsProps> = ({
  dateRange,
  path,
  title,
}) => {
  const [tagFilters, setTagFilters] = useState<string[]>([]);

  const [selectedConditionFilter, setSelectedConditionFilter] =
    useState<string>('ALL');

  const {
    data: { data } = {},
    error,
    isLoading: isReportLoading,
  } = useCorporateReports({
    dateRange,
    tagIds: tagFilters,
    condition: selectedConditionFilter,
  });

  const { isLoading: isFiltersLoading } = useCorporateReportFilters();

  const isLoading = isFiltersLoading || isReportLoading;
  var OverviewRowSquares = ({
    overviewData: {
      completedVehicles,
      averageActualRecon,
      averageEstimatedRecon,
      averageOverage,
      averageReconTimeAsSeconds,
      averageRetailReadyAsSeconds,
      totalOverage,
    },
  }: OverviewRowProps) => {
    const nonCurrencySummarySquares = [
      {
        itemType: 'VehiclesCompleted',
        statValue: completedVehicles || 0,
        statLabel: String(strings.VEHICLES_COMPLETED).toUpperCase(),
        unit: 'Items',
      },
      {
        itemType: 'RetailReadyTime',
        statValue: averageRetailReadyAsSeconds || 0,
        statLabel: String(strings.RETAIL_READY_TIME).toUpperCase(),
        unit: 'Seconds',
      },
      {
        itemType: 'AverageReconTime',
        statValue: averageReconTimeAsSeconds || 0,
        statLabel: String(strings.AVERAGE_RECON_TIME).toUpperCase(),
        unit: 'Seconds',
      },
    ].map((summaryItem: SummaryItem) => (
      <div
        key={summaryItem.statLabel}
        className="CorporateReport-SummaryCard-container"
      >
        <SummaryCard summaryItem={summaryItem} />
      </div>
    ));

    return (
      <div className="CorporateReport-overview">
        {nonCurrencySummarySquares}
        <CurrencyQuadSquare
          averageOverage={averageOverage}
          averageActualRecon={averageActualRecon}
          averageEstimatedRecon={averageEstimatedRecon}
          totalOverage={totalOverage}
        />
      </div>
    );
  };
  const overviewData = data?.overview;
  const rooftopsData = data?.data ?? [];

  const { data: { data: filters } = {} } = useCorporateReportFilters();

  return (
    <ReportContainer
      className="CorporateReport"
      loading={isLoading}
      error={error}
    >
      <div className="CorporateReport-header">
        <div className="CorporateReport-title">{strings.CORPORATE_REPORT}</div>
        <TagFilter
          onChange={setTagFilters}
          selectedTags={tagFilters}
          allOptionsLabel="All Tags"
        />
        <ConditionFilter
          onChange={setSelectedConditionFilter}
          selectedConditionId={selectedConditionFilter}
          conditions={filters?.conditions}
        />
      </div>
      {overviewData && <OverviewRowSquares overviewData={overviewData} />}
      <div
        className="flex-rows full-width"
        id="corporate-report-summary-container"
      >
        <div className="CorporateReport-title">
          {strings.ORGANIZATION_SUMMARY}
        </div>
        <ReportTitleRow
          titles={[
            { name: strings.VEHICLES_COMPLETED },
            { name: strings.RETAIL_READY_TIME },
            { name: strings.AVERAGE_RECON_TIME },
            { name: strings.AVERAGE_RECON_COST },
            { name: strings.AVERAGE_OVERAGE },
          ]}
        />

        {rooftopsData?.map((rooftop) => (
          <Accordion
            key={rooftop.tenantId}
            TransitionProps={{ mountOnEnter: true }}
          >
            <AccordionSummary expandIcon={false}>
              <RooftopSummaryRow rooftop={rooftop} />
            </AccordionSummary>
            <AccordionDetails>
              <DealerStepReport
                path={path}
                title={title}
                dateRange={dateRange}
                corporateTenantId={rooftop.tenantId}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </ReportContainer>
  );
};

export default CorporateReport;
