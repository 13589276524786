import React, { Suspense, useContext, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { noop } from 'lodash';
import { Send, Visibility } from '@material-ui/icons';

import { currencyFormatter } from 'utils/formatter';
import PermissionsGate from 'components/shared/PermissionsGate/PermissionsGate';
import VehicleImageView from 'components/shared/VehicleImageView';
import TagList from 'components/pages/VehicleCollectionBrowser/VehicleCard/components/VehicleCardTagListAndActions/TagListSrp';
import Skeleton from 'react-loading-skeleton';
import strings from 'common/strings';
import permissions from 'common/permissions';
import ModalCarousel from 'components/shared/ModalCarousel';

import {
  VehicleCardConfigProp,
  VehicleCardContext,
  WrapImagePreviewForShowroom,
} from './VehicleCard';
import VehicleDetailsTable from './components/VehicleCardDetailsTable';
import VehicleCardProgressBar from './components/VehicleCardProgressBar';
import VehicleCardReconInventoryTabs from './components/VehicleCardReconInventoryTabs';
import VehicleCardYearMakeModel from './components/VehicleCardYearMakeModel';
import { getIsCertifiedPreOwned } from 'utils/vehicle';
import { useFeatures, usePermissions } from 'hooks';
import features from 'common/features';

const VehicleCardMobileV2 = React.lazy(() => import('./VehicleCardMobileV2'));
const VehicleCardShowroomMobile = React.lazy(
  () => import('./VehicleCardShowroomMobile')
);

interface VehicleCardMobileProps {
  style: object;
  config: VehicleCardConfigProp;
}

const VehicleCardMobile = ({ style, config }: VehicleCardMobileProps) => {
  const { onVehicleCardEngageSendClick, onViewCVDPClick } = config;
  const { vehicle, isShowroomMode } = useContext(VehicleCardContext);
  const vehicleId = vehicle?.vehicleCard.id;
  const [isModalGalleryOpen, setIsModalGalleryOpen] = useState(false);
  const { hasPermission } = usePermissions();
  const { hasFeature } = useFeatures();
  const hasUIPreview = hasFeature(features.UI_PREVIEW);

  // TODO: remove suspense after full transition
  if (hasUIPreview) {
    if (isShowroomMode) {
      return (
        <Suspense fallback={<></>}>
          <VehicleCardShowroomMobile style={style} config={config} />
        </Suspense>
      );
    }
    return (
      <Suspense fallback={<></>}>
        <VehicleCardMobileV2 />
      </Suspense>
    );
  }

  const toggleIsModalGalleryOpen = () => {
    setIsModalGalleryOpen((currentVal) => !currentVal);
  };

  const handleViewCVDPClick = () => {
    if (vehicle && vehicleId) {
      onViewCVDPClick();
    }
  };

  if (isShowroomMode) {
    const inventoryPrice = vehicle?.price?.amount;

    const canSendToShopper = hasPermission(
      permissions.PLUGIN_VELOCITYENGAGE_SEND
    );

    return (
      <>
        <Row className="VehicleCard-main-row" style={style}>
          <Col className="VehicleCard-main-col  isShowroomMode-specific">
            <div className="d-flex no-flex-shrink flex-column">
              <WrapImagePreviewForShowroom
                toggleIsModalGalleryOpen={toggleIsModalGalleryOpen}
              >
                <VehicleImageView
                  thumbnail
                  imageUrl={vehicle?.vehicleCard.vehicleImage}
                  skeleton={!vehicle}
                  className="align-self-center VehicleImageView-showroom-size"
                />
              </WrapImagePreviewForShowroom>
              <VehicleCardYearMakeModel />
              <div className="horizontal-border" />
            </div>
            <Row className="no-flex-shrink mobile-showroom-content">
              <Col>
                <Row noGutters>
                  <div className="showroom-price">
                    Price{' '}
                    {typeof inventoryPrice === 'number'
                      ? currencyFormatter(inventoryPrice)
                      : strings.EMPTY_VALUE}
                  </div>
                  {vehicle && getIsCertifiedPreOwned(vehicle) && (
                    <div className="showroom-chip">
                      {strings.CERTIFIED_PREOWNED_CHIP}
                    </div>
                  )}
                </Row>
                <VehicleDetailsTable showVin={false} />
              </Col>
            </Row>
            <Row className="showroom-bottom-content">
              <Button
                as="button"
                className={`engage-buttons ${
                  !canSendToShopper ? 'full-width' : ''
                }`}
                variant="outline-dark"
                onClick={handleViewCVDPClick}
              >
                <span>{strings.VIEW}</span>
                <Visibility className="engage-btn-icon" />
              </Button>
              <PermissionsGate
                permissions={[permissions.PLUGIN_VELOCITYENGAGE_SEND]}
              >
                <Button
                  as="button"
                  className="engage-buttons"
                  variant="outline-dark"
                  onClick={onVehicleCardEngageSendClick}
                >
                  <span>{strings.SEND}</span>
                  <Send className="engage-btn-icon" />
                </Button>
              </PermissionsGate>
            </Row>
          </Col>
        </Row>
        {vehicle && (
          <ModalCarousel
            modalIsOpen={isModalGalleryOpen}
            currentIndex={0}
            images={vehicle?.vehicleCard.imageCarousel ?? []}
            onClose={toggleIsModalGalleryOpen}
            onDeleteImage={noop}
            onAssignImage={noop}
          />
        )}
      </>
    );
  }

  return (
    <Row className="VehicleCard-main-row" style={style}>
      <Col className="VehicleCard-main-col">
        <div className="d-flex no-flex-shrink mobile-header">
          <VehicleImageView
            thumbnail
            imageUrl={vehicle?.vehicleCard.vehicleImage}
            skeleton={!vehicle}
          />
          <VehicleCardYearMakeModel />
        </div>
        <Row className="no-flex-shrink">
          <Col>
            <VehicleDetailsTable showVin={false} />
          </Col>
        </Row>
        <>
          <Row className="VehicleCard-tag-row-container-mobile no-flex-shrink">
            <Col className="TagList-Container-mobile">
              <PermissionsGate permissions={[permissions.TAGS_VIEW]}>
                {vehicle ? (
                  <TagList
                    showEditButton
                    vehicleIsSold={vehicle.vehicleCard.sold ?? true}
                    initialTags={vehicle.tags}
                    vehicleId={vehicleId ?? ''}
                  />
                ) : (
                  <Skeleton />
                )}
              </PermissionsGate>
            </Col>
          </Row>
          <Row className="VehicleCard-progress-row no-flex-shrink mt-0">
            <Col className="px-0">
              <VehicleCardProgressBar />
            </Col>
          </Row>
          <Row className="full-height">
            <Col className="VehicleCard-details-container">
              <VehicleCardReconInventoryTabs />
            </Col>
          </Row>
        </>
      </Col>
    </Row>
  );
};

export default VehicleCardMobile;
