import React from 'react';
import { Button } from 'react-bootstrap';
import { navigate } from '@reach/router';

import { Badge } from '@material-ui/core';
import { AssignmentTurnedIn, ReportProblem } from '@material-ui/icons';

import { copyToClipboard } from 'utils/vehicle';
import strings from 'common/strings';
import { truncateEndOfStringByLength, STOCK_LAST_LENGTH } from 'utils/string';
import Notes from 'components/pages/Notes';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import VehicleImageView from 'components/shared/VehicleImageView';
import { ContentCopy } from 'components/shared/icons';
import TagList from 'components/pages/VehicleCollectionBrowser/VehicleCard/components/VehicleCardTagListAndActions/TagListSrp';
import {
  recallsBuilder,
  tasksBuilder,
  vehicleInfoBuilder,
} from 'navigation/routes';

import './VehiclePreviewPanel.scss';
import PermissionsGate from 'components/shared/PermissionsGate';
import permissions from 'common/permissions';
import { testIds } from 'common/testIds';
import { formatMileage } from 'utils/formatter';
import { useWindowSize } from 'hooks';
import { VehicleCardContext } from '../VehicleCard/VehicleCard';

import { getLastQueryParamWithName } from 'navigation/util/ParamHelpers';
import { useVehicleSummary } from 'api/inventory/useInventory';

const VehiclePreviewPanel = () => {
  const windowSize = useWindowSize();

  const { data, isLoading } = useVehicleSummary(
    getLastQueryParamWithName('preview')?.split(',')[0] ?? ''
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const vehicle = data?.data;

  if (!vehicle) {
    return <span />;
  }

  const vehicleCard = vehicle.vehicleCard;
  const sold = vehicle?.vehicleCard.sold || false;
  const soldClassName = sold ? 'VehiclePreviewPanel-sold' : '';

  const handleViewVehicleButtonClick = async (clickSource: string) => {
    const vdpURL = vehicleInfoBuilder(vehicleCard.id!, false);
    await navigate(`${vdpURL}?fromVDP=inventory`);
  };

  const recallCount = vehicleCard.recallCount || 0;
  const taskCount = vehicleCard.tasksNeedingAttentionCount || 0;

  return (
    <div
      data-vas-testing={testIds.ACTIVE_RECON_PREVIEW_PANEL_CONTAINER}
      className="VehiclePreviewPanel full-height flex-rows"
    >
      <div className="VehiclePreviewPanel-info">
        <div
          className="VehiclePreviewPanel-info-row"
          role="link"
          tabIndex={-1}
          onClick={() =>
            handleViewVehicleButtonClick('.VehiclePreviewPanel-info-row')
          }
        >
          <div className={`vehicle-type vertical-center ${soldClassName}`}>
            <div className="year-make">
              {vehicleCard.year || vehicleCard.make
                ? `${vehicleCard.year} ${vehicleCard.make || ''}`
                : strings.NO_YEAR_OR_MAKE}
            </div>
            <span className="model">
              {vehicleCard.model || strings.EMPTY_VALUE}
            </span>
          </div>
          <div className={`photo ${soldClassName}`}>
            <VehicleImageView size="xs" imageUrl={vehicleCard.vehicleImage} />
          </div>
        </div>
        <PermissionsGate permissions={[permissions.TAGS_VIEW]}>
          <VehicleCardContext.Provider
            value={{
              inExtensionCardMode: false,
              isOnboarding: false,
              isShowroomMode: false,
              position: 0,
              forceShowEngageTab: false,
            }}
          >
            <TagList
              showEditButton
              vehicleIsSold={vehicleCard.sold ?? true}
              initialTags={vehicle.tags}
              vehicleId={vehicleCard.id ?? ''}
            />
          </VehicleCardContext.Provider>
        </PermissionsGate>
        <div className={`flex-columns space-between ${soldClassName}`}>
          <dl
            className={`key-value ${vehicleCard.vin && 'clickable'} vin-column`}
            onClick={() => vehicleCard.vin && copyToClipboard(vehicleCard.vin)}
            role="none"
          >
            <dt>{strings.VIN}</dt>
            <dd data-vas-testing={testIds.PREVIEW_PANEL_VIN}>
              {vehicleCard.vin
                ? windowSize.width < 1200
                  ? truncateEndOfStringByLength(vehicleCard.vin, 9)
                  : vehicleCard.vin
                : strings.EMPTY_VALUE}
              {vehicleCard.vin?.length > 0 && <ContentCopy />}
            </dd>
          </dl>
          <dl className="key-value">
            <dt>{strings.STOCK_NO}</dt>
            <dd data-vas-testing={testIds.PREVIEW_PANEL_STOCK_NUMBER}>
              {truncateEndOfStringByLength(
                vehicleCard.stockNumber,
                STOCK_LAST_LENGTH
              )}
            </dd>
          </dl>
          <div className="VehiclePreviewPanel-alerts-row">
            <Badge
              badgeContent={recallCount}
              invisible={!recallCount}
              className={`VehiclePreviewPanel-alert recallBadge ${
                recallCount > 0 ? 'active' : 'inactive'
              }`}
              tabIndex={-1}
              role="link"
              onClick={() => {
                navigate(
                  `${recallsBuilder(vehicleCard.id!, false)}?fromVDP=inventory`
                );
              }}
            >
              <div className="flex-rows align-center">
                <ReportProblem color="secondary" />
                <div>{strings.RECALLS.toUpperCase()}</div>
              </div>
            </Badge>
            <Badge
              badgeContent={taskCount}
              invisible={!taskCount}
              className={`VehiclePreviewPanel-alert ${
                taskCount > 0 ? 'active' : 'inactive'
              }`}
              tabIndex={-1}
              role="link"
              onClick={() => {
                navigate(`${tasksBuilder(vehicleCard.id!, false)}`);
              }}
            >
              <div className="flex-rows align-center">
                <AssignmentTurnedIn color="secondary" />
                <div>{strings.TASKS.toUpperCase()}</div>
              </div>
            </Badge>
          </div>
        </div>
        <div className={`VehiclePreviewPanel-mileage-row ${soldClassName}`}>
          <div className="VehiclePreviewPanel-mileage-title">
            {strings.MILEAGE}
          </div>
          <div className="VehiclePreviewPanel-mileage-value">
            {formatMileage(vehicleCard.mileage, vehicleCard.odometerUnit)}
          </div>
        </div>
        <Button
          className="VehiclePreviewPanel-button"
          variant="secondary"
          size="sm"
          block
          onClick={() =>
            handleViewVehicleButtonClick('.VehiclePreviewPanel-button')
          }
          id="view-vehicle-button"
        >
          {strings.VIEW_VEHICLE.toUpperCase()}
        </Button>
      </div>
      <div className="VehiclePreviewPanel-notes-container flex-grow">
        <Notes vehicleId={vehicleCard.id || ''} pinned inPreviewPanel />
      </div>
    </div>
  );
};

export default VehiclePreviewPanel;
