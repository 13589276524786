import { DateRange } from 'common/DateRange';
import React, { useEffect, useState } from 'react';
import StatelessDownloadMenu from './StatelessDownloadMenu';

export type ReportType = 'PDF' | 'EXCEL';
interface DownloadMenuProps {
  dateRange: DateRange;
  downloadFileUrl?: string;
  onSelectedMenuType: (type: ReportType | null) => void;
  isLoading: boolean;
  hidePDF?: boolean;
  hideExcel?: boolean;
  condition?: string;
}
const DownloadMenu: React.FC<React.PropsWithChildren<DownloadMenuProps>> = ({
  dateRange,
  downloadFileUrl,
  onSelectedMenuType,
  isLoading,
  hidePDF = false,
  hideExcel = false,
  condition,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (downloadFileUrl) {
      window.open(downloadFileUrl, '_newtab');
      setOpen(false);
      onSelectedMenuType(null);
    } else {
      if (!isLoading) {
        setOpen(false);
        onSelectedMenuType(null);
      }
    }
  }, [downloadFileUrl, isLoading, setOpen, onSelectedMenuType]);
  const onDownloadClick = async (type: 'PDF' | 'EXCEL') => {
    onSelectedMenuType(type);
  };
  return (
    <StatelessDownloadMenu
      isFetchingDownload={isLoading}
      onClick={() => {
        setOpen(!open);
      }}
      onPDFClick={!hidePDF ? () => onDownloadClick('PDF') : null}
      onXLSClick={!hideExcel ? () => onDownloadClick('EXCEL') : null}
      setShow={(value: boolean) => {
        setOpen(value);
      }}
      showMenu={open}
    />
  );
};

export default DownloadMenu;
