import React, { useState, useEffect, useRef } from 'react';
import { Router, navigate, WindowLocation, useMatch } from '@reach/router';
import { VehicleSummary } from 'models';

import strings from 'common/strings';
import { useCurrentLocationId, useWindowSize } from 'hooks';
import {
  LOCATION_RELATIVE,
  MPI_RELATIVE,
  NOTES_RELATIVE,
  MEDIA_RELATIVE,
  RECON_INFO_RELATIVE,
  TASKS_RELATIVE,
  VEHICLE_INFO_RELATIVE,
  DOCUMENTS_RELATIVE,
  RECALLS_RELATIVE,
  notesBuilder,
  CHECKLIST_RELATIVE,
  DASHBOARD_RELATIVE,
  HISTORY_RELATIVE,
  ORDERS_RELATIVE,
  OVERVIEW_RELATIVE,
  ENGAGE_RELATIVE,
  overviewBuilder,
  INVENTORY_RELATIVE,
} from 'navigation/routes';
import { FlatVDP } from 'navigation/VehicleDetailsRouter';
import { getLastQueryParamWithName } from 'navigation/util/ParamHelpers';

import VehicleMenu from './VehicleMenu';
import VehicleHeader from './VehicleHeader/VehicleHeader';
import MobileVDPHeader from './MobileVDPHeader';
import MobileVehicleDetails from './MobileVehicleDetails';
import {
  PinnedComponent,
  PINNED_COMPONENT_LOCAL_STORAGE_KEY,
  PinnableComponentType,
  isPinnableComponent,
} from './PinnedComponent';

import './VehicleDetails.scss';
import { useVdpMenuState } from 'store/useVdpMenuCollapsedState';

const VDP_INFO_HEIGHT = 68;

const getSelectedMenuOptionTitle = (pathname: string) => {
  const pathArr = pathname.split('/');
  if (
    pathArr.indexOf(OVERVIEW_RELATIVE) >= 0 &&
    !(pathArr.indexOf(ENGAGE_RELATIVE) >= 0)
  ) {
    return strings.RECON_VELOCITY;
  }
  if (pathArr.indexOf(ENGAGE_RELATIVE) >= 0) {
    return strings.VELOCITY_ENGAGE;
  }
  if (pathArr.indexOf(NOTES_RELATIVE) >= 0) {
    return strings.NOTES;
  }
  if (pathArr.indexOf(VEHICLE_INFO_RELATIVE) >= 0) {
    return strings.VEHICLE_INFORMATION;
  }
  if (pathArr.indexOf(RECON_INFO_RELATIVE) >= 0) {
    return strings.RECON_INFORMATION;
  }
  if (pathArr.indexOf(MPI_RELATIVE) >= 0) {
    return strings.INSPECTION;
  }
  if (pathArr.indexOf(CHECKLIST_RELATIVE) >= 0) {
    return strings.MULTI_POINT_INSPECTION;
  }
  if (pathArr.indexOf(TASKS_RELATIVE) >= 0) {
    return strings.TASKS;
  }
  if (pathArr.indexOf(MEDIA_RELATIVE) >= 0) {
    return strings.MEDIA;
  }
  if (pathArr.indexOf(LOCATION_RELATIVE) >= 0) {
    return strings.LOCATION;
  }
  if (pathArr.indexOf(DOCUMENTS_RELATIVE) >= 0) {
    return strings.DOCUMENTS;
  }
  if (pathArr.indexOf(RECALLS_RELATIVE) >= 0) {
    return strings.RECALLS;
  }
  if (pathArr.indexOf(ORDERS_RELATIVE) >= 0) {
    return strings.REPAIR_ORDERS;
  }
  if (pathArr.indexOf(HISTORY_RELATIVE) >= 0) {
    return strings.HISTORY;
  }
  return strings.VEHICLE_INFORMATION;
};

interface VehicleDetailsProps {
  vehicle: VehicleSummary;
  location?: WindowLocation;
  path?: string;
  showCloseButton: boolean;
}

const VehicleDetails2: React.FC<VehicleDetailsProps> = (props) => {
  const { location, vehicle, showCloseButton } = props;
  const { sections } = vehicle;
  const windowSize = useWindowSize();
  const [selected, setSelected] = useState(
    getSelectedMenuOptionTitle(location!.pathname)
  );
  const mobileVehicleDetailsRef = useRef<HTMLDivElement>(null);
  const [vdpInfoHeight, setVdpInfoHeight] = useState(VDP_INFO_HEIGHT);
  const [customVDPHeaderTitle, setCustomVDPHeaderTitle] = useState('');
  const [customBackAction, setCustomBackAction] = useState<
    undefined | (() => void)
  >();
  const [hasScrolledToTop, setHasScrolledToTop] = useState<boolean>(false);
  const [isOpenPinnedComponent, setIsOpenPinnedComponent] = useState(false);
  const [pinnedComponent, setPinnedComponent] =
    useState<PinnableComponentType>();
  const tenantId = useCurrentLocationId();

  useEffect(() => {
    const pinnedComponent = localStorage.getItem(
      PINNED_COMPONENT_LOCAL_STORAGE_KEY
    );
    if (pinnedComponent !== null && isPinnableComponent(pinnedComponent)) {
      setIsOpenPinnedComponent(true);
      setPinnedComponent(pinnedComponent);
    }
  }, []);

  const isPinnedRoute = useMatch(`/:vehicleId/${pinnedComponent || ''}`);
  const isPinnedComponentVisible =
    isOpenPinnedComponent && !isPinnedRoute && windowSize.isVdpLargeViewport();

  const openPinnedComponent = (componentName: PinnableComponentType) => {
    setPinnedComponent(componentName);
    window.localStorage.setItem(
      PINNED_COMPONENT_LOCAL_STORAGE_KEY,
      componentName
    );
    setIsOpenPinnedComponent(true);
  };

  const closePinnedComponent = () => {
    setPinnedComponent(undefined);
    window.localStorage.removeItem(PINNED_COMPONENT_LOCAL_STORAGE_KEY);
    setIsOpenPinnedComponent(false);
  };

  useEffect(() => {
    setSelected(getSelectedMenuOptionTitle(location!.pathname));
  }, [location]);

  const goBack = () => {
    const from = getLastQueryParamWithName('fromVDP', INVENTORY_RELATIVE);
    switch (from) {
      case INVENTORY_RELATIVE:
        navigateToSRP();
        break;
      case 'dashboard':
        navigate(`/${tenantId}/${DASHBOARD_RELATIVE}`);
        break;
      default:
        navigateToSRP();
    }
  };

  const navigateToSRP = () => {
    let path = `/${tenantId}/${INVENTORY_RELATIVE}`;
    if (vehicle) {
      path += `?preview=${vehicle.vehicleCard.id}%2C${vehicle.vehicleCard.stockNumber}`;
    }
    navigate(path);
  };
  const vdpMenuCollapsed = useVdpMenuState((state) => state.collapsed);

  const renderDesktopVDP = () => {
    const { sections } = vehicle;
    const { sold: isSold, vehicleImage: imageUrl } = vehicle.vehicleCard;
    const soldClassName = isSold ? 'VehicleDetails-sold' : '';

    return (
      <div className="page">
        <div className="VehicleDetails2">
          <VehicleHeader
            vehicle={vehicle}
            location={location}
            className={soldClassName}
            imageUrl={imageUrl}
          />
          <div className="VehicleDetails-Page">
            <div className="VehicleDetails-Container">
              <div
                className={`VehicleDetails-Menu full-height vertical-scroll ${
                  vdpMenuCollapsed ? ' collapsed' : 'open'
                }`}
              >
                <VehicleMenu
                  // @todo - pathname not used
                  // pathname={location!.pathname}
                  isDesktop={windowSize.isDesktopViewport()}
                  selected={selected}
                  sections={sections}
                  vehicle={vehicle}
                />
              </div>
              <div className="VehicleDetails-Section vertical-scroll">
                <Router className="full-height-calc">
                  <Root path="/" />
                  <FlatVDP
                    path="/*"
                    vehicle={vehicle}
                    location={location}
                    openPinnedComponent={openPinnedComponent}
                  />
                </Router>
              </div>
              {isPinnedComponentVisible && pinnedComponent && (
                <PinnedComponent
                  closePinnedComponent={closePinnedComponent}
                  openPinnedComponent={openPinnedComponent}
                  pinnedComponent={pinnedComponent}
                  vehicle={vehicle}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Root: React.FC<any> = () => {
    if (windowSize.isDesktopViewport()) {
      navigate(notesBuilder(vehicle.vehicleCard.id!, true), {
        replace: true,
      });
    }
    return null;
  };

  const MobileRoot: React.FC<any> = () => {
    navigate(overviewBuilder(vehicle.vehicleCard.id!, true));
    return null;
  };

  const renderMobileVDP = (showCloseButton: boolean) => {
    const isNotesPage = (pathname: string) => {
      const pathArr = pathname.split('/');
      return pathArr.indexOf('notes') > -1;
    };

    const handleScroll = (event: any) => {
      if (
        mobileVehicleDetailsRef &&
        mobileVehicleDetailsRef.current &&
        !(mobileVehicleDetailsRef.current as any).contains(event.target)
      ) {
        return;
      }

      if (!customVDPHeaderTitle) {
        const { scrollTop } = event.target as HTMLDivElement;
        const minHeight = 0;
        if (
          isNotesPage(location!.pathname) &&
          scrollTop === 0 &&
          !hasScrolledToTop
        ) {
          setHasScrolledToTop(true);
        } else if (isNotesPage(location!.pathname) && !hasScrolledToTop) {
          setVdpInfoHeight(VDP_INFO_HEIGHT);
          return;
        }
        setVdpInfoHeight(Math.max(minHeight, VDP_INFO_HEIGHT - scrollTop));
      }
    };

    const resetVdpInfoHeight = () => setVdpInfoHeight(VDP_INFO_HEIGHT);
    const setCustomTitle = (title: string) => {
      setVdpInfoHeight(0);
      setCustomVDPHeaderTitle(title);
    };
    const resetBackAction = () => {
      setCustomTitle('');
      setCustomBackAction(undefined);
    };
    const setBackAction = (backAction: () => void) => {
      setCustomBackAction(() => backAction);
    };

    return (
      <div
        className="FlatMobileVDP"
        onScroll={handleScroll}
        ref={mobileVehicleDetailsRef}
      >
        <MobileVDPHeader
          onCloseVDP={goBack}
          location={location}
          vehicle={vehicle}
          onChangeVDPPage={resetVdpInfoHeight}
          customTitle={customVDPHeaderTitle}
          backAction={customBackAction}
          resetBackAction={resetBackAction}
          sections={sections}
          showCloseButton={showCloseButton}
        />
        <MobileVehicleDetails height={vdpInfoHeight} vehicle={vehicle} />
        <Router className="full-height-calc">
          <MobileRoot path="/" />
          <FlatVDP
            path="/*"
            vehicle={vehicle}
            location={location}
            setCustomHeaderTitle={setCustomTitle}
            setBackAction={setBackAction}
            mobileVDPInfoHeight={vdpInfoHeight}
          />
        </Router>
      </div>
    );
  };
  return (
    <div className="vdp-page-container" data-vas-testing="rv-vdp-page">
      {windowSize.isDesktopViewport()
        ? renderDesktopVDP()
        : renderMobileVDP(showCloseButton)}
    </div>
  );
};

export default VehicleDetails2;
