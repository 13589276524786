/* eslint-disable max-len */
// @ts-nocheck
import React from 'react';
import { IconProps } from './types';

const VelocityLocateIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="17"
    height="28"
    viewBox="0 0 17 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8638 15.2605C14.6015 13.99 16.5 11.2167 16.5 8C16.5 3.58172 12.9183 0 8.5 0C4.08172 0 0.5 3.58172 0.5 8C0.5 11.2167 2.39852 13.99 5.13618 15.2605L8.5 24L11.8638 15.2605Z"
      fill="#D32F2F"
    />
    <circle cx="8.5" cy="8" r="6" fill="white" />
    <circle cx="8.5" cy="8" r="4" fill="#D32F2F" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.07276 22.5675C1.28552 23.0355 1 23.4978 1 23.863C1 24.1106 1.12596 24.3998 1.46863 24.7175C1.81193 25.0358 2.33606 25.3454 3.02569 25.6158C4.40189 26.1555 6.33716 26.5002 8.5 26.5002C10.6628 26.5002 12.5981 26.1555 13.9743 25.6158C14.6639 25.3454 15.1881 25.0358 15.5314 24.7175C15.874 24.3998 16 24.1106 16 23.863C16 23.4978 15.7145 23.0355 14.9272 22.5675C14.2581 22.1697 13.3171 21.8271 12.1816 21.5875C11.9114 21.5305 11.7295 21.2722 11.7775 21.0002C11.8255 20.7283 12.0851 20.5458 12.3554 20.6022C13.5773 20.8573 14.6414 21.2342 15.4382 21.7079C16.3163 22.2299 17 22.9534 17 23.863C17 24.4817 16.6782 25.0178 16.2113 25.4508C15.7449 25.8832 15.0975 26.2495 14.3394 26.5468C12.8202 27.1426 10.7554 27.5002 8.5 27.5002C6.24456 27.5002 4.17984 27.1426 2.6606 26.5468C1.90251 26.2495 1.25508 25.8832 0.788731 25.4508C0.321759 25.0178 0 24.4817 0 23.863C0 22.9534 0.683732 22.2299 1.56175 21.7079C2.35861 21.2342 3.42267 20.8573 4.64459 20.6022C4.91491 20.5458 5.17455 20.7283 5.2225 21.0002C5.27046 21.2722 5.08861 21.5305 4.81841 21.5875C3.6829 21.8271 2.74193 22.1697 2.07276 22.5675Z"
      fill="#263238"
    />
  </svg>
);

export default VelocityLocateIcon;
