import React, { useState } from 'react';
import { DriveEta } from '@material-ui/icons';

import strings from 'common/strings';

import './SendVehicleCard.scss';
import { useWindowSize } from 'hooks';
import { truncateEllipsisMiddle } from 'utils/string';
import { VehicleSummary } from 'models';

interface SendVehicleCardProps {
  height: number;
  vehicle: VehicleSummary;
}

const SendVehicleCard: React.FC<SendVehicleCardProps> = ({
  height,
  vehicle,
}) => {
  const [hasImageError, setHasImageError] = useState(false);
  const windowSize = useWindowSize();
  const vehicleCard = vehicle?.vehicleCard;

  return (
    <div className="SendVehicleCard" style={{ height }}>
      <div role="none" className="SendVehicleCard-info">
        {vehicleCard?.vehicleImage && !hasImageError ? (
          <img
            className="SendVehicleCard-info-image"
            src={vehicleCard.vehicleImage}
            alt="vehicle"
            onError={() => setHasImageError(true)}
          />
        ) : (
          <DriveEta className="SendVehicleCard-info-image" />
        )}
        {!windowSize.isMobileViewport() ? (
          <>
            <div className="SendVehicleCard-info-car-details">
              <div className="SendVehicleCard-info-car-details-column p-3">
                {!vehicleCard.year &&
                !vehicleCard.make &&
                !vehicleCard.model ? (
                  <span className="SendVehicleCard-info-car-details-year-make">
                    {strings.EMPTY_VALUE}
                  </span>
                ) : (
                  <>
                    <span className="SendVehicleCard-info-car-details-year-make">
                      {`${vehicleCard.year} ${vehicleCard.make}`}
                    </span>
                    <span className="SendVehicleCard-info-car-details-year-make">
                      {`${vehicleCard.model ? vehicleCard.model : ''}`}
                      {`${vehicleCard.trim ? ` ${vehicleCard.trim}` : ''}`}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="SendVehicleCard-info-car-details-column-title">
              <span className="SendVehicleCard-info-car-details-stock-number">
                <div>{strings.VIN}</div>
              </span>
              <span className="SendVehicleCard-info-car-details-stock-number">
                <div>{strings.STK}</div>
              </span>
            </div>
            <div className="SendVehicleCard-info-car-details-column">
              <span className="SendVehicleCard-info-car-details-stock-number">
                <div>{vehicleCard?.vin?.toUpperCase()}</div>
              </span>
              <span className="SendVehicleCard-info-car-details-stock-number">
                <div>{vehicleCard?.stockNumber?.toUpperCase()}</div>
              </span>
            </div>
            <div className="SendVehicleCard-info-car-details-column-title">
              <span className="SendVehicleCard-info-car-details-stock-number">
                <div>
                  {vehicleCard.odometerUnit?.toUpperCase() ===
                  strings.KILOMETERS.toUpperCase()
                    ? strings.KILOMETERS.toUpperCase()
                    : strings.MILES.toUpperCase()}
                </div>
              </span>
              <span className="SendVehicleCard-info-car-details-stock-number">
                <div>{strings.COLOR.toUpperCase()}</div>
              </span>
            </div>
            <div className="SendVehicleCard-info-car-details-column">
              <span className="SendVehicleCard-info-car-details-stock-number">
                <div>{vehicleCard.mileage}</div>
              </span>
              <span className="SendVehicleCard-info-car-details-stock-number">
                <div>
                  {vehicleCard.color && vehicleCard.color !== 'UNKNOWN'
                    ? vehicleCard.color.toUpperCase()
                    : strings.EMPTY_VALUE}
                </div>
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="SendVehicleCard-info-car-details-column">
              <span className="SendVehicleCard-info-car-details">
                <span className="SendVehicleCard-info-car-details-year-make">
                  {!vehicleCard.year && !vehicleCard.make && !vehicleCard.model
                    ? strings.EMPTY_VALUE
                    : `${vehicleCard.year} ${vehicleCard.make} ${vehicleCard.model}`}
                </span>
              </span>
              <span className="SendVehicleCard-info-car-details-stock-number">
                <div>
                  {`${strings.VIN}: ${truncateEllipsisMiddle(
                    vehicleCard.vin.toUpperCase(),
                    14
                  )}`}
                </div>
              </span>
              <span className="SendVehicleCard-info-car-details-stock-number">
                <div>
                  {`${strings.STK}: ${truncateEllipsisMiddle(
                    vehicleCard.stockNumber.toUpperCase(),
                    14
                  )}`}
                </div>
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SendVehicleCard;
