import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import {
  EditorState,
  convertFromRaw,
  ContentState,
  getDefaultKeyBinding,
} from 'draft-js';
import strings from 'common/strings';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, {
  defaultSuggestionsFilter,
  MentionData,
} from '@draft-js-plugins/mention';

import { User } from 'models';
import { useUsers } from 'api';
import { convertStringToRawState } from '../utils';
import { getRawContent, MentionConfig } from './utils';
import { getFullName } from 'utils/user';

type SyntheticKeyboardEvent = React.KeyboardEvent<{}>;

interface MentionableInputProps {
  onChange: (textBlob: string) => void;
  onFocus?: () => void;
  onBlur?: (e: any) => void;
  submitAction: () => void;
  shouldClearContent: boolean;
  existingContent?: string;
  shouldFocus?: boolean;
  disabled?: boolean;
}

const MentionableInput: React.FC<
  React.PropsWithChildren<MentionableInputProps>
> = ({
  onChange,
  onFocus,
  onBlur,
  shouldClearContent,
  submitAction,
  existingContent,
  disabled,
  shouldFocus = false,
}) => {
  const refEditor = useRef<Editor>(null);
  const [suggestions, setSuggestions] = useState<MentionData[]>([]);
  const { data } = useUsers();
  const [editorState, setEditorState] = useState<EditorState>(() => {
    if (!existingContent) return EditorState.createEmpty();

    const rawContent = convertStringToRawState(existingContent);
    const fromRaw = convertFromRaw(rawContent);
    return EditorState.createWithContent(fromRaw);
  });
  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin(MentionConfig);
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const [open, setOpen] = useState(false);

  const onOpenChange = useCallback((open: boolean) => {
    setOpen(open);
  }, []);

  useEffect(() => {
    if (data) {
      const users: MentionData[] =
        data.data.map((user) => ({ name: getFullName(user) })) ?? [];
      setSuggestions(users);
    }
  }, [data]);

  const handleChange = (editorState: EditorState) => {
    setEditorState(editorState);
    onChange(getRawContent(editorState.getCurrentContent()));
  };

  const handleFocus = () => (onFocus ? onFocus() : null);
  const handleBlur = (e: any) => (onBlur ? onBlur(e) : null);

  useEffect(() => {
    if (shouldClearContent) {
      setEditorState((editor) => {
        const newEditorState = EditorState.push(
          editor,
          ContentState.createFromText(''),
          'remove-range'
        );
        return EditorState.moveFocusToEnd(newEditorState);
      });
    }
  }, [shouldClearContent]);

  useEffect(() => {
    if (shouldFocus) {
      setTimeout(() => {
        refEditor?.current?.focus();
        onChange(getRawContent(editorState.getCurrentContent()));
      }, 50);
    } else {
      refEditor?.current?.blur();
    }
  }, [editorState, onChange, shouldFocus]);

  const onSearchChange = async ({ value }: any) => {
    const newSuggestions =
      data?.data.filter(
        (user: User) =>
          user.id &&
          !user.company &&
          (user.companyName || user.firstName || user.lastName)
      ) || [];
    const suggestions = newSuggestions.map((serviceUser: User) => {
      const { companyName, firstName, lastName, id } = serviceUser;
      return {
        name: companyName ?? `${firstName} ${lastName}`,
        id,
      };
    });
    setSuggestions(defaultSuggestionsFilter(value, suggestions));
  };

  function handleKeyBinding(event: SyntheticKeyboardEvent): string | null {
    const { keyCode, shiftKey } = event;
    const emptyInput =
      getRawContent(editorState.getCurrentContent()).trim().length === 0;

    if (keyCode === 13 && emptyInput) {
      return null;
    }

    if (keyCode === 13 && !shiftKey) {
      submitAction();
      return null;
    }

    return getDefaultKeyBinding(event);
  }

  return (
    <div>
      <MentionSuggestions
        open={open}
        onOpenChange={onOpenChange}
        onSearchChange={onSearchChange}
        suggestions={suggestions}
      />
      <div className="mentionable-input-editor">
        <Editor
          readOnly={disabled}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={strings.NOTES_PLACEHOLDER}
          editorState={editorState}
          onChange={handleChange}
          plugins={plugins}
          ref={refEditor}
          keyBindingFn={handleKeyBinding}
        />
      </div>
    </div>
  );
};

export default MentionableInput;
