// Base Routes
export const CHANGE_PASSWORD = 'reset-password';
export const CHECKLIST_RELATIVE = 'checklist';
export const DASHBOARD = ':rooftop/dashboard';
export const DASHBOARD_RELATIVE = 'dashboard';
export const DASHBOARD_HIGHLIGHT = 'dashboard/highlights';
export const DASHBOARD_ASSIGNMENTS = 'dashboard/assignments';
export const DASHBOARD_VEHICLES_NEEDING_ATTENTION = 'dashboard/attention';
export const ENGAGE_RELATIVE = 'engage';
export const DOCUMENTS_RELATIVE = 'documents';
export const FORGOT_PASSWORD = 'forgot-password';
export const HISTORY_RELATIVE = 'history';
export const FLAT_HISTORY_RELATIVE = 'flat-history';
export const INDEX = '/';
export const INVENTORY_RELATIVE = 'inventory';
export const DEALER_SETTINGS_RELATIVE = 'settings';
export const LOCATION_RELATIVE = 'location';
export const MEDIA_RELATIVE = 'media';
export const FLAT_MEDIA_RELATIVE = 'flat-media';
export const MPI_RELATIVE = 'mpi';
export const NOTES_RELATIVE = 'notes';
export const ORDERS_RELATIVE = 'orders';
export const OVERVIEW_RELATIVE = 'overview';
export const RECALLS_RELATIVE = 'recalls';
export const FLAT_RECALLS_RELATIVE = 'flat-recalls';
export const RECON_INFO_RELATIVE = 'recon-info';
export const RECON_RELATIVE = 'recon';
export const REPORTS_RELATIVE = 'reports';
export const TASKS_RELATIVE = 'tasks';
export const VEHICLE_DETAILS = 'vehicle-details/:vehicleId';
export const VEHICLE_INFO_RELATIVE = 'vehicle-info';
export const RECON_VELOCITY_OVERVIEW_RELATIVE = 'rv-overview';
export const VELOCITY_ENGAGE_RELATIVE = 'velocityEngage';
export const GENERAL_SETTINGS_RELATIVE = 'general';
export const ENGAGE_SETTINGS_RELATIVE = 'engage';
export const LOCATE_SETTINGS_RELATIVE = 'locate';
export const ENGAGE_ENGAGEMENTS = 'user-actions';
export const REPAIR_ORDERS_RELATIVE = 'repair-orders';
export const VELOCITY_INSIGHT = 'velocity-insight';
export const LOGIN = 'login';

// Vehicle Details Routes
export const MPI = `${VEHICLE_DETAILS}/mpi`;
export const NOTES = `${VEHICLE_DETAILS}/notes`;
export const OVERVIEW = `${VEHICLE_DETAILS}/overview`;
export const RECON_INFO = `${VEHICLE_DETAILS}/recon-info`;
export const TASKS = `${VEHICLE_DETAILS}/tasks`;
export const VEHICLE_INFO = `${VEHICLE_DETAILS}/vehicle-info`;
export const RECON_VELOCITY_OVERVIEW = `${VEHICLE_DETAILS}/rv-overview`;
export const VEHICLE_ENGAGE_ENGAGEMENTS = `${VEHICLE_DETAILS}/${ENGAGE_RELATIVE}/${ENGAGE_ENGAGEMENTS}`;

// Settings Routes
export const GENERAL_SETTINGS = `${DEALER_SETTINGS_RELATIVE}/general`;
export const ENGAGE_SETTINGS = `${DEALER_SETTINGS_RELATIVE}/engage`;
export const LOCATE_SETTINGS = `${DEALER_SETTINGS_RELATIVE}/locate`;

// Recon Info Routes
export const EDIT_RECON_INFO = `${RECON_INFO}/edit`;
export const EDIT_RECON_INFO_RELATIVE = `${RECON_INFO_RELATIVE}/edit`;

// Task Routes
export const ADD_TASKS = `${TASKS}/add`;
export const EDIT_TASKS = `${TASKS}/:taskId/edit`;
export const TASKS_DETAILS = `${TASKS}/:taskId/details`;

// Tasks Relative Routes
export const ADD_TASKS_RELATIVE = `${TASKS_RELATIVE}/add`;
export const EDIT_TASKS_RELATIVE = `${TASKS_RELATIVE}/:taskId/edit`;
export const TASKS_DETAILS_RELATIVE = `${TASKS_RELATIVE}/:taskId/details`;

// Route Builders
export const vehicleDetailsBuilder = (
  inventoryId: string,
  keepSearch?: boolean,
  tenantId?: string
) => {
  const urlSegments = window.location.pathname.split('/');
  let urlParsedTenantId = urlSegments?.[1];
  if (urlSegments?.[1] === 'velocity-insight') {
    urlParsedTenantId = urlSegments?.[3];
  }
  const resultTenantId = tenantId ?? urlParsedTenantId;

  if (!resultTenantId) return '';

  if (urlSegments?.[1] === 'velocity-insight') {
    return `/${VELOCITY_INSIGHT}/tenant/${resultTenantId}/inventory/${inventoryId}/details${
      keepSearch ? window.location.search : ''
    }`;
  }

  return `/${resultTenantId}/inventory/${inventoryId}${
    keepSearch ? window.location.search : ''
  }`;
};

export const vehicleInfoBuilder = (
  vehicleId: string,
  keepSearch?: boolean,
  tenantId?: string
) => {
  const vehiclesDetails = vehicleDetailsBuilder(
    vehicleId,
    keepSearch,
    tenantId
  );
  return `${vehiclesDetails}/overview${
    keepSearch ? window.location.search : ''
  }`;
};

export const vehicleExtensionCardBuilder = (
  inventoryId: string,
  tenantId: string | undefined
) => {
  const urlSegments = window.location.pathname.split('/');
  if (urlSegments?.[1] === 'velocity-insight') {
    const resultTenantId = tenantId ?? urlSegments?.[3];
    return `/${VELOCITY_INSIGHT}/tenant/${resultTenantId}/inventory/${inventoryId}/card${window.location.search}`;
  }

  const vehiclesDetails = vehicleDetailsBuilder(inventoryId, false, tenantId);
  return `${vehiclesDetails}${window.location.search}`;
};
export const mpiBuilder = (vehicleId: string, keepSearch?: boolean) => {
  const vehiclesDetails = vehicleDetailsBuilder(vehicleId);
  return `${vehiclesDetails}/mpi${keepSearch ? window.location.search : ''}`;
};

export const reconInfoBuilder = (vehicleId: string, keepSearch?: boolean) => {
  const vehiclesDetails = vehicleDetailsBuilder(vehicleId);
  return `${vehiclesDetails}/recon-info${
    keepSearch ? window.location.search : ''
  }`;
};

export const editReconInfoBuilder = (
  vehicleId: string,
  keepSearch?: boolean
) => {
  const reconInfo = reconInfoBuilder(vehicleId);
  return `${reconInfo}/edit${keepSearch ? window.location.search : ''}`;
};

export const tasksBuilder = (
  vehicleId: string,
  keepSearch?: boolean,
  tenantId?: string
) => {
  const vehiclesDetails = vehicleDetailsBuilder(vehicleId, undefined, tenantId);
  return `${vehiclesDetails}/tasks${keepSearch ? window.location.search : ''}`;
};

export const recallsBuilder = (vehicleId: string, keepSearch?: boolean) => {
  const vehiclesDetails = vehicleDetailsBuilder(vehicleId);
  return `${vehiclesDetails}/recalls${
    keepSearch ? window.location.search : ''
  }`;
};

export const notesBuilder = (
  vehicleId: string,
  keepSearch?: boolean,
  tenantId?: string
) => {
  const vehiclesDetails = vehicleDetailsBuilder(vehicleId, undefined, tenantId);
  return `${vehiclesDetails}/notes${keepSearch ? window.location.search : ''}`;
};

export const engageMediaBuilder = (vehicleId: string, keepSearch?: boolean) => {
  const vehiclesDetails = vehicleDetailsBuilder(vehicleId);
  return `${vehiclesDetails}/engage/media${
    keepSearch ? window.location.search : ''
  }`;
};

export const locationBuilder = (
  vehicleId: string,
  keepSearch?: boolean,
  tenantId?: string
) => {
  const vehiclesDetails = vehicleDetailsBuilder(vehicleId, undefined, tenantId);
  return `${vehiclesDetails}/location${
    keepSearch ? window.location.search : ''
  }`;
};

export const mediaBuilder = (vehicleId: string, keepSearch?: boolean) => {
  const vehiclesDetails = vehicleDetailsBuilder(vehicleId);
  return `${vehiclesDetails}/media${keepSearch ? window.location.search : ''}`;
};

export const documentsBuilder = (vehicleId: string, keepSearch?: boolean) => {
  const vehiclesDetails = vehicleDetailsBuilder(vehicleId);
  return `${vehiclesDetails}/documents${
    keepSearch ? window.location.search : ''
  }`;
};

export const mediaGalleryBuilder = (
  vehicleId: string,
  keepSearch?: boolean
) => {
  const media = mediaBuilder(vehicleId);
  return `${media}/gallery${keepSearch ? window.location.search : ''}`;
};

export const mediaSyndicationBuilder = (
  vehicleId: string,
  keepSearch?: boolean
) => {
  const media = mediaBuilder(vehicleId);
  return `${media}/syndication${keepSearch ? window.location.search : ''}`;
};

export const addTasksBuilder = (vehicleId: string, keepSearch?: boolean) => {
  const tasks = tasksBuilder(vehicleId);
  return `${tasks}/add${keepSearch ? window.location.search : ''}`;
};

export const editTasksBuilder = (
  vehicleId: string,
  taskId: string,
  keepSearch?: boolean
) => {
  const tasks = tasksBuilder(vehicleId);
  return `${tasks}/${taskId}/edit${keepSearch ? window.location.search : ''}`;
};

export const taskDetailsBuilder = (
  vehicleId: string,
  keepSearch?: boolean,
  tenantId?: string
) => {
  const tasks = tasksBuilder(vehicleId, undefined, tenantId);
  return `${tasks}${keepSearch ? window.location.search : ''}`;
};

export const historyBuilder = (vehicleId: string, keepSearch?: boolean) => {
  const vehiclesDetails = vehicleDetailsBuilder(vehicleId);
  return `${vehiclesDetails}/history${
    keepSearch ? window.location.search : ''
  }`;
};

export const vehicleLogBuilder = (vehicleId: string, keepSearch?: boolean) => {
  const history = historyBuilder(vehicleId);
  return `${history}/vehicle-log${keepSearch ? window.location.search : ''}`;
};

export const stepSummaryBuilder = (vehicleId: string, keepSearch?: boolean) => {
  const history = historyBuilder(vehicleId);
  return `${history}/step-summary${keepSearch ? window.location.search : ''}`;
};

export const overviewBuilder = (vehicleId: string, keepSearch?: boolean) => {
  const vehiclesDetails = vehicleDetailsBuilder(vehicleId);
  return `${vehiclesDetails}/overview${
    keepSearch ? window.location.search : ''
  }`;
};

export const engageBuilder = (vehicleId: string, keepSearch?: boolean) => {
  const vehiclesDetails = vehicleDetailsBuilder(vehicleId);
  return `${vehiclesDetails}/${ENGAGE_SETTINGS_RELATIVE}/${
    keepSearch ? window.location.search : ''
  }`;
};

export const engageEngagementBuilder = (
  vehicleId: string,
  keepSearch?: boolean,
  engagementId?: string
) => {
  const vehicleEngageDetails = engageBuilder(vehicleId, keepSearch);
  const engagementRoute =
    typeof engagementId !== 'undefined'
      ? `${keepSearch ? '&' : '?'}engagementId=${engagementId}`
      : '';
  return `${vehicleEngageDetails}${ENGAGE_ENGAGEMENTS}${
    keepSearch
      ? `${window.location.search}${engagementRoute}`
      : `${engagementRoute}`
  }`;
};

export const repairOrderBuilder = (vehicleId: string, keepSearch?: boolean) => {
  const vehiclesDetails = vehicleDetailsBuilder(vehicleId);
  return `${vehiclesDetails}/${REPAIR_ORDERS_RELATIVE}${
    keepSearch ? window.location.search : ''
  }`;
};
