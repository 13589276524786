import React from 'react';
import { Section, VehicleSummary } from 'models';

import LoadingIndicator from 'components/shared/LoadingIndicator';

import './VehicleMenu.scss';
import IconButton from '@material-ui/core/IconButton';
import { ChevronRight, ChevronLeft } from '@material-ui/icons';
import VehicleMenuRow from './VehicleMenuRow/VehicleMenuRow';
import { useVdpMenuState } from 'store/useVdpMenuCollapsedState';

interface VehicleMenuProps {
  sections: Section[] | undefined;
  vehicle: VehicleSummary;
  selected?: string;
  isDesktop: boolean;
  // pathname: string;
}

const VehicleMenu: React.FC<VehicleMenuProps> = ({
  sections,
  vehicle,
  selected,
  isDesktop,
}) => {
  const toggleVdpMenu = useVdpMenuState((state) => state.toggle);
  const vdpMenuCollapsed = useVdpMenuState((state) => state.collapsed);
  const collapseState = vdpMenuCollapsed ? 'collapsed' : 'notCollapsed';
  return (
    <div className={`VehicleMenu ${collapseState}`}>
      {!sections ? (
        <LoadingIndicator />
      ) : (
        sections.map((item) => (
          <div key={`vehicle-menu-row-${item.id}`}>
            <VehicleMenuRow
              vehicle={vehicle}
              selected={isDesktop && selected === item.label}
              section={item}
              showLabel={!vdpMenuCollapsed}
            />
          </div>
        ))
      )}
      <div className="text-right menu-chevron-icon">
        <IconButton disabled={false} onClick={toggleVdpMenu}>
          {vdpMenuCollapsed ? (
            <ChevronRight className="menu-chevron-icon" />
          ) : (
            <ChevronLeft className="menu-chevron-icon" />
          )}
        </IconButton>
      </div>
    </div>
  );
};

export default VehicleMenu;
