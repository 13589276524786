import React, { useCallback } from 'react';
import { Container } from 'react-bootstrap';
import { navigate } from '@reach/router';

import strings from 'common/strings';
import permissions from 'common/permissions';
import ApiError from 'components/shared/ApiError';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import PaneHeader from 'components/shared/PaneHeader';
import { useWindowSize } from 'hooks';
import { vehicleDetailsBuilder } from 'navigation/routes';
import { testIds } from 'common/testIds';
import { useVehicleRepairOrders } from 'api';
import PermissionsGate from 'components/shared/PermissionsGate';
import RepairOrderItem from './RepairOrderItem';

import './RepairOrder.scss';

const PermissionFallback = (
  <div className="RepairOrder-empty full-height flex-rows align-center justify-center">
    {strings.REPAIR_ORDER_PERMISSION_DENIED}
  </div>
);

const EmptyRepairOrders = (
  <div className="RepairOrder-empty full-height flex-rows align-center justify-center">
    {strings.REPAIR_ORDER_EMPTY}
  </div>
);

interface RepairOrderProps {
  vehicleId: string;
  responsive?: boolean;
}

const RepairOrder: React.FC<React.PropsWithChildren<RepairOrderProps>> = ({
  vehicleId,
  responsive = true,
}) => {
  const {
    data: { data } = {},
    isError,
    isLoading,
  } = useVehicleRepairOrders(vehicleId);
  const handleVDPNavigation = () => {
    navigate(vehicleDetailsBuilder(vehicleId, true), {
      replace: true,
    });
  };
  const windowSize = useWindowSize();

  const renderRepairOrders = useCallback(() => {
    if (!data?.length) {
      return EmptyRepairOrders;
    }

    return data?.map((repairOrder: any) => (
      <RepairOrderItem
        repairOrder={repairOrder}
        key={repairOrder.dmsRoNumber}
        responsive={responsive}
      />
    ));
  }, [data, responsive]);

  return (
    <Container
      fluid
      className="RepairOrder full-height"
      data-vas-testing={testIds.REPAIR_ORDER_CONTAINER}
    >
      {!windowSize.isMobileViewport() && (
        <PaneHeader
          title={strings.REPAIR_ORDERS}
          onBackButtonClick={handleVDPNavigation}
        />
      )}
      <PermissionsGate
        permissions={[permissions.INVENTORY_VDP_ROS_VIEW]}
        fallback={PermissionFallback}
      >
        {isLoading ? <LoadingIndicator /> : renderRepairOrders()}
      </PermissionsGate>
      <ApiError error={isError} />
    </Container>
  );
};

export default RepairOrder;
