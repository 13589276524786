import * as Sentry from '@sentry/browser';
import { extensionStrings } from '../common/constants';

export const EXTENSION_SOURCE = 'extension';

type ExtensionCardLoadedAction = 'extension_card_loaded';
type WebStorageAction = 'web_storage';
type ExtensionCardClickedAction = 'extension_card_clicked';
type ExtensionClipboardAction = 'clipboard';

type ExtensionStorageFunctionality = 'extension_storage';
type ExtensionAutoRefreshFunctionality = 'auto_refresh';
type ExtensionModeFunctionality =
  | ExtensionStorageFunctionality
  | ExtensionAutoRefreshFunctionality;

interface BasicExtensionMessage {
  action:
    | ExtensionCardLoadedAction
    | WebStorageAction
    | ExtensionCardClickedAction
    | ExtensionClipboardAction;
}

interface AuthExtensionMessage extends BasicExtensionMessage {
  token: string;
  tokenExpiration: string;
  rooftopId: string;
}

interface ExtensionStorageMessage extends BasicExtensionMessage {
  method: string;
  key: string;
  value?: string;
}

interface ExtensionClipboardMessage extends BasicExtensionMessage {
  method: string;
  value: string;
}

type ExtensionMessage =
  | BasicExtensionMessage
  | AuthExtensionMessage
  | ExtensionStorageMessage
  | ExtensionClipboardMessage;

export interface NewHoverTargetMessage {
  vehicleId: string;
  locationId: string;
}

export interface CacheVehiclesMessage {
  vehicleIds: string[];
  locationId: string;
}

export interface BrowserRuntimeMessageResponse {
  status: string;
  key: string;
  value?: string;
}

export const ID_LENGTH = 24;
const LEGACY_ROOFTOP_PREFIX_ID_LENGTH = 'ROOFTOP-'.length;
const LOCATION_ID_LENGTH = LEGACY_ROOFTOP_PREFIX_ID_LENGTH + ID_LENGTH;
const WAT_ID_LENGTH = 36;

export const defaultSupportedExtensionIds = [
  'nmnbkeffccomlhaepaaiglmolinmjapm',
  'mcgepijpaooibppegecokeodkmanncij',
  'mkanlikcnccbgabpaphgjidigdjnekab',
];

export const isSupportedExtention = (): boolean => {
  const params = new URLSearchParams(window.location.search);
  const source = params.get('source');
  const sourceId = params.get('sourceId') ?? '';
  return (
    source === EXTENSION_SOURCE &&
    defaultSupportedExtensionIds.includes(sourceId)
  );
};

const IGNORED_ERRORS = ['ReferenceError: browser is not defined'];
const shouldIgnoreError = (e: Error): boolean =>
  IGNORED_ERRORS.reduce<boolean>((ignore, ignoredError) => {
    if (ignore) {
      return true;
    }

    if (e?.toString().indexOf(ignoredError) !== -1) {
      return true;
    }

    return false;
  }, false);

// eslint-disable-next-line arrow-body-style
export const inExtensionMode = (
  source?: string | null,
  sourceId?: string | null
) => {
  return sourceId && source === EXTENSION_SOURCE
    ? defaultSupportedExtensionIds.includes(sourceId)
    : false;
};

const isMinimumVersionSupported = (
  currentVersion: string,
  expectedVersion: string
) => {
  if (currentVersion === expectedVersion) {
    return true;
  }

  return (
    currentVersion.localeCompare(expectedVersion, undefined, {
      numeric: true,
    }) === 1
  );
};

export const isExtensionModeSupported = (
  sourceVersion: string,
  functionality: ExtensionModeFunctionality
) => {
  if (isMinimumVersionSupported(sourceVersion, '0.5.0')) {
    const supportedFunctionality = ['extension_storage', 'auto_refresh'];
    return supportedFunctionality.includes(functionality);
  }

  return false;
};

export function sendMessageToExtensionAsync(
  extensionId: string,
  message: ExtensionMessage
): Promise<BrowserRuntimeMessageResponse> {
  return new Promise((resolve) => {
    try {
      const chrome = window?.chrome;
      if (chrome?.runtime) {
        chrome.runtime.sendMessage(
          extensionId,
          message,
          {},
          (response: PromiseLike<BrowserRuntimeMessageResponse>) => {
            resolve(response);
          }
        );
        // eslint-disable-next-line
        // @ts-ignore
      } else if (browser?.runtime) {
        // eslint-disable-next-line
        // @ts-ignore
        browser.runtime
          .sendMessage(extensionId, message)
          .then((response: PromiseLike<BrowserRuntimeMessageResponse>) => {
            resolve(response);
          });
      }
    } catch (e) {
      if (shouldIgnoreError(e)) {
        return;
      }

      Sentry.captureException(e);
    }
  });
}

export function sendMessageToExtension(
  extensionId: string,
  message: ExtensionMessage
): void {
  try {
    const chrome = window?.chrome;
    if (chrome?.runtime) {
      chrome.runtime.sendMessage(extensionId, message, {}, () => {});
      // @ts-ignore
    } else if (browser?.runtime) {
      // @ts-ignore
      browser.runtime.sendMessage(extensionId, message).then(() => {});
    }
  } catch (e) {
    if (shouldIgnoreError(e)) {
      return;
    }
    Sentry.captureException(e);
  }
}

export function sendMessageToParentViaWindow(
  message: BasicExtensionMessage,
  useHostName = false
) {
  if (useHostName) {
    window.postMessage(message, window.location.origin);
    return;
  }

  // BasicExtensionMessage type is defined to avoid sending additional data to Extension
  // If data needs to be sent, avoid postMessage and use sendMessageToExtension above
  window.top.postMessage(message, '*');
}

export function sanitizeWindowPostMessage(
  event: MessageEvent
): NewHoverTargetMessage | CacheVehiclesMessage | undefined {
  switch (event.data.action) {
    case extensionStrings.EXTENSION_EVENT_NEW_HOVER_TARGET: {
      // Verify vehicleId and locationId are strings and valid length
      const { vehicleId, locationId } = event.data;
      const result: NewHoverTargetMessage = { vehicleId: '', locationId: '' };

      const locationIdLengthValid =
        locationId.length === ID_LENGTH || // Required for users of extension v0.5
        locationId.length === LOCATION_ID_LENGTH || // Required for legacy users
        locationId.length === WAT_ID_LENGTH; // Required for wat users
      const vehicleIdValid =
        typeof vehicleId === 'string' &&
        (vehicleId.length === ID_LENGTH || vehicleId.length === WAT_ID_LENGTH);
      const locationIdValid =
        typeof locationId === 'string' && locationIdLengthValid;
      if (vehicleIdValid && locationIdValid) {
        result.vehicleId = vehicleId;
        result.locationId = locationId;

        return result;
      }

      return undefined;
    }
    case extensionStrings.EXTENSION_EVENT_CACHE_VEHICLES: {
      const { vehicleIds, locationId } = event.data;
      const result: CacheVehiclesMessage = { vehicleIds: [], locationId: '' };

      // Verify all vehicleIds are strings and valid length
      const vehicleIdsValid = vehicleIds.reduce(
        (idsValid: boolean, vehicleId: any) => {
          const vehicleIdValid =
            typeof vehicleId === 'string' &&
            (vehicleId.length === ID_LENGTH ||
              vehicleId.length === WAT_ID_LENGTH);
          if (!vehicleIdValid) {
            idsValid = false;
            return idsValid;
          }

          result.vehicleIds.push(vehicleId);
          return idsValid;
        },
        true
      );

      const locationIdLengthValid =
        locationId.length === ID_LENGTH || // Required for users of extension v0.5
        locationId.length === LOCATION_ID_LENGTH || // Required for legacy users
        locationId.length === WAT_ID_LENGTH; // Required for wat users
      const locationIdValid =
        typeof locationId === 'string' && locationIdLengthValid;
      if (!vehicleIdsValid && !locationIdValid) {
        return undefined;
      }

      result.locationId = locationId;
      return result;
    }
    default:
      return undefined;
  }
}
