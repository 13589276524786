import React, { useCallback, useContext, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AttachMoney, Send } from '@material-ui/icons';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LinkIcon from '@material-ui/icons/Link';
import IconButton from '@material-ui/core/IconButton';
import { navigate } from '@reach/router';

import { testIds, generateTestId } from 'common/testIds';
import PermissionsGate from 'components/shared/PermissionsGate';
import strings from 'common/strings';
import permissions from 'common/permissions';
import { useFeatures, useWindowSize } from 'hooks';
import { sendMessageToExtension } from 'utils/extension';
import { vehicleDetailsBuilder } from 'navigation/routes';
import { getFormattedDurationFromSeconds } from 'utils/time';
import PriceHistoryModal from 'components/shared/PriceHistoryModal';
import useEngageOverview from 'api/inventory/useEngageOverview';
import { getExtensionUrlParams } from 'common/urlParams';
import SendVehicleModal from 'components/shared/SendVehicleModal';
import { EngageOverviewCard, VehicleSummary } from 'models';
import { VehicleCardContext } from '../../VehicleCard';
import VehicleCardTabFooter from './VehiceCardTabFooter/VehicleCardTabFooter';
import features from 'common/features';
import LabelValue from 'components/shared/LabelValue';

const engagePlaceholderTitles = [
  'SHOPPERS',
  'PORTFOLIOS SENT',
  'PORTFOLIO OPENS',
  'OPEN RATE',
  'ENGAGEMENTS',
  'AVG. ENGAGEMENT TIME',
];

const extensionUrlParams = getExtensionUrlParams();

const TabButtonsRow = (
  handleClickNav: (clickSource: string, engage?: boolean) => void,
  openPriceHistoryModal: () => void
) => {
  const { vehicle, inExtensionCardMode } = useContext(VehicleCardContext);
  const windowSize = useWindowSize();
  const [isSendVehicleModalOpen, setIsSendVehicleModalOpen] = useState(false);

  const handleEngageSendClick = (vehicle: VehicleSummary | undefined) => {
    if (vehicle) {
      setIsSendVehicleModalOpen(true);
    }
  };

  const EngageButtons = () => {
    return (
      <>
        <Button
          as="button"
          className="engage-buttons"
          variant="outline-dark"
          onClick={openPriceHistoryModal}
        >
          <div>{strings.PRICE}</div>
          <AttachMoney className="engage-btn-icon" />
        </Button>
        <PermissionsGate permissions={[permissions.PLUGIN_VELOCITYENGAGE_SEND]}>
          <Button
            as="button"
            className="engage-buttons"
            variant="outline-dark"
            onClick={() => handleEngageSendClick(vehicle)}
          >
            <div>{strings.SEND}</div>
            <Send className="engage-btn-icon" />
          </Button>
          <SendVehicleModal
            selectedVehicleList={vehicle ? [vehicle] : []}
            open={isSendVehicleModalOpen}
            onClose={() => setIsSendVehicleModalOpen(false)}
          />
        </PermissionsGate>
      </>
    );
  };

  if (windowSize.width < 1350) {
    return (
      <div className="VehicleCard-step-buttons-container">
        {EngageButtons()}
        {!inExtensionCardMode && (
          <Button
            as="button"
            variant="outline-dark"
            onClick={() =>
              handleClickNav('VehicleCard-view-more-container', true)
            }
          >
            {strings.VIEW_VEHICLE.toUpperCase()}
          </Button>
        )}
      </div>
    );
  }

  return (
    <div className="VehicleCard-step-buttons-container">
      <Button
        as="button"
        className="see-more-button"
        variant="link"
        onClick={() => handleClickNav('VehicleCard-view-more-container', true)}
      >
        <span>{strings.VIEW_MORE}</span>
        <ChevronRightIcon />
      </Button>
      {EngageButtons()}
    </div>
  );
};

const VehicleCardEngageDetails = () => {
  const {
    vehicle,
    inExtensionCardMode,
    position,
    currentTab: activeCollectionType,
  } = useContext(VehicleCardContext);
  const ref = useRef<HTMLDivElement>(document.createElement('div'));
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isCopyPopupOpen, setIsCopyPopupOpen] = useState<boolean>(false);
  const { hasFeature } = useFeatures();

  const [isSendVehicleModalOpen, setIsSendVehicleModalOpen] = useState(false);
  const handleEngageSendClick = (vehicle: VehicleSummary | undefined) => {
    if (vehicle) {
      setIsSendVehicleModalOpen(true);
    }
  };

  const { refetch: refetchEngageOverview } = useEngageOverview(
    vehicle?.vehicleCard?.id,
    false
  );

  const openPriceHistoryModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const renderEngageValues = useCallback(() => {
    const overviewCards = vehicle?.vehicleCard?.velocityEngageOverviewCards;
    if (!overviewCards) return undefined;

    const buildValue = (
      card?: EngageOverviewCard | null,
      override?: string
    ) => {
      let convertedValue: string | number | null = null;
      if (card?.name === 'AVG_SESSION_DURATION_SECONDS') {
        convertedValue = getFormattedDurationFromSeconds(Number(card.value));
      } else if (card?.name === 'FOLIO_OPEN_PERCENTAGE') {
        // rounding a server side value to two decimal percent (0.0492 to 4.92% for example)
        const percent = Math.floor(Number(card?.value) * 10000) / 100;
        convertedValue = percent ? `${percent}%` : strings.EMPTY_VALUE;
      }

      const key = override || `${card?.name}-${card?.id}`;
      const label = card?.displayName || '';

      return (
        <LabelValue key={key} label={label} labelCaps>
          {convertedValue || card?.value || strings.EMPTY_VALUE}
        </LabelValue>
      );
    };

    if (overviewCards?.length === 0) {
      return (
        <div
          className={`VehicleCard-engage-data-container VehicleCard-engage-data-container-column`}
        >
          {engagePlaceholderTitles.map(() => buildValue(null, ''))}
        </div>
      );
    }

    return (
      <div
        className={`VehicleCard-engage-data-container VehicleCard-engage-data-container-column`}
      >
        {overviewCards.map((card) => buildValue(card))}
      </div>
    );
  }, [vehicle?.vehicleCard?.velocityEngageOverviewCards]);

  const handleClickNav = (clickSource: string, engage = false) => {
    if (vehicle?.vehicleCard?.id) {
      if (engage) {
        navigate(
          `${vehicleDetailsBuilder(
            vehicle?.vehicleCard?.id
          )}/engage/?fromVDP=${activeCollectionType}`
        );
      } else {
        navigate(
          `${vehicleDetailsBuilder(
            vehicle?.vehicleCard?.id
          )}?fromVDP=${activeCollectionType}`
        );
      }
    }
  };

  const handleClickEngageLink = async () => {
    if (
      !vehicle?.vehicleCard?.vin ||
      !vehicle.vehicleCard?.id ||
      !extensionUrlParams?.sourceId
    ) {
      return;
    }
    const { data: engageOverviewResponse, isError: isErrorEngageOverview } =
      await refetchEngageOverview();
    if (engageOverviewResponse?.data && !isErrorEngageOverview) {
      let shareUrl = engageOverviewResponse?.data.folioUrl;
      if (shareUrl) {
        shareUrl = shareUrl.replace(
          vehicle.vehicleCard.vin,
          vehicle.vehicleCard.id
        );
      }
      sendMessageToExtension(extensionUrlParams.sourceId, {
        action: 'clipboard',
        method: 'copy',
        value: shareUrl,
      });
      setIsCopyPopupOpen(true);
      setTimeout(() => {
        setIsCopyPopupOpen(false);
      }, 2000);
    }
  };

  const hasUIPreview = hasFeature(features.UI_PREVIEW);
  return (
    <>
      <div
        ref={ref}
        className="VehicleCard-tab-container engage"
        data-vas-testing={generateTestId(
          testIds.INVENTORY_CARD_ENGAGE_CONTAINER,
          {
            position: position.toString(),
          }
        )}
      >
        {inExtensionCardMode && (
          <div className="VehicleCard-tabs-engage-link">
            <IconButton onClick={handleClickEngageLink} size="small">
              <LinkIcon />
            </IconButton>
            {isCopyPopupOpen && (
              <div className="VehicleCard-tabs-engage-link-popover">
                URL Copied to Clipboard
              </div>
            )}
          </div>
        )}
        {renderEngageValues()}
        {!inExtensionCardMode && hasUIPreview ? (
          <VehicleCardTabFooter
            vehicle={vehicle}
            navClick={() => handleClickNav('', true)}
            showEngage={true}
          >
            <div className="VehicleCardTabFooter-Engage-buttons">
              <Button
                as="button"
                className="engage-buttons engage-price`"
                variant="outline-dark"
                onClick={openPriceHistoryModal}
              >
                <div>{strings.PRICE}</div>
                <AttachMoney className="engage-btn-icon" />
              </Button>
              <PermissionsGate
                permissions={[permissions.PLUGIN_VELOCITYENGAGE_SEND]}
              >
                <Button
                  as="button"
                  className="engage-buttons engage-send"
                  variant="outline-dark"
                  onClick={() => handleEngageSendClick(vehicle)}
                >
                  <div>{strings.SEND}</div>
                  <Send className="engage-btn-icon" />
                </Button>
                <SendVehicleModal
                  selectedVehicleList={vehicle ? [vehicle] : []}
                  open={isSendVehicleModalOpen}
                  onClose={() => setIsSendVehicleModalOpen(false)}
                />
              </PermissionsGate>
            </div>
          </VehicleCardTabFooter>
        ) : (
          TabButtonsRow(handleClickNav, openPriceHistoryModal)
        )}
      </div>
      {vehicle?.vehicleCard?.priceHistory && (
        <PriceHistoryModal
          open={isModalOpen}
          onClose={closeModal}
          onClick={openPriceHistoryModal}
          priceHistory={vehicle.vehicleCard.priceHistory}
          customButton
        />
      )}
    </>
  );
};

export default VehicleCardEngageDetails;
