import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import strings from 'common/strings';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import moment from 'moment';

import './CustomDatePickerModal.scss';
import theme from 'common/theme';
import { useWindowSize } from 'hooks';

interface CustomDatePickerModalProps {
  onClose: any;
  onAccept: (s: DateRangeProps) => void;
  showModal: boolean;
}

interface DateRangeChanges {
  range1: DateRangeProps;
}

export interface DateRangeProps {
  startDate: Date;
  endDate: Date;
  showDateDisplay: Boolean;
}

const CustomDatePickerModal: React.FC<
  React.PropsWithChildren<CustomDatePickerModalProps>
> = ({ onClose, showModal, onAccept }) => {
  const [selectedRange, setSelectedRange] = useState<DateRangeProps>({
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now()),
    showDateDisplay: false,
  });
  const lastMonth = moment().subtract(1, 'months').toDate();
  const lastYear = moment().subtract(1, 'years').toDate();
  const windowSize = useWindowSize();

  function handleSelect(changes: DateRangeChanges) {
    setSelectedRange(changes.range1);
  }

  return (
    <Modal
      show={showModal}
      size="lg"
      centered
      onHide={onClose}
      dialogClassName="CustomDatePickerModal"
    >
      <div className="CustomDatePickerModal-body">
        <DateRange
          onChange={(object: any) => handleSelect(object)}
          moveRangeOnFirstSelection={false}
          ranges={[selectedRange]}
          months={windowSize.isWideViewport() ? 2 : 1}
          className="CustomDatePicker"
          direction="horizontal"
          rangeColors={[theme.palette.secondary.main]}
          shownDate={lastMonth}
          minDate={lastYear}
          maxDate={new Date(Date.now())}
          scroll={{ enabled: true }}
        />
      </div>
      <div className="CustomDatePickerModal-footer">
        <button
          type="button"
          onClick={() => onClose()}
          className="white-button CustomDatePickerModal-cancel-button"
        >
          {strings.CANCEL}
        </button>
        <button
          type="button"
          className="blue-button CustomDatePickerModal-ok-button"
          onClick={() => onAccept(selectedRange)}
        >
          {strings.OK}
        </button>
      </div>
    </Modal>
  );
};

export default CustomDatePickerModal;
