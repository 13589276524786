import React, { useState } from 'react';
import { Close } from '@material-ui/icons';
import { VehicleSummary } from 'models';

import FlatVDPPaneHeader from 'components/shared/FlatVDPPaneHeader';
import FlatVDPPaneContent from 'components/shared/FlatVDPPaneContent';
import Dropdown, { DropdownOption } from 'components/shared/Dropdown';
import Notes from 'components/pages/Notes';
import Recalls from 'components/pages/Recalls';
import FlatTasks from 'components/pages/FlatTasks';
import MultipointInspection from 'components/pages/MultipointInspection';

import './PinnedComponent.scss';

export const PINNED_COMPONENT_LOCAL_STORAGE_KEY = 'pinned-component-open';

// Read-only array of strings.
const pinnableComponentNames = ['notes', 'recalls', 'mpi', 'tasks'] as const;
// Union type of the strings in the array.
export type PinnableComponentType = typeof pinnableComponentNames[number];

// Type assertion method for checking values from localStorage.
// https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
export const isPinnableComponent = (
  componentName: string
): componentName is PinnableComponentType => {
  return pinnableComponentNames.includes(
    componentName as PinnableComponentType
  );
};

export interface PinnableDropdownOption extends DropdownOption {
  id: PinnableComponentType;
}

const dropdownOptions: PinnableDropdownOption[] = [
  {
    id: 'notes',
    value: 'Notes',
  },
  {
    id: 'recalls',
    value: 'Recalls',
  },
  {
    id: 'mpi',
    value: 'MPI',
  },
  {
    id: 'tasks',
    value: 'Tasks',
  },
];

interface PinnedComponentProps {
  closePinnedComponent: () => void;
  pinnedComponent: PinnableComponentType;
  openPinnedComponent: (componentName: PinnableComponentType) => void;
  vehicle: VehicleSummary;
  className?: string;
}

export const PinnedComponent: React.FC<PinnedComponentProps> = ({
  closePinnedComponent,
  openPinnedComponent,
  pinnedComponent,
  vehicle,
  className = '',
}) => {
  const [selectedOption, setSelectedOption] = useState<DropdownOption>(
    dropdownOptions.find((option) => option.id === pinnedComponent) ??
      dropdownOptions[0]
  );

  const handleSelectOption = (componentName: string) => {
    if (isPinnableComponent(componentName)) {
      openPinnedComponent(componentName);
      setSelectedOption(
        dropdownOptions.find((option) => option.id === componentName) ??
          dropdownOptions[0]
      );
    }
  };

  const renderPinnedComponent = () => {
    switch (pinnedComponent) {
      case 'notes':
        return <Notes vehicleId={vehicle.vehicleCard.id || ''} pinned />;
      case 'recalls':
        return (
          <Recalls vehicleId={vehicle.vehicleCard.id!} responsive={false} />
        );
      case 'mpi':
        return (
          <MultipointInspection vehicleId={vehicle.vehicleCard.id!} pinned />
        );
      case 'tasks':
        return <FlatTasks vehicle={vehicle} pinned />;
    }
  };

  return (
    <div className={`PinnedComponent ${className}`}>
      <FlatVDPPaneHeader
        className="PinnedComponent-Header"
        vehicleId={vehicle.vehicleCard.id || ''}
      >
        <Dropdown
          options={dropdownOptions}
          selectedOption={selectedOption}
          onSelect={handleSelectOption}
          containerClassName="PinnedComponent-Header-dropdown"
        />
        <button
          type="button"
          className="PinnedComponent-Header-close-button"
          onClick={() => closePinnedComponent()}
        >
          <Close className="PinnedComponent-Header-close-button-icon" />
        </button>
      </FlatVDPPaneHeader>
      <FlatVDPPaneContent className="PinnedComponent-Content">
        {renderPinnedComponent()}
      </FlatVDPPaneContent>
    </div>
  );
};
