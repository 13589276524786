import ApiError from 'components/shared/ApiError';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './ReportContainer.scss';

var LoadingIndicatorContainer = () => {
  return (
    <div className="ReportContainer-loadingContainer">
      <LoadingIndicator />
    </div>
  );
};

const ReportContainer: React.FC<{
  className?: string;
  error?: any;
  loading?: boolean;
}> = ({ children, className, error, loading }) => (
  <div className="ReportContainer">
    <div className="ReportContainer-panel-container">
      <Container fluid>
        <Row noGutters className="no-margin">
          <Col className={'ReportContainer-overview ' + className ?? ''}>
            {loading ? (
              <LoadingIndicatorContainer />
            ) : error ? (
              <ApiError error={error} />
            ) : (
              children
            )}
          </Col>
        </Row>
      </Container>
    </div>
  </div>
);
export default ReportContainer;
