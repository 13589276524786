import React, { useState, useCallback } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { VehicleSummary } from 'models';

import permissions from 'common/permissions';
import strings from 'common/strings';
import images from 'common/images';
import FlatVDPPaneHeader from 'components/shared/FlatVDPPaneHeader';
import FlatVDPPaneContent from 'components/shared/FlatVDPPaneContent';
import ConfirmationDialog from 'components/shared/ConfirmationDialog';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import Alert from 'components/shared/Alert';
import PermissionsGate from 'components/shared/PermissionsGate';
import useUploadStore from 'store/uploads/useUploadStore';
import {
  ALLOWED_ATTACHMENT_FILETYPES_STRING,
  UploadItem,
  UploadType,
} from 'store/uploads/types';
import { useFileSelector, useWindowSize } from 'hooks';
import { VehicleAttachment, emptyAttachment } from 'models';
import { useDeleteAttachment, useVehicleAttachments } from 'api';
import { testIds } from 'common/testIds';
import DocumentRow from '../DocumentRow';

import './FlatDocumentsSection.scss';

interface FlatDocumentsSectionProps {
  vehicle: VehicleSummary;
  path?: string;
  title?: string;
}

const FlatDocumentsSection: React.FC<FlatDocumentsSectionProps> = ({
  vehicle,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [errorUploadingAttachment, setErrorUploadingAttachment] =
    useState(false);
  const [tempAttachmentLoading, setTempAttachmentLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(strings.API_MESSAGE);
  const [attachmentToDelete, setAttachmentToDelete] = useState<
    VehicleAttachment | undefined
  >(undefined);
  const windowSize = useWindowSize();
  const { deleteAttachmentAsync } = useDeleteAttachment();
  const {
    data: { data: attachments = [] } = {},
    isLoading,
    refetch,
  } = useVehicleAttachments(vehicle?.vehicleCard?.id!);
  const uploadStoreData = useUploadStore((uploadStore) => ({
    uploads: uploadStore.uploads,
    uploadFiles: uploadStore.uploadFiles,
  }));
  const activeUploads = uploadStoreData.uploads.filter(
    (upload: UploadItem) =>
      upload.meta.parentId === vehicle?.vehicleCard?.id &&
      upload.meta.target.type === UploadType.DOCUMENT
  );

  const [tempAttachment, setTempAttachment] = useState(emptyAttachment);

  const onChange = useCallback(
    (event: Event) => {
      const target = event.target as HTMLInputElement;
      const files = target.files ? Array.from(target.files) : [];

      setTempAttachment({
        ...tempAttachment,
        name: files[0].name,
        createdDate: new Date(files[0].lastModified).toString(),
      });
      setTempAttachmentLoading(true);

      const onUploadSuccess = async () => {
        refetch();
        setTempAttachment({
          ...tempAttachment,
          name: '',
          createdDate: '',
        });
        setTempAttachmentLoading(false);
      };
      const onUploadCancel = () => {
        setTempAttachment(emptyAttachment);
        setTempAttachmentLoading(false);
        const element = event?.target; //retrieve fileSelector
        if (element instanceof HTMLInputElement) {
          element.value = '';
        }
      };
      const onUploadError = (message?: string) => {
        if (message) {
          setErrorMessage(message);
        }
        setErrorUploadingAttachment(true);
      };
      const uploadTarget = {
        type: UploadType.DOCUMENT,
      };

      uploadStoreData.uploadFiles(
        files,
        vehicle?.vehicleCard?.id!,
        uploadTarget,
        onUploadSuccess,
        onUploadError,
        onUploadCancel
      );
    },
    [refetch, tempAttachment, uploadStoreData, vehicle?.vehicleCard?.id]
  );

  const onError = useCallback((event) => {
    setErrorMessage(event ?? strings.API_MESSAGE);
    setErrorUploadingAttachment(true);
  }, []);

  const [fileSelector] = useFileSelector(
    onChange,
    onError,
    ALLOWED_ATTACHMENT_FILETYPES_STRING
  );

  const handleOpenConfirmationDialog = (attachment: VehicleAttachment) => {
    setAttachmentToDelete(attachment);
    setIsConfirmationDialogOpen(true);
  };

  const deleteAttachment = async () => {
    if (!attachmentToDelete) return;
    try {
      setIsSubmitting(true);
      await deleteAttachmentAsync({
        vehicleId: vehicle?.vehicleCard?.id!,
        attachmentId: attachmentToDelete.id!,
      });
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
    }
    setIsConfirmationDialogOpen(false);
  };

  const handleCloseConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);
  };

  const handleCloseError = () => {
    setErrorUploadingAttachment(false);
    setErrorMessage(strings.API_MESSAGE);
  };

  return (
    <>
      <FlatVDPPaneHeader
        className="FlatMedia-header"
        vehicleId={vehicle?.vehicleCard?.id}
        shouldShowBackButton={false}
      >
        {strings.DOCUMENTS}
        <PermissionsGate
          permissions={[permissions.INVENTORY_VDP_ATTACHMENTS_CREATE]}
        >
          <button
            type="button"
            className="FlatMedia-upload-button"
            onClick={() => fileSelector.click()}
          >
            <img
              data-vas-testing={testIds.DOCUMENTS_SECTION_UPLOAD_BUTTON_ICON}
              className="FlatMedia-upload-button-icon"
              src={images.UploadIcon}
              alt="Upload button"
            />
          </button>
        </PermissionsGate>
      </FlatVDPPaneHeader>
      <FlatVDPPaneContent className="FlatMedia-documents-content vertical-scroll">
        {windowSize.isMobileViewport() && (
          <PermissionsGate
            permissions={[permissions.INVENTORY_VDP_ATTACHMENTS_CREATE]}
          >
            <button
              type="button"
              className="FlatMedia-upload-button-mobile FlatMedia-upload-button"
              onClick={() => fileSelector.click()}
            >
              <img
                className="FlatMedia-upload-button-icon"
                src={images.UploadIcon}
                alt="Upload button"
              />
            </button>
          </PermissionsGate>
        )}
        <Container fluid className="p-0 pb-5">
          <Row noGutters>
            <Col>
              {isLoading ? (
                <LoadingIndicator />
              ) : attachments?.length > 0 ? (
                attachments?.map((attachment: VehicleAttachment) => (
                  <DocumentRow
                    attachment={attachment}
                    onDeleteClick={handleOpenConfirmationDialog}
                  />
                ))
              ) : (
                !tempAttachmentLoading && (
                  <div className="FlatDocumentsSection-content-empty">
                    {strings.ATTACHMENTS_EMPTY}
                  </div>
                )
              )}
              {activeUploads.length > 0 &&
                activeUploads.map((upload: UploadItem) => (
                  <DocumentRow
                    loading
                    attachment={tempAttachment}
                    onDeleteClick={() => {}}
                    upload={upload}
                  />
                ))}
            </Col>
          </Row>
        </Container>
      </FlatVDPPaneContent>
      {isConfirmationDialogOpen && (
        <ConfirmationDialog
          onClose={handleCloseConfirmationDialog}
          title={strings.DELETE_DOCUMENT_TITLE}
          onAccept={deleteAttachment}
          mainButtonTitle={strings.DELETE}
          body={strings.DELETE_DOCUMENT_BODY}
          disabled={isSubmitting}
        />
      )}
      <Alert
        open={errorUploadingAttachment}
        contentProps={{
          variant: 'error',
          onClose: handleCloseError,
          message: errorMessage,
        }}
      />
    </>
  );
};

export default FlatDocumentsSection;
