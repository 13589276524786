import React from 'react';
import Grid, { GridProps } from '@material-ui/core/Grid';

export interface Section {
  title: string;
  data: any;
}

interface SectionListProps extends GridProps {
  renderItem: (item: any, index: number, array: any[]) => React.ReactNode;
  renderSectionTitle: (key: string) => React.ReactNode;
  sections: Section[];
  reverse?: boolean;
}

var SectionList = ({
  renderItem,
  renderSectionTitle: renderSection,
  sections,
  reverse,
  ...other
}: SectionListProps) => {
  return (
    <Grid {...other}>
      {sections.map((item, index) => (
        <div key={`section-list-${index}`}>
          {reverse
            ? item.data.map((itemObj: any, index: number, array: any[]) =>
                renderItem(itemObj, index, array)
              )
            : renderSection(item.title)}
          {reverse
            ? renderSection(item.title)
            : item.data.map((itemObj: any, index: number, array: any[]) =>
                renderItem(itemObj, index, array)
              )}
        </div>
      ))}
    </Grid>
  );
};

export default SectionList;
