import React, { Suspense, useContext, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { noop } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { Send, Visibility } from '@material-ui/icons';

import strings from 'common/strings';
import { currencyFormatter } from 'utils/formatter';
import PermissionsGate from 'components/shared/PermissionsGate/PermissionsGate';
import permissions from 'common/permissions';

import VehicleImageView from 'components/shared/VehicleImageView';
import TagList from 'components/pages/VehicleCollectionBrowser/VehicleCard/components/VehicleCardTagListAndActions/TagListSrp';
import ModalCarousel from 'components/shared/ModalCarousel';
import { testIds, generateTestId } from 'common/testIds';

import {
  VehicleCardConfigProp,
  VehicleCardContext,
  WrapImagePreviewForShowroom,
} from './VehicleCard';
import VehicleDetailsTable from './components/VehicleCardDetailsTable';
import VehicleCardProgressBar from './components/VehicleCardProgressBar';
import VehicleCardReconInventoryTabs from './components/VehicleCardReconInventoryTabs';
import VehicleCardYearMakeModel from './components/VehicleCardYearMakeModel';
import { getIsCertifiedPreOwned } from 'utils/vehicle';
import features from 'common/features';
import { useFeatures } from 'hooks';
const VehicleCardDesktopV2 = React.lazy(() => import('./VehicleCardDesktopV2'));
const VehicleCardShowroomDesktop = React.lazy(
  () => import('./VehicleCardShowroomDesktop')
);

interface VehicleCardDesktopProps {
  config: VehicleCardConfigProp;
}

const VehicleCardDesktop = ({ config }: VehicleCardDesktopProps) => {
  const { onVehicleCardEngageSendClick, onViewCVDPClick } = config;
  const { vehicle, isShowroomMode, position } = useContext(VehicleCardContext);
  const [isModalGalleryOpen, setIsModalGalleryOpen] = useState(false);
  const { hasFeature } = useFeatures();
  const hasUIPreview = hasFeature(features.UI_PREVIEW);

  // TODO: remove suspense after full transition
  if (hasUIPreview) {
    if (isShowroomMode) {
      return (
        <Suspense fallback={<></>}>
          <VehicleCardShowroomDesktop config={config} />
        </Suspense>
      );
    }
    return (
      <Suspense fallback={<></>}>
        <VehicleCardDesktopV2 />
      </Suspense>
    );
  }

  const vehicleId = vehicle?.vehicleCard.id;

  const toggleIsModalGalleryOpen = () => {
    setIsModalGalleryOpen((currentVal) => !currentVal);
  };

  const handleViewCVDPClick = () => {
    if (vehicle && vehicle.vehicleCard.id) {
      onViewCVDPClick();
    }
  };

  const inventoryPrice = vehicle?.price?.amount;

  const isCertifiedPreOwned = vehicle && getIsCertifiedPreOwned(vehicle);
  return (
    <>
      <Row
        className={`VehicleCard-main-row ${
          isShowroomMode ? 'Vehiclecard-showroom' : ''
        }`}
      >
        <Col className="col-image">
          <Row className="full-width cover-size-image-container">
            <WrapImagePreviewForShowroom
              toggleIsModalGalleryOpen={toggleIsModalGalleryOpen}
            >
              <VehicleImageView
                thumbnail
                imageUrl={vehicle?.vehicleCard.vehicleImage}
                skeleton={!vehicle}
              />
            </WrapImagePreviewForShowroom>
          </Row>
        </Col>
        <Col className="col-rest">
          <Row className="row-nowrap">
            <Col className="col-details">
              <Row>
                <Col>
                  <VehicleCardYearMakeModel size="wide" />
                </Col>
              </Row>
              <Row className="VehicleCard-details-table-container row-details">
                <Col>
                  <VehicleDetailsTable />
                </Col>
              </Row>
              {!isShowroomMode && (
                <>
                  <Row
                    className="VehicleCard-tag-row-container"
                    id={`VehicleCard-${position}-tag-row`}
                  >
                    <Col>
                      <PermissionsGate permissions={[permissions.TAGS_VIEW]}>
                        {vehicle ? (
                          <TagList
                            showEditButton
                            vehicleIsSold={vehicle.vehicleCard.sold ?? true}
                            initialTags={vehicle.tags ?? []}
                            vehicleId={vehicleId ?? ''}
                          />
                        ) : (
                          <Skeleton />
                        )}
                      </PermissionsGate>
                    </Col>
                  </Row>
                  <Row className="VehicleCard-progress-row">
                    <Col>
                      <VehicleCardProgressBar />
                    </Col>
                  </Row>
                </>
              )}
            </Col>
            {isShowroomMode ? (
              <>
                <Col
                  className={`showroom-right-content ${
                    isCertifiedPreOwned ? 'preownedMargin' : ''
                  }`}
                >
                  <Row className="showroom-price">
                    Price{' '}
                    {typeof inventoryPrice === 'number'
                      ? currencyFormatter(inventoryPrice)
                      : strings.EMPTY_VALUE}
                  </Row>
                  <Row>
                    {isCertifiedPreOwned && (
                      <div className="showroom-chip">
                        {strings.CERTIFIED_PREOWNED_CHIP}
                      </div>
                    )}
                  </Row>
                  <Row>
                    <Button
                      data-vas-testing={`${generateTestId(
                        testIds.SHOWROOM_MODE_VIEW_BUTTON,
                        {
                          position: position.toString(),
                        }
                      )}`}
                      as="button"
                      className="engage-buttons"
                      variant="outline-dark"
                      onClick={handleViewCVDPClick}
                    >
                      <span>{strings.VIEW}</span>
                      <Visibility className="engage-btn-icon" />
                    </Button>
                    <PermissionsGate
                      permissions={[permissions.PLUGIN_VELOCITYENGAGE_SEND]}
                    >
                      <Button
                        data-vas-testing={`${generateTestId(
                          testIds.SHOWROOM_MODE_SEND_BUTTON,
                          {
                            position: position.toString(),
                          }
                        )}`}
                        as="button"
                        className="engage-buttons"
                        variant="outline-dark"
                        onClick={onVehicleCardEngageSendClick}
                      >
                        <span>{strings.SEND}</span>
                        <Send className="engage-btn-icon" />
                      </Button>
                    </PermissionsGate>
                  </Row>
                </Col>
                {vehicle?.vehicleCard.imageCarousel && (
                  <ModalCarousel
                    modalIsOpen={isModalGalleryOpen}
                    currentIndex={0}
                    images={vehicle?.vehicleCard.imageCarousel}
                    onClose={toggleIsModalGalleryOpen}
                    onDeleteImage={noop}
                    onAssignImage={noop}
                  />
                )}
              </>
            ) : (
              <>
                <div className="border-right" />
                <Col className="VehicleCard-details-container col-recon-inventory">
                  <VehicleCardReconInventoryTabs />
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default VehicleCardDesktop;
