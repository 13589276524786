import React from 'react';
import { isUndefined, isNull } from 'lodash';

import {
  CheckCircle,
  PlayCircleFilled,
  PauseCircleFilled,
  Person,
  Delete,
  Save,
  DirectionsCar,
  Update,
  Room,
  LocalOffer,
} from '@material-ui/icons/';
import {
  BriefcaseCheck,
  BriefcaseX,
  ArrowUpBold,
  Database,
  Gavel,
  BriefcasePlus,
  BriefcaseEdit,
  Pulse,
} from 'components/shared/icons';
import './HistoryMessage.scss';
import { getFormattedHour, getFormattedDurationFromSeconds } from 'utils/time';
import { HistoryItem } from 'models';

interface HistoryMessageProps {
  historyItem: HistoryItem;
}

const HISTORY_STATUS = {
  STATUS_TASK_APPROVED: 'TASK_APPROVED',
  STATUS_TASK_DENIED: 'TASK_DENIED',
  STATUS_TASK_COMPLETED: 'TASK_COMPLETED',
  STATUS_TASK_CREATED: 'TASK_CREATED',
  STATUS_TASK_DELETED: 'TASK_DELETED',
  STATUS_TASK_DRAFT_CREATED: 'TASK_DRAFT_CREATED',
  STATUS_TASK_MODIFIED: 'TASK_MODIFIED',
  STATUS_RECON_STEP_ASSIGNED: 'RECON_STEP_ASSIGNED',
  STATUS_RECON_STEP_ENTERED: 'RECON_STEP_ENTERED',
  STATUS_RECON_STEP_CHANGED: 'RECON_STEP_CHANGED',
  STATUS_RECON_STEP_STARTED: 'RECON_STEP_STARTED',
  STATUS_RECON_STEP_COMPLETED: 'RECON_STEP_COMPLETED',
  STATUS_RECON_STEP_PAUSED: 'RECON_STEP_PAUSED',
  STATUS_DATA_REFRESHED: 'DATA_REFRESHED',
  STATUS_UPDATED_GPS_LOCATION: 'UPDATED_GPS_LOCATION',
  STATUS_UPDATE: 'STATUS_UPDATE',
  STATUS_TAG_ADDED_REMOVED: 'TAG_ADDED_REMOVED',
};

var HistoryMessage = ({ historyItem }: HistoryMessageProps) => {
  const getIcon = () => {
    switch (historyItem.itemType) {
      case HISTORY_STATUS.STATUS_TASK_APPROVED:
        return <Gavel className="grayTint" />;
      case HISTORY_STATUS.STATUS_TASK_DENIED:
        return <BriefcaseX className="grayTint" />;
      case HISTORY_STATUS.STATUS_TASK_COMPLETED:
        return <BriefcaseCheck className="grayTint" />;
      case HISTORY_STATUS.STATUS_TASK_CREATED:
        return <BriefcasePlus className="grayTint" />;
      case HISTORY_STATUS.STATUS_TASK_DELETED:
        return <Delete className="grayTint" />;
      case HISTORY_STATUS.STATUS_TASK_DRAFT_CREATED:
        return <Save className="grayTint" />;
      case HISTORY_STATUS.STATUS_TASK_MODIFIED:
        return <BriefcaseEdit className="grayTint" />;
      case HISTORY_STATUS.STATUS_RECON_STEP_ASSIGNED:
        return <Person className="grayTint" />;
      case HISTORY_STATUS.STATUS_RECON_STEP_ENTERED:
        return <ArrowUpBold className="grayTint" />;
      case HISTORY_STATUS.STATUS_RECON_STEP_CHANGED:
        return <Update className="grayTint" />;
      case HISTORY_STATUS.STATUS_RECON_STEP_COMPLETED:
        return <CheckCircle className="grayTint" />;
      case HISTORY_STATUS.STATUS_RECON_STEP_STARTED:
        return <PlayCircleFilled className="defaultTint" />;
      case HISTORY_STATUS.STATUS_RECON_STEP_PAUSED:
        return <PauseCircleFilled className="defaultTint" />;
      case HISTORY_STATUS.STATUS_DATA_REFRESHED:
        return <Database className="grayTint" />;
      case HISTORY_STATUS.STATUS_UPDATED_GPS_LOCATION:
        return <Room className="grayTint" />;
      case HISTORY_STATUS.STATUS_UPDATE:
        return <Pulse className="grayTint" />;
      case HISTORY_STATUS.STATUS_TAG_ADDED_REMOVED:
        return <LocalOffer className="grayTint" />;
      default:
        return <DirectionsCar className="grayTint" />;
    }
  };

  const getDivider = () => <div className="HistoryItem-divider" />;

  const { actor } = historyItem;
  const hour = getFormattedHour(new Date(historyItem.timestamp));
  const chip =
    !isUndefined(historyItem.timeInStepSeconds) &&
    !isNull(historyItem.timeInStepSeconds);
  const minutes =
    chip && historyItem.timeInStepSeconds
      ? getFormattedDurationFromSeconds(historyItem.timeInStepSeconds)
      : '0M';

  return (
    <div className="HistoryItem-container">
      <div className="HistoryItem-IconContainer">
        {getIcon()}
        {getDivider()}
      </div>
      <div className="HistoryItem-labelChipContainer">
        <div className="HistoryItem-labelsContainer">
          <div className="HistoryItem-label">
            {historyItem.label}
            {chip && <div className="history-alert-chip">{minutes}</div>}
          </div>
          <div className="HistoryItem-linkContainer">
            <div className="HistoryItem-time">{hour}</div>
            &nbsp;&bull;&nbsp;
            <div className="HistoryItem-link">
              {actor ? `@${actor?.firstName} ${actor?.lastName}` : ''}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryMessage;
