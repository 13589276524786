import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import './ShopperModal.scss';
import strings from 'common/strings';
import { testIds } from 'common/testIds';
import permissions from 'common/permissions';
import Alert from '../Alert';
import { mockStateList } from './MockShopperData';
import { TextInput } from '../Inputs';
import { useCreateProspect } from 'api';
import { ShopperForm, State } from 'models';
import Button from 'components/shared/Button';
import { usePermissions } from 'hooks';

interface ShopperModalProps {
  shopper?: ShopperForm;
  onClose: () => void;
}

const ShopperModal: React.FC<React.PropsWithChildren<ShopperModalProps>> = ({
  onClose,
  shopper,
}) => {
  const [shopperFormState, setShopperFormState] = useState<ShopperForm>(() => {
    const emptyShopper = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address1: '',
      address2: '',
      city: '',
      state: mockStateList[0],
      zip: '',
    };
    return shopper ? Object.assign(shopper, emptyShopper) : emptyShopper;
  });
  const [formValid, setFormValid] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState('');
  const createProspectAsync = useCreateProspect();

  useEffect(() => {
    if (
      shopperFormState.firstName &&
      shopperFormState.lastName &&
      (shopperFormState.email || shopperFormState.phone)
    ) {
      setFormValid(true);
      return;
    }
    setFormValid(false);
  }, [shopperFormState]);

  const onSubmit = async () => {
    await createProspectAsync.mutateAsync({ shopperForm: shopperFormState });
    onClose();
  };

  const onCancel = () => {
    onClose();
  };

  const renderShopperForm = () => (
    <div className="ShopperModal-form-column">
      <div className="ShopperModal-form-row">
        <TextInput
          id={testIds.ADD_SHOPPER_FIRST_NAME}
          disabled={false}
          className="ShopperModal-form-input"
          containerClassName="border-primary"
          placeholderClassName="text-primary"
          value={shopperFormState.firstName}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            const firstName = value || '';
            setShopperFormState((prevState) => ({ ...prevState, firstName }));
          }}
          placeholder={strings.SHOPPER_FIRST_NAME}
          autoComplete="given-name"
        />
        <TextInput
          className="ShopperModal-form-input"
          id={testIds.ADD_SHOPPER_LAST_NAME}
          containerClassName="border-primary"
          placeholderClassName="text-primary"
          value={shopperFormState.lastName}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            const lastName = value || '';
            setShopperFormState((prevState) => ({ ...prevState, lastName }));
          }}
          placeholder={strings.SHOPPER_LAST_NAME}
          autoComplete="family-name"
        />
      </div>
      <h6>{strings.CONTACT_DETAILS}</h6>
      <div className="ShopperModal-form-row">
        <TextInput
          id={testIds.ADD_SHOPPER_EMAIL}
          disabled={false}
          className="ShopperModal-form-input"
          containerClassName="border-primary"
          placeholderClassName="text-primary"
          value={shopperFormState.email}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            const email = value || '';
            setShopperFormState((prevState) => ({ ...prevState, email }));
          }}
          placeholder={strings.EMAIL}
          autoComplete="email"
        />
        <TextInput
          id={testIds.ADD_SHOPPER_PHONE}
          className="ShopperModal-form-input"
          containerClassName="border-primary"
          placeholderClassName="text-primary"
          value={shopperFormState.phone}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            const phone = value || '';
            setShopperFormState((prevState) => ({ ...prevState, phone }));
          }}
          placeholder={strings.PHONE}
          autoComplete="phone"
        />
      </div>
      <h6>{strings.ADDRESS}</h6>
      <div className="ShopperModal-form-row">
        <TextInput
          id={testIds.ADD_SHOPPER_ADDRESS1}
          disabled={false}
          className="ShopperModal-form-input"
          containerClassName="border-primary"
          placeholderClassName="text-primary"
          value={shopperFormState.address1}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            const address1 = value || '';
            setShopperFormState((prevState) => ({ ...prevState, address1 }));
          }}
          placeholder={strings.ADDRESS_1}
          autoComplete="address-line1"
        />
        <TextInput
          id={testIds.ADD_SHOPPER_ADDRESS2}
          className="ShopperModal-form-input"
          containerClassName="border-primary"
          placeholderClassName="text-primary"
          value={shopperFormState.address2}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            const address2 = value || '';
            setShopperFormState((prevState) => ({ ...prevState, address2 }));
          }}
          placeholder={strings.ADDRESS_2}
          autoComplete="address-line2"
        />
      </div>
      <div className="ShopperModal-form-row">
        <TextInput
          id={testIds.ADD_SHOPPER_CITY}
          disabled={false}
          className="ShopperModal-form-input"
          containerClassName="border-primary"
          placeholderClassName="text-primary"
          value={shopperFormState.city}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            const city = value || '';
            setShopperFormState((prevState) => ({ ...prevState, city }));
          }}
          placeholder={strings.CITY}
          autoComplete="address-level2"
        />
        <Select
          className="ShopperModal-form-row-select"
          options={mockStateList}
          inputId={testIds.ADD_SHOPPER_STATE}
          classNamePrefix="Select-State"
          isSearchable
          menuPlacement="top"
          maxMenuHeight={200}
          noOptionsMessage={() => strings.NO_STATE_OPTION}
          onChange={(selectedState: any) => {
            if (selectedState) {
              const newState = selectedState as State;
              setShopperFormState((prevState) => ({ ...prevState, newState }));
            }
          }}
          placeholder={strings.SELECT_STATE_PLACEHOLDER}
          name="address-level1"
        />
        <TextInput
          id={testIds.ADD_SHOPPER_ZIP}
          className="ShopperModal-form-input-zip"
          containerClassName="border-primary"
          placeholderClassName="text-primary"
          value={shopperFormState.zip}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            const zip = value || '';
            setShopperFormState((prevState) => ({ ...prevState, zip }));
          }}
          placeholder={strings.ZIP}
          autoComplete="postal-code"
        />
      </div>
    </div>
  );

  const { hasPermission } = usePermissions();

  return (
    <>
      {hasPermission(permissions.PLUGIN_VELOCITYENGAGE_SEND) && (
        <Modal
          show
          centered
          onHide={onClose}
          className="ShopperModal"
          backdrop="static"
          dialogClassName="ShopperDialog"
        >
          {showAlertMessage && (
            <Alert
              open={showAlertMessage.length > 0}
              duration={3000}
              handleClose={() => setShowAlertMessage('')}
              contentProps={{
                message: showAlertMessage,
                variant:
                  showAlertMessage === strings.COPIED_SHARE_LINK
                    ? 'success'
                    : 'error',
                onClose: () => setShowAlertMessage(''),
              }}
            />
          )}
          <Modal.Header className="modal-header-container">
            <Modal.Title>{strings.ADD_SHOPPER}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="ShopperModal-body flex-grow">
            {renderShopperForm()}
          </Modal.Body>
          <Modal.Footer>
            <Button
              data-vas-testing={testIds.ADD_SHOPPER_CANCEL_BUTTON}
              type="button"
              onClick={onCancel}
              className="ShopperModal-cancel-button"
              variant={'secondary'}
            >
              {strings.CANCEL}
            </Button>
            <Button
              data-vas-testing={testIds.ADD_SHOPPER_SUBMIT_BUTTON}
              type="button"
              className="blue-button ShopperModal-submit-button"
              onClick={onSubmit}
              loading={createProspectAsync.isLoading}
              disabled={!formValid}
              variant={'primary'}
            >
              <div>{strings.SUBMIT}</div>
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default ShopperModal;
