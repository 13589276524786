import React, { RefObject } from 'react';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { ALLOWED_FILETYPES_STRING } from 'store/uploads/types';

import './NoteAttachmentInput.scss';

interface NoteAttachementInputProps {
  onFileSelected(event: React.FormEvent<HTMLInputElement>): void;
  disabled: boolean;
  attachmentInputRef: RefObject<HTMLInputElement>;
}

var NoteAttachementInput = ({
  onFileSelected,
  disabled,
  attachmentInputRef,
}: NoteAttachementInputProps) => {
  return (
    <>
      <label htmlFor="browse" className="mb-0">
        <input
          ref={attachmentInputRef}
          style={{ display: 'none' }}
          type="file"
          name="browse"
          id="browse"
          onChange={onFileSelected}
          disabled={disabled}
          multiple
          accept={ALLOWED_FILETYPES_STRING}
        />
        <AttachmentIcon
          className={`upload-icon ${disabled ? 'disabled' : ''}`}
        />
      </label>
    </>
  );
};

export default NoteAttachementInput;
