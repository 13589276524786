// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { APIResponse, defaultMutationFn } from 'api';
import { Attachment, Task, TaskStatus } from 'models';
import { useMutation, useQuery, useQueryClient } from 'react-query';

/**
 * @package api/inventory
 * @description - Get tasks for specific inventory
 * @param id vehicle UUID
 */
interface Totals {
  partsTotal: number;
  laborTotal: number;
  hoursTotal?: number;
  total: number;
}
interface VehicleTasksApiResponse extends APIResponse<Task[]> {
  meta?: {
    returnedRecords: number;
    totalRecords: number;
    estimatesTaskTotal: Totals;
    requestsTaskTotal: Totals;
    approvedTaskTotal: Totals;
    overallTaskTotal: Totals;
  };
}
export function useVehicleTasks(id?: string) {
  const queryClient = useQueryClient();
  const path = `/inventory/${id}/tasks`;
  return useQuery<VehicleTasksApiResponse>(path, {
    enabled: !!id,
    onSuccess: ({ data: tasks }) => {
      tasks.forEach((task) => {
        const { attachments, id } = task;
        queryClient.setQueryData(`${path}/${id}`, { data: task });
        queryClient.setQueryData(`${path}/${id}/attachments`, {
          data: attachments,
        });
      });
    },
  });
}

/**
 * @package api/inventory
 * @description - Get a vehicle task
 * @param id vehicle UUID
 */
export function useVehicleTask(vehicleId?: string, taskId?: string) {
  const queryClient = useQueryClient();
  const path = `/inventory/${vehicleId}/tasks/${taskId}`;
  return useQuery<APIResponse<Task>>(path, {
    enabled: !!vehicleId && !!taskId,
    onSuccess: ({ data: task }) => {
      const { attachments, id } = task;
      queryClient.setQueryData(`${path}/${id}/attachments`, {
        data: attachments,
      });
    },
  });
}

/**
 * @package api/inventory
 * @description - create or update a task
 * @param vehicleId vehicle UUID
 * @param taskId task UUID
 */
export function useCreateOrUpdateVehicleTask(vehicleId?: string) {
  const queryClient = useQueryClient();
  const basePath = `/inventory/${vehicleId}/tasks`;
  return useMutation(
    (task: Task) => {
      const method = !!task.id ? 'PUT' : 'POST';
      const path = `${basePath}${!!task.id ? `/${task.id}` : ''}`;
      return defaultMutationFn(path, method, task);
    },
    {
      onSuccess: ({ data: task }, beforeSaveTask, context) => {
        queryClient.setQueryData(basePath, (data: any) => {
          if (data?.data) {
            if (!beforeSaveTask.id) {
              const tasks = [task].concat(data?.data);
              data.data = tasks;
            } else {
              const index = data.data.findIndex((t: Task) => t.id === task.id);
              data.data[index] = task;
            }
          }

          return data;
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(basePath);
      },
    }
  );
}

/**
 * @package api/inventory
 * @description - create or update a task
 * @param vehicleId vehicle UUID
 */
export function useCreateVehicleTasksBulk(vehicleId?: string) {
  const queryClient = useQueryClient();
  const basePath = `/inventory/${vehicleId}/tasksbulk`;
  const tasksPath = `/inventory/${vehicleId}/tasks`;
  return useMutation(
    (tasks: Array<Task>) => {
      return defaultMutationFn(basePath, 'POST', tasks);
    },
    {
      onSuccess: ({ data: taskArray }, beforeSaveTask, context) => {
        queryClient.setQueryData(tasksPath, (data: any) => {
          if (data?.data) {
            const tasks = taskArray.concat(data?.data);
            data.data = tasks;
          } else {
            data.data = taskArray;
          }

          return data;
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(tasksPath);
      },
    }
  );
}

/**
 * @package api/inventory
 * @description - delete a vehicle task
 * @param vehicleId vehicle UUID
 * @param taskId task UUID
 */
export function useDeleteVehicleTask(vehicleId?: string, taskId?: string) {
  const queryClient = useQueryClient();
  const basePath = `/inventory/${vehicleId}/tasks`;
  const path = `${basePath}/${taskId}`;
  return useMutation(() => defaultMutationFn(path, 'DELETE'), {
    onSuccess: () => {
      queryClient.setQueryData(basePath, (data: any) => {
        if (data?.data) {
          const tasks = data?.data.filter((task: Task) => task.id !== taskId);
          data.data = tasks;
        }

        return data;
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(basePath);
    },
  });
}

/**
 * @package api/inventory
 * @description - update a vehicle task status
 * @param vehicleId vehicle UUID
 * @param taskId task UUID
 */
export function useUpdateVehicleTaskStatus(
  vehicleId?: string,
  taskId?: string
) {
  const queryClient = useQueryClient();
  const basePath = `/inventory/${vehicleId}/tasks`;
  const path = `${basePath}/${taskId}/status`;
  return useMutation(
    (status: TaskStatus) => defaultMutationFn(path, 'PUT', status),
    {
      onSuccess: ({ data: task }) => {
        queryClient.setQueryData(`${basePath}/${task.id}`, { data: task });
      },
      onSettled: () => {
        queryClient.invalidateQueries(basePath);
      },
    }
  );
}

/**
 * @package api/inventory
 * @description - update vehicle tasks status
 * @param vehicleId vehicle UUID
 */
export function useUpdateVehicleTasksStatus(vehicleId?: string) {
  const queryClient = useQueryClient();
  const basePath = `/inventory/${vehicleId}/tasks`;
  const path = `${basePath}/status`;
  return useMutation(
    ({ taskIds, status }: { taskIds: string[]; status: TaskStatus }) => {
      const data = taskIds.map((id) => ({ id, status }));
      return defaultMutationFn(path, 'PUT', data);
    },
    {
      onSuccess: ({ data: tasks }) => {
        tasks.forEach((task: Task) => {
          queryClient.setQueryData(`${basePath}/${task.id}`, { data: task });
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(basePath);
      },
    }
  );
}

/**
 * @package api/inventory
 * @description - Get attachments as Attachment[] for specific task
 * @param vehicleId vehicle UUID
 * @param taskId task UUID
 */
export function useTasksAttachments(vehicleId?: string, taskId?: string) {
  const path = `/inventory/${vehicleId}/tasks/${taskId}/attachments`;
  return useQuery<APIResponse<Attachment[]>>(path, {
    enabled: !!vehicleId && !!taskId,
  });
}

/**
 * @package api/inventory
 * @description - Delete attachment from a task
 * @param vehicleId vehicle UUID
 * @param taskId task UUID
 */
export function useDeleteTasksAttachment(vehicleId?: string, taskId?: string) {
  const queryClient = useQueryClient();
  const path = `/inventory/${vehicleId}/tasks/${taskId}/attachments`;
  return useMutation(
    (attachmentId: string) =>
      defaultMutationFn(`${path}/${attachmentId}`, 'DELETE'),
    {
      onSettled: () => {
        queryClient.invalidateQueries(path);
      },
    }
  );
}
