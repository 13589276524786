import React from 'react';
import { AttachMoney } from '@material-ui/icons';

import Modal from 'components/shared/Modal';
import strings from 'common/strings';
import { testIds } from 'common/testIds';
import { currencyFormatter } from 'utils/formatter';
import { RedUpArrow, GreenDownArrow } from 'components/shared/icons';

import './PriceHistoryModal.scss';
import { PriceHistoryItem } from 'models/inventory/EngageOverview';

interface PriceHistoryModalProps {
  open: boolean;
  onClose: () => void;
  onClick: () => void;
  priceHistory: PriceHistoryItem[];
  customButton?: boolean;
}

const PriceHistoryModal: React.FC<PriceHistoryModalProps> = ({
  open,
  onClose,
  onClick,
  priceHistory = [],
  customButton,
}) => (
  <div>
    {!customButton && (
      <div className="PriceHistoryModal-ctrl-btn" onClick={onClick} role="none">
        <span className="PriceHistoryModal-ctrl-btn-text">
          {strings.VIEW_PRICE_HISTORY}
        </span>
        <AttachMoney className="PriceHistoryModal-ctrl-btn-icon" />
      </div>
    )}
    <Modal
      open={open}
      onClose={onClose}
      onPrimaryButtonClick={onClose}
      primaryButtonLabel={strings.CLOSE}
      secondaryButton={false}
      title={strings.PRICE_CHANGE_HISTORY}
      className="PriceHistoryModal"
    >
      <table className="PriceHistoryModal-table">
        <tr className="PriceHistoryModal-header">
          <th
            className="PriceHistoryModal-header-item"
            data-vas-testing={testIds.PRICE_HISTORY_MODAL_DATE_HEADER}
          >
            {strings.DATE}
          </th>
          <th
            className="PriceHistoryModal-header-item"
            data-vas-testing={testIds.PRICE_HISTORY_MODAL_START_PRICE_HEADER}
          >
            {strings.START_PRICE}
          </th>
          <th
            className="PriceHistoryModal-header-item"
            data-vas-testing={testIds.PRICE_HISTORY_MODAL_NEW_PRICE_HEADER}
          >
            {strings.NEW_PRICE}
          </th>
        </tr>
        {priceHistory.map((historyItem: PriceHistoryItem) => {
          const getDate = () => {
            const { timestamp } = historyItem;
            const timesTampAsDate = Date.parse(timestamp);
            return new Intl.DateTimeFormat('en-US')
              .format(timesTampAsDate)
              .replace(/\//g, '-');
          };
          const { price, delta } = historyItem;
          const originalPrice =
            price.amount && delta.amount ? price.amount - delta.amount : 0.0;
          return (
            <tr
              key={`historyItem-${historyItem.timestamp}`}
              className="PriceHistoryModal-table-item"
            >
              <td>
                <span>{getDate()}</span>
              </td>
              <td>
                <span>{currencyFormatter(originalPrice || 0)}</span>
              </td>
              <td>
                <span className="PriceHistoryModal-new-price">
                  {currencyFormatter(price.amount || 0)}
                </span>
                {delta.amount && Math.sign(delta.amount) === 1 ? (
                  <RedUpArrow className="PriceHistoryModal-arrow-icon" />
                ) : (
                  <GreenDownArrow className="PriceHistoryModal-arrow-icon" />
                )}
              </td>
            </tr>
          );
        })}
      </table>
    </Modal>
  </div>
);

export default PriceHistoryModal;
