import * as Sentry from '@sentry/browser';

import initNewRelicAgent from './newrelic-agent';

const appVersion = `${process.env.REACT_APP_VERSION}`;
// there will be more functions here, there just aren't yet
/* eslint-disable import/prefer-default-export */

/** Sets up the telemetry agent(s) in the browser.
 * This should be called as early as possible in the loading process so the
 * agents can monitor loading performance and trap errors and XHRs.
 */
export function init(): void {
  // NewRelic Browser
  if (
    process.env.REACT_APP_NEW_RELIC_LICENSE_KEY &&
    process.env.REACT_APP_NEW_RELIC_APPLICATION_ID
  ) {
    initNewRelicAgent();

    if (window.newrelic) {
      window.newrelic.addRelease('car-web-reconvelocity-react', appVersion);
    }
  }

  // Sentry
  if (process.env.REACT_APP_SENTRY_DNS) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DNS,
      environment: process.env.NODE_ENV,
      release: `car-web-reconvelocity-react@${appVersion}`,
    });
  }
}
