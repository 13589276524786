import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { getFormattedDurationFromSeconds } from 'utils/time';
import { vehicleDetailsBuilder } from 'navigation/routes';
import { navigate } from '@reach/router';

import strings from 'common/strings';
import { formatMileage } from 'utils/formatter';
import {
  STOCK_LAST_LENGTH,
  truncateEndOfStringByLength,
  VIN_LAST_LENGTH,
} from 'utils/string';

import VehicleImageView from 'components/shared/VehicleImageView';

import './AssignmentsCard.scss';
import InventoryVehicleSummary from 'models/inventory/inventoryVehicleSummary';
import { generateTestId } from 'common';
import { testIds } from 'common/testIds';

interface AssignmentsCardProps {
  inventoryVehicleSummary: InventoryVehicleSummary;
  index: number;
}

const AssignmentsCard: React.FC<React.PropsWithChildren<AssignmentsCardProps>> =
  ({ inventoryVehicleSummary, index }: AssignmentsCardProps) => {
    const { stepItem } = inventoryVehicleSummary;
    const timeInStepSeconds = stepItem?.timeInStepSeconds || 0;
    const safeDurationSeconds = stepItem?.safeDurationSeconds || 0;
    const recommendedDurationSeconds =
      stepItem?.recommendedDurationSeconds || 0;

    function getStepStatus() {
      if (
        timeInStepSeconds &&
        safeDurationSeconds &&
        recommendedDurationSeconds
      ) {
        if (timeInStepSeconds < safeDurationSeconds) {
          return 'ok';
        }
        if (timeInStepSeconds < recommendedDurationSeconds) {
          return 'warning';
        }
        return 'danger';
      }
      return '';
    }

    function getProgressBarPercent() {
      if (timeInStepSeconds && recommendedDurationSeconds) {
        return (timeInStepSeconds / recommendedDurationSeconds) * 100;
      }
      return 0;
    }

    return (
      <Container
        className="AssignmentsCard"
        data-vas-testing={generateTestId(testIds.ASSIGNMENT_VEHICLE_CARD, {
          position: `${index}`,
        })}
        onClick={() => {
          navigate(
            `${vehicleDetailsBuilder(
              inventoryVehicleSummary.vehicleCard.id!,
              false
            )}?fromVDP=dashboard`
          );
        }}
      >
        <Row className="AssignmentsCard-step-info-row">
          <Col xl={12} xs={12} className="AssignmentsCard-step-progress-col">
            <div className="ProgressBar">
              <div className="ProgressBar-progress-bar">
                <div
                  className={`ProgressBar-percentage ${getStepStatus()}`}
                  style={{ width: `${getProgressBarPercent()}%` }}
                />
              </div>
            </div>
          </Col>
          <Col className="AssignmentsCard-step-name-col">
            <span className="AssignmentsCard-step-name-text">
              {stepItem?.name ?? strings.EMPTY_VALUE}
            </span>
          </Col>
          <Col className="AssignmentsCard-step-progress-text-col">
            <span className="AssignmentsCard-time-name-text">
              {timeInStepSeconds && recommendedDurationSeconds
                ? `${getFormattedDurationFromSeconds(timeInStepSeconds)} /
              ${getFormattedDurationFromSeconds(recommendedDurationSeconds)}`
                : strings.EMPTY_VALUE}
            </span>
          </Col>
        </Row>
        <Row className="vehicle-info-container">
          <Col xl={12} xs={12} className="vehicle-attention-image-container">
            <div className="vehicle-attention-image-photo center-content">
              <VehicleImageView
                size="normal"
                imageUrl={inventoryVehicleSummary.vehicleCard.vehicleImage}
              />
            </div>
          </Col>
          <Col
            xs={12}
            xl={12}
            className="vehicle-attention-description-container"
          >
            <div className="year-make">
              {inventoryVehicleSummary.vehicleCard.year ||
              inventoryVehicleSummary.vehicleCard.make
                ? `${inventoryVehicleSummary.vehicleCard.year} ${
                    inventoryVehicleSummary.vehicleCard.make || ''
                  }`
                : strings.EMPTY_VALUE}
            </div>
            <div className="model">
              {inventoryVehicleSummary.vehicleCard.model || strings.EMPTY_VALUE}
            </div>
          </Col>
        </Row>
        <Row className="AssignmentsCard-vin-stock-row">
          <Col xl={5} xs={6} className="AssignmentsCard-vin-stock-container">
            <div className="AssignmentsCard-vin-stock-label">VIN </div>
            <span className="vin">
              {truncateEndOfStringByLength(
                inventoryVehicleSummary.vehicleCard.vin,
                VIN_LAST_LENGTH
              )}
            </span>
          </Col>
          <Col xl={4} xs={3} className="AssignmentsCard-vin-stock-container">
            <div className="AssignmentsCard-vin-stock-label">STOCK </div>
            <span className="stock-number">
              {truncateEndOfStringByLength(
                inventoryVehicleSummary.vehicleCard.stockNumber,
                STOCK_LAST_LENGTH
              )}
            </span>
          </Col>
          {/* <Col xl={3} xs={3} className="AssignmentsCard-icon-container"> TODO - not sure what this does
          <div className="AssignmentsCard-icon-bubble">
            <IconButton disabled>
              <AccessAlarmIcon className="AssignmentsCard-icon" />
            </IconButton>
          </div>
        </Col> */}
        </Row>
        <Row className="AssignmentsCard-mileage-row">
          <Col xl={12} xs={12} className="AssignmentsCard-mileage-container">
            <div className="AssignmentsCard-mileage-label mr-1">
              {strings.MILEAGE}
            </div>
            <span className="vin">
              {formatMileage(
                inventoryVehicleSummary.vehicleCard.mileage,
                inventoryVehicleSummary.vehicleCard?.odometerUnit
              )}
            </span>
          </Col>
        </Row>
        {/* <Row className="AssignmentsCard-attention-row"> TODO - not sure what this does
        <Col xl={12} xs={12} className="attention-overage-container center-content">
          <div className="AssignmentsCard-value-metric-container center-content">
            <span className="AssignmentsCard-value-metric-text">
              {recommendedDurationSeconds && timeInStepSeconds
              ? getFormattedDurationFromSeconds(recommendedDurationSeconds - timeInStepSeconds)
              : strings.EMPTY_VALUE}
            </span>
          </div>
        </Col>
      </Row> */}
      </Container>
    );
  };

export default AssignmentsCard;
