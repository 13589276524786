import React, { useContext } from 'react';
import strings from 'common/strings';
import Skeleton from 'components/shared/Skeleton';
import { VehicleCardContext } from '../../VehicleCard';
import TextCopy from 'components/shared/Inputs/TextCopy';
import './VehicleCardYearMakeModel.scss';

interface VehicleCardYearMakeModelProps {
  size?: 'wide' | 'small';
}

var VehicleCardYearMakeModel = ({
  size = 'small',
}: VehicleCardYearMakeModelProps) => {
  const { vehicle } = useContext(VehicleCardContext);
  const { year, make, model, vin } = vehicle?.vehicleCard ?? {};
  if (size === 'wide') {
    return (
      <Skeleton renderSkeleton={!vehicle}>
        <div className="Vehicle-card-year-make-model">
          {vehicle && (year || make)
            ? `${year || ''} ${make || ''} ${model || ''}`
            : strings.EMPTY_VALUE}
        </div>
      </Skeleton>
    );
  }

  return (
    <div className="VehicleCard-year-make-model">
      <div className="Vehicle-card-year-make">
        <Skeleton renderSkeleton={!vehicle}>
          {vehicle && (year || make)
            ? `${year || ''} ${make || ''}`
            : strings.EMPTY_VALUE}
        </Skeleton>
      </div>
      <div className="VehicleCard-model">
        <Skeleton renderSkeleton={!vehicle}>{model || ''}</Skeleton>
      </div>
      <Skeleton renderSkeleton={!vehicle}>
        <div className="VehicleCard-vin">
          <TextCopy value={vin!} />
        </div>
      </Skeleton>
    </div>
  );
};

export default VehicleCardYearMakeModel;
