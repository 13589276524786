import React from 'react';
import { ArrowDropDown } from '@material-ui/icons';
import strings from 'common/strings';
import DownloadMenu from 'components/shared/DownloadMenu';
import { Accordion, Card } from 'react-bootstrap';

import { DealerStepReportSummaryItem, DealerStepReportVehicle } from 'models';
import './DealerStepReport.scss';
import StepCell from '../StepCell';
import { DateRange } from 'common/DateRange';
import { ReportType } from 'components/shared/DownloadMenu/DownloadMenu';

export const StepSummary = ({
  summaryItems,
  vehicles,
  dateRange,
  downloadFileUrl,
  isLoading,
  setSelectedReportType,
  openStepModal,
  isInCorporateReport,
}: {
  isLoading: boolean;
  downloadFileUrl: string;
  summaryItems: DealerStepReportSummaryItem[];
  vehicles: DealerStepReportVehicle[];
  dateRange: DateRange;
  setSelectedReportType: (type: ReportType | null) => void;
  openStepModal: (step: DealerStepReportSummaryItem | null) => void;
  isInCorporateReport?: string;
}) => {
  const totalNumberOfVehicles =
    summaryItems.reduce(
      (acc: number, item: DealerStepReportSummaryItem) =>
        acc + item.totalVehicles,
      0
    ) || 0;

  return (
    <>
      <div className="DealerStepReport-title">
        {strings.STEP_SUMMARY}
        {totalNumberOfVehicles > 0 && !isInCorporateReport && (
          <DownloadMenu
            dateRange={dateRange}
            downloadFileUrl={downloadFileUrl}
            onSelectedMenuType={setSelectedReportType}
            isLoading={isLoading}
          />
        )}
      </div>
      <div className="DealerStepReport-StepSummary flex-rows full-width">
        <div className="DealerStepReport-StepSummary-summary-row flex-columns full-width border-bottom">
          <div className="w-25" />
          <div className=" w-75 evenly-spaced-container">
            {!isInCorporateReport && (
              <>
                <div className="evenly-spaced-item">
                  <span className="blue-text font-bold">
                    {strings.VEHICLES}
                  </span>
                </div>
                <div className="evenly-spaced-item">
                  <span className="blue-text font-bold">
                    {strings.STEP_GOAL}
                  </span>
                </div>
                <div className="evenly-spaced-item">
                  <span className="blue-text font-bold">
                    {strings.STEP_TIME}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
        {summaryItems.map((step: DealerStepReportSummaryItem, index: number) =>
          step.childSteps && step.childSteps.length > 0 ? (
            <Accordion key={step.stepId}>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey={step.stepId}>
                  <StepRow
                    step={step}
                    customStyle={'can-hover'}
                    showArrow={true}
                    vehicleData={index === 0 ? vehicles : undefined}
                    openStepModal={openStepModal}
                    isInCorporateReport={isInCorporateReport}
                  />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={step.stepId}>
                  <Card.Body>
                    {step.childSteps.map(
                      (childStep: DealerStepReportSummaryItem) => (
                        <StepRow
                          key={`step-row-${childStep.stepId}`}
                          step={childStep}
                          openStepModal={openStepModal}
                          isInCorporateReport={isInCorporateReport}
                        />
                      )
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          ) : (
            <StepRow
              step={step}
              customStyle={'border-bottom'}
              showArrow={false}
              vehicleData={index === 0 ? vehicles : undefined}
              openStepModal={openStepModal}
              isInCorporateReport={isInCorporateReport}
            />
          )
        )}
      </div>
    </>
  );
};

export const StepRow = ({
  step,
  vehicleData,
  showArrow = false,
  customStyle,
  openStepModal,
  isInCorporateReport,
}: {
  step: DealerStepReportSummaryItem;
  vehicleData?: DealerStepReportVehicle[];
  showArrow?: boolean;
  openStepModal: (step: DealerStepReportSummaryItem) => void;
  customStyle?: string;
  isInCorporateReport?: string;
}) => {
  const {
    stepId,
    stepName,
    idleTimeAsSeconds,
    workTimeAsSeconds,
    stepTimeAsSeconds,
    stepGoalAsSeconds,
    averageRetailReadyTimeAsSeconds,
    averageReconTimeAsSeconds,
    totalVehicles,
  } = step;
  const excludedFromRecon =
    idleTimeAsSeconds === 0 && stepTimeAsSeconds !== workTimeAsSeconds;
  let stepClassName = '';
  if (stepGoalAsSeconds !== 0) {
    stepClassName = stepGoalAsSeconds > stepTimeAsSeconds ? 'early' : 'overdue';
  }
  const grayedClassName = excludedFromRecon ? 'grey-text' : '';
  const onClick = () => openStepModal(step);

  return (
    <div
      key={stepId}
      className={`flex-columns full-width DealerStepReports-StepSummary-summary-row ${customStyle}`}
    >
      <div className="w-25">
        {showArrow && <ArrowDropDown fontSize="small" />}
        <span
          className={`DealerStepReport-StepSummary-step ${grayedClassName}`}
        >
          {stepName}
        </span>
      </div>
      <div className=" w-75 evenly-spaced-container">
        <StepCell
          {...{
            onClick,
            step,
            value: totalVehicles,
            addClassName: grayedClassName,
            vehicleData,
          }}
        />
        {!isInCorporateReport ? (
          <>
            <StepCell
              {...{
                onClick,
                step,
                value: stepGoalAsSeconds,
                formatValue: true,
                addClassName: grayedClassName,
                vehicleData,
              }}
            />
            <StepCell
              {...{
                onClick,
                step,
                value: stepTimeAsSeconds,
                formatValue: true,
                addClassName: `${
                  !excludedFromRecon && stepClassName
                } ${grayedClassName}`,
                vehicleData,
              }}
            />
          </>
        ) : (
          <>
            <StepCell
              {...{
                onClick,
                step,
                value: averageRetailReadyTimeAsSeconds,
                formatValue: true,
                addClassName: `${
                  !excludedFromRecon && stepClassName
                } ${grayedClassName}`,
                vehicleData,
              }}
            />
            <StepCell
              {...{
                onClick,
                step,
                value: averageReconTimeAsSeconds,
                formatValue: true,
                addClassName: `${
                  !excludedFromRecon && stepClassName
                } ${grayedClassName}`,
                vehicleData,
              }}
            />
            <div className={`evenly-spaced-item`} />
            <div className={`evenly-spaced-item`} />
          </>
        )}
      </div>
    </div>
  );
};
