import React from 'react';

import './HighlightCard.scss';
import { getFormattedDurationFromSeconds } from 'utils/time';
import strings from 'common/strings';

interface HighlightCardProps {
  title: string;
  value: number | string;
  name?: string;
}

const HighlightCard: React.FC<HighlightCardProps> = ({
  title,
  value,
  name,
}) => {
  let convertedValue: string | number | null = null;
  if (name === 'AVG_SESSION_DURATION_SECONDS') {
    convertedValue = getFormattedDurationFromSeconds(Number(value));
  } else if (name === 'FOLIO_OPEN_PERCENTAGE') {
    // rounding a server side value to two decimal percent (0.0492 to 4.92% for example)
    const percent = Math.floor(Number(value) * 10000) / 100;
    convertedValue = percent ? `${percent}%` : strings.EMPTY_VALUE;
  }

  return (
    <div className="HighlightCard">
      <div className="value">
        {convertedValue || value || strings.EMPTY_VALUE}
      </div>
      <div className="title">{title}</div>
    </div>
  );
};

export default HighlightCard;
