import { defaultMutationFn } from 'api/useAPI';
import { useMutation } from 'react-query';

export function useUpdateDeviceTokenMutation() {
  return useMutation(async (deviceId: string) => {
    await defaultMutationFn('/notifications/devices', 'POST', {
      deviceId,
      deviceType: 'WEB',
    });
  });
}
