import permissions from 'common/permissions';
import {
  useUpdateVehicleInfo,
  useGetVehicleColors,
  useVehicleSummary,
} from 'api';
import strings from 'common/strings';
import ApiError from 'components/shared/ApiError';
import Dropdown from 'components/shared/Dropdown';
import StepDropdown from 'components/shared/StepDropdown';
import UsersDropdown from 'components/shared/UsersDropdown';
import { usePermissions } from 'hooks';
import { VehicleType } from 'models/inventory/inventoryVehicleSummary';
import React, { useCallback, useMemo, useState } from 'react';
import { formatMileage } from 'utils/formatter';
import { truncateEndOfStringByLength } from 'utils/string';
import './VehicleInfoItem.scss';
import TextCopy from 'components/shared/Inputs/TextCopy';

const MAX_SIZE_DISPOSITION = 15;

interface VehicleInfoItemProps {
  vehicleId: string;
}

export const VehicleInfoItem: React.FC<VehicleInfoItemProps> = ({
  vehicleId,
}) => {
  const { hasPermission } = usePermissions();
  const { data: colors = [] } = useGetVehicleColors();
  const { data: { data: vehicleSummary } = {} } = useVehicleSummary(vehicleId);

  const { vehicleCard, vehicleType, stepItem } = vehicleSummary ?? {};
  const {
    driveTrain,
    trim,
    stockNumber = '',
    vin = '',
    odometerUnit,
    disposition = '',
    mileage,
  } = vehicleCard ?? {};

  const updateVehicleMutation = useUpdateVehicleInfo(vehicleId);
  const colorOptions = useMemo(
    () => colors.map((color) => ({ id: color, value: color })),
    [colors]
  );

  const [selectedColor, setSelectedColor] = useState('');
  const color =
    selectedColor || vehicleCard?.exteriorColor || vehicleCard?.color || '';

  const updatePermission =
    vehicleType === VehicleType.INVENTORY_VEHICLE
      ? permissions.INVENTORY_VDP_UPDATE
      : permissions.RECON_VDP_UPDATE;
  const userHasUpdatePermission = hasPermission(updatePermission);

  const handleSelectColor = useCallback(
    (option: string) => {
      if (color !== option) {
        updateVehicleMutation.mutate({ color: option });
        setSelectedColor(option);
      }
    },
    [color, updateVehicleMutation]
  );

  const selectedOption = useMemo(() => ({ id: color, value: color }), [color]);

  const renderColorSelect = () => (
    <Dropdown
      selectedOption={selectedOption}
      onSelect={handleSelectColor}
      options={colorOptions}
      disabled={!userHasUpdatePermission}
    />
  );

  const info = [
    {
      title: strings.VIN,
      value: (
        <TextCopy
          displayValue={truncateEndOfStringByLength(vin, 17)}
          value={vin}
        />
      ),
    },
    {
      title: strings.STK,
      value: (
        <TextCopy
          displayValue={truncateEndOfStringByLength(stockNumber, 12)}
          value={stockNumber}
        />
      ),
    },
    {
      title: strings.STEP,
      value: stepItem && (
        <StepDropdown vehicleId={vehicleId} currentStep={stepItem} />
      ),
    },
    {
      title: strings.ASSIGNEE,
      value: <UsersDropdown vehicleId={vehicleId} currentStep={stepItem} />,
    },
    {
      title: strings.DISPOSITION,
      value: truncateEndOfStringByLength(disposition, MAX_SIZE_DISPOSITION),
    },
    {
      title: strings.MILEAGE,
      value: formatMileage(mileage, odometerUnit),
    },
    {
      title: strings.COLOR,
      value: renderColorSelect(),
    },
  ];

  if (trim) {
    info.push({ title: strings.TRIM, value: trim });
  }
  if (driveTrain) {
    info.push({ title: strings.DRIVETRAIN, value: driveTrain });
  }

  return (
    <div className="VehicleInfoItem-container flex-columns align-items-start">
      <ApiError error={updateVehicleMutation.error} />
      {info.map(({ title, value }) => (
        <dl className="key-value" key={title}>
          <dt className="VehicleInfoItem-key flex-centered">
            {title || strings.EMPTY_VALUE}
          </dt>
          <dd className="VehicleInfoItem-value flex-centered">
            {value || strings.EMPTY_VALUE}
          </dd>
        </dl>
      ))}
    </div>
  );
};

export default VehicleInfoItem;
