import React, { useState } from 'react';
import { TextInput } from 'components/shared/Inputs';
import validateJs from 'validate.js';

import strings from 'common/strings';
import Modal from 'components/shared/Modal';
import { changePasswordModalValidations } from './validations';
import Alert from '../../Alert';
import { useChangePassword } from 'api';

import '../../../../scss/theme/theme.scss';
import './ChangePasswordModal.scss';

export interface ChangePasswordModalProps {
  onClose: any;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  onClose,
}) => {
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [formError, setFormError] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);

  const { mutateAsync: resetPassword, isLoading } = useChangePassword({
    oldPassword: currentPassword,
    newPassword,
  });

  const clearErrors = (): void => {
    setNewPasswordError('');
    setConfirmNewPasswordError('');
    setCurrentPasswordError('');
    setFormError(false);
  };

  const validateFields = (): boolean => {
    clearErrors();

    const results = validateJs(
      { newPassword, confirmNewPassword, currentPassword },
      { ...changePasswordModalValidations }
    );

    if (results) {
      setNewPasswordError(
        results.newPassword?.length ? results.password[0] : ''
      );
      setConfirmNewPasswordError(
        results?.confirmNewPassword?.length ? results.confirmPassword[0] : ''
      );
      setCurrentPasswordError(
        results?.currentPassword?.length ? results.currentPassword[0] : ''
      );

      return false;
    }

    return true;
  };

  const saveForm = async () => {
    try {
      if (validateFields()) {
        await resetPassword();
        setPasswordChanged(true);
        handleCloseOnSuccess();
      }
    } catch (error) {
      setFormError(true);
      setPasswordChanged(false);
    }
  };

  const handleCloseOnError = () => {
    setFormError(false);
  };

  const handleCloseOnSuccess = () => {
    setPasswordChanged(false);
    onClose();
  };

  const renderAlert = () => {
    const open = formError || passwordChanged;
    const message = formError
      ? strings.ERROR_CHANGING_PASSWORD
      : strings.SUCCESS_CHANGING_PASSWORD;
    const variant = passwordChanged ? 'success' : 'error';
    const onClose = passwordChanged ? handleCloseOnSuccess : handleCloseOnError;

    return (
      <Alert
        open={open}
        contentProps={{
          message,
          variant,
          onClose,
        }}
      />
    );
  };

  return (
    <>
      <Modal
        title={strings.CHANGE_PASSWORD_TITLE}
        open
        onClose={onClose}
        primaryButtonLabel={strings.CHANGE_PASSWORD_BUTTON_TEXT}
        primaryButtonLoading={isLoading}
        onPrimaryButtonClick={saveForm}
        secondaryButtonLabel={strings.CANCEL}
        onSecondaryButtonClick={() => {
          onClose();
        }}
        className="ChangePasswordModal"
      >
        <div>
          <div className="text-primary font-weight-bold">
            {strings.CHANGE_PASSWORD_SUBTITLE}
          </div>
          <div>
            <span className="mr-1">-</span>
            <span>{strings.CHANGE_PASSWORD_TEXT_1}</span>
          </div>
          <div>
            <span className="mr-1">-</span>
            <span>{strings.CHANGE_PASSWORD_TEXT_2}</span>
          </div>
          <div>
            <span className="mr-1">-</span>
            <span>{strings.CHANGE_PASSWORD_TEXT_3}</span>
          </div>
          <div>
            <span className="mr-1">-</span>
            <span>{strings.CHANGE_PASSWORD_TEXT_4}</span>
          </div>
          <TextInput
            type="password"
            error={!!currentPasswordError}
            helperText={currentPasswordError}
            value={currentPassword}
            placeholder={strings.CURRENT_PASSWORD_FIELD}
            onChange={(e) => setCurrentPassword(e.target.value)}
            containerClassName={`mt-4 mb-0 ${
              !currentPasswordError ? 'border-primary' : 'border-danger'
            }`}
            placeholderClassName="text-primary"
            helperTextClassName="mt-2"
          />
          <TextInput
            type="password"
            error={!!newPasswordError}
            helperText={newPasswordError}
            value={newPassword}
            placeholder={strings.NEW_PASSWORD_FIELD}
            onChange={(e) => setNewPassword(e.target.value)}
            containerClassName={`mt-4 mb-0 ${
              !newPasswordError ? 'border-primary' : 'border-danger'
            }`}
            helperTextClassName="mt-2"
            placeholderClassName="text-primary"
          />
          <TextInput
            type="password"
            error={!!confirmNewPasswordError}
            helperText={confirmNewPasswordError}
            value={confirmNewPassword}
            placeholder={strings.CONFIRM_PASSWORD_FIELD}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            containerClassName={`mt-4 mb-0 ${
              !confirmNewPasswordError ? 'border-primary' : 'border-danger'
            }`}
            helperTextClassName="mt-2"
            placeholderClassName="text-primary"
          />
        </div>
      </Modal>
      {renderAlert()}
    </>
  );
};

export default ChangePasswordModal;
