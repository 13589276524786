/* eslint-disable max-len */
// @ts-nocheck
import React from 'react';
import { IconProps } from './types';

const RedUpArrow: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.87 11.88L4.37 11.88L4.37 5.88L0.74 5.88L6.62 -3.71464e-07L12.5 5.88L8.87 5.88L8.87 11.88Z"
      fill="#D32F2F"
    />
  </svg>
);

export default RedUpArrow;
