import { strings } from 'common';
import fetchBuilder from 'fetch-retry-ts';

let setRetryMessage = (msg: string) => '';

const fetchRetry = fetchBuilder(fetch, {
  retries: 3,
  retryDelay: (attempt: number): number => 2 ** attempt * 1000,
  retryOn: (
    attempt: number,
    retries: number,
    error: Error | null,
    response: Response | null
  ): boolean => {
    const tryAgain =
      attempt < retries && (!!error || !response || response.status >= 400);
    tryAgain
      ? setRetryMessage(strings.ENGAGE_MEDIA_RETRYING)
      : setRetryMessage('');
    return tryAgain;
  },
});

export async function fetchMedia(
  url: string,
  setInfo: (value: string) => string
): Promise<Response> {
  setRetryMessage = setInfo;
  return await fetchRetry(url);
}

export async function extractBlob(response: Response) {
  const blob = await response.blob();
  window.open(URL.createObjectURL(blob), '_blank');
}
