import { User } from 'models';

export const getUsersFullName = (emptyValue: string, user?: User) =>
  user && (user.firstName ?? user.lastName)
    ? `${user.firstName} ${user.lastName}`
    : emptyValue;

/**
 * This uses the logic from data-layer to compute the fullName of a user.
 * @param user
 */
export function getFullName({ firstName, lastName, companyName }: User) {
  return firstName || lastName
    ? `${firstName} ${lastName}`.trim()
    : companyName || 'Unnamed User';
}
