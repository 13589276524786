import React from 'react';
import Delete from '@material-ui/icons/Delete';

import permissions from 'common/permissions';
import {
  AttachmentIcon,
  ImageIcon,
} from 'components/shared/UploadPlaceholder/Icons';
import PermissionsGate from 'components/shared/PermissionsGate';
import { UploadItem } from 'store/uploads/types';
import UploadPlaceholder from 'components/shared/UploadPlaceholder';
import LoadingSpinner from 'components/shared/LoadingSpinner';

import './DocumentRow.scss';
import { getCalendarDateTimeStringFromDate } from 'utils/time';
import { VehicleAttachment } from 'models';

interface DocumentRowProps {
  onDeleteClick: (attachment: VehicleAttachment) => void;
  loading?: boolean;
  attachment: VehicleAttachment;
  upload?: UploadItem;
}

const DocumentRow: React.FC<DocumentRowProps> = ({
  attachment,
  onDeleteClick,
  loading = false,
  upload,
}) => {
  const renderIcon = () => {
    if (loading && !upload) {
      return (
        <div className="DocumentRow-loading-spinner">
          <LoadingSpinner />
        </div>
      );
    }
    if (loading && upload) {
      return <UploadPlaceholder upload={upload} showOverlay />;
    }
    switch (attachment.contentType) {
      case 'application/pdf':
        return <AttachmentIcon />;
      case 'image/png':
      case 'image/jpg':
      case 'image/jpeg':
        return <ImageIcon />;
      default:
        return <AttachmentIcon />;
    }
  };

  const viewDocument = () => {
    window.open(attachment.uri);
  };

  return (
    <div className="DocumentRow" role="none" onClick={viewDocument}>
      <div className="DocumentRow-icon-container">{renderIcon()}</div>
      <div className="DocumentRow-file-info">
        <div className="DocumentRow-title">{attachment.name}</div>
        {attachment.createdDate && (
          <div className="DocumentRow-subtitle">
            {getCalendarDateTimeStringFromDate(
              new Date(attachment.createdDate)
            )}
          </div>
        )}
      </div>
      <PermissionsGate
        permissions={[permissions.INVENTORY_VDP_ATTACHMENTS_DELETE]}
      >
        <Delete
          className="DocumentRow-delete-button"
          onClick={(event: any) => {
            event.stopPropagation();
            onDeleteClick(attachment);
          }}
        />
      </PermissionsGate>
    </div>
  );
};

export default DocumentRow;
