import { User } from '../utility/user';

export enum StepItemState {
  ACTIVE = 'ACTIVE',
  ACTIVE_PAUSED = 'ACTIVE_PAUSED',
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE',
  NOT_STARTED = 'NOT_STARTED',
  PENDING = 'PENDING',
  UNKNOWN = 'UNKNOWN',
}

export enum StepItemStatus {
  LATE = 'LATE',
  ONTIME = 'ONTIME',
  WARNING = 'WARNING',
  UNKNOWN = 'UNKNOWN',
}

export type StepItemMap = { [id: string]: StepItem };
export type StepItemLevelMap = { [id: string]: number };
export function mapStepsToLevels(
  items: StepItem[] = [],
  stepItemLevels: StepItemLevelMap = {},
  baseLevel = 0
) {
  items.forEach((item: StepItem) => {
    if (!item.id) return;

    stepItemLevels[item.id] = baseLevel;

    if (item.childSteps?.length) {
      stepItemLevels = {
        ...mapStepsToLevels(item.childSteps, stepItemLevels, 1),
      };
    }
  });

  return stepItemLevels;
}

export interface StepItem {
  id: string;
  assignedTo?: User;
  assignmentDate: string;
  childSteps?: StepItem[];
  name: string;
  recommendedDurationSeconds?: number;
  safeDurationSeconds?: number;
  state?: StepItemState;
  status?: StepItemStatus;
  isTerminal?: boolean; // TODO the data layer is causing this bad code here
  terminalStep?: boolean;
  timeInStepSeconds?: number;
  workingTimeSeconds?: number;
  idleTimeSeconds?: number;
  reconTimeInStepSeconds?: number;
  level?: number;
  enhancedStateTracking: boolean;
  allowCompleteRecon: boolean;
  nextStep?: {
    id: string;
    name: string;
  };
}

export type Workflow = StepItem[];
