import { useMutation, useQuery } from 'react-query';

import { APIResponse } from 'api';
import { defaultMutationFn } from 'api/useAPI';
import {
  DecodedVin,
  TaskTemplate,
  UIFilter,
  User,
  UpdateData,
  VehicleSummary,
} from 'models';
import { VehicleTag } from 'models/inventory/vehicleTag';
import { getLastQueryParamWithName } from '../../navigation/util/ParamHelpers';
import permissions from '../../common/permissions';
import { usePermissions } from 'hooks';
import { useQueryClient } from 'react-query';
import { useMatch } from '@reach/router';

export function useUsers() {
  const path = '/utility/users';
  return useQuery<APIResponse<User[]>>(path);
}

export function useGetVehicleColors() {
  const path = '/utility/vehicle/colors';
  return useQuery<string[]>(path);
}

export function useGetVehicleDispositions() {
  const path = '/utility/vehicle/dispositions';
  return useQuery<string[]>(path);
}

export default function useGetReconUsers() {
  const path = '/utility/users';
  return useQuery<APIResponse<User[]>>(path);
}

export function useInventoryTags() {
  const path = '/utility/tags';
  return useQuery<VehicleTag[]>(path);
}

/**
 * @package api/utility
 * @description - Get task templates as TaskTemplate[]
 */
export function useTaskTemplates() {
  const path = `/utility/tasks/templates`;
  return useQuery<APIResponse<TaskTemplate[]>>(path);
}

export function useGetVehicleImagesShots() {
  const path = `/utility/vehicle/imageshots`;
  return useQuery<{ name: string; value: string }[]>(path);
}

export function useSubmitFeedback() {
  return useMutation(
    (data: {
      subject: string;
      message: string;
      userName: string;
      userEmail: string;
      rooftop: string;
      userPhone?: string;
    }) => {
      const path = '/profile/feedback';
      return defaultMutationFn(path, 'POST', data);
    }
  );
}

export function useInventoryFilters() {
  const path = `/utility/inventory/filters`;
  return useQuery<UIFilter[]>(path);
}

export function useDecodeVin(vin: string, enabled: boolean) {
  const path = `/utility/vindecode/${vin}`;
  return useQuery<DecodedVin>(path, { enabled: enabled });
}

export function useVehicleYears() {
  const path = '/utility/vehicle/years';
  return useQuery<number[]>(path);
}

export function useVehicleMakesByYear(year: number | undefined) {
  const path = `/utility/vehicle/makes/${year}`;
  return useQuery<{ id: string; name: string; year: number }[]>(path, {
    enabled: !!year,
  });
}

export function useVehicleModelsByYearAndMake(
  year: number | undefined,
  makeId: string | undefined
) {
  const path = `/utility/vehicle/models/${year}/${makeId}`;
  return useQuery<
    {
      make: { id: string; name: string; year: number };
      id: string;
      name: string;
    }[]
  >(path, {
    enabled: !!(year && makeId),
  });
}

export function useUpdateData() {
  const path = `/utility/updated?minusSeconds=16`;
  const vdpMatch = useMatch('inventory/:inventoryId/*');
  const queryClient = useQueryClient();
  const { hasPermission } = usePermissions();

  return useQuery<UpdateData>(path, {
    enabled: !(vdpMatch && vdpMatch.inventoryId === 'background'),
    notifyOnChangeProps: ['data'],
    refetchInterval: 15000,
    onSuccess: async (data) => {
      if (data.changedVehicles.length > 0) {
        queryClient.invalidateQueries('/inventory', {
          // This will determine when useVehicleList Infinite Query page is reloaded
          // refetchPage function specifies if a page gets refreshed based on custom logic
          // https://react-query.tanstack.com/reference/QueryClient#queryclientinvalidatequeries
          refetchPage: (page: APIResponse<VehicleSummary[]>) => {
            //Invalidate page only if vehicle was found in collection;
            let refreshPage = false;
            data?.changedVehicles?.some((id) => {
              page?.data.some((item) => {
                if (id === item?.vehicleCard?.id) {
                  refreshPage = true;
                  return true;
                }
              });
            });
            return refreshPage;
          },
        });

        // Update preview panel if necessary
        const previewedVehicleId =
          getLastQueryParamWithName('preview')?.split(',')[0];
        if (
          previewedVehicleId &&
          data.changedVehicles.includes(previewedVehicleId)
        ) {
          queryClient.invalidateQueries(`/inventory/${previewedVehicleId}`);
          queryClient.invalidateQueries(
            `/inventory/${previewedVehicleId}/notes`
          );
        }

        if (hasPermission(permissions.RECON_VIEW)) {
          queryClient.invalidateQueries('/dashboard/goals');
        }

        // Update VDP if necessary
        if (
          vdpMatch &&
          vdpMatch.inventoryId &&
          data.changedVehicles.includes(vdpMatch.inventoryId)
        ) {
          queryClient.invalidateQueries(`/inventory/${vdpMatch.inventoryId}`);
          queryClient.invalidateQueries(
            `/inventory/${vdpMatch.inventoryId}/notes`
          );
        }
      }

      if (
        data.changedNotifications.length > 0 &&
        hasPermission(permissions.NOTIFICATIONS_VIEW)
      ) {
        queryClient.invalidateQueries('/notifications');
      }

      if (data?.changedCachedItems.length > 0) {
        queryClient.invalidateQueries('/utility/tags');
        queryClient.invalidateQueries('/utility/tasks/templates');
        queryClient.invalidateQueries('/utility/workflow/steps');
      }
    },
  });
}
