import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation, useMatch } from '@reach/router';

import permissions from 'common/permissions';
import Tabs from 'components/shared/Tabs';
import features from 'common/features';
import { DateRange } from 'common/DateRange';

import './Reports.scss';
import DealerStepReport from './DealerStepReport';
import CorporateReport from './CorporateReport';
import VelocityEngageCorporateReport from './VelocityEngageCorporateReport';
import ReportsDateFilter, { getDefaultDateRange } from './ReportsDateFilter';
import { useFeatures, usePermissions } from 'hooks';
import VelocityEngageModuleReport from './VelocityEngageModuleReport';

interface ReportsProps {
  path?: string;
}

enum ReportPaths {
  dealer = 'dealer-step',
  corporate = 'corporate',
  velocityEngageCorporate = 'velocityEngageCorporate',
  velocityEngageModule = 'velocityEngageModule',
}

const Reports: React.FC<ReportsProps> = (props) => {
  const { hasFeature } = useFeatures();
  const { hasPermission } = usePermissions();
  const location = useLocation();

  const [dateRange, setDateRange] = useState<DateRange>(getDefaultDateRange());

  const defaultTabPath = () => {
    if (hasPermission(permissions.REPORTS_STEP_REPORT_VIEW)) {
      return ReportPaths.dealer;
    }
    if (hasPermission(permissions.REPORTS_CORPORATE_REPORT_VIEW)) {
      return ReportPaths.corporate;
    }
    if (
      hasPermission(permissions.PLUGIN_VELOCITYENGAGE_CORPORATE_REPORT_VIEW) &&
      hasFeature(features.VELOCITY_ENGAGE_CORPORATE_REPORT)
    ) {
      return ReportPaths.velocityEngageCorporate;
    }

    return ReportPaths.dealer;
  };

  const matchesVelocityEngageModuleReport = useMatch(
    ReportPaths.velocityEngageModule
  );

  return (
    <div className="Reports page vertical-scroll">
      <div className="Reports-panel-container">
        <Container fluid>
          <Row noGutters className="no-margin">
            <Col className="Reports-overview">
              {!matchesVelocityEngageModuleReport && (
                <ReportsDateFilter
                  allowCustomRange={location.pathname
                    .split('/')
                    .includes(ReportPaths.dealer)}
                  onChange={setDateRange}
                />
              )}
              <Tabs
                basePath="reports"
                defaultPath={defaultTabPath()}
                tabContentClassName={
                  (matchesVelocityEngageModuleReport ? '' : 'bg-white') +
                  ' tab-container'
                }
                className="full-width"
              >
                {hasPermission(permissions.REPORTS_STEP_REPORT_VIEW) && (
                  <DealerStepReport
                    path={ReportPaths.dealer}
                    title="Dealer Step Report"
                    dateRange={dateRange}
                  />
                )}
                {hasPermission(permissions.REPORTS_CORPORATE_REPORT_VIEW) && (
                  <CorporateReport
                    path={ReportPaths.corporate}
                    title="Corporate Report"
                    dateRange={dateRange}
                  />
                )}
                {hasPermission(
                  permissions.PLUGIN_VELOCITYENGAGE_CORPORATE_REPORT_VIEW
                ) &&
                  hasFeature(features.VELOCITY_ENGAGE_CORPORATE_REPORT) && (
                    <VelocityEngageCorporateReport
                      path={ReportPaths.velocityEngageCorporate}
                      title="Velocity Engage Corporate Report"
                      dateRange={dateRange}
                    />
                  )}
                {hasPermission(permissions.PLUGIN_VELOCITYENGAGE_VIEW) &&
                  hasFeature(features.VELOCITY_ENGAGE_MODULE_REPORT) && (
                    <VelocityEngageModuleReport
                      path={ReportPaths.velocityEngageModule}
                      title="Velocity Engage Module Report"
                    />
                  )}
              </Tabs>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Reports;
