import React, { useCallback, useState } from 'react';
import { ChevronLeft, Print } from '@material-ui/icons';
import IconButton from 'components/shared/IconButton';

import strings from 'common/strings';
import permissions from 'common/permissions';
import Pane from 'components/shared/Pane';
import FlatVDPPaneHeader from 'components/shared/FlatVDPPaneHeader';
import { Pin } from 'components/shared/icons';
import PermissionsGate from 'components/shared/PermissionsGate';
import { useWindowSize } from 'hooks';

import { Checklist } from 'models';

import MpiChecklist from './Components/MpiChecklist';
import MpiFooter from './Components/MpiFooter';
import MpiChecklistMenu from './Components/MpiChecklistMenu';

import './MultipointInspection.scss';
import { PinnableComponentType } from '../VehicleDetails/PinnedComponent';

interface MultipointInspectionProps {
  vehicleId: string;
  pinned?: boolean;
  setCustomHeaderTitle?: (title: string) => void;
  setBackAction?: (backAction: () => void) => void;
  openPinnedComponent?: (componentName: PinnableComponentType) => void;
}

var MultipointInspection = ({
  vehicleId,
  pinned = false,
  setCustomHeaderTitle,
  setBackAction,
  openPinnedComponent,
}: MultipointInspectionProps) => {
  // TODO refactor PinnedComponentState
  const [activeChecklist, setActiveChecklist] = useState<Checklist | null>();
  const windowSize = useWindowSize();

  // TODO if windowSize.isVdpLargeViewport() shows tasks
  // useEffect(() => {
  //   // TODO this is false the first time...
  //   if (windowSize.isVdpLargeViewport()) {
  //     // TODO this function causes a navigation away from inspection
  //     pinnedComponentState.pinComponent('tasks');
  //   }
  // }, []);

  const PinnedBackButton = useCallback(() => {
    if (pinned) {
      return (
        <button
          type="button"
          className="Mpi-pinned-back-button"
          onClick={() => setActiveChecklist(null)}
        >
          <ChevronLeft className="Mpi-pinned-back-button-icon" />
          {activeChecklist?.name || strings.MULTIPOINT_INSPECTION}
        </button>
      );
    }
    return null;
  }, [pinned, activeChecklist?.name, setActiveChecklist]);

  const print = useCallback(() => {
    try {
      const css = '@page { size: landscape; }';
      const head = document.head || document.getElementsByTagName('head')[0];
      const style = document.createElement('style');

      style.type = 'text/css';
      style.media = 'print';

      if (style.sheet) {
        style.sheet.insertRule(css);
      } else {
        style.appendChild(document.createTextNode(css));
      }

      head.appendChild(style);
      window.print();
    } catch (error) {
      // TODO display error
    }
  }, []);

  const renderChecklistOrMenu = useCallback(() => {
    if (activeChecklist?.id) {
      return (
        <>
          <PinnedBackButton />
          <MpiChecklist
            checklistId={activeChecklist.id}
            vehicleId={vehicleId}
          />
        </>
      );
    }

    return (
      <MpiChecklistMenu
        vehicleId={vehicleId}
        onClick={(checklist) => {
          setActiveChecklist(checklist);
          if (setCustomHeaderTitle && setBackAction) {
            setCustomHeaderTitle(activeChecklist?.name || '');
            setBackAction(() => setActiveChecklist(null));
          }
        }}
      />
    );
  }, [
    activeChecklist?.id,
    activeChecklist?.name,
    vehicleId,
    PinnedBackButton,
    setCustomHeaderTitle,
    setBackAction,
  ]);

  return (
    <Pane
      header={
        <FlatVDPPaneHeader
          className="MpiPane-Header border-bottom"
          vehicleId={vehicleId}
          shouldNavOnBackClick={() => {
            if (activeChecklist) {
              setActiveChecklist(null);
              return false;
            }
            return true;
          }}
        >
          <div className="MpiPane-StateTitle">
            {!activeChecklist ? (
              strings.MULTI_POINT_INSPECTION
            ) : (
              <span>
                <span
                  role="none"
                  onClick={() => {
                    setActiveChecklist(null);
                  }}
                  className="text-muted hide-mobile pointer"
                >
                  {strings.MULTI_POINT_INSPECTION}
                </span>
                <span className="text-muted mx-1 hide-mobile">/</span>
                <span>{activeChecklist?.name}</span>
              </span>
            )}
          </div>
          <div>
            {windowSize.isVdpLargeViewport() && openPinnedComponent && (
              <button
                type="button"
                className="MpiPane-Header-pin-button"
                onClick={() => openPinnedComponent('mpi')}
              >
                <Pin size={35} />
              </button>
            )}
            {activeChecklist && (
              <IconButton
                iconComponent={
                  <Print className="MpiPane-Header-print-button-icon" />
                }
                onClick={print}
              />
            )}
          </div>
        </FlatVDPPaneHeader>
      }
      footer={
        <MpiFooter
          checklistId={activeChecklist?.id ?? ''}
          vehicleId={vehicleId}
        />
      }
      className="MpiPane"
    >
      <div className="MpiPane-body h-100">
        <PermissionsGate permissions={[permissions.RECON_VDP_CHECKLISTS_VIEW]}>
          {renderChecklistOrMenu()}
        </PermissionsGate>
      </div>
    </Pane>
  );
};

export default MultipointInspection;
