import React from 'react';
import { Section, VehicleSummary } from 'models';
import { navigate } from '@reach/router';
import { useQueryClient } from 'react-query';

import Chip from '@material-ui/core/Chip';
import {
  Assignment,
  AssignmentOutlined,
  AssignmentTurnedIn,
  AttachFile,
  AttachMoney,
  Build,
  Chat,
  Comment,
  DirectionsCar,
  FolderOpen,
  History,
  LocationOn,
  PhotoLibrary,
  Warning,
} from '@material-ui/icons';

import strings from 'common/strings';
import {
  historyBuilder,
  locationBuilder,
  engageBuilder,
  ENGAGE_RELATIVE,
  MPI_RELATIVE,
  mpiBuilder,
  OVERVIEW_RELATIVE,
  notesBuilder,
  mediaBuilder,
  tasksBuilder,
  vehicleInfoBuilder,
  recallsBuilder,
  documentsBuilder,
  overviewBuilder,
  repairOrderBuilder,
} from 'navigation/routes';
import {
  RVLogo,
  VelocityLocateIcon,
  VelocityEngageIcon,
} from 'components/shared/icons';
import { useGenerateVelocityLocateSSOUrlForVehicle } from 'api';

import './VehicleMenuRow.scss';
import { testIds, generateTestId } from 'common/testIds';

const iconMapList = [
  {
    name: strings.RECON_VELOCITY,
    relativeName: OVERVIEW_RELATIVE,
    iconName: 'velocity',
    navigateTo: (vehicleId: string) => overviewBuilder(vehicleId, true),
  },
  {
    name: strings.VELOCITY_ENGAGE,
    relativeName: ENGAGE_RELATIVE,
    iconName: 'velocity_engage',
    navigateTo: (vehicleId: string) => engageBuilder(vehicleId, true),
  },
  {
    name: strings.INSPECTION,
    relativeName: MPI_RELATIVE,
    iconName: 'assignment_outline',
    navigateTo: (vehicleId: string) => mpiBuilder(vehicleId, true),
  },
  {
    name: strings.VEHICLE_INFORMATION,
    iconName: 'directions_car',
    navigateTo: (vehicleId: string) => vehicleInfoBuilder(vehicleId, true),
  },
  {
    name: strings.REPAIR_ORDERS,
    iconName: 'build',
    navigateTo: (vehicleId: string) => repairOrderBuilder(vehicleId, true),
  },
  {
    name: strings.TASKS,
    iconName: 'briefcase_check',
    navigateTo: (vehicleId: string) => tasksBuilder(vehicleId, true),
  },
  {
    name: strings.RECALLS,
    iconName: 'warning',
    navigateTo: (vehicleId: string) => recallsBuilder(vehicleId, true),
  },
  {
    name: strings.NOTES,
    iconName: 'comment',
    navigateTo: (vehicleId: string) => notesBuilder(vehicleId, true),
  },
  {
    name: strings.LOCATION,
    iconName: 'location_on',
    navigateTo: (vehicleId: string) => locationBuilder(vehicleId, true),
  },
  {
    name: strings.VELOCITY_LOCATE,
    iconName: 'velocity_locate',
    navigateTo: (vehicleId: string) => locationBuilder(vehicleId, true),
  },
  {
    name: strings.MEDIA,
    iconName: 'folder_open',
    navigateTo: (vehicleId: string) => mediaBuilder(vehicleId, true),
  },
  {
    name: strings.MEDIA_AND_DOCS,
    iconName: 'folder_open',
    navigateTo: (vehicleId: string) => mediaBuilder(vehicleId, true),
  },
  {
    name: strings.DOCUMENTS,
    iconName: 'attach_file',
    navigateTo: (vehicleId: string) => documentsBuilder(vehicleId, true),
  },
  {
    name: strings.HISTORY,
    iconName: 'history',
    navigateTo: (vehicleId: string) => historyBuilder(vehicleId, true),
  },
];

const getIconObj = (id: string) => {
  const iconObj = iconMapList.find((item) => item.name === id);

  return iconObj || iconMapList[0];
};

interface VehicleMenuRowProps {
  section: Section;
  vehicle: VehicleSummary;
  selected?: boolean;
  showLabel?: boolean;
}

var VehicleMenuRow = ({
  section,
  vehicle,
  selected,
  showLabel = true,
}: VehicleMenuRowProps) => {
  const queryClient = useQueryClient();
  const isVelocityLocate = section.label === strings.VELOCITY_LOCATE_MENU_LABEL;
  const { data: { data: velocityLocateSSOUrl } = {} } =
    useGenerateVelocityLocateSSOUrlForVehicle(
      vehicle.vehicleCard.vin,
      isVelocityLocate
    );
  if (section.id === 'documents') {
    return null;
  }

  const { alertCount } = section;
  let { label } = section;
  let Icon = Assignment;
  let dataVasTesting = '';
  const iconObj = getIconObj(label);
  switch (iconObj.iconName) {
    case 'assignment_outline':
      Icon = AssignmentOutlined;
      break;
    case 'attach_file':
      Icon = AttachFile;
      break;
    case 'attach_money':
      Icon = AttachMoney;
      break;
    case 'briefcase_check':
      Icon = AssignmentTurnedIn;
      break;
    case 'build':
      Icon = Build;
      break;
    case 'chat':
      Icon = Chat;
      break;
    case 'comment':
      Icon = Comment;
      break;
    case 'directions_car':
      Icon = DirectionsCar;
      break;
    case 'folder_open':
      Icon = FolderOpen;
      break;
    case 'history':
      Icon = History;
      break;
    case 'location_on':
      Icon = LocationOn;
      break;
    case 'photo_library':
      Icon = PhotoLibrary;
      break;
    case 'warning':
      Icon = Warning;
      break;
    case 'velocity_locate':
      Icon = VelocityLocateIcon as any;
      break;
    case 'velocity':
      Icon = RVLogo as any;
      break;
    case 'velocity_engage':
      Icon = VelocityEngageIcon as any;
      dataVasTesting = 'rv-vdp-velocityEngage-menu';
      break;
    default:
      break;
  }

  const handleNavigation = async () => {
    if (section.id === 'velocityLocate') {
      window.open(velocityLocateSSOUrl, '_newtab');
      await queryClient.invalidateQueries(
        `/plugins/velocitylocate/generateSSOUrl?vin=${encodeURIComponent(
          vehicle.vehicleCard.vin
        )}`
      );
      return;
    }
    navigate(iconObj.navigateTo(vehicle.vehicleCard.id!), {
      replace: true,
    });
  };

  const isCustomOversize = () =>
    iconObj.iconName === 'velocity' || iconObj.iconName === 'velocity_engage'
      ? 'oversized-icon'
      : '';

  if (label === strings.MEDIA) {
    label = strings.MEDIA_AND_DOCS;
  }

  return (
    <div
      role="none"
      className={`VehicleMenuRow-flat ${selected ? 'selected' : null} no-wrap`}
      onClick={handleNavigation}
      data-vas-testing={dataVasTesting}
    >
      <Icon className={`VehicleMenuRow-icon ${isCustomOversize()}`}>
        {iconObj.iconName}
      </Icon>
      {showLabel && (
        <span
          className="VehicleMenuRow-label text-ellipsis"
          data-vas-testing={generateTestId(testIds.VDP_MENU_ITEM, {
            label: `${label}`,
          })}
        >
          {label}
        </span>
      )}
      {!!alertCount && (
        <div className="VehicleMenuRow-chip">
          <Chip label={alertCount} className="menu-icon error font-bold" />
        </div>
      )}
    </div>
  );
};

export default VehicleMenuRow;
