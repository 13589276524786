import React, { useState } from 'react';
import { Section, VehicleSummary } from 'models';
import { WindowLocation } from '@reach/router';
import {
  Close,
  Apps,
  Comment,
  AssignmentOutlined,
  Warning,
  AssignmentTurnedIn,
  FolderOpen,
  AttachFile,
  History,
  ArrowBack,
  LocationOn,
  Build,
} from '@material-ui/icons';

import {
  LOCATION_RELATIVE,
  MPI_RELATIVE,
  NOTES_RELATIVE,
  MEDIA_RELATIVE,
  RECON_INFO_RELATIVE,
  TASKS_RELATIVE,
  VEHICLE_INFO_RELATIVE,
  DOCUMENTS_RELATIVE,
  RECALLS_RELATIVE,
  CHECKLIST_RELATIVE,
  ORDERS_RELATIVE,
  OVERVIEW_RELATIVE,
  HISTORY_RELATIVE,
  VELOCITY_ENGAGE_RELATIVE,
  REPAIR_ORDERS_RELATIVE,
  overviewBuilder,
  notesBuilder,
  mpiBuilder,
  recallsBuilder,
  locationBuilder,
  tasksBuilder,
  mediaBuilder,
  historyBuilder,
  vehicleDetailsBuilder,
  engageBuilder,
  repairOrderBuilder,
} from 'navigation/routes';
import strings from 'common/strings';
import {
  RVLogoSmall,
  MPIIcon,
  VelocityLocateIcon,
  VelocityEngageIcon,
} from 'components/shared/icons';
import features from 'common/features';
import UploadManager from 'components/shared/UploadManager';
import MenuItem from './MenuItem';

import './MobileVDPHeader.scss';
import { useFeatures } from 'hooks';

const getSelectedMenuOption = (pathname: string) => {
  const pathArr = pathname.split('/');
  if (pathArr.indexOf(OVERVIEW_RELATIVE) >= 0) {
    return OVERVIEW_RELATIVE;
  }
  if (pathArr.indexOf(VELOCITY_ENGAGE_RELATIVE) >= 0) {
    return VELOCITY_ENGAGE_RELATIVE;
  }
  if (pathArr.indexOf(NOTES_RELATIVE) >= 0) {
    return NOTES_RELATIVE;
  }
  if (pathArr.indexOf(VEHICLE_INFO_RELATIVE) >= 0) {
    return VEHICLE_INFO_RELATIVE;
  }
  if (pathArr.indexOf(RECON_INFO_RELATIVE) >= 0) {
    return RECON_INFO_RELATIVE;
  }
  if (pathArr.indexOf(MPI_RELATIVE) >= 0) {
    return MPI_RELATIVE;
  }
  if (pathArr.indexOf(CHECKLIST_RELATIVE) >= 0) {
    return CHECKLIST_RELATIVE;
  }
  if (pathArr.indexOf(TASKS_RELATIVE) >= 0) {
    return TASKS_RELATIVE;
  }
  if (pathArr.indexOf(MEDIA_RELATIVE) >= 0) {
    return MEDIA_RELATIVE;
  }
  if (pathArr.indexOf(LOCATION_RELATIVE) >= 0) {
    return LOCATION_RELATIVE;
  }
  if (pathArr.indexOf(DOCUMENTS_RELATIVE) >= 0) {
    return DOCUMENTS_RELATIVE;
  }
  if (pathArr.indexOf(RECALLS_RELATIVE) >= 0) {
    return RECALLS_RELATIVE;
  }
  if (pathArr.indexOf(ORDERS_RELATIVE) >= 0) {
    return ORDERS_RELATIVE;
  }
  if (pathArr.indexOf(HISTORY_RELATIVE) >= 0) {
    return HISTORY_RELATIVE;
  }
  if (pathArr.indexOf('repairOrders') >= 0) {
    return REPAIR_ORDERS_RELATIVE;
  }
  if (pathArr.indexOf('velocityLocate') >= 0) {
    return 'velocityLocate';
  }
  return OVERVIEW_RELATIVE;
};

export interface MobileHeaderData {
  title: string;
  icon?: React.ReactElement;
  navigateTo: (vehicleId: string) => string;
}

const mobileHeaderData: Record<string, MobileHeaderData> = {
  overview: {
    title: strings.RECON_VELOCITY,
    icon: <RVLogoSmall />,
    navigateTo: (vehicleId: string) => overviewBuilder(vehicleId, true),
  },
  velocityEngage: {
    title: strings.VELOCITY_ENGAGE,
    icon: <VelocityEngageIcon />,
    navigateTo: (vehicleId: string) => engageBuilder(vehicleId, true),
  },
  notes: {
    title: strings.NOTES,
    icon: <Comment />,
    navigateTo: (vehicleId: string) => notesBuilder(vehicleId, true),
  },
  mpi: {
    title: strings.MPI,
    icon: <MPIIcon />,
    navigateTo: (vehicleId: string) => mpiBuilder(vehicleId, true),
  },
  // Need to handle different id of VehicleSection
  // but this is the same as MPI
  checklist: {
    title: strings.MPI,
    icon: <AssignmentOutlined />,
    navigateTo: (vehicleId: string) => mpiBuilder(vehicleId, true),
  },
  recalls: {
    title: strings.RECALLS,
    icon: <Warning />,
    navigateTo: (vehicleId: string) => recallsBuilder(vehicleId, true),
  },
  [REPAIR_ORDERS_RELATIVE]: {
    title: strings.REPAIR_ORDERS,
    icon: <Build />,
    navigateTo: (vehicleId: string) => repairOrderBuilder(vehicleId, true),
  },
  location: {
    title: strings.LOCATION,
    icon: <LocationOn />,
    navigateTo: (vehicleId: string) => locationBuilder(vehicleId, true),
  },
  tasks: {
    title: strings.TASKS,
    icon: <AssignmentTurnedIn />,
    navigateTo: (vehicleId: string) => tasksBuilder(vehicleId, true),
  },
  media: {
    title: strings.MEDIA_AND_DOCS,
    icon: <FolderOpen />,
    navigateTo: (vehicleId: string) => mediaBuilder(vehicleId, true),
  },
  documents: {
    title: strings.DOCUMENTS,
    icon: <AttachFile />,
    navigateTo: (vehicleId: string) => mediaBuilder(vehicleId, true),
  },
  history: {
    title: strings.HISTORY,
    icon: <History />,
    navigateTo: (vehicleId: string) => historyBuilder(vehicleId, true),
  },
  velocityLocate: {
    title: strings.VELOCITY_LOCATE,
    icon: <VelocityLocateIcon />,
    navigateTo: (vehicleId: string) =>
      `${vehicleDetailsBuilder(vehicleId)}/locate`,
  },
};

const RV_SERVICE_SECTIONS = [
  'velocityEngage',
  'overview',
  'vehicleInfo',
  'velocityLocate',
];
const MENU_ITEM_HEIGHT = 42;
const MENU_DIVIDER_HEIGHT = 1;
const MENU_PADDING = 14;

interface MobileVDPHeaderProps {
  onCloseVDP: () => void;
  onChangeVDPPage: () => void;
  location?: WindowLocation;
  vehicle: VehicleSummary;
  customTitle?: string;
  backAction?: () => void;
  resetBackAction?: () => void;
  sections?: Section[];
  showCloseButton: boolean;
}

const MobileVDPHeader: React.FC<MobileVDPHeaderProps> = ({
  customTitle,
  onChangeVDPPage,
  onCloseVDP,
  location,
  vehicle,
  backAction,
  resetBackAction,
  sections,
  showCloseButton,
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const getMobileHeaderData = (): MobileHeaderData => {
    const activeSection = getSelectedMenuOption(location!.pathname);
    if (activeSection) {
      return mobileHeaderData[activeSection];
    }
    // Default return overview
    return mobileHeaderData[OVERVIEW_RELATIVE];
  };

  const onItemClick = () => {
    setMobileMenuOpen(false);
    onChangeVDPPage();
  };

  const { title, icon } = getMobileHeaderData();
  const titleToDisplay = customTitle || title;
  const iconToDisplay = customTitle ? null : icon;
  const CloseIconToDisplay = customTitle ? ArrowBack : Close;
  const menuClassName = mobileMenuOpen
    ? 'MobileVDPHeader-menuOpen'
    : 'MobileVDPHeader-menuClosed';

  const rvServiceSections = sections?.filter((vehicleSection: Section) =>
    RV_SERVICE_SECTIONS.includes(vehicleSection.id)
  );
  const nonRvServiceSections = sections?.filter((vehicleSection: Section) => {
    // TODO: Once API stops sending the Docs section we can remove this
    if (vehicleSection.id === 'documents') return false;
    return !RV_SERVICE_SECTIONS.includes(vehicleSection.id);
  });

  const numMenuItems =
    (rvServiceSections?.length || 0) + (nonRvServiceSections?.length || 0);
  const openMenuHeight =
    numMenuItems * MENU_ITEM_HEIGHT + MENU_DIVIDER_HEIGHT + MENU_PADDING * 2;
  const menuHeight = mobileMenuOpen ? openMenuHeight : 0;
  const { hasFeature } = useFeatures();

  return (
    <div className="MobileVDPHeader">
      <div className="MobileVDPHeader-close box">
        {showCloseButton && (
          <CloseIconToDisplay
            className="MobileVDPHeader-close-icon"
            onClick={() => {
              if (backAction && resetBackAction) {
                backAction();
                resetBackAction();
                return;
              }
              if (mobileMenuOpen) {
                setMobileMenuOpen(false);
                return;
              }
              onCloseVDP();
            }}
          />
        )}
      </div>
      <div className="MobileVDPHeader-title box">
        {iconToDisplay && (
          <div className="MobileVDPHeader-title-icon">{iconToDisplay}</div>
        )}
        {titleToDisplay}
      </div>
      <div className="MobileVDPHeader-menu-button box">
        {hasFeature(features.BIG_FILE_UPLOAD) && <UploadManager />}
        <Apps
          className="MobileVDPHeader-menu-button-icon"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        />
      </div>
      <div
        style={{ height: menuHeight }}
        className={`MobileVDPHeader-menu ${menuClassName}`}
      >
        {rvServiceSections?.map((vehicleSection: Section) => {
          const sectionDataKey = getSelectedMenuOption(vehicleSection.id);
          const sectionData = mobileHeaderData[sectionDataKey];
          return (
            <MenuItem
              key={`rv-service-section-${vehicleSection.id}`}
              section={vehicleSection}
              sectionData={sectionData}
              vehicle={vehicle}
              onClick={onItemClick}
            />
          );
        })}
        <div className="MobileVDPHeader-menu-divider" />
        {nonRvServiceSections?.map((section: Section) => {
          // TODO: Once API stops sending the Docs section we can remove this
          if (section.id === 'documents') return null;
          const sectionDataKey = getSelectedMenuOption(section.id);
          const sectionData = mobileHeaderData[sectionDataKey];
          return (
            <MenuItem
              key={`non-rv-service-section-${section.id}`}
              section={section}
              sectionData={sectionData}
              vehicle={vehicle}
              onClick={onItemClick}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MobileVDPHeader;
