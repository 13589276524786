import React from 'react';

import './ActiveReconSummary.scss';

import StepReportSummaryItem from 'models/stepReportSummaryItem';
import { getFormattedDurationFromSeconds } from 'utils/time';
import { navigate } from '@reach/router';
import { RECON_RELATIVE } from 'navigation/routes';
import { useCurrentLocationId } from 'hooks';

interface ActiveReconSummaryProps {
  item: StepReportSummaryItem;
  type: string;
}

const ActiveReconSummary: React.FC<ActiveReconSummaryProps> = ({
  item,
  type,
}: ActiveReconSummaryProps) => {
  const overage = item.stepTimeAsSeconds - item.stepGoalAsSeconds;
  const status = overage > 0 ? 'bad' : 'neutral';

  const overageValue =
    overage > 0 ? getFormattedDurationFromSeconds(overage) : 'None';

  const rooftopId = useCurrentLocationId();
  const navToActiveRecon = () => {
    const route = `/${rooftopId}/${RECON_RELATIVE}?stepId=${item.stepId}`;
    navigate(route);
  };

  return (
    <>
      <div
        className="ActiveReconSummary-item-row flex-columns full-width"
        tabIndex={-1}
        role="button"
        onClick={() => navToActiveRecon()}
      >
        <div className="w-25">
          <div className={`ActiveReconSummary-${type}-name-container overflow`}>
            <span className="item-centered-vertically blue-text overflow">
              {item.stepName}
            </span>
          </div>
        </div>
        <div className=" w-75 evenly-spaced-container">
          <div className="evenly-spaced-item">
            <span className="item-centered-vertically blue-text">
              {item.totalVehicles}
            </span>
          </div>
          <div className="evenly-spaced-item">
            <span className="item-centered-vertically blue-text">
              {getFormattedDurationFromSeconds(item.stepGoalAsSeconds)}
            </span>
          </div>
          <div className="evenly-spaced-item">
            <span className="item-centered-vertically blue-text">
              {getFormattedDurationFromSeconds(item.stepTimeAsSeconds)}
            </span>
          </div>
          <div className="evenly-spaced-item">
            <span className={'item-centered-vertically overage-' + status}>
              {overageValue}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveReconSummary;
