import { RawDraftContentBlock, RawDraftContentState } from 'draft-js';
import Linkify from 'react-linkify';

interface ParseMessageObj {
  mentionStrings: string[] | undefined;
  urlStrings: string[] | undefined;
  parts: string[];
}

interface ParseNoteMessageObj {
  mentionStrings: string[] | undefined;
  parts: string[];
}

export function parseNoteMessage(message: string): ParseNoteMessageObj {
  const mentions = message.match(/\[(.*?)\]/g);

  const mentionStrings = mentions?.map((item) =>
    item.toString().replace('[', '').replace(']', '')
  );
  const parts = message.split(/\[(.*?)\]/g);

  return {
    mentionStrings,
    parts,
  };
}

export function getParsedMessage(message: string) {
  const { parts, mentionStrings } = parseNoteMessage(message);

  return parts.map((part: string, idx: number) => {
    if (mentionStrings?.includes(part)) {
      const matchParts = part.split(':');
      if (matchParts.length > 1) {
        return (
          <span key={idx.toString()} className="text-danger font-weight-bold">
            @{matchParts[1]}
          </span>
        );
      }
      return part;
    }

    if (part.includes('/n')) {
      return part.split('/n').map((line: any) => (
        <span key={idx.toString()}>
          <Linkify>{`${line.replace('@', '')}`}</Linkify>
          <br />
        </span>
      ));
    }

    return <Linkify key={idx.toString()}>{`${part.replace('@', '')}`}</Linkify>;
  });
}

export function parseMessage(message: string): ParseMessageObj {
  const mentions = message.match(/\[(.*?)\]/g);

  const mentionStrings = mentions?.map((item) =>
    item.toString().replace('[', '').replace(']', '')
  );

  const topLevelParts = message.split(/\[(.*?)\]/g);

  // eslint-disable-next-line no-useless-escape
  const urlRegex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
  const urlStrings = message.match(urlRegex)?.map((item) => item.toString());

  const parts: string[] = [];
  topLevelParts.forEach((topLevelPart) => {
    const childParts = topLevelPart.split(' ');
    childParts.forEach((childPart) => {
      if (childPart) {
        parts.push(childPart);
      }
    });
  });

  return {
    mentionStrings,
    urlStrings,
    parts,
  };
}

export function convertStringToRawState(text: string): RawDraftContentState {
  const lines = text.split('/n');
  let entityKey = 0;
  const entityMap: any = {};

  const blockArray = lines.map((lineText: string): RawDraftContentBlock => {
    const { mentionStrings, parts } = parseNoteMessage(lineText);
    let text = '';
    const entityRanges: any = [];

    parts.forEach((part: string) => {
      entityKey++;
      if (mentionStrings?.includes(part)) {
        const matchParts = part.split(':');
        const name = matchParts[1];

        if (matchParts.length > 1) {
          const mentionStringWithAt = `@${name}`;
          text += mentionStringWithAt;

          return;
        }

        text += part;
      }

      text += part.replace('@', '');
    });

    if (mentionStrings) {
      const mentionsUsedMap: any = {};

      mentionStrings.forEach((part: string, idx: number) => {
        const matchParts = part.split(':');
        const id = matchParts[0];
        const name = matchParts[1];

        /*
          This keeps track of which mention we need to find in the
          case that there are multiple mentions for the same user
        */
        if (!mentionsUsedMap[id]) {
          mentionsUsedMap[id] = 1;
        } else {
          mentionsUsedMap[id]++;
        }

        const mentionStringWithAt = `@${name}`;
        const offset = getOffset(
          text,
          mentionStringWithAt,
          mentionsUsedMap[id]
        );

        const entity = {
          type: 'mention',
          mutability: 'SEGMENTED',
          data: { mention: { name, id } },
        };

        const entityRange = {
          key: idx,
          offset,
          length: mentionStringWithAt.length,
        };

        entityMap[idx] = entity;
        entityRanges.push(entityRange);
      });
    }

    return {
      inlineStyleRanges: [],
      entityRanges,
      depth: 0,
      type: 'unstyled',
      text,
      key: entityKey.toString(),
    };
  });

  return {
    blocks: blockArray,
    entityMap,
  };
}

function getOffset(string: string, subString: string, idx: number) {
  return string.split(subString, idx).join(subString).length;
}

export function addMiddleElipse(str: string) {
  if (str.length > 35) {
    return str.substr(0, 20) + '...' + str.substr(str.length - 10, str.length);
  }
  return str;
}
