import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { navigate } from '@reach/router';

import strings from 'common/strings';
import { currencyFormatter } from 'utils/formatter';
import permissions from 'common/permissions';
import { useFeatures, usePermissions, useWindowSize } from 'hooks';
import { overviewBuilder } from 'navigation/routes';
import { getDaysInInventory } from 'utils/vehicle';
import { VehicleCardContext } from '../../VehicleCard';
import VehicleCardTabFooter from './VehiceCardTabFooter/VehicleCardTabFooter';
import features from 'common/features';
import LabelValue from 'components/shared/LabelValue';

type InventoryFinanceMetric = 'price' | 'cost' | 'profit';

var TabButtonsRow = (handleClickNav: (clickSource: string) => void) => {
  const windowSize = useWindowSize();
  const { inExtensionCardMode } = useContext(VehicleCardContext);

  if (windowSize.width < 1350) {
    return (
      !inExtensionCardMode && (
        <Button
          className="view-vehicle"
          variant="outline-primary"
          block
          onClick={() => handleClickNav('VehicleCard-step-buttons-container')}
        >
          {strings.VIEW_VEHICLE.toUpperCase()}
        </Button>
      )
    );
  }

  return (
    <Button
      as="button"
      className="see-more-button"
      variant="link"
      onClick={() => handleClickNav('VehicleCard-step-buttons-container')}
    >
      <span>View more</span>
      <ChevronRightIcon />
    </Button>
  );
};

var VehicleCardInventoryDetails = () => {
  const { vehicle, inExtensionCardMode } = useContext(VehicleCardContext);
  const { hasPermission } = usePermissions();
  const { hasFeature } = useFeatures();

  const vehicleId = vehicle?.vehicleCard?.id ?? '';
  const daysInInventory = vehicle && getDaysInInventory(vehicle);
  const vehicleInventoryFinanceCurrency = (
    metric: InventoryFinanceMetric
  ): string => {
    const value = vehicle?.[metric]?.amount;
    if (!value) {
      return '';
    }
    const currency = vehicle?.[metric]?.currency || strings.DEFAULT_CURRENCY;
    return currency === 'USD' ? '' : currency;
  };

  const vehicleInventoryFinanceValue = (
    metric: InventoryFinanceMetric
  ): string => {
    if (!hasPermission(permissions.INVENTORY_VDP_RECON_INFO_VIEW))
      return strings.EMPTY_VALUE;
    const value = vehicle?.[metric]?.amount;
    return value ? currencyFormatter(value) : strings.EMPTY_VALUE;
  };

  const handleClickNavToRecon = (clickSource: string): Promise<void> | null => {
    if (vehicleId) {
      return navigate(`${overviewBuilder(vehicleId, true)}`);
    }
    return null;
  };

  const hasUIPreview = hasFeature(features.UI_PREVIEW);
  return (
    <div className="VehicleCard-tab-container">
      <LabelValue label={strings.SALES_PRICE} labelCaps>
        <span>{vehicleInventoryFinanceValue('price')}</span>
        <small className="mr-1">
          {vehicleInventoryFinanceCurrency('price')}
        </small>
      </LabelValue>

      <LabelValue label={strings.COST} labelCaps>
        <span>{vehicleInventoryFinanceValue('cost')}</span>
        <small className="mr-1">
          {vehicleInventoryFinanceCurrency('cost')}
        </small>
      </LabelValue>

      <LabelValue label={strings.PROFIT} labelCaps>
        <span className="font-weight-600">
          {vehicleInventoryFinanceValue('profit')}
        </span>
        <small className="mr-1">
          {vehicleInventoryFinanceCurrency('profit')}
        </small>
      </LabelValue>

      <LabelValue label={strings.DAYS_IN_INVENTORY} labelCaps>
        {daysInInventory ? daysInInventory : strings.EMPTY_VALUE}
      </LabelValue>

      {!inExtensionCardMode && hasUIPreview ? (
        <VehicleCardTabFooter
          vehicle={vehicle}
          navClick={handleClickNavToRecon}
          showRecon={true}
        />
      ) : (
        <div className="VehicleCardTabFooter">
          {TabButtonsRow(handleClickNavToRecon)}
        </div>
      )}
    </div>
  );
};

export default VehicleCardInventoryDetails;
