import React from 'react';
import { components } from 'react-select';
import ArrowDropDownSharpIcon from '@material-ui/icons/ArrowDropDownSharp';

var CaretDownIcon = () => {
  return <ArrowDropDownSharpIcon />;
};

var DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
