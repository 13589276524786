import { useAddNote } from 'api';
import { permissions } from 'common';
import MentionableInput from 'components/pages/Notes/MentionableInput';
import SendIconButton from 'components/pages/Notes/SendIconButton';
import { getParsedMessage } from 'components/pages/Notes/utils';
import PermissionsGate from 'components/shared/PermissionsGate';
import { NoteV2 } from 'models';
import { useEffect, useMemo, useRef, useState } from 'react';
import './VehicleCardNotes.scss';

interface VehicleCardNotesProps {
  vehicleId: string;
  notes: NoteV2[];
  showHeader?: boolean;
}
const VehicleCardNotes = ({
  vehicleId,
  notes,
  showHeader = true,
}: VehicleCardNotesProps) => {
  const { isLoading: isLoadingAddNote, addNoteAsync: addNote } = useAddNote();

  const [shouldFocus, setShouldFocus] = useState(false);
  const [message, setMessage] = useState('');
  const [shouldClearContent, setShouldClearContent] = useState(false);

  const notesRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    notesRef?.current?.scrollTo({
      top: notesRef?.current?.scrollHeight,
      behavior: 'auto',
    });
  }, [notes]);

  const Notes = useMemo(() => {
    return notes?.map((notesByDate) => {
      const { date } = notesByDate;
      return (
        <div
          key={`notesByDate-${notesByDate.date}`}
          className="VehicleCardNotes-note"
        >
          <div className="VehicleCardNotes-date">{date}</div>
          {notesByDate?.notes?.map((note) => {
            const { fullName, messages } = note;
            const time = messages?.[0].time;
            return (
              <div key={`note-${note.fullName}`}>
                <div className="VehicleCardNotes-senderTime">
                  <div className="VehicleCardNotes-sender">{fullName}</div>
                  <div className="VehicleCardNotes-time">{time}</div>
                </div>
                {messages?.map((message, index) => {
                  const text = getParsedMessage(message.message);
                  return (
                    <div
                      key={`message-${message.time}-${index}`}
                      className="VehicleCardNotes-message"
                    >
                      {text}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      );
    });
  }, [notes]);

  const handleNoteSubmit = async () => {
    setShouldClearContent(true);
    setShouldFocus(false);
    await addNote({ vehicleId, note: message });
    setShouldClearContent(false);
    setShouldFocus(true);
  };

  const isLoading = isLoadingAddNote;
  const body = notes?.length ? (
    Notes
  ) : (
    <div className="VehicleCardNotes-emptyNotes">
      When a note is shared about this vehicle, it will appear here.
    </div>
  );

  return (
    <div className="VehicleCardNotes">
      {showHeader && <div className="VehicleCardNotes-header">Notes</div>}
      <div ref={notesRef} className="VehicleCardNotes-notes">
        {body}
      </div>
      <div className="VehicleCardNotes-input">
        <PermissionsGate permissions={[permissions.INVENTORY_VDP_NOTES_CREATE]}>
          <MentionableInput
            disabled={isLoading}
            shouldFocus={shouldFocus}
            onFocus={() => setShouldFocus(true)}
            onBlur={() => setShouldFocus(false)}
            existingContent={message}
            onChange={setMessage}
            submitAction={handleNoteSubmit}
            shouldClearContent={shouldClearContent}
          />
          <SendIconButton
            disabled={!message || isLoading}
            onClick={handleNoteSubmit}
          />
        </PermissionsGate>
      </div>
    </div>
  );
};

export default VehicleCardNotes;
