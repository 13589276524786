import React, { createContext, ReactNode, useMemo, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { useWindowSize } from 'hooks';

import { VehicleImage, VehicleSummary } from 'models';
import { useVehicleMedia } from 'api';
import FlatDocumentsSection from './FlatDocumentsSection';
import FlatMediaSection from './FlatMediaSection';

import './FlatMedia.scss';

interface FlatMediaProps {
  vehicle: VehicleSummary;
}

interface MediaContextProps {
  images: VehicleImage[];
  isLoading: boolean;
  refetch: () => void;
  onImageError: (index: number) => void;
}

interface MediaProviderProps {
  vehicleId: string;
  children: ReactNode;
}

export const FlatMediaContext = createContext<MediaContextProps>({
  images: [],
  isLoading: true,
  refetch: () => {},
  onImageError: () => {},
});

var FlatMediaProvider = (props: MediaProviderProps) => {
  const { vehicleId } = props;
  const {
    data: { data = { images: [], videos: [] } } = {},
    isLoading,
    refetch,
  } = useVehicleMedia(vehicleId);
  const [tempErrorIdList, setTempErrorIdList] = useState<string[]>([]);

  const images = useMemo(() => {
    const media = [...data.images, ...data.videos];
    const imagesWithoutErrors = media.filter(
      (item) => !tempErrorIdList.includes(item.id!)
    );
    return imagesWithoutErrors;
  }, [data, tempErrorIdList]);

  const onImageError = (index: number) => {
    setTempErrorIdList((errorIdList) => [...errorIdList, images[index].id!]);
  };

  return (
    <FlatMediaContext.Provider
      value={{ images, isLoading, refetch, onImageError }}
    >
      {props.children}
    </FlatMediaContext.Provider>
  );
};

const FlatMedia: React.FC<FlatMediaProps> = ({ vehicle }) => {
  const windowSize = useWindowSize();

  return (
    <Container fluid className="FlatMedia full-height">
      <Row noGutters className="full-height FlatMedia-overflow">
        <Col xs={12} lg={8} className="FlatMedia-media-section full-height">
          <FlatMediaProvider vehicleId={vehicle?.vehicleCard?.id!}>
            <FlatMediaSection vehicle={vehicle} />
          </FlatMediaProvider>
        </Col>
        {!windowSize.isMobileViewport() && (
          <Col xs={12} lg={4} className="full-height">
            <FlatDocumentsSection vehicle={vehicle} />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default FlatMedia;
