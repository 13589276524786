import React from 'react';

import './ReportTitleRow.scss';

interface ReportTitleRowConfig {
  name: string;
  columnClass?: string;
  subTitles?: string[];
}

interface ReportTitleRowProps {
  titles: ReportTitleRowConfig[];
  titleRowContainerClassName?: string;
  rowContentClassName?: string;
  rowTitleClassName?: string;
  titleWhitespace?: boolean;
}

const ReportTitleRow: React.FC<ReportTitleRowProps> = ({
  titles,
  titleRowContainerClassName,
  rowContentClassName,
  rowTitleClassName,
  titleWhitespace = true,
}) => (
  <div className="ReportTitleRow report-flex-columns full-width border-bottom">
    <div
      className={
        rowTitleClassName ? rowTitleClassName : titleWhitespace ? 'w-25' : ''
      }
    />
    <div
      className={
        rowContentClassName
          ? rowContentClassName
          : `${titleWhitespace ? 'w-75' : 'w-100'} evenly-spaced-container`
      }
    >
      {titles.map(({ name, columnClass, subTitles }, index) => (
        <div
          key={name}
          className={columnClass ? columnClass : 'evenly-spaced-item'}
          data-name={name}
        >
          <span className="font-bold" key={index}>
            {name}
          </span>
          {subTitles && subTitles?.length > 0 ? (
            <div className="sub-title-container">
              {subTitles.map((s) => {
                return <span className="sub-title-text">{s}</span>;
              })}
            </div>
          ) : null}
        </div>
      ))}
    </div>
  </div>
);

export default ReportTitleRow;
