import React from 'react';
import './ActiveReconSummary.scss';
import strings from 'common/strings';
import StepReportSummaryItem from 'models/stepReportSummaryItem';
import ActiveReconSummaryItem from './ActiveReconSummaryItem';

interface ActiveReconSummaryProps {
  items?: StepReportSummaryItem[];
}

const ActiveReconSummary: React.FC<
  React.PropsWithChildren<ActiveReconSummaryProps>
> = ({ items }) => (
  <>
    <div className="monthly-review-title-container">
      <span className="monthly-review-title-text">
        {strings.ACTIVE_RECON_SUMMARY}
      </span>
    </div>
    <div className="ActiveReconSummary-horizontal-scroll-container">
      <div className="ActiveReconSummary-container">
        <div className="ActiveReconSummary-title-row flex-columns full-width">
          <div className="w-25" />
          <div className=" w-75 evenly-spaced-container">
            <div className="evenly-spaced-item">
              <span className="ActiveReconSummary-title-text grey-text font-bold">
                {strings.ACTIVE_RECON_VEHICLES}
              </span>
            </div>
            <div className="evenly-spaced-item">
              <span className="ActiveReconSummary-title-text grey-text font-bold">
                {strings.ACTIVE_RECON_GOAL_TIME}
              </span>
            </div>
            <div className="evenly-spaced-item">
              <span className="ActiveReconSummary-title-text grey-text font-bold">
                {strings.ACTIVE_RECON_AVG_STEP_TIME}
              </span>
            </div>
            <div className="evenly-spaced-item">
              <span className="ActiveReconSummary-title-text grey-text font-bold">
                {strings.ACTIVE_RECON_OVERAGE}
              </span>
            </div>
          </div>
        </div>
        <div>
          {items?.map((stepItem: StepReportSummaryItem) => (
            <>
              <ActiveReconSummaryItem item={stepItem} type="parent" />
              {stepItem.childSteps &&
                stepItem.childSteps.map((childItem: StepReportSummaryItem) => (
                  <ActiveReconSummaryItem item={childItem} type="child" />
                ))}
            </>
          ))}
        </div>
      </div>
    </div>
  </>
);

export default ActiveReconSummary;
