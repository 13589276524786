import React from 'react';
import { TabProps } from 'components/shared/Tabs/Tabs';

import strings from 'common/strings';
import { useWindowSize } from 'hooks';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { getFormattedDurationFromSeconds } from 'utils/time';

import HighlightCard from './HighlightCard';
import PriceInfo from './PriceInfo';
import MostEngagedShoppers from './MostEngagedShoppers';
import Opens from './Opens';

import './VelocityEngageOverviewTab.scss';
import useEngageOverview from 'api/inventory/useEngageOverview';

var Divider = () => {
  return <div className="VelocityEngageOverviewTab-divider" />;
};

interface VelocityEngageOverviewTabProps extends TabProps {
  inventoryId?: string;
}

const VelocityEngageOverviewTab: React.FC<VelocityEngageOverviewTabProps> = ({
  inventoryId,
}) => {
  const windowSize = useWindowSize();

  const { data: { data: overview } = {}, isLoading } =
    useEngageOverview(inventoryId);

  const formatValue = (card: { name: string; value: any }) => {
    if (!card.value) {
      return strings.EMPTY_VALUE;
    }

    switch (card.name) {
      case 'AVG_SESSION_DURATION_SECONDS': {
        return getFormattedDurationFromSeconds(card.value);
      }
      case 'FOLIO_OPEN_PERCENTAGE': {
        // rounding a server side value to two decimal percent (0.0492 to 4.92% for example)
        const percent = Math.floor(Number(card.value) * 10000) / 100;
        return percent ? `${percent}%` : strings.EMPTY_VALUE;
      }
      default: {
        return card.value;
      }
    }
  };

  const renderOverviewDesktop = () =>
    isLoading ? (
      <LoadingIndicator />
    ) : (
      <div className="VelocityEngageOverviewTab-desktop">
        <div className="highlights">
          {overview?.overviewCards.length === 0 ? (
            <>
              <HighlightCard
                title={strings.SHOPPERS}
                value={strings.EMPTY_VALUE}
              />
              <HighlightCard
                title={strings.PORTFOLIOS_SENT}
                value={strings.EMPTY_VALUE}
              />
              <HighlightCard
                title={strings.PORTFOLIO_OPENS}
                value={strings.EMPTY_VALUE}
              />
              <HighlightCard
                title={strings.OPEN_RATE}
                value={strings.EMPTY_VALUE}
              />
              <HighlightCard
                title={strings.ENGAGEMENTS}
                value={strings.EMPTY_VALUE}
              />
              <HighlightCard
                title={strings.AVG_ENGAGEMENT_TIME}
                value={strings.EMPTY_VALUE}
              />
            </>
          ) : (
            overview?.overviewCards.map((card) => (
              <HighlightCard
                name={card.name}
                title={card.displayName}
                value={formatValue(card)}
              />
            ))
          )}
        </div>
        <div className="content">
          <div className="engage-info">
            <PriceInfo
              price={overview?.currentPrice}
              sentCount={overview?.priceAlertsSentCount!}
              openCount={overview?.priceAlertsOpenedCount!}
              priceHistory={overview?.priceHistory!}
            />
            <Divider />
            <MostEngagedShoppers
              engagedShoppers={overview?.engagementShoppers!}
            />
          </div>
          <Opens data={overview?.overviewOpens!} />
        </div>
      </div>
    );

  const renderOverviewTablet = () =>
    isLoading ? (
      <LoadingIndicator />
    ) : (
      <div className="VelocityEngageOverviewTab-tablet">
        <div className="highlights">
          {overview?.overviewCards.length === 0 ? (
            <>
              <HighlightCard
                title={strings.SHOPPERS}
                value={strings.EMPTY_VALUE}
              />
              <HighlightCard
                title={strings.PORTFOLIOS_SENT}
                value={strings.EMPTY_VALUE}
              />
              <HighlightCard
                title={strings.PORTFOLIO_OPENS}
                value={strings.EMPTY_VALUE}
              />
              <HighlightCard
                title={strings.OPEN_RATE}
                value={strings.EMPTY_VALUE}
              />
              <HighlightCard
                title={strings.ENGAGEMENTS}
                value={strings.EMPTY_VALUE}
              />
              <HighlightCard
                title={strings.AVG_ENGAGEMENT_TIME}
                value={strings.EMPTY_VALUE}
              />
            </>
          ) : (
            overview?.overviewCards.map((card) => (
              <HighlightCard
                title={card.displayName}
                value={formatValue(card)}
              />
            ))
          )}
        </div>
        <div className="content">
          <PriceInfo
            price={overview?.currentPrice}
            sentCount={overview?.priceAlertsSentCount!}
            openCount={overview?.priceAlertsOpenedCount!}
            priceHistory={overview?.priceHistory!}
          />
          <Divider />
          <MostEngagedShoppers
            engagedShoppers={overview?.engagementShoppers!}
          />
          <Divider />
          <Opens data={overview?.overviewOpens!} />
        </div>
      </div>
    );
  const renderOverviewMobile = () =>
    isLoading ? (
      <LoadingIndicator />
    ) : (
      <div className="VelocityEngageOverviewTab-mobile">
        <div className="highlights">
          {overview?.overviewCards.length === 0 ? (
            <>
              <HighlightCard
                title={strings.SHOPPERS}
                value={strings.EMPTY_VALUE}
              />
              <HighlightCard
                title={strings.PORTFOLIOS_SENT}
                value={strings.EMPTY_VALUE}
              />
              <HighlightCard
                title={strings.PORTFOLIO_OPENS}
                value={strings.EMPTY_VALUE}
              />
              <HighlightCard
                title={strings.OPEN_RATE}
                value={strings.EMPTY_VALUE}
              />
              <HighlightCard
                title={strings.ENGAGEMENTS}
                value={strings.EMPTY_VALUE}
              />
              <HighlightCard
                title={strings.AVG_ENGAGEMENT_TIME}
                value={strings.EMPTY_VALUE}
              />
            </>
          ) : (
            overview?.overviewCards.map((card) => (
              <HighlightCard
                title={card.displayName}
                value={formatValue(card)}
              />
            ))
          )}
        </div>
        <PriceInfo
          price={overview?.currentPrice}
          sentCount={overview?.priceAlertsSentCount!}
          openCount={overview?.priceAlertsOpenedCount!}
          priceHistory={overview?.priceHistory!}
        />
        <Divider />
        <MostEngagedShoppers engagedShoppers={overview?.engagementShoppers!} />
        <Divider />
        <Opens data={overview?.overviewOpens!} />
      </div>
    );

  return windowSize.isMobileViewport()
    ? renderOverviewMobile()
    : windowSize.width && windowSize.width <= 1000
    ? renderOverviewTablet()
    : renderOverviewDesktop();
};

export default VelocityEngageOverviewTab;
