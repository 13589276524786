import React, { useState } from 'react';
import { VehicleSummary, VehicleTag } from 'models';
import TruncatedList from 'react-truncate-list';
import 'react-truncate-list/dist/styles.css';
import { useWindowSize } from 'hooks';
import { useQueryClient } from 'react-query';

import Edit from '@material-ui/icons/Edit';

import permissions from 'common/permissions';

import EditTagsModal from 'components/shared/EditTagsModal';
import PermissionsGate from 'components/shared/PermissionsGate';
import TagComponent from './Tag';

import './TagList.scss';

interface TagListProps {
  vehicle: VehicleSummary;
  tags: VehicleTag[];
  showEditButton?: boolean;
  truncate?: boolean;
  onEditClick?: () => void;
  onCloseModal?: () => void;
}

const TagList = (props: TagListProps) => {
  const { vehicle, truncate = false } = props;
  const { tags } = vehicle;
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const windowSize = useWindowSize();

  const openModal = () => {
    if (props.onEditClick) {
      props.onEditClick();
    }
    setIsEditModalOpen(true);
  };
  const closeModal = () => {
    if (props.onCloseModal) {
      props.onCloseModal();
    }
    setIsEditModalOpen(false);
    queryClient.invalidateQueries(`/inventory/${vehicle.vehicleCard.id}`);
  };

  const { sold } = vehicle.vehicleCard;
  const tagsRowClassName =
    truncate && tags?.length !== 0 ? 'TagList-tags-row-truncate' : '';

  return (
    <PermissionsGate permissions={[permissions.TAGS_VIEW]}>
      <div className={`TagList-Container ${tagsRowClassName}`}>
        {tags?.length === 0 && (
          <PermissionsGate permissions={[permissions.TAGS_UPDATE]}>
            <button
              type="button"
              className="TagList-empty-container"
              onClick={openModal}
            >
              Add tags...
            </button>
          </PermissionsGate>
        )}
        <TruncatedList
          className="TagList"
          alwaysShowTruncator
          renderTruncator={({ hiddenItemsCount }) => (
            <>
              {hiddenItemsCount > 0 ? (
                <div
                  className="TagList-truncate-message"
                  role="link"
                  tabIndex={-1}
                  onClick={openModal}
                >
                  {`+ ${hiddenItemsCount}${
                    windowSize.isTabletLandscape() ? '' : ' more'
                  }`}
                </div>
              ) : (
                <PermissionsGate permissions={[permissions.TAGS_UPDATE]}>
                  {tags?.length === 0 && !sold ? (
                    <button
                      type="button"
                      className="TagList-empty-container"
                      onClick={openModal}
                    >
                      Add tags...
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="TagList-icon-button"
                      onClick={openModal}
                    >
                      <Edit className="TagList-icon" />
                    </button>
                  )}
                </PermissionsGate>
              )}
            </>
          )}
        >
          {Array.isArray(tags) &&
            tags.map((tag, index) => (
              <TagComponent key={`tag-component-${index}`} tag={tag} />
            ))}
        </TruncatedList>
      </div>
      {isEditModalOpen && (
        <EditTagsModal
          tags={vehicle.tags}
          onClose={closeModal}
          vehicleId={vehicle.vehicleCard.id}
        />
      )}
    </PermissionsGate>
  );
};

export default TagList;
