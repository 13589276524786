import { useUpdateVehicleChecklist, useVehicleChecklist } from 'api';
import { omit, set } from 'lodash';
import { ChecklistItem } from 'models/inventory/checklist';
import React, { useCallback } from 'react';
import MpiCheckboxGroup from './MpiCheckboxGroup';
import MpiRadioGroup from './MpiRadioGroup';
import MpiSelect from './MpiSelect';
import MpiTextInput from './MpiTextInput';
import permissions from 'common/permissions';
import { usePermissions } from 'hooks';

const FIELD_TYPES = {
  CHECKBOX: 'CHECKBOX',
  RADIO_BUTTON: 'RADIO_BUTTON',
  YES_NO: 'YES_NO',
  TEXT_INPUT: 'TEXTBOX',
  DROPDOWN: 'DROPDOWN',
};

const handleOnChange = (
  answers: any = {},
  key: any,
  answer: any,
  mutate: any
) => {
  if (Array.isArray(answer)) {
    if (answer.length === 0) {
      return mutate(omit(answers, key));
    }
  } else if (!answer) {
    return mutate(omit(answers, key));
  }
  return mutate(set(answers, key, answer));
};

interface MpiChecklistProps {
  checklistId: string;
  vehicleId: string;
}

var MpiChecklist = ({ checklistId, vehicleId }: MpiChecklistProps) => {
  const { data: { data } = {} } = useVehicleChecklist(vehicleId, checklistId);
  const { answers, checkList } = data || {};

  const { mutate } = useUpdateVehicleChecklist(vehicleId, checklistId);
  const onChange = useCallback(
    (key: string, answer: any) => handleOnChange(answers, key, answer, mutate),
    [answers, mutate]
  );
  const { hasPermission } = usePermissions();

  const disabled = !(
    hasPermission(permissions.RECON_VDP_CHECKLISTS_ANSWSER) ||
    hasPermission(permissions.RECON_VDP_CHECKLISTS_ANSWER)
  );

  return (
    <div className="Mpi-checklist-menu-item">
      {checkList?.items?.map((field: ChecklistItem) => {
        switch (field.uiType) {
          case FIELD_TYPES.CHECKBOX:
            return (
              <MpiCheckboxGroup
                disabled={disabled}
                key={field.id}
                field={field}
                answer={answers[field.id] ? [].concat(answers[field.id]) : []}
                onChange={onChange}
              />
            );
          case FIELD_TYPES.YES_NO:
          case FIELD_TYPES.RADIO_BUTTON:
            return (
              <MpiRadioGroup
                disabled={disabled}
                key={field.id}
                field={field}
                answer={answers[field.id]}
                onChange={onChange}
              />
            );
          case FIELD_TYPES.DROPDOWN:
            return (
              <MpiSelect
                disabled={disabled}
                key={field.id}
                field={field}
                answer={answers[field.id]}
                onChange={onChange}
              />
            );
          case FIELD_TYPES.TEXT_INPUT:
            return (
              <MpiTextInput
                disabled={disabled}
                key={field.id}
                field={field}
                answer={answers[field.id]}
                onChange={onChange}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default MpiChecklist;
