import features from 'common/features';
import { useFeatures } from 'hooks';
import React, { Suspense, useEffect, useState } from 'react';
import { getAppService, Vehicle } from 'reconvelocity-data';
import { useParams } from '@reach/router';
import { getCredentialValue } from 'api/lib/localStorageManagement';

const BestTasksEverrr = React.lazy(() => import('../BestTasksEverrr'));
const FlatTasks = React.lazy(() => import('./FlatTasks'));

const Tasks = (props: any) => {
  const { hasFeature } = useFeatures();
  const flag = hasFeature(features.UI_PREVIEW_TASKS);
  const routerParams = useParams();

  // TODO: remove this vehicle, its fetch, and BestTasksEverrr component once customers are migrated to the new version of tasks.
  const [mobxDataLayerVehicle, setMobxDataLayerVehicle] = useState<Vehicle>();

  useEffect(() => {
    let isMounted = true;
    const getMobxDataLayerVehicle = async (vehicleId: string) => {
      // Cast data layer service to "any" type so that we can directly access its properties.
      const service = getAppService() as any;
      // Set token keys that the data layer recognizes.
      const authBearerToken = getCredentialValue('authBearerToken') ?? '';
      const authRefreshToken = getCredentialValue('authRefreshToken') ?? '';
      const authBearerTokenExpiration =
        getCredentialValue('authBearerTokenExpiration') ?? '';
      window.localStorage.setItem('authBearerToken', authBearerToken);
      window.localStorage.setItem('authRefreshToken', authRefreshToken);
      window.localStorage.setItem(
        'authBearerTokenExpiration',
        authBearerTokenExpiration
      );
      // Directly set data layer tokens to make sure it uses latest.
      service.session.bearerToken = authBearerToken;
      service.session.bearerTokenExpirationDate = authBearerTokenExpiration
        ? new Date(authBearerTokenExpiration)
        : undefined;
      service.session.refreshToken = authRefreshToken;
      // Checking for a user as a way of asking, "is the data layer authed?".
      if (!service.session.user) {
        await service.session.authenticateWithBearerToken();
      }
      // Directly set the data layer rooftop to current tenant.
      service.currentRooftopId = routerParams.rooftopId;
      if (!mobxDataLayerVehicle) {
        // Fetch mobx vehicle and capture in local component state.
        const vehicle = await service.inventoryVehiclesCollection.getById(
          vehicleId,
          true
        );
        if (vehicle && isMounted) {
          setMobxDataLayerVehicle(vehicle);
        }
      }
    };
    if (props?.vehicle?.vehicleCard?.id && !flag) {
      getMobxDataLayerVehicle(props?.vehicle?.vehicleCard?.id);
    }
    return () => {
      isMounted = false;
    };
  }, [
    flag,
    mobxDataLayerVehicle,
    props?.vehicle?.vehicleCard?.id,
    routerParams,
  ]);

  const renderBestTasksEverrr = () => {
    if (mobxDataLayerVehicle) {
      return (
        <BestTasksEverrr
          {...props}
          vehicle={mobxDataLayerVehicle}
          pinned={props?.pinned}
          printPressed={props?.printPressed}
        />
      );
    }
    return <div>Loading...</div>;
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {flag ? <FlatTasks {...props} /> : renderBestTasksEverrr()}
    </Suspense>
  );
};

export default Tasks;
