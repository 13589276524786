import React, { useState } from 'react';
import { MoreHoriz, DriveEta } from '@material-ui/icons';
import { VehicleSummary } from 'models';
import { Dropdown as BSDropdown } from 'react-bootstrap';
import IconButton from '@material-ui/core/IconButton';
import { navigate } from '@reach/router';
import axios from 'axios';

import Modal from 'components/shared/Modal';
import strings from 'common/strings';
import { RECON_STEP_STATE } from 'common/constants';
import Dropdown from 'components/shared/Dropdown';
import PermissionsGate from 'components/shared/PermissionsGate';
import permissions from 'common/permissions';
import CustomDropdownToggle from 'components/shared/CustomDropdownToggle';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import ConfirmationDialog from 'components/shared/ConfirmationDialog';
import Alert from 'components/shared/Alert';
import TagList from 'components/shared/TagList';
import StepDropdown from 'components/shared/StepDropdown';
import UsersDropdown from 'components/shared/UsersDropdown';
import { formatMileage } from 'utils/formatter';

import './VehicleDetailsModal.scss';
import { usePermissions } from 'hooks';
import {
  getAPIHeaders,
  useBackToReconWorkflow,
  useCompleteWorkflow,
  useDeleteWorkflow,
  useGetVehicleColors,
  useGetVehicleDispositions,
  useUpdateVehicleInfo,
} from 'api';
import TextCopy from 'components/shared/Inputs/TextCopy';

interface MetaData {
  source: string;
}

interface VehicleDetailsModalProps {
  open: boolean;
  onClose: () => void;
  vehicle: VehicleSummary;
}

const confirmationModalProps = {
  onClose: () => {},
  onAccept: () => {},
  title: '',
  body: '',
  mainButtonTitle: '',
};

const VehicleDetailsModal: React.FC<VehicleDetailsModalProps> = ({
  open,
  onClose,
  vehicle,
}) => {
  const { hasPermission } = usePermissions();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [isFetchingDownload, setIsFetchingDownload] = useState(false);
  const [disposition, setDisposition] = useState(
    vehicle?.vehicleCard?.disposition || strings.EMPTY_VALUE
  );
  const [color, setColor] = useState(
    vehicle?.vehicleCard?.color || strings.EMPTY_VALUE
  );
  const [isVisible, setIsVisible] = useState(true);
  const updatePermission = vehicle?.vehicleCard?.inRecon
    ? permissions.RECON_VDP_UPDATE
    : permissions.INVENTORY_VDP_UPDATE;
  const userHasUpdatePermission = hasPermission(updatePermission);
  const [hasImageError, setHasImageError] = useState(false);
  const completeWorkflowMutation = useCompleteWorkflow(vehicle.vehicleCard.id);
  const deleteWorkflowMutation = useDeleteWorkflow(vehicle.vehicleCard.id);
  const updateVehicleMutation = useUpdateVehicleInfo(vehicle.vehicleCard.id);
  const moveBackToReconMutation = useBackToReconWorkflow();
  const { data: colorData = [] } = useGetVehicleColors();
  const { data: dispositionData = [] } = useGetVehicleDispositions();

  const interceptDropdownToggle = (
    isOpen: boolean,
    // ev: SyntheticEvent<BSDropdown<'div'>, Event>,
    event: React.SyntheticEvent<BSDropdown, Event>,
    metadata: MetaData
  ) => {
    if (metadata.source === 'select') {
      setDropdownOpen(true);
      return;
    }
    setDropdownOpen(isOpen);
  };

  const handleCloseConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);
  };

  const handleOpenConfirmationDialog = () => {
    setDropdownOpen(false);
    setIsConfirmationDialogOpen(true);
  };

  const forceCompleteWorkflow = async () => {
    try {
      handleCloseConfirmationDialog();
      setIsSubmitting(true);
      await completeWorkflowMutation.mutateAsync();
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      setApiError(true);
    }
  };

  const removeFromRecon = async () => {
    try {
      handleCloseConfirmationDialog();
      setIsSubmitting(true);
      await deleteWorkflowMutation.mutateAsync();
      setIsSubmitting(false);
      navigate('/');
    } catch (error) {
      setIsSubmitting(false);
      setApiError(true);
    }
  };

  const printVehicleInfoPDF = async (): Promise<void> => {
    const { host, options } = await getAPIHeaders('GET', {
      'content-type': 'application/pdf',
    });
    const displayAllSections =
      '?excludeNotes=false&excludeTasks=false&excludeHeader=false&excludeAppraisalInfo=false';
    const exportPDFURL = `${host}/inventory/${vehicle.vehicleCard.id}/export/pdf${displayAllSections}`;

    try {
      setIsFetchingDownload(true);
      const response = await axios.get(exportPDFURL, options);
      if (!response.data) return;
      window.open(response.data.uri, '_newtab');
      setIsFetchingDownload(false);
    } catch {
      setDropdownOpen(false);
    }
    setIsFetchingDownload(false);
    setDropdownOpen(false);
  };

  const onComplete = () => {
    setDropdownOpen(false);
    confirmationModalProps.onClose = handleCloseConfirmationDialog;
    confirmationModalProps.title = strings.TITLE_COMPLETE;
    confirmationModalProps.onAccept = forceCompleteWorkflow;
    confirmationModalProps.mainButtonTitle = strings.BUTTON_TITLE_COMPLETE;
    confirmationModalProps.body = strings.FORCE_COMPLETION_DESCRIPTION;

    handleOpenConfirmationDialog();
  };

  const onDelete = () => {
    setDropdownOpen(false);
    confirmationModalProps.onClose = handleCloseConfirmationDialog;
    confirmationModalProps.title = strings.TITLE_REMOVE;
    confirmationModalProps.onAccept = removeFromRecon;
    confirmationModalProps.mainButtonTitle = strings.BUTTON_TITLE_REMOVE;
    confirmationModalProps.body = strings.REMOVE_FROM_RECON_DESCRIPTION;

    handleOpenConfirmationDialog();
  };

  const onSave = async () => {
    updateVehicleMutation.mutateAsync({
      color: color,
      disposition: disposition,
    });
    onClose();
  };

  const onBackToRecon = () => {
    confirmationModalProps.onClose = handleCloseConfirmationDialog;
    confirmationModalProps.title = strings.TITLE_BACK_TO_RECON;
    confirmationModalProps.onAccept = backToReconWorkflow;
    confirmationModalProps.mainButtonTitle = strings.BUTTON_TITLE_SEND_TO_RECON;
    confirmationModalProps.body = strings.DESCRIPTION_BACK_TO_RECON;

    handleOpenConfirmationDialog();
  };

  const backToReconWorkflow = async () => {
    try {
      handleCloseConfirmationDialog();
      setIsSubmitting(true);
      if (vehicle.vehicleCard.id) {
        await moveBackToReconMutation.mutateAsync({
          vehicleId: vehicle.vehicleCard.id,
        });
      }
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      setApiError(true);
    }
  };

  const reconActions = () => (
    <BSDropdown.Menu>
      {hasPermission(permissions.RECON_VDP_FORCE_COMPLETE_UPDATE) &&
        vehicle?.vehicleCard?.reconStatus === RECON_STEP_STATE.ACTIVE && (
          <BSDropdown.Item
            onClick={onComplete}
            className="loadingIconAlongside"
          >
            {strings.FORCE_COMPLETION}
            {isSubmitting && <LoadingIndicator size={18} />}
          </BSDropdown.Item>
        )}
      {hasPermission(permissions.RECON_VDP_REMOVE_FROM_RECON_UPDATE) &&
        vehicle?.vehicleCard?.reconStatus === RECON_STEP_STATE.ACTIVE && (
          <BSDropdown.Item onClick={onDelete} className="loadingIconAlongside">
            {strings.REMOVE_FROM_RECON}
            {isSubmitting && <LoadingIndicator size={18} />}
          </BSDropdown.Item>
        )}
      {hasPermission(permissions.RECON_VDP_FORCE_COMPLETE_UPDATE) &&
        (vehicle?.vehicleCard?.reconStatus === RECON_STEP_STATE.COMPLETED ||
          vehicle?.vehicleCard?.reconStatus ===
            RECON_STEP_STATE.MANUALLY_REMOVED ||
          vehicle?.vehicleCard?.reconStatus ===
            RECON_STEP_STATE.NOT_STARTED) && (
          <BSDropdown.Item onClick={onBackToRecon}>
            {strings.DROPDOWN_TITLE_SEND_TO_RECON}
          </BSDropdown.Item>
        )}
      <BSDropdown.Item
        onClick={printVehicleInfoPDF}
        className="loadingIconAlongside"
      >
        {strings.PRINT_VDP_INFO}
        {isFetchingDownload && <LoadingIndicator size={18} />}
      </BSDropdown.Item>
    </BSDropdown.Menu>
  );

  return (
    <Modal
      title=""
      open={open}
      onClose={() => {
        setDisposition(vehicle?.vehicleCard?.disposition);
        setColor(vehicle?.vehicleCard?.color);
        onClose();
      }}
      onPrimaryButtonClick={onSave}
      className="VehicleDetailsModal"
      secondaryButtonLabel={strings.CANCEL}
    >
      {isVisible && (
        <>
          <div className="VehicleDetailsModal-vehicle-row">
            <div className="VehicleDetailsModal-vehicle-row-vehicle">
              {vehicle?.vehicleCard?.vehicleImage && !hasImageError ? (
                <img
                  className="VehicleDetailsModal-info-image"
                  src={vehicle?.vehicleCard?.vehicleImage}
                  alt="vehicle"
                  onError={() => setHasImageError(true)}
                />
              ) : (
                <DriveEta className="VehicleDetailsModal-info-image" />
              )}
              <div className="VehicleDetailsModal-year-model">
                {!vehicle?.vehicleCard?.year &&
                !vehicle?.vehicleCard?.make &&
                !vehicle?.vehicleCard?.model ? (
                  <span>{strings.EMPTY_VALUE}</span>
                ) : (
                  <>
                    <span className="VehicleDetailsModal-bold-text">
                      {`${vehicle?.vehicleCard?.year} ${vehicle?.vehicleCard?.make}`}
                    </span>
                    <span>
                      {`${
                        vehicle?.vehicleCard?.model
                          ? vehicle?.vehicleCard?.model
                          : ''
                      }`}
                    </span>
                  </>
                )}
              </div>
            </div>
            <PermissionsGate
              permissions={[permissions.RECON_VDP_FORCE_COMPLETE_UPDATE]}
            >
              <BSDropdown
                show={dropdownOpen}
                onToggle={interceptDropdownToggle}
                drop="left"
              >
                <BSDropdown.Toggle
                  as={CustomDropdownToggle}
                  id="VehicleDetailsModal-dropdown-id"
                >
                  <IconButton size="small">
                    <MoreHoriz />
                  </IconButton>
                </BSDropdown.Toggle>
                {reconActions()}
              </BSDropdown>
              {isConfirmationDialogOpen && (
                <ConfirmationDialog {...confirmationModalProps} />
              )}
            </PermissionsGate>
          </div>
          <div className="VehicleDetailsModal-info-container">
            <div>
              <div className="VehicleDetailsModal-label">{strings.VIN}</div>
              <div className="VehicleDetailsModal-value">
                <TextCopy value={vehicle?.vehicleCard?.vin} />
              </div>
            </div>
            <div>
              <div className="VehicleDetailsModal-label">{strings.STK}</div>
              <div className="VehicleDetailsModal-value">
                <TextCopy value={vehicle?.vehicleCard?.stockNumber!} />
              </div>
            </div>
          </div>
          <div className="VehicleDetailsModal-info-container">
            <div>
              <div className="VehicleDetailsModal-label">
                {strings.DISPOSITION}
              </div>
              <Dropdown
                selectedOption={{
                  id: disposition,
                  value: disposition,
                }}
                onSelect={(option: any) => setDisposition(option)}
                options={dispositionData
                  .filter(
                    (disposition: string) =>
                      disposition !== strings.UNKNOWN_OPTION
                  )
                  .map((disposition: string) => ({
                    id: disposition,
                    value: disposition,
                  }))}
                disabled={!userHasUpdatePermission}
              />
            </div>
          </div>
          <div className="VehicleDetailsModal-info-container">
            <div>
              <div className="VehicleDetailsModal-label">{strings.MILEAGE}</div>
              <div className="VehicleDetailsModal-value">
                {formatMileage(
                  vehicle?.vehicleCard?.mileage,
                  vehicle?.vehicleCard?.odometerUnit,
                  true
                ) || strings.EMPTY_VALUE}
              </div>
            </div>
            <div>
              <div className="VehicleDetailsModal-label">{strings.COLOR}</div>
              <Dropdown
                selectedOption={{
                  id: color,
                  value: color,
                }}
                onSelect={(option: any) => setColor(option)}
                options={colorData
                  .filter((color: string) => color !== strings.UNKNOWN_OPTION)
                  .map((color: string) => ({ id: color, value: color }))}
                disabled={!userHasUpdatePermission}
              />
            </div>
            <div>
              <div className="VehicleDetailsModal-label">{strings.TRIM}</div>
              <div className="VehicleDetailsModal-value">
                {vehicle?.vehicleCard?.trim || strings.EMPTY_VALUE}
              </div>
            </div>
          </div>
          <div className="VehicleDetailsModal-info-container">
            <div>
              <div className="VehicleDetailsModal-label">
                {strings.DRIVETRAIN}
              </div>
              <div className="VehicleDetailsModal-value">
                {vehicle?.vehicleCard?.driveTrain || strings.EMPTY_VALUE}
              </div>
            </div>
          </div>
          <div className="VehicleDetailsModal-info-container">
            <div>
              <div className="VehicleDetailsModal-label">{strings.STEP}</div>
              <StepDropdown
                vehicleId={vehicle?.vehicleCard?.id}
                currentStep={vehicle.stepItem ?? undefined}
              />
            </div>
            <div>
              <div className="VehicleDetailsModal-label">
                {strings.ASSIGNEE}
              </div>
              <UsersDropdown
                vehicleId={vehicle?.vehicleCard?.id}
                currentStep={vehicle?.stepItem}
              />
            </div>
          </div>
          <Alert
            open={apiError}
            contentProps={{
              variant: 'error',
              message: strings.API_MESSAGE,
              onClose: () => setApiError(false),
            }}
          />
        </>
      )}
      <TagList
        tags={vehicle.tags ?? []}
        vehicle={vehicle}
        showEditButton
        truncate={false}
        onEditClick={() => setIsVisible(false)}
        onCloseModal={() => setIsVisible(true)}
      />
    </Modal>
  );
};

export default VehicleDetailsModal;
