import React, { useContext, useRef } from 'react';
import { Button, Col } from 'react-bootstrap';
import { navigate } from '@reach/router';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { useFeatures, useWindowSize } from 'hooks';
import { overviewBuilder } from 'navigation/routes';
import strings from 'common/strings';
import StepDropdown from 'components/shared/StepDropdown';
import UsersDropdown from 'components/shared/UsersDropdown';
import StepButtons from 'components/shared/StepButtons';
import { StepItem } from 'models';
import { VehicleCardContext } from '../../VehicleCard';
import { getFullName } from 'utils/user';
import VehicleCardTabFooter from './VehiceCardTabFooter/VehicleCardTabFooter';
import features from 'common/features';
import LabelValue from 'components/shared/LabelValue';
import { getFormattedDurationFromSeconds } from 'utils/time';

interface VehicleCardUserDropDownInterface {
  currentStep?: StepItem;
}

var VehicleCardReconDetails = () => {
  const { vehicle, inExtensionCardMode, isOnboarding, position } =
    useContext(VehicleCardContext);
  const ref = useRef<HTMLDivElement>(document.createElement('div'));
  const vehicleInRecon = vehicle?.vehicleCard.inRecon;
  const vehicleId = vehicle?.vehicleCard.id;
  const currentStep = vehicle?.stepItem;
  const windowSize = useWindowSize();
  const { hasFeature } = useFeatures();

  const handleClickNavToRecon = (clickSource: string): Promise<void> | null => {
    if (vehicleId) {
      return navigate(`${overviewBuilder(vehicleId, true)}`);
    }
    return null;
  };

  var VehicleCardUserDropDown = (): React.ReactElement | null => {
    if (!vehicle) {
      return null;
    }
    if (inExtensionCardMode) {
      const assignedTo = currentStep?.assignedTo;
      return (
        <div>{assignedTo ? getFullName(assignedTo) : strings.EMPTY_VALUE}</div>
      );
    }
    if (currentStep) {
      return (
        <UsersDropdown
          vehicleId={vehicleId}
          currentStep={currentStep}
          loaderType="srpGradient"
        />
      );
    }
    return null;
  };

  var VehicleCardStepDropdown = ({
    currentStep,
  }: VehicleCardUserDropDownInterface): React.ReactElement | null => {
    const { vehicle } = useContext(VehicleCardContext);
    if (!vehicle) {
      return null;
    }
    if (inExtensionCardMode) {
      return <div>{currentStep?.name || strings.EMPTY_VALUE}</div>;
    }
    const vehicleCurrentStep = currentStep;
    if (
      vehicleCurrentStep !== undefined &&
      vehicleCurrentStep?.name !== undefined &&
      vehicleCurrentStep?.id !== undefined
    ) {
      const currentStepPart = {
        name: vehicleCurrentStep.name,
        id: vehicleCurrentStep.id,
      };
      return (
        <StepDropdown
          vehicleId={vehicleId}
          currentStep={currentStepPart}
          containerClassName="UsersDropdown"
          loaderType="srpGradient"
        />
      );
    }
    return null;
  };

  if (inExtensionCardMode) {
    const assignedTo = currentStep?.assignedTo;
    return (
      <div className="VehicleCard-tab-container">
        <LabelValue label={strings.STEP} labelCaps>
          {vehicle && currentStep && vehicleInRecon ? (
            <div>{currentStep?.name || strings.EMPTY_VALUE}</div>
          ) : (
            strings.EMPTY_VALUE
          )}
        </LabelValue>

        <LabelValue label={strings.ASSIGNEE} labelCaps>
          {vehicleInRecon ? (
            <div>
              {vehicleInRecon && assignedTo
                ? getFullName(assignedTo)
                : strings.EMPTY_VALUE}
            </div>
          ) : (
            strings.EMPTY_VALUE
          )}
        </LabelValue>

        <LabelValue label={strings.RECON_TIME} labelCaps>
          <div id={`vehicle-card-recon-time-${position}`}>
            {vehicle?.reconTimeAsSeconds
              ? getFormattedDurationFromSeconds(
                  vehicle?.reconTimeAsSeconds || 0
                )
              : strings.EMPTY_VALUE}
          </div>
        </LabelValue>

        <LabelValue label={strings.RETAIL_READY} labelCaps>
          <div id={`vehicle-card-retail-time-${position}`}>
            {vehicle?.retailReadyTimeAsSeconds
              ? getFormattedDurationFromSeconds(
                  vehicle?.retailReadyTimeAsSeconds || 0
                )
              : strings.EMPTY_VALUE}
          </div>
        </LabelValue>
      </div>
    );
  }

  var TabButtonsRow = () => {
    if (windowSize.width < 1350) {
      return (
        <div className="VehicleCard-step-buttons-container d-flex">
          <Col className="px-0 pr-2">
            {currentStep && (
              <StepButtons
                vehicleId={vehicleId}
                currentStep={currentStep}
                isOnboarding={isOnboarding}
                onComplete={() => true}
                small
              />
            )}
          </Col>
          <Col className="px-0 pl-2">
            <Button
              variant="outline-primary"
              block
              onClick={() =>
                handleClickNavToRecon('VehicleCard-step-buttons-container')
              }
            >
              {strings.VIEW_VEHICLE.toUpperCase()}
            </Button>
          </Col>
        </div>
      );
    }
    return (
      <div className="VehicleCardTabFooter flex-columns align-center">
        <Button
          as="button"
          className="see-more-button"
          variant="link"
          onClick={() =>
            handleClickNavToRecon('VehicleCard-step-buttons-container')
          }
        >
          <span>{strings.VIEW_MORE}</span>
          <ChevronRightIcon />
        </Button>
        <div className="VehicleCard-step-buttons-container ml-auto">
          {currentStep && (
            <StepButtons
              vehicleId={vehicleId}
              currentStep={currentStep}
              isOnboarding={isOnboarding}
              onComplete={() => true}
              small
            />
          )}
        </div>
      </div>
    );
  };

  const hasUIPreview = hasFeature(features.UI_PREVIEW);
  return (
    <div ref={ref} className="VehicleCard-tab-container">
      <LabelValue label={strings.STEP} labelCaps>
        <VehicleCardStepDropdown currentStep={currentStep ?? undefined} />
      </LabelValue>

      <LabelValue label={strings.ASSIGNEE} labelCaps>
        <VehicleCardUserDropDown />
      </LabelValue>

      <LabelValue label={strings.RECON_TIME} labelCaps>
        <div id={`vehicle-card-recon-time-${position}`}>
          {vehicle?.reconTimeAsSeconds
            ? getFormattedDurationFromSeconds(vehicle?.reconTimeAsSeconds || 0)
            : strings.EMPTY_VALUE}
        </div>
      </LabelValue>

      <LabelValue label={strings.RETAIL_READY} labelCaps>
        <div id={`vehicle-card-retail-time-${position}`}>
          {vehicle?.retailReadyTimeAsSeconds
            ? getFormattedDurationFromSeconds(
                vehicle?.retailReadyTimeAsSeconds || 0
              )
            : strings.EMPTY_VALUE}
        </div>
      </LabelValue>

      {hasUIPreview ? (
        <VehicleCardTabFooter
          vehicle={vehicle}
          navClick={handleClickNavToRecon}
          showRecon={true}
        >
          {currentStep && (
            <StepButtons
              vehicleId={vehicleId}
              currentStep={currentStep}
              isOnboarding={isOnboarding}
              onComplete={() => true}
              small
            />
          )}
        </VehicleCardTabFooter>
      ) : (
        <TabButtonsRow />
      )}
    </div>
  );
};

export default VehicleCardReconDetails;
