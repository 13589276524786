import { Redirect } from '@reach/router';
import { useToken } from 'api';
import { getCredentialValue } from 'api/lib/localStorageManagement';
import React from 'react';
import useUploadStore from 'store/uploads/useUploadStore';
import { LOGIN } from './routes';
let isInitialized = false;

interface ProtectedRouteProps {
  default: any;
  component: any;
}
const ProtectedRoute = React.memo(
  ({ component: Component, ...rest }: ProtectedRouteProps) => {
    const { isError, isLoading } = useToken();

    const initializeUploadStore = useUploadStore(
      (uploadStore) => uploadStore.initialize
    );

    const authBearerToken = getCredentialValue('authBearerToken');
    if (isError || !authBearerToken) {
      return <Redirect noThrow to={`/${LOGIN}`} />;
    }

    if (isError || isLoading) {
      return null;
    }

    if (!isInitialized) {
      isInitialized = true;
      initializeUploadStore();
    }

    return <Component {...rest} />;
  }
);

export default ProtectedRoute;
