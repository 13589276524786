import React from 'react';
import { Cancel } from '@material-ui/icons';

import { useUsers } from 'api';
import { UIFilter, workflowStatusesDownMap } from 'models';
import { testIds, generateTestId } from 'common/testIds';
import './FilterChip.scss';
import { useFilterColumn } from '../useFilterColumn';
import { WorkflowMenuItem } from '../../Sidebar/Sidebar';

const STEP_ID = 'STEP';
const STATUS = 'STEP_STATUS';
const ASSIGNEE = 'ASSIGNEE';
const ALL = 'ALL';

interface FilterChipProps {
  title: string;
  onCancelClick: () => void;
  filter: string;
  workflowMenuItems: WorkflowMenuItem[];
}

function findLabelForFilterOption(
  filter: string,
  title: string,
  filters: UIFilter[]
): string | undefined {
  const filterLookup = filters.find((indexFilter) => indexFilter.id === filter);
  let labelIfPossible: string | undefined;
  if (filterLookup) {
    const foundOption = filterLookup.options.find(
      (indexOption) => indexOption.id === title
    );
    labelIfPossible = foundOption?.label;
  }
  return labelIfPossible;
}

const FilterChip: React.FC<FilterChipProps> = ({
  title,
  onCancelClick,
  filter,
  workflowMenuItems,
}) => {
  const { filters } = useFilterColumn();
  const { data: { data: users = [] } = { data: [] } } = useUsers();
  const mapTitle = (filter: string, title: string) => {
    const label = findLabelForFilterOption(filter, title, filters);

    if (filter === STEP_ID && workflowMenuItems) {
      if (title === ALL) {
        return 'All Steps';
      }
      const step = workflowMenuItems.find((step) => step.id === title);
      return step?.name;
    }

    if (filter === STATUS) {
      if (title === ALL) return 'All Statuses';
      return workflowStatusesDownMap[title];
    }

    if (filter === ASSIGNEE) {
      const user = users.find((user) => user.id === title);
      return user ? `${user?.firstName} ${user?.lastName}` : 'Unknown User';
    }
    return label ?? title;
  };

  let filterOption = title;
  if (filter === ASSIGNEE) {
    // Redact the user id as a test attribute value on the DOM
    const user = users.find((user) => user.id === title);
    filterOption = `${user?.lastName}_${user?.firstName}`;
  }

  const testId = generateTestId(testIds.FILTER_CHIP_ELEMENT, {
    value: `${filter}:${filterOption}`,
  });

  return (
    <div className="FilterChip" data-vas-testing={testId}>
      <span className="FilterChip-title">{mapTitle(filter, title)}</span>
      <Cancel
        data-vas-testing={`${testId}-cancel`}
        className="FilterChip-icon"
        onClick={onCancelClick}
      />
    </div>
  );
};

export default FilterChip;
