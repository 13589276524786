import {
  APIResponse,
  defaultMutationFn,
  defaultTextPlainMutationFn,
  getVehicleListParams,
} from 'api';
import { QueryClient, useMutation, useQueryClient } from 'react-query';
import { Note, VehicleSummary, NoteV2 } from 'models';
import { getCalendarDateStringFromDate, getFormattedHour } from 'utils/time';

export function useDeleteNote() {
  const queryClient = useQueryClient();

  type DeleteNoteData = { vehicleId: string; noteId: string };
  const mutation = useMutation(({ vehicleId, noteId }: DeleteNoteData) => {
    const path = `/inventory/${vehicleId}/notes/${noteId}`;
    return defaultMutationFn(path, 'DELETE');
  });

  async function deleteNoteAsync({ vehicleId, noteId }: DeleteNoteData) {
    return mutation.mutateAsync(
      { vehicleId, noteId },
      {
        onSuccess: () => {
          deleteNoteFromQueryCache(vehicleId, queryClient, noteId);
        },
      }
    );
  }

  return {
    ...mutation,
    deleteNoteAsync,
  };
}

export function useDeleteNoteAttachment() {
  const queryClient = useQueryClient();

  type NoteAttachmentData = {
    vehicleId: string;
    noteId: string;
    attachmentId: string;
  };
  const mutation = useMutation(
    ({ vehicleId, noteId, attachmentId }: NoteAttachmentData) => {
      const path = `/inventory/${vehicleId}/notes/${noteId}/attachments/${attachmentId}`;
      return defaultMutationFn(path, 'DELETE');
    }
  );

  async function deleteNoteAttachmentAsync({
    vehicleId,
    noteId,
    attachmentId,
  }: NoteAttachmentData) {
    const response = await mutation.mutateAsync({
      vehicleId,
      noteId,
      attachmentId,
    });
    await queryClient.invalidateQueries(`/inventory/${vehicleId}/notes`);
    return response;
  }

  return {
    ...mutation,
    deleteNoteAttachmentAsync,
  };
}

export function useUpdateNote(vehicleId: string) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ noteId, note }: { noteId: string; note: string }) => {
      const path = `/inventory/${vehicleId}/notes/${noteId}`;
      return defaultTextPlainMutationFn(path, 'PUT', note);
    },
    {
      onSuccess: (data) => {
        updateNotesQueryCache(vehicleId, queryClient, data.data);
      },
    }
  );
}

export function useAddNote() {
  type AddNoteData = { vehicleId: string; note: string };
  const queryClient = useQueryClient();
  const mutation = useMutation(({ vehicleId, note }: AddNoteData) => {
    const path = `/inventory/${vehicleId}/notes`;
    return defaultTextPlainMutationFn(path, 'POST', note);
  });

  async function addNoteAsync({
    vehicleId,
    note,
  }: AddNoteData): Promise<{ data: { id: string } }> {
    return mutation.mutateAsync(
      { vehicleId, note },
      {
        onSuccess: (data) => {
          addNoteToQueryCache(vehicleId, queryClient, data.data);
        },
      }
    );
  }

  return {
    ...mutation,
    addNoteAsync,
  };
}

function addToInventoryNotes(inventoryNotes: NoteV2[], note: Note) {
  const { sender, note: message, timestamp, attachments } = note ?? {};
  const date = getCalendarDateStringFromDate(new Date(timestamp));
  const time = getFormattedHour(new Date(timestamp));
  const fullName = `${sender?.firstName} ${sender?.lastName}`.trim();
  const newMessage = { message, time, attachments };
  const newNote = { fullName, messages: [newMessage] };
  const newNoteByDate = { date, notes: [newNote] };

  if (!inventoryNotes?.length) {
    inventoryNotes = [newNoteByDate];
  } else {
    let foundDate = false;
    inventoryNotes = inventoryNotes.map((notesByDate) => {
      const { notes = [] } = notesByDate ?? {};

      if (notesByDate.date === date) {
        foundDate = true;
        const lastNote = notes[notes.length - 1];

        if (lastNote.fullName === fullName) {
          lastNote.messages = lastNote.messages?.concat(newMessage);
        } else {
          notesByDate.notes = notes.concat(newNote);
        }
      }
      return notesByDate;
    });

    if (!foundDate) {
      inventoryNotes = inventoryNotes.concat(newNoteByDate);
    }
  }
  return inventoryNotes;
}

function addNoteToQueryCache(
  vehicleId: string | null,
  queryClient: QueryClient,
  note: Note
) {
  queryClient.setQueryData(`/inventory/${vehicleId}/notes`, (data: any) => {
    if (data?.data) {
      data.data = data.data?.concat(note) ?? [note];
    }
    return data;
  });

  queryClient.setQueryData(`/inventory/${vehicleId}`, (data: any) => {
    if (data?.data) {
      data.data.notes = addToInventoryNotes(data.data?.notes, note);
    }
    return data;
  });

  const { queryKey: vehicleListQueryKey } = getVehicleListParams();
  queryClient.setQueryData(vehicleListQueryKey, (data: any) => {
    data?.pages?.map((page: APIResponse<VehicleSummary[]>) => {
      page.data?.map((summary) => {
        if (summary.vehicleCard?.id === vehicleId) {
          summary.notes = addToInventoryNotes(summary?.notes, note);
        }
        return summary;
      });
      return page;
    });
    return data;
  });
}

function deleteNoteFromQueryCache(
  vehicleId: string | null,
  queryClient: QueryClient,
  noteId: string
) {
  const queryKey = `/inventory/${vehicleId}/notes`;
  const cachedNotes: APIResponse<Note[]> | undefined =
    queryClient.getQueryData(queryKey);

  if (cachedNotes) {
    const index = cachedNotes.data.findIndex((n) => n.id === noteId);
    if (index > -1) {
      queryClient.setQueriesData(queryKey, {
        ...cachedNotes,
        data: [
          ...cachedNotes.data.slice(0, index),
          ...cachedNotes.data.slice(index + 1),
        ],
      });
    }
  }
}

function updateNotesQueryCache(
  vehicleId: string | null,
  queryClient: QueryClient,
  note: Note
) {
  const queryKey = `/inventory/${vehicleId}/notes`;
  const cachedNotes: APIResponse<Note[]> | undefined =
    queryClient.getQueryData(queryKey);

  cachedNotes?.data?.some?.((cachedNote, index) => {
    if (cachedNote.id === note.id) {
      const updatedNotes = Object.assign([], cachedNotes.data, {
        [index]: note,
      });
      queryClient.setQueriesData(queryKey, {
        ...cachedNotes,
        data: [...updatedNotes],
      });
      return true;
    }
    return false;
  });
}
