export const permissions = {
  PLUGIN_VELOCITYENGAGE_VIEW_OTHER_USER_ACTIVITY:
    'PLUGIN_VELOCITYENGAGE_VIEW_OTHER_USER_ACTIVITY',
  // ALL PERMISSIONS FOR RV_ADMIN
  DASHBOARD_GOALS_VIEW: 'DASHBOARD_GOALS_VIEW',
  DASHBOARD_INVENTORY_NEEDING_ATTENTION_VIEW:
    'DASHBOARD_INVENTORY_NEEDING_ATTENTION_VIEW',
  DASHBOARD_SUMMARY_VIEW: 'DASHBOARD_SUMMARY_VIEW',
  INVENTORY_COST_VIEW: 'INVENTORY_COST_VIEW',
  INVENTORY_VDP_ATTACHMENTS_CREATE: 'INVENTORY_VDP_ATTACHMENTS_CREATE',
  INVENTORY_VDP_ATTACHMENTS_DELETE: 'INVENTORY_VDP_ATTACHMENTS_DELETE',
  INVENTORY_VDP_ATTACHMENTS_VIEW: 'INVENTORY_VDP_ATTACHMENTS_VIEW',
  INVENTORY_VDP_CREATE: 'INVENTORY_VDP_CREATE',
  INVENTORY_VDP_HISTORY_VIEW: 'INVENTORY_VDP_HISTORY_VIEW',
  INVENTORY_VDP_LOCATION_CREATE: 'INVENTORY_VDP_LOCATION_CREATE',
  INVENTORY_VDP_LOCATION_VIEW: 'INVENTORY_VDP_LOCATION_VIEW',
  INVENTORY_VDP_NOTES_ATTACHMENT_CREATE:
    'INVENTORY_VDP_NOTES_ATTACHMENT_CREATE',
  INVENTORY_VDP_NOTES_ATTACHMENT_DELETE:
    'INVENTORY_VDP_NOTES_ATTACHMENT_DELETE',
  INVENTORY_VDP_NOTES_ATTACHMENT_VIEW: 'INVENTORY_VDP_NOTES_ATTACHMENT_VIEW',
  INVENTORY_VDP_NOTES_CREATE: 'INVENTORY_VDP_NOTES_CREATE',
  INVENTORY_VDP_NOTES_DELETE: 'INVENTORY_VDP_NOTES_DELETE',
  INVENTORY_VDP_NOTES_UPDATE: 'INVENTORY_VDP_NOTES_UPDATE',
  INVENTORY_VDP_NOTES_VIEW: 'INVENTORY_VDP_NOTES_VIEW',
  INVENTORY_VDP_PHOTOS_CREATE: 'INVENTORY_VDP_PHOTOS_CREATE',
  INVENTORY_VDP_PHOTOS_DELETE: 'INVENTORY_VDP_PHOTOS_DELETE',
  INVENTORY_VDP_PHOTOS_UPDATE: 'INVENTORY_VDP_PHOTOS_UPDATE',
  INVENTORY_VDP_PHOTOS_VIEW: 'INVENTORY_VDP_PHOTOS_VIEW',
  INVENTORY_VDP_RECALLS_COMPLETE: 'INVENTORY_VDP_RECALLS_COMPLETE',
  INVENTORY_VDP_RECALLS_VIEW: 'INVENTORY_VDP_RECALLS_VIEW',
  INVENTORY_VDP_RECON_INFO_VIEW: 'INVENTORY_VDP_RECON_INFO_VIEW',
  INVENTORY_VDP_ROS_COMPLETE: 'INVENTORY_VDP_ROS_COMPLETE',
  INVENTORY_VDP_ROS_VIEW: 'INVENTORY_VDP_ROS_VIEW',
  INVENTORY_VDP_TAGS_UPDATE: 'INVENTORY_VDP_TAGS_UPDATE',
  INVENTORY_VDP_TASKS_APPROVE: 'INVENTORY_VDP_TASKS_APPROVE',
  INVENTORY_VDP_TASKS_ATTACHMENT_CREATE:
    'INVENTORY_VDP_TASKS_ATTACHMENT_CREATE',
  INVENTORY_VDP_TASKS_ATTACHMENT_DELETE:
    'INVENTORY_VDP_TASKS_ATTACHMENT_DELETE',
  INVENTORY_VDP_TASKS_ATTACHMENT_VIEW: 'INVENTORY_VDP_TASKS_ATTACHMENT_VIEW',
  INVENTORY_VDP_TASKS_CREATE: 'INVENTORY_VDP_TASKS_CREATE',
  INVENTORY_VDP_TASKS_DELETE: 'INVENTORY_VDP_TASKS_DELETE',
  INVENTORY_VDP_TASKS_LABOR_COST_UPDATE:
    'INVENTORY_VDP_TASKS_LABOR_COST_UPDATE',
  INVENTORY_VDP_TASKS_LABOR_COST_VIEW: 'INVENTORY_VDP_TASKS_LABOR_COST_VIEW',
  INVENTORY_VDP_TASKS_PARTS_COST_UPDATE:
    'INVENTORY_VDP_TASKS_PARTS_COST_UPDATE',
  INVENTORY_VDP_TASKS_PARTS_COST_VIEW: 'INVENTORY_VDP_TASKS_PARTS_COST_VIEW',
  INVENTORY_VDP_TASKS_UPDATE: 'INVENTORY_VDP_TASKS_UPDATE',
  INVENTORY_VDP_TASKS_VIEW: 'INVENTORY_VDP_TASKS_VIEW',
  INVENTORY_VDP_UPDATE: 'INVENTORY_VDP_UPDATE',
  INVENTORY_VDP_VIDEO_CREATE: 'INVENTORY_VDP_VIDEO_CREATE',
  INVENTORY_VDP_VIDEO_DELETE: 'INVENTORY_VDP_VIDEO_DELETE',
  INVENTORY_VDP_VIDEO_UPDATE: 'INVENTORY_VDP_VIDEO_UPDATE',
  INVENTORY_VDP_VIDEO_VIEW: 'INVENTORY_VDP_VIDEO_VIEW',
  INVENTORY_VDP_VIEW: 'INVENTORY_VDP_VIEW',
  INVENTORY_VIEW: 'INVENTORY_VIEW',
  NOTIFICATIONS_DELETE: 'NOTIFICATIONS_DELETE',
  NOTIFICATIONS_UPDATE: 'NOTIFICATIONS_UPDATE',
  NOTIFICATIONS_VIEW: 'NOTIFICATIONS_VIEW',
  PLUGIN_VELOCITYENGAGE_CREATE: 'PLUGIN_VELOCITYENGAGE_CREATE',
  PLUGIN_VELOCITYENGAGE_DELETE: 'PLUGIN_VELOCITYENGAGE_DELETE',
  PLUGIN_VELOCITYENGAGE_UPDATE: 'PLUGIN_VELOCITYENGAGE_UPDATE',
  PLUGIN_VELOCITYENGAGE_VIEW: 'PLUGIN_VELOCITYENGAGE_VIEW',
  PLUGIN_VELOCITYENGAGE_ADMIN: 'PLUGIN_VELOCITYENGAGE_ADMIN',
  PLUGIN_VELOCITYENGAGE_SEND: 'PLUGIN_VELOCITYENGAGE_SEND',
  PLUGIN_VELOCITYINSIGHT_VIEW: 'PLUGIN_VELOCITYINSIGHT_VIEW',
  PLUGIN_VELOCITYLOCATE_CREATE: 'PLUGIN_VELOCITYLOCATE_CREATE',
  PLUGIN_VELOCITYLOCATE_DELETE: 'PLUGIN_VELOCITYLOCATE_DELETE',
  PLUGIN_VELOCITYLOCATE_UPDATE: 'PLUGIN_VELOCITYLOCATE_UPDATE',
  PLUGIN_VELOCITYLOCATE_VIEW: 'PLUGIN_VELOCITYLOCATE_VIEW',
  PLUGIN_VELOCITYWINDOWSTICKER_ADMIN: 'PLUGIN_VELOCITYWINDOWSTICKER_ADMIN',
  PLUGIN_VELOCITYWINDOWSTICKER_VIEW: 'PLUGIN_VELOCITYWINDOWSTICKER_VIEW',
  RECON_ASSIGNMENTS_VIEW: 'RECON_ASSIGNMENTS_VIEW',
  RECON_VDP_CHECKLISTS_ANSWER: 'RECON_VDP_CHECKLISTS_ANSWER', // NEW MPI
  RECON_VDP_CHECKLISTS_ANSWSER: 'RECON_VDP_CHECKLISTS_ANSWSER', // LEGACY MPI
  RECON_VDP_CHECKLISTS_VIEW: 'RECON_VDP_CHECKLISTS_VIEW',
  RECON_VDP_CREATE: 'RECON_VDP_CREATE',
  RECON_VDP_FORCE_COMPLETE_UPDATE: 'RECON_VDP_FORCE_COMPLETE_UPDATE',
  RECON_VDP_REMOVE_FROM_RECON_UPDATE: 'RECON_VDP_REMOVE_FROM_RECON_UPDATE',
  RECON_VDP_UPDATE: 'RECON_VDP_UPDATE',
  RECON_VDP_VIEW: 'RECON_VDP_VIEW',
  RECON_VDP_WORKFLOW_COMPLETE: 'RECON_VDP_WORKFLOW_COMPLETE',
  RECON_VDP_WORKFLOW_EFFORT_UPDATE: 'RECON_VDP_WORKFLOW_EFFORT_UPDATE',
  RECON_VDP_WORKFLOW_STEP_UPDATE: 'RECON_VDP_WORKFLOW_STEP_UPDATE',
  RECON_VIEW: 'RECON_VIEW',
  REPORTS_ASSOCIATE_REPORT_VIEW: 'REPORTS_ASSOCIATE_REPORT_VIEW',
  REPORTS_CORPORATE_REPORT_VIEW: 'REPORTS_CORPORATE_REPORT_VIEW',
  REPORTS_STEP_REPORT_VIEW: 'REPORTS_STEP_REPORT_VIEW',
  TAGS_CREATE: 'TAGS_CREATE',
  TAGS_DELETE: 'TAGS_DELETE',
  TAGS_UPDATE: 'TAGS_UPDATE',
  TAGS_VIEW: 'TAGS_VIEW',
  USERS_SELF_UPDATE: 'USERS_SELF_UPDATE',
  UTILITY_TASK_TEMPLATES_CREATE: 'UTILITY_TASK_TEMPLATES_CREATE',
  UTILITY_TASK_TEMPLATES_DELETE: 'UTILITY_TASK_TEMPLATES_DELETE',
  UTILITY_TASK_TEMPLATES_UPDATE: 'UTILITY_TASK_TEMPLATES_UPDATE',
  UTILITY_TASK_TEMPLATES_VIEW: 'UTILITY_TASK_TEMPLATES_VIEW',
  DEALERSHIP_ADMIN_VIEW: 'DEALERSHIP_ADMIN_VIEW',
  PLUGIN_VELOCITYENGAGE_VIEW_DASHBOARD: 'PLUGIN_VELOCITYENGAGE_VIEW_DASHBOARD',
  PLUGIN_VELOCITYENGAGE_VIEW_SHOWROOM: 'PLUGIN_VELOCITYENGAGE_VIEW_SHOWROOM',
  PLUGIN_VELOCITYENGAGE_CORPORATE_REPORT_VIEW:
    'PLUGIN_VELOCITYENGAGE_CORPORATE_REPORT_VIEW',
} as const;

export default permissions;
