import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Add, FilterList, Sort } from '@material-ui/icons';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import PDFIcon from '@material-ui/icons/PictureAsPdf';

import permissions from 'common/permissions';
import strings from 'common/strings';
import InfoModal from 'components/shared/Alert';
import PermissionsGate from 'components/shared/PermissionsGate';
import MultiDownloadMenu, {
  DownloadOption,
  XLSIcon,
} from 'components/shared/MultiDownloadMenu';
import SendVehicleModal from 'components/shared/SendVehicleModal';
import ApiError from 'components/shared/ApiError';
import AddVehicleModal from './AddVehicleModal';
import SortSelector from './SortSelector';

import './VehicleListHeader.scss';
import VehicleSearch from '../VehicleSearch/VehicleSearch';
import { VehicleSummary } from 'models';
import {
  getLastQueryParamWithName,
  updateUrlSearchParam,
} from '../../../../navigation/util/ParamHelpers';
import {
  getCurrentFilterParamsFromUrl,
  useVehicleList,
  useVehicleListExport,
} from 'api';
import { usePermissions, useWindowSize } from 'hooks';

const hasSearch = (): boolean => !!getLastQueryParamWithName('s');

interface VehicleListHeaderProps {
  isShowroomMode: boolean;
  engageSendToShopperVehicles: VehicleSummary[];
  isSendVehicleModalDisplayed: boolean;
  setIsSendVehicleModalDisplayed: (isDisplayed: boolean) => void;
  setIsOverviewFiltersModalOpen: (isOpen: boolean) => void;
  toggleShowroom: () => void;
}

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface TestProps extends SwitchProps {
  classes: Styles;
}

const ShowroomSwitch = withStyles(() => ({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#1AB245',
    },
    '&$checked + $track': {
      backgroundColor: 'rgba(38, 50, 56, 0.22);',
    },
  },
  track: {
    backgroundColor: 'rgba(38, 50, 56, 0.22);',
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }: TestProps) => (
  <Switch
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    {...props}
  />
));

const VehicleListHeader = (props: VehicleListHeaderProps) => {
  const {
    isShowroomMode,
    engageSendToShopperVehicles,
    isSendVehicleModalDisplayed,
    setIsSendVehicleModalDisplayed,
    setIsOverviewFiltersModalOpen,
    toggleShowroom,
  } = props;
  const windowSize = useWindowSize();
  const [isAddVehicleModalOpen, setIsAddVehicleModalOpen] = useState(false);
  const [isDownloadMenuOpen, setIsDownloadMenuOpen] = useState(false);
  const [isShowroomEngageInfoDisplayed, setIsShowroomEngageInfoDisplayed] =
    useState(false);
  const [searchInputValue, setSearchInputValue] = useState(
    getLastQueryParamWithName('s') || ''
  );
  const {
    data,
    isLoading: isLoadingVehicleList,
    isError: isErrorVehicleListFetch,
    isFetching: isFetchingVehicleList,
  } = useVehicleList();
  const [exportType, setExportType] = useState<'PDF' | 'EXCEL'>('PDF');
  const {
    data: vehicleListExportData,
    isError: isErrorVehicleListExport,
    isLoading: isLoadingVehicleListExport,
    refetch: refetchVehicleListExport,
  } = useVehicleListExport(exportType);

  useEffect(() => {
    if (!isLoadingVehicleList && !hasSearch()) {
      setSearchInputValue('');
    }
  }, [data, isLoadingVehicleList]);

  useEffect(() => {
    if (!isLoadingVehicleListExport && vehicleListExportData?.uri) {
      window.open(vehicleListExportData.uri, '_blank');
    }
  }, [isLoadingVehicleListExport, vehicleListExportData]);
  const { hasPermission } = usePermissions();

  const isEngageUser = hasPermission(permissions.PLUGIN_VELOCITYENGAGE_VIEW);
  const sortDirectionClassName = `VehicleListHeader-sort-dir-icon ${
    getLastQueryParamWithName('sortDirection') === 'ASCENDING'
      ? 'VehicleListHeader-sort-dir-icon-ascending'
      : ''
  }`;
  const onSortDirectionClick = () => {
    const currentDirection =
      getLastQueryParamWithName('sortDirection') || 'DESCENDING';
    const newSortDirection =
      currentDirection === 'DESCENDING' ? 'ASCENDING' : 'DESCENDING';
    updateUrlSearchParam({ sortDirection: newSortDirection });
  };

  function closeAddVehicleModal() {
    setIsAddVehicleModalOpen(false);
  }

  return (
    <>
      <div
        className={`srp-header-row d-flex ${
          isShowroomMode ? 'srp-header-showroom' : ''
        }`}
      >
        <div className="d-flex showroom-and-count">
          <div className="header-content d-flex align-center">
            <div className="vehicle-count">
              {strings.VEHICLE_COUNT(data?.pages?.[0]?.meta?.totalRecords ?? 0)}
            </div>
          </div>
          <div
            className={`showroom-toggle-container d-flex align-center ${
              !isEngageUser ? 'hoverHand' : ''
            }`}
            {...(!isEngageUser && {
              onClick: () =>
                setIsShowroomEngageInfoDisplayed(
                  !isShowroomEngageInfoDisplayed
                ),
            })}
          >
            <ShowroomSwitch
              checked={isShowroomMode}
              onChange={toggleShowroom}
              name="checkedShowroom"
              disabled={!isEngageUser}
              inputProps={{ 'aria-label': strings.SHOWROOM_MODE }}
            />
            <div className="showroom-label">{strings.SHOWROOM_MODE}</div>
          </div>
        </div>
        <VehicleSearch
          searchInputValue={searchInputValue}
          setSearchInputValue={setSearchInputValue}
        />
        <div className="d-flex align-center responsive-col">
          <div className="d-flex Listheader-responsive-right">
            {!(windowSize.width >= 1024) && (
              <FilterList
                className={`VehicleListHeader-filter-icon
                ${
                  getCurrentFilterParamsFromUrl() ||
                  getLastQueryParamWithName('s')
                    ? 'VehicleListHeader-filter-icon-active'
                    : ''
                }`}
                onClick={() => setIsOverviewFiltersModalOpen(true)}
              />
            )}
            <PermissionsGate permissions={[permissions.INVENTORY_VDP_CREATE]}>
              <div
                className={`rounded ${isEngageUser ? 'header-content' : ''}`}
              >
                {!isShowroomMode && (
                  <Button
                    className="VehicleSearch-add-vehicle-button"
                    variant="outline-dark"
                    size="sm"
                    block
                    onClick={() => {
                      setIsAddVehicleModalOpen(true);
                    }}
                  >
                    <span className="add-vehicle-text">
                      {strings.ADD_VEHICLE.toUpperCase()}
                    </span>
                    <Add className="add-icon" />
                  </Button>
                )}
              </div>
            </PermissionsGate>
          </div>
          <div className="d-flex align-items-center">
            <SortSelector />
            <Sort
              className={sortDirectionClassName}
              onClick={onSortDirectionClick}
            />
            {!!data?.pages?.[0]?.meta?.totalRecords &&
              !isShowroomMode &&
              !isFetchingVehicleList && (
                <PermissionsGate permissions={[permissions.INVENTORY_VIEW]}>
                  <MultiDownloadMenu
                    onClick={() => setIsDownloadMenuOpen(!isDownloadMenuOpen)}
                    setShow={(value) => setIsDownloadMenuOpen(value)}
                    showMenu={isDownloadMenuOpen}
                    isFetchingDownload={isLoadingVehicleListExport}
                  >
                    <DownloadOption
                      title={strings.DOWNLOAD_PDF}
                      Icon={PDFIcon}
                      onClick={() => {
                        setExportType('PDF');
                        refetchVehicleListExport();
                      }}
                    />
                    <DownloadOption
                      title={strings.DOWNLOAD_XLS}
                      Icon={XLSIcon}
                      onClick={() => {
                        setExportType('EXCEL');
                        refetchVehicleListExport();
                      }}
                    />
                  </MultiDownloadMenu>
                </PermissionsGate>
              )}
          </div>
        </div>
        {isAddVehicleModalOpen && (
          <div className="VehicleList-add-vehicle-modal-container">
            <AddVehicleModal
              onClose={closeAddVehicleModal}
              setSearchInputValue={setSearchInputValue}
            />
          </div>
        )}
      </div>
      <PermissionsGate permissions={[permissions.PLUGIN_VELOCITYENGAGE_CREATE]}>
        {isSendVehicleModalDisplayed && (
          <SendVehicleModal
            selectedVehicleList={engageSendToShopperVehicles}
            open={isSendVehicleModalDisplayed}
            onClose={() => setIsSendVehicleModalDisplayed(false)}
          />
        )}
      </PermissionsGate>
      <InfoModal
        open={isShowroomEngageInfoDisplayed}
        className="ShowroomEngageInfo"
        contentProps={{
          message: strings.SHOWROOM_ENGAGE_ADVERT,
          variant: 'info',
          onClose: () =>
            setIsShowroomEngageInfoDisplayed(!isShowroomEngageInfoDisplayed),
        }}
        handleClose={() =>
          setIsShowroomEngageInfoDisplayed(!isShowroomEngageInfoDisplayed)
        }
      />
      {(isErrorVehicleListFetch || isErrorVehicleListExport) && <ApiError />}
    </>
  );
};

export default VehicleListHeader;
