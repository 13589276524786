import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import './AlertContent.scss';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

export interface AlertContentProps {
  className?: string;
  message?: React.ReactNode;
  onClose?: () => void;
  variant: keyof typeof variantIcon;
}

var AlertContent = (props: AlertContentProps) => {
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={`${variant} ${className}`}
      aria-describedby="client-snackbar"
      message={
        <span className="message">
          <Icon className="icon icon-variant" />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className="icon" />
        </IconButton>,
      ]}
      {...other}
    />
  );
};

export default AlertContent;
