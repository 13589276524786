import { useCurrentLocation } from 'api';
import features from 'common/features';
import { useMemo } from 'react';
export type Feature = keyof typeof features;
export function useFeatures() {
  const currentLocation = useCurrentLocation();

  return useMemo(() => {
    // fix after solution for VAS-4964
    const featureFlags = currentLocation?.featureFlags;
    const currentFeatures = featureFlags ?? [];
    function hasFeature(checkedFeature: Feature) {
      return currentFeatures.includes(checkedFeature);
    }
    function hasFeatures(checkedFeatures: Feature[]) {
      return checkedFeatures.every(hasFeature);
    }
    return { hasFeature, hasFeatures };
  }, [currentLocation?.featureFlags]);
}
