import React from 'react';
import { Container, Row, Col, Table, ProgressBar } from 'react-bootstrap';

import LoadingIndicator from 'components/shared/LoadingIndicator';
import strings from 'common/strings';
import images from 'common/images';
import { getFormattedDurationFromSeconds } from 'utils/time';
import { truncateEllipsisMiddle } from 'utils/string';
import { useVehicleWorkflow } from 'api/inventory/workflows';
import { StepItem } from 'models';

import './StepProgressSection.scss';
import { testIds } from 'common/testIds';
import { VehicleSummary } from 'models';

interface StepProgressSectionProps {
  vehicleSummary: VehicleSummary;
}

const DEFAULT_MAX_STEP_NAME_LENGTH = 28;

const StepProgressSection: React.FC<StepProgressSectionProps> = ({
  vehicleSummary,
}) => {
  const { data: workflow, isLoading } = useVehicleWorkflow(
    vehicleSummary.vehicleCard.id
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }
  const flatList: StepItem[] = [];
  workflow?.data.forEach((item: StepItem) => {
    flatList.push(item);
    if (item.childSteps && item.childSteps?.length > 0) {
      item.childSteps.forEach((child: StepItem) => {
        child.level = (item?.level || 0) + 1;
        flatList.push(child);
      });
    }
  });
  return (
    <Container
      fluid
      className="StepProgressSection"
      data-vas-testing={testIds.RECON_STEP_PROGRESS_SECTION_CONTAINER}
    >
      <Row noGutters className="StepProgressSection-title-container">
        <Col className="StepProgressSection-title">{strings.STEP_PROGRESS}</Col>
      </Row>
      <div className="d-flex">
        <Table className="StepProgressSection-table step-name-table">
          <thead>
            <tr className="StepProgressSection-table-header-row">
              <th>{strings.STEP}</th>
            </tr>
          </thead>
          <tbody>
            {flatList.map((step: StepItem) => {
              const statusName =
                step.state === 'COMPLETE'
                  ? strings.COMPLETE
                  : step.state === 'ACTIVE'
                  ? strings.ACTIVE
                  : strings.INCOMPLETE;

              const stepTitleClassName = [
                'StepProgressSection-step-title',
                'd-flex',
                'align-items-center',
                `padding-${step.level}`,
                step.state === 'ACTIVE'
                  ? 'StepProgressSection-step-title-active'
                  : '',
              ].join(' ');

              const maxStepNameLength =
                DEFAULT_MAX_STEP_NAME_LENGTH - (step.level || 0) * 3;
              return (
                <tr
                  key={`step-progress-section-row-${step.id}`}
                  className="StepProgressSection-table-data-row"
                >
                  <td className={stepTitleClassName}>
                    <div>
                      {statusName === 'Active' && (
                        <img
                          src={images.AlarmIcon}
                          alt="Alarm icon"
                          className="alarm-icon"
                        />
                      )}
                      {truncateEllipsisMiddle(step.name, maxStepNameLength)}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Table responsive className="StepProgressSection-table">
          <thead>
            <tr className="StepProgressSection-table-header-row">
              <th>{strings.PROGRESS}</th>
              <th>{strings.STATUS}</th>
              <th>{strings.RECON_TIME_IN_STEP}</th>
            </tr>
          </thead>
          <tbody>
            {flatList.map((step: StepItem) => {
              const showProgressBar =
                step.childSteps && step.childSteps.length === 0;
              const statusName =
                step.state === 'COMPLETE'
                  ? strings.COMPLETE
                  : step.state === 'ACTIVE'
                  ? strings.ACTIVE
                  : strings.INCOMPLETE;
              const percentage =
                ((step.reconTimeInStepSeconds || 0) /
                  (step.recommendedDurationSeconds || 0)) *
                100;
              const variant =
                (step.reconTimeInStepSeconds || 0) <
                (step.safeDurationSeconds || 0)
                  ? 'success'
                  : (step.reconTimeInStepSeconds || 0) <
                    (step.recommendedDurationSeconds || 0)
                  ? 'warning'
                  : 'danger';

              return (
                <tr
                  key={`step-progress-section-row-${step.id}`}
                  className="StepProgressSection-table-data-row"
                >
                  <td className="StepProgressSection-progress-bar-container">
                    <div>
                      {showProgressBar && (
                        <ProgressBar
                          className="StepProgressSection-progress-bar"
                          variant={variant}
                          now={percentage}
                        />
                      )}
                    </div>
                  </td>
                  <td className={`${statusName.toLowerCase()}`}>
                    <div>{statusName}</div>
                  </td>
                  <td>
                    <div>
                      {getFormattedDurationFromSeconds(
                        step.reconTimeInStepSeconds || 0
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </Container>
  );
};

export default StepProgressSection;
