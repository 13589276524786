import React, { useContext, useState } from 'react';
import { PhotoLibrary } from '@material-ui/icons';
import { Card } from 'react-bootstrap';
import { usePermissions, useWindowSize } from 'hooks';
import { VehicleCardTabType } from 'models';
import VehicleCardDesktop from './VehicleCardDesktop';
import VehicleCardMobile from './VehicleCardMobile';
import './VehicleCard.scss';
import { VehicleSummary } from 'models';
import { useEffect } from 'react';
import { permissions } from 'common';

interface VehicleCardContextData {
  vehicle?: VehicleSummary;
  inExtensionCardMode: boolean;
  isOnboarding: boolean;
  isShowroomMode: boolean;
  position: number;
  forceShowEngageTab: boolean;
  currentTab?: VehicleCardTabType;
  setCurrentTab?: (t: VehicleCardTabType) => void;
}

export const VehicleCardContext = React.createContext<VehicleCardContextData>({
  inExtensionCardMode: false,
  isOnboarding: false,
  isShowroomMode: false,
  position: 0,
  forceShowEngageTab: false,
  currentTab: 'engage',
});

interface WrapConditionalContainerProps {
  children: React.ReactNode;
  toggleIsModalGalleryOpen: () => void;
}

export const WrapImagePreviewForShowroom = ({
  children,
  toggleIsModalGalleryOpen,
}: WrapConditionalContainerProps) => {
  const { vehicle, isShowroomMode } = useContext(VehicleCardContext);
  const vehicleImagesCount = vehicle?.vehicleCard?.imageCarousel?.length || 0;

  if (!isShowroomMode || !vehicle) {
    return <>{children}</>;
  }

  const runOnClickIfImages = () => {
    if (vehicleImagesCount > 0) toggleIsModalGalleryOpen();
  };

  return (
    <div onClick={runOnClickIfImages} role="button" tabIndex={0}>
      {children}
      {vehicleImagesCount > 0 && (
        <div className="vehicle-image-count">
          {vehicleImagesCount || ''}
          <PhotoLibrary />
        </div>
      )}
    </div>
  );
};

interface VehicleCardSizeObj {
  className: string;
  Component: any;
}

interface VehicleCardSizes {
  desktop: VehicleCardSizeObj;
  mobile: VehicleCardSizeObj;
}

const vehicleCardSizes: VehicleCardSizes = {
  desktop: {
    className: 'VehicleCardDesktop',
    Component: VehicleCardDesktop,
  },
  mobile: {
    className: 'VehicleCardMobile',
    Component: VehicleCardMobile,
  },
};
interface GetInitialTabParameters {
  vehicleIsInRecon: boolean;
  hasReconViewPermission: boolean;
  hasVelocityEngageViewPermission: boolean;
}
function getInitialTab({
  vehicleIsInRecon,
  hasReconViewPermission,
  hasVelocityEngageViewPermission,
}: GetInitialTabParameters): VehicleCardTabType {
  let initialTab: VehicleCardTabType = 'recon';
  if (hasVelocityEngageViewPermission) {
    initialTab = 'engage';
    if (hasReconViewPermission) {
      initialTab = 'recon';
    }
  } else if (hasReconViewPermission) {
    if (!vehicleIsInRecon) {
      initialTab = 'inventory';
    } else {
      initialTab = 'recon';
    }
  } else {
    initialTab = 'inventory';
  }
  return initialTab;
}

function useBaseSize(isShowroomMode: boolean): 'mobile' | 'desktop' {
  const windowSize = useWindowSize();

  if (isShowroomMode && windowSize.width <= 1401) {
    return 'mobile';
  }
  if (windowSize.width >= 1350) {
    return 'desktop';
  }
  return 'mobile';
}

export interface VehicleCardConfigProp {
  onVehicleCardEngageSendClick: () => void;
  onViewCVDPClick: () => void;
}

interface VehicleCardProps {
  contextData: {
    vehicle?: VehicleSummary;
    inExtensionCardMode: boolean;
    isOnboarding: boolean;
    isShowroomMode: boolean;
    position: number;
    forceShowEngageTab: boolean;
  };
  config: VehicleCardConfigProp;
  height?: number | string;
  onPreviewVehicle: () => void;
  isPreviewed: boolean;
}

const VehicleCard = ({
  height,
  onPreviewVehicle,
  isPreviewed,
  contextData,
  config,
}: VehicleCardProps) => {
  const [currentTab, setCurrentTab] = useState<VehicleCardTabType>('recon');
  const style = height ? { height, maxHeight: height } : {};
  const { vehicle, isShowroomMode } = contextData;

  const vehicleIsInRecon: boolean | undefined =
    vehicle && vehicle?.vehicleCard.inRecon;
  const { hasPermission } = usePermissions();
  const hasReconViewPermission = hasPermission(permissions.RECON_VIEW);
  const hasVelocityEngageViewPermission = hasPermission(
    permissions.PLUGIN_VELOCITYENGAGE_VIEW
  );
  useEffect(() => {
    if (vehicleIsInRecon !== undefined) {
      setCurrentTab(
        getInitialTab({
          vehicleIsInRecon,
          hasReconViewPermission,
          hasVelocityEngageViewPermission,
        })
      );
    }
  }, [
    vehicleIsInRecon,
    setCurrentTab,
    hasReconViewPermission,
    hasVelocityEngageViewPermission,
  ]);

  const baseSize = useBaseSize(isShowroomMode);
  const soldClassName = vehicle?.vehicleCard.sold ? 'VehicleCard-sold' : '';
  const status = vehicle?.stepItem?.status;
  const statusClassName = status ? `status-${status}` : '';

  const extraClasses = `${soldClassName} ${statusClassName} ${vehicleCardSizes[baseSize].className}`;

  const VehicleCardContentComponent =
    vehicleCardSizes[baseSize]?.Component || VehicleCardDesktop;

  return (
    <VehicleCardContext.Provider
      value={{
        ...contextData,
        currentTab,
        setCurrentTab,
      }}
    >
      <Card
        id={`vehicleCard-${contextData.position}`}
        className={`VehicleCard2 VehicleCard-mode-inventory ${extraClasses}`}
        onClick={onPreviewVehicle}
      >
        <VehicleCardContentComponent style={style} config={config} />
        {isPreviewed && <div className="VehicleCard2-selected" />}
      </Card>
    </VehicleCardContext.Provider>
  );
};

export default VehicleCard;
