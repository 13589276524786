const features = {
  BIG_FILE_UPLOAD: 'BIG_FILE_UPLOAD',
  DEALER_SETTINGS: 'DEALER_SETTINGS',
  DEALERSHIP_ADMIN: 'DEALERSHIP_ADMIN',
  UI_PREVIEW: 'UI_PREVIEW',
  UI_PREVIEW_TASKS: 'UI_PREVIEW_TASKS',
  VELOCITY_ENGAGE_CORPORATE_REPORT: 'VELOCITY_ENGAGE_CORPORATE_REPORT',
  WINDOW_STICKER_PREVIEW: 'WINDOW_STICKER_PREVIEW',
  VELOCITY_ENGAGE_MODULE_REPORT: 'VELOCITY_ENGAGE_MODULE_REPORT',
} as const;

export default features;
