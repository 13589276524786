import { navigate } from '@reach/router';
import { removeCredentialValue } from 'api/lib/localStorageManagement';
import { useMutation, useQueryClient } from 'react-query';

export const logoutActions = () => {
  removeCredentialValue('authBearerToken');
  removeCredentialValue('authBearerTokenExpiration');
  removeCredentialValue('authRefreshToken');
  navigate('/');
};

export function useLogoutMutation() {
  const queryClient = useQueryClient();
  return useMutation(async () => {
    logoutActions();
    queryClient.clear();
  });
}
