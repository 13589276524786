import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { v4 as uuid } from 'uuid';
import { isUndefined } from 'lodash';
import TagManager from 'react-gtm-module';
import { AjaxAppService, useAppService } from 'reconvelocity-data';

import 'typeface-open-sans';
import 'typeface-source-sans-pro';
import './scss/App.scss';

import App from 'components/App';
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';
import { notificationPermissions } from 'utils/push-notifications';
import { inExtensionMode, isExtensionModeSupported } from 'utils/extension';

import * as Telemetry from './utils/telemetry';
import { getItem as getItemFromExtension } from './utils/storage/ExtensionStorage';
import './index.scss';
import { saveCredentials } from 'api/lib/localStorageManagement';

const version = `${process.env.REACT_APP_VERSION}`;

export type EnvironmentName = 'production' | 'staging' | 'demo' | 'dev' | 'qa';

declare global {
  interface Window {
    recon: any;
    service: any;
  }
}

async function extractCredentialsFromExtension(
  sourceId: string
): Promise<void> {
  const [authBearerToken, authBearerTokenExpiration, authRefreshToken] =
    await Promise.all([
      (async () =>
        (await getItemFromExtension(sourceId, 'token')) ||
        (await getItemFromExtension(sourceId, 'authBearerToken')) ||
        undefined)(),
      (async () =>
        (await getItemFromExtension(sourceId, 'expirationDate')) ||
        (await getItemFromExtension(sourceId, 'authBearerTokenExpiration')) ||
        undefined)(),
      (async () =>
        (await getItemFromExtension(sourceId, 'refreshToken')) ||
        (await getItemFromExtension(sourceId, 'authRefreshToken')) ||
        undefined)(),
    ]);
  saveCredentials({
    authBearerToken,
    authBearerTokenExpiration,
    authRefreshToken,
  });
}

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_CONTAINER_ID || '',
};

if (tagManagerArgs.gtmId) TagManager.initialize(tagManagerArgs);

const render = (AppComponent: any) => {
  ReactDOM.render(<AppComponent />, document.getElementById('root'));
};

async function start() {
  const environmentName =
    (process.env.REACT_APP_RECONVELOCITY_APPSERVICE_ENV?.toLowerCase() ||
      'dev') as EnvironmentName;
  const hostnameOverride =
    process.env.REACT_APP_RECONVELOCITY_APPSERVICE_URL_OVERRIDE;

  const urlParams = new URLSearchParams(window.location.search);
  const source = urlParams.get('source') || '';
  const sourceId = urlParams.get('sourceId') || '';
  const sourceVersion = urlParams.get('sourceVersion') || '';

  if (
    inExtensionMode(source, sourceId) &&
    isExtensionModeSupported(sourceVersion, 'extension_storage')
  ) {
    await extractCredentialsFromExtension(sourceId);
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const service = useAppService(
    new AjaxAppService(window.localStorage, environmentName, hostnameOverride)
  ) as AjaxAppService;
  window.service = service;

  const sessionExpirySecondsString =
    process.env.REACT_APP_SESSION_EXPIRY_SECONDS;
  const sessionExpirySeconds = isUndefined(sessionExpirySecondsString)
    ? undefined
    : parseInt(sessionExpirySecondsString, 10);
  const apiVersion = '1.4';

  let clientType: 'WEB' | 'BROWSER-EXTENSION' = 'WEB';
  let appVersion = version;

  if (inExtensionMode(source, sourceId) && sourceVersion) {
    clientType = 'BROWSER-EXTENSION';
    appVersion = sourceVersion;
  }

  // initialRooftopId overrides rooftop associated to bearer token
  const pathname = window.location?.pathname;
  const initialRooftopId = pathname.split('/')[1];

  await service.initialize({
    apiVersion,
    appVersion,
    clientType,
    initialRooftopId,
    onForceUpgrade: () => {
      // window.location.reload();
      console.warn('426 should be fired');
      return false;
    },
    sessionExpirySeconds,
    sessionAutoRefresh:
      inExtensionMode(source, sourceId) &&
      isExtensionModeSupported(sourceVersion, 'auto_refresh'),
    uuidGenerator: uuid,
  });

  const readableVersionSummary = `Velocity Automotive Web-${version}`;
  // eslint-disable-next-line no-console
  console.info(readableVersionSummary);
  if (window) {
    window.recon = {
      version,
      versionSummary: readableVersionSummary,
      gtmInit: false,
    };
  }

  // Google Analytics
  if (process.env.REACT_APP_GOOGLE_TRACKING_ID) {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID);
    if (window.recon) window.recon.gtmInit = true;
  }

  await notificationPermissions();

  render(App);
}

// should be called as early as possible, at least before any XHRs
Telemetry.init();

// asynchronously start up the app proper
start();

// To prevent tree-shaking from removing the schedule code.
// console.log(schedule);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
