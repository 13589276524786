import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { TabProps } from 'components/shared/Tabs/Tabs';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { testIds } from 'common/testIds';
import { useDashboardHighlights } from 'api';
import MonthlyReviewGraph from '../MonthlyReviewGraph/MonthlyReviewGraph';
import MonthlyReviewCard from '../MonthlyReviewCard/MonthlyReviewCard';
import ActiveReconSummary from '../ActiveReconSummary/ActiveReconSummary';

const HighlightsTab: React.FC<TabProps> = () => {
  const { data: { data: dashboardHighlights } = {}, isLoading } =
    useDashboardHighlights();
  var LoadingDisplay = ({ type }: { type: string }) => {
    return (
      <>
        <div className="Dashboard-highlight-title-skeleton" />
        <div className={`Dashboard-highlight-${type}-skeleton`}>
          <LoadingIndicator />
        </div>
      </>
    );
  };
  return (
    <Container className="highlights-grid vertical-scroll" fluid>
      <Row>
        <Col xl="4" md="12">
          <Row>
            <Col
              xl="12"
              md="6"
              className="highlights-monthly-review-col-container"
            >
              <div className="highlights-row-container">
                {!isLoading && dashboardHighlights?.vehiclesInRecon ? (
                  <MonthlyReviewGraph
                    data={dashboardHighlights.vehiclesInRecon}
                  />
                ) : (
                  <LoadingDisplay type="graph" />
                )}
              </div>
            </Col>
            <Col
              xl="12"
              md="6"
              className="highlights-monthly-review-col-container"
            >
              <div className="highlights-row-container">
                {!isLoading && dashboardHighlights?.averageRetailReadyTime ? (
                  <MonthlyReviewCard
                    cardTitle="Retail-Ready Time"
                    reviewCard={dashboardHighlights.averageRetailReadyTime}
                  />
                ) : (
                  <LoadingDisplay type="card" />
                )}
              </div>
              <div className="highlights-row-container">
                {!isLoading && dashboardHighlights?.averageReconTime ? (
                  <MonthlyReviewCard
                    cardTitle="Average-Recon Time"
                    reviewCard={dashboardHighlights.averageReconTime}
                  />
                ) : (
                  <LoadingDisplay type="card" />
                )}
              </div>
            </Col>
          </Row>
        </Col>
        <Col
          xl="8"
          md="12"
          className="active-recon-summary-col-container"
          data-vas-testing={testIds.HIGHLIGHTS_ACTIVE_RECON_CONTAINER}
        >
          <div className="highlights-row-container">
            {!isLoading && dashboardHighlights?.activeReconSummary ? (
              <>
                <ActiveReconSummary
                  items={dashboardHighlights.activeReconSummary}
                />
              </>
            ) : (
              <LoadingDisplay type="card" />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default HighlightsTab;
